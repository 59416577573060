import {useImperativeHandle, useEffect, useState,forwardRef, useRef } from 'react';
import GraphComponent from './GraphComponent';
import QRGridLayout from './QRGridLayout';

//
const ControlledStack = forwardRef(({ events, developer, cascadeRefresh, 
	dashboardBackgroundColor, stackProps, notifyContainer=()=>null }, ref) => {
	useImperativeHandle(ref, () => ({
		resetAll() {
			if (gridRef && gridRef.current) {
				gridRef.current.resetAll();
			}
		},
		saveProps() {
			return{
				resizable: true,
				draggable: true,
				gridProps: gridRef.current.saveProps(),
			}
		},
		getOuterId() {
			if (gridRef && gridRef.current) {
				return gridRef.current.getOuterId();
			} else {
				return '';
			}
		},
		getContainerPropsOnly() {
			if (gridRef && gridRef.current)
				return gridRef.current.getContainerPropsOnly();
			else
				return {};
		},
		getAxisPropsOnly() {
			if (gridRef && gridRef.current)
				return gridRef.current.getAxisPropsOnly();
			else
				return {};
		},
		getBasePropsOnly() {
			if (gridRef && gridRef.current)
				return gridRef.current.getBasePropsOnly();
			else
				return {};
		},
		getPalettePropsOnly() {
			if (gridRef && gridRef.current)
				return gridRef.current.getPalettePropsOnly();
			else
				return {};
		},
		toggleSelectedEditMode() {
			gridRef.current.toggleSelectedEditMode();
		},
		getSelectedIsEditing() {
			return gridRef.current.getSelectedIsEditing();
		},
		useImagesAsFill() {
			gridRef.current.useImagesAsFill();
		},
		setGridSize(width, height) {
			if (gridRef.current) {
				gridRef.current.setGridSize(width, height);
			}
		},
		getPivot() {
			if (gridRef.current) {
				return gridRef.current.getPivot();
			} else {
				return  false;
			}
		},
		getCellBackgroundColor() {
			if (gridRef.current) {
				return gridRef.current.getCellBackgroundColor();
			}
		},
		setCellBackgroundColor(color) {
			if (gridRef.current) {
				gridRef.current.setCellBackgroundColor(color);
			} else {
				return '';
			}
		},
		applyThemes(item) {
			if (gridRef.current) {
				gridRef.current.applyThemes(item);
			}
		},
		getProperty(arProps) {
			if (gridRef.current) {
				return gridRef.current.getProperty(arProps);
			} else {
				return {};
			}
		},
		setProperty(hasNV) {
			if (gridRef.current) {
				gridRef.current.setProperty(hasNV);
			}
		},
		setSelectedItem(ix) {
			if (gridRef.current) {
				gridRef.current.setSelectedItem(ix);
			}
		},
		setItemSize(ix, size) {
			let gProps = [...graphComponents.gProps];
			gProps[ix].size.width = size.width;
			gProps[ix].size.height = size.height;
			gProps[ix].manualChange = true;
			setGraphComponents({ comps: graphComponents.comps, gProps: gProps });
			size.x = gProps[ix].size.x;
			size.y = gProps[ix].size.y;
			if (gridRef.current) {
				gridRef.current.manualSizeChange(ix, size);
			}
		},
		setItemLocation(ix, size) {
			let gProps = [...graphComponents.gProps];
			gProps[ix].size = size;
			gProps[ix].manualChange = true;
			setGraphComponents({ comps: graphComponents.comps, gProps: gProps });
			if (gridRef.current) {
				gridRef.current.manualSizeChange(ix, size);
			}
		},
		autoLayout() {
			if (gridRef.current) {
				gridRef.current.autoLayout();
			}
		},
		isDataEmpty() {
			if (gridRef.current) {
				return gridRef.current.isDataEmpty();
			} else {
				return false;
			}
		},
		generateSampleData() {
			if (gridRef.current) {
				gridRef.current.generateSampleData();
			}
	
		}

	}));
	const [graphComponents, setGraphComponents] = useState(null);
	const [localRefresh, setLocalRefresh] = useState(0);


	// if we have graphComponents already and are getting an update, we are either adding or removing a graphComponent
	// look at graphComponents.gProps[x]._uid and compare to stackProps.gridProps[y]._uid
	// Keep the ones in gProps that are in stackProps.gridProps[i]. add or remove as needed
	useEffect(() => {
		if (stackProps.gridProps) {
			let comps, gProps;
			if (graphComponents && graphComponents.gProps) {	// comps and gProps are parallel arrays
				const gpIds = graphComponents.gProps.map((gp) => gp._uid);
				const spIds = stackProps.gridProps.map((sp) => sp._uid);
				const toRemove = gpIds.filter((gpId) => !spIds.includes(gpId));
				const toAdd = spIds.filter((spId) => !gpIds.includes(spId));
				if (toRemove.length === 0 && toAdd.length === 0) {
					// We may have changed stackProps.gridProps[i].saveProps.graphType - which should match
					// graphComponents.comps[i].props.savedProps.graphType.  So, if one doesn't match, then the user changed
					// graphTypes, so we have to refresh. do we pass graphType to GraphComponent?
					// Loop through graphComponents.comps[i].props.savedProps.graphType.  Find _uid in gridProps that matches
					// compare graphType.  If different, then change props.savedProps.graphType.
					//TODO: update pivot to match graphType selection (sunburst, treemap = false, others OK)
				
					let newComps = [...graphComponents.comps];
					let newGProps = [...graphComponents.gProps];
					let toSet = false;
					for (let i = 0; i < graphComponents.gProps.length; i++) {
						const gcmp = graphComponents.gProps[i];		// _uid is on gProps, not comps (they are parallel tho)
						const sp = stackProps.gridProps.find((sp) => sp._uid === gcmp._uid);
						if (sp && ((sp.saveProps.graphType !== graphComponents.comps[i].props.graphType) || 
											 (sp.saveProps.pivot !== graphComponents.comps[i].props.pivot))) {
							// remove newComps[i] and replace it with a new one.
							// If we keep the 'old' _uid, then the graph will be the same size, but the graphType will change (meaning it won't jump around)
							// however, this causes issues in GraphComponent because the data/myGraph are out of sync...
							let newUid = newGProps[i]._uid;		// try to keep old one? genCleanId(); or new? newGProps[i]._uid
							newGProps[i]._uid = newUid;
							newComps.splice(i, 1);
							newComps.splice(i, 0,
								<GraphComponent
									key={newUid}
									baseId={gcmp.baseId}
									developer={developer}
									projectId={gcmp.projectId}
									savedProps={sp.saveProps}
									graphType={sp.saveProps.graphType}
									srcType={gcmp.leafType}
									pivot={sp.saveProps.pivot}
								/>
							)
							toSet = true;
						}
					}
					if (toSet) {
						setLocalRefresh(localRefresh+1);
						setGraphComponents({ comps: newComps, gProps: newGProps });
					}

					return;
				}
				let gPropsIx = [];
				if (toRemove.length > 0) {
					for (let i = 0; i < graphComponents.gProps.length; i++) {
						if (toRemove.includes(graphComponents.gProps[i]._uid)) {
							gPropsIx.push(i);
						}
					}
				}
				gProps = [...graphComponents.gProps];
				comps = [...graphComponents.comps];
				for (let i = gPropsIx.length - 1; i >= 0; i--) {
					gProps.splice(gPropsIx[i], 1);
					comps.splice(gPropsIx[i], 1);
				}
				// now, add new ones
				for (let i = 0; i < toAdd.length; i++) {
					const ix = stackProps.gridProps.findIndex((sp) => sp._uid === toAdd[i]);
					const gridProp = stackProps.gridProps[ix];
					const savedProps = gridProp.saveProps;
					gProps.push({
						_uid: gridProp._uid,
						baseId: gridProp.baseId,
						manualChange: gridProp.manualChange,
						size: gridProp.size,
						leafType: gridProp.leafType || 'assignment',
						lockAspectRatio: gridProp.lockAspectRatio,
						projectId: gridProp.projectId,
					});
					comps.push(
						<GraphComponent
							key={gridProp._uid}
							baseId={gridProp.baseId}
							developer={developer}
							projectId={gridProp.projectId}
							savedProps={savedProps}
							graphType={savedProps.graphType}
							srcType={gridProp.leafType || 'assignment'}
							pivot={savedProps.pivot}
						/>
					)
				}
			} else {		// original...

				gProps = stackProps.gridProps ? stackProps.gridProps.map((gridProp) => {
					return {
						_uid: gridProp._uid,
						baseId: gridProp.baseId,
						manualChange: gridProp.manualChange,
						size: gridProp.size,
						leafType: gridProp.leafType || 'assignment',
						lockAspectRatio: gridProp.lockAspectRatio,
						projectId: gridProp.projectId,
					}
				}) : [];

				comps = gProps.map((gp, i) => {
					const gridProp = stackProps.gridProps[i];
					const savedProps = gridProp.saveProps;
					return (
						<GraphComponent
							key={gp._uid}
							baseId={gp.baseId}
							developer={developer}
							projectId={gp.projectId}
							savedProps={savedProps}
							graphType={savedProps.graphType}
							srcType={gridProp.leafType || 'assignment'}
							pivot={savedProps.pivot}
						/>
					)
				})
			}

			setGraphComponents({ comps: comps, gProps: gProps });

		}

	}, [stackProps.gridProps]);

	const myNotifyContainer = (msg) => {
		if (msg.type === 'resize') {	// index,  size (in percentages, caller converted them)
			let gProps = [...graphComponents.gProps];
			gProps[msg.index].size = msg.size;
			setGraphComponents({ comps: graphComponents.comps, gProps: gProps });
			notifyContainer({ type: 'resize', index: msg.index, size: msg.size });
		} else {
			notifyContainer(msg);
		}

	}
	
	const gridRef = useRef();

	// TODO: Move Graph component into array and pass it https://react.dev/reference/react/Children#alternatives
	if (1 /*graphComponents*/) {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<QRGridLayout
					style={{ backgroundColor: dashboardBackgroundColor }}
					ref={gridRef}
					resizable={stackProps.resizable}
					draggable={stackProps.draggable}
					gridProps={graphComponents ? graphComponents.gProps : []}
					developer={developer}
					cascadeRefresh={cascadeRefresh+localRefresh}
					events={events}
					graphComponents={graphComponents ? graphComponents.comps : []}
					notifyContainer={myNotifyContainer}
				>
				</QRGridLayout>
			</div>
		)
	} else {
		return null;
	}
}
)

export default ControlledStack;