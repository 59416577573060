export const getAPIDashboard = /* GraphQL */ `
  query GetAPIDashboard($id: ID!) {
    getAPIDashboard(id: $id) {
      id
      sub
      clientID
      username
      name
      baseIDs
      projectIDs
      props
      groupR
      groupCRUD
      recordStatus
      createdAt
      updatedAt
    }
  }
`;
export const apiDashboardBySub = /* GraphQL */ `
  query ApiDashboardBySub(
    $sub: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAPIDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiDashboardBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        clientID
        username
        name
        baseIDs
        projectIDs
        props
        groupR
        groupCRUD
        recordStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const apiDashboardByClient = /* GraphQL */ `
  query ApiDashboardByClient(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAPIDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiDashboardByClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        clientID
        username
        name
        baseIDs
        projectIDs
        props
        groupR
        groupCRUD
        recordStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectByClient = /* GraphQL */ `
  query ProjectByClient(
    $clientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByClient(
      clientID: $clientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        name
        abbreviation
        tags
        description
        recordStatus
        groupR
        groupCRUD
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignByClient = /* GraphQL */ `
  query CampaignByClient(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        clientID
        name
        abbreviation
        tags
        disposition
        schedule
        description
        settings
        startDate
        endDate
        recordStatus
        groupR
        groupCRUD
        automatic
        scheduleInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionByProject = /* GraphQL */ `
  query QuestionByProject(
    $projectID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionByProject(
      projectID: $projectID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        text
        tags
        description
        answers {
          nextToken
        }
        answerOrder
        recordStatus
        groupR
        groupCRUD
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignByProject = /* GraphQL */ `
  query CampaignByProject(
    $projectID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByProject(
      projectID: $projectID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        clientID
        name
        abbreviation
        tags
        disposition
        schedule
        description
        settings
        startDate
        endDate
        questionLocation {
          nextToken
        }
        recordStatus
        groupR
        groupCRUD
        automatic
        scheduleInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rqaByCampaign = /* GraphQL */ `
  query RqaByCampaign(
    $campaignID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResponseQuestionAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rqaByCampaign(
      campaignID: $campaignID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignID
        projectID
        questionAnswers
        recordStatus
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;