import * as d3 from 'd3';
    // need   {name: "flare", children: [{name: "c1", children: []...}]}
    //  last node is  {name: "finallevel", value: 1234}
    // USA   Alabama  A123   1
    // USA   Alabama  B123   2
    // USA   Alaska   A234   3
    // USA   Arkansas A889   4
    // Canada Saska   A123   5
    //
    /* e.g.
    var obj = {name: "flare", children: [
            {name: "USA", children: [
                {name: "Alabama", children: [
                    {name: "A123", value: 1},
                    {name: "B123", value: 2}
                ]},
                {name: "Alaska", children: [
                    {name: "A234", value: 3}
                ]},
                {name: "Arkansas", children: [
                    {name: "A889", value: 4}
                ]}               
               ]},
            {name: "Canada", children: [
                {name: "Saska", children: [
                    {name: "A123", value: 1}
                ]}             
             ]}
        ]}
        */

function _keysMatch(dlast, d, dlen) {
	for (let i = 0; i < dlen; i++) {
			if (dlast[i] !== d[i])
					return i;
	}
	return dlen;
}

export const decompressD3HierarchyOld = (data, dlen) => {
	var starter = { name: "-", children: [] };
	var stack = [];
	var dlast = null;
	var newobj;

	stack.push(starter);

	data.forEach(function (d, i) {
			if (!dlast) {
					let newobj;
					for (let j = 0; j < dlen - 1; j++) {
							newobj = { name: d[j], children: [] };
							stack[stack.length - 1].children.push(newobj);
							stack.push(newobj);
					}
					newobj = { name: d[dlen - 1], value: +d[dlen] };
					stack[stack.length - 1].children.push(newobj);

					dlast = d;
			} else {
					for (let j = 0; j < dlen; j++) {	// this won't happen, it was for compressed server data
							if (d[j] === undefined)
									d[j] = dlast[j];
					}
					if (dlen > 1) {
							let ndiff = _keysMatch(dlast, d, dlen);
							if (ndiff === dlen) {
									// error, same as last one which should *never* happen
							} else if (ndiff === dlen - 1) {
									newobj = { name: d[ndiff], value: +d[dlen] };
									stack[stack.length - 1].children.push(newobj);
							} else {
									let ix = ndiff;
									while (ndiff < dlen - 1) {
											stack.pop();
											ndiff++;
									}

									for (let j = ix; j < dlen - 1; j++) {
											newobj = { name: d[j], children: [] };
											stack[stack.length - 1].children.push(newobj);
											stack.push(newobj);
									}
									newobj = { name: d[dlen - 1], value: +d[dlen] };
									stack[stack.length - 1].children.push(newobj);
							}

					} else {
							newobj = { name: d[0], value: +d[dlen] };
							stack[stack.length - 1].children.push(newobj);
					}

					dlast = d;
			}
	});

	return stack[0];
};

// dlen is dimensions (groups). does not contain dlen'th value
export const tabularToChildren_old = (data, dlen) => {
	var starter = { name: "-", children: [] };
	var stack = [];
	var dlast = null;
	var newobj;

	let sumThem = true;		// added to sum *all* values vs. just 1st one

	// Need to sort by first dlen columns
	data.sort((a, b) => {
		for (let i = 0; i < dlen; i++) {
			if (a[i] < b[i]) {
				return -1;
			} else if (a[i] > b[i]) {
				return 1;
			}
		}
		return 0;
	});


	stack.push(starter);

	data.forEach(function (d, i) {
		if (!dlast) {
			let newobj;
			for (let j = 0; j < dlen - 1; j++) {
				newobj = { name: d[j], children: [] };
				stack[stack.length - 1].children.push(newobj);
				stack.push(newobj);
			}
			let val=0;
			if (sumThem) {
				for (let k = dlen; k < d.length; k++) {
					val += +d[k];
				}
			} else {
				val = +d[dlen];
			}
			newobj = { name: d[dlen - 1], value: val };
			stack[stack.length - 1].children.push(newobj);

			dlast = d;
		} else {
			for (let j = 0; j < dlen; j++) {	// this won't happen, it was for compressed server data
				if (d[j] === undefined)
					d[j] = dlast[j];
			}
			if (dlen > 1) {
				let ndiff = _keysMatch(dlast, d, dlen);
				if (ndiff === dlen) {
					// error, same as last one which should *never* happen
				} else if (ndiff === dlen - 1) {
					let val=0;
					if (sumThem) {
						for (let k = dlen; k < d.length; k++) {
							val += +d[k];
						}
					} else {
						val = +d[dlen];
					}

					newobj = { name: d[ndiff], value: val };
					stack[stack.length - 1].children.push(newobj);
				} else {
					let ix = ndiff;
					while (ndiff < dlen - 1) {
						stack.pop();
						ndiff++;
					}

					for (let j = ix; j < dlen - 1; j++) {
						newobj = { name: d[j], children: [] };
						stack[stack.length - 1].children.push(newobj);
						stack.push(newobj);
					}
					let val=0;
					if (sumThem) {
						for (let k = dlen; k < d.length; k++) {
							val += +d[k];
						}
					} else {
						val = +d[dlen];
					}

					newobj = { name: d[dlen - 1], value: val };
					stack[stack.length - 1].children.push(newobj);
				}

			} else {
				let val=0;
				if (sumThem) {
					for (let k = dlen; k < d.length; k++) {
						val += +d[k];
					}
				} else {
					val = +d[dlen];
				}

				newobj = { name: d[0], value: val };
				stack[stack.length - 1].children.push(newobj);
			}

			dlast = d;
		}
	});

	return stack[0];
};
export const tabularToChildren = (data, dlen) => {

	// Need to sort by first dlen columns
	data.sort((a, b) => {
		for (let i = 0; i < dlen; i++) {
			if (a[i] < b[i]) {
				return -1;
			} else if (a[i] > b[i]) {
				return 1;
			}
		}
		return 0;
	});

	const groupingFns = [];
	for (let i = 0; i < dlen; i++) {
		groupingFns.push((d) => d[i]);
	}
	const reduceFn = data => d3.sum(data, d => {
		let val = 0;
		for (let k = dlen; k < d.length; k++) {
			val += +d[k];
		}
		return val;
	});
	const rollupData = d3.rollup(data, reduceFn, ...groupingFns);
	return rollupData;

};

export function getSampleData() {
	return (
		{
			"data": [
				[
					"city",
					"deposits"
				],
				[
					"ACTON",
					"136891.885416667"
				],
				[
					"ALBANY",
					"206241.244444444"
				],
				[
					"Alhambra",
					"35091.125"
				],
				[
					"ALLSTON",
					"73731.84375"
				],
				[
					"AMHERST",
					"78701.9456521739"
				],
				[
					"Arcadia",
					"44263.875"
				],
				[
					"ARLINGTON",
					"42368.25"
				],
				[
					"Arnold",
					"14022.375"
				],
				[
					"ASTORIA",
					"127123.71875"
				],
				[
					"AVON",
					"90509.3035714286"
				],
				[
					"Ballwin",
					"41816.25"
				],
				[
					"BAY SHORE",
					"63676.125"
				],
				[
					"BETHPAGE",
					"105931.7"
				],
				[
					"Beverly Hills",
					"164698.875"
				],
				[
					"BILLERICA",
					"81435.59375"
				],
				[
					"BLOOMFIELD",
					"47179.6"
				],
				[
					"BOSTON",
					"87557.7067307692"
				],
				[
					"BOXBOROUGH",
					"34099.625"
				],
				[
					"Breese",
					"58704.625"
				],
				[
					"BRIARCLIFF MANOR",
					"92483.875"
				],
				[
					"BRIGHTON",
					"110118.75"
				],
				[
					"BRISTOL",
					"66308.8529411765"
				],
				[
					"BRONX",
					"66698.90625"
				],
				[
					"BROOKLINE",
					"122638.98125"
				],
				[
					"BUFFALO",
					"8980.91666666667"
				],
				[
					"BURLINGTON",
					"231220.142857143"
				],
				[
					"CAMBRIDGE",
					"97575.5625"
				],
				[
					"Campbell",
					"42495.75"
				],
				[
					"Carbondale",
					"34614"
				],
				[
					"CASTLETON ON HUDSON",
					"24530.625"
				],
				[
					"CENTERVILLE",
					"95920"
				],
				[
					"CHEEKTOWAGA",
					"55411.25"
				],
				[
					"CHELMSFORD",
					"94513.5277777778"
				],
				[
					"Chester",
					"38064"
				],
				[
					"Chesterfield",
					"37105.5"
				],
				[
					"CHICOPEE",
					"59804.2"
				],
				[
					"Chula Vista",
					"55739.8125"
				],
				[
					"Clayton",
					"85687.125"
				],
				[
					"CLIFTON PARK",
					"82531.65625"
				],
				[
					"CLINTON",
					"39826.75"
				],
				[
					"COCHITUATE",
					"265953.625"
				],
				[
					"COLCHESTER",
					"27665.4583333333"
				],
				[
					"COLD SPRING",
					"60988.375"
				],
				[
					"COLONIE",
					"50067.9519230769"
				],
				[
					"Columbia",
					"51261.125"
				],
				[
					"Corona",
					"18131"
				],
				[
					"COS COB",
					"72279.875"
				],
				[
					"Costa Mesa",
					"38405.5"
				],
				[
					"Creve Coeur",
					"258577"
				],
				[
					"DANBURY",
					"59380.9625"
				],
				[
					"Dardenne Prairie",
					"16576.625"
				],
				[
					"DEDHAM",
					"94140.7333333333"
				],
				[
					"DORCHESTR CTR",
					"207058.625"
				],
				[
					"E GREENBUSH",
					"43829.3125"
				],
				[
					"E LONGMEADOW",
					"39301.375"
				],
				[
					"EAST AMHERST",
					"85212.234375"
				],
				[
					"EAST FALMOUTH",
					"34655.3125"
				],
				[
					"EAST GREENBUSH",
					"57436.5"
				],
				[
					"EAST HARTFORD",
					"33960.09375"
				],
				[
					"EAST LONGMEADOW",
					"51898.1145833333"
				],
				[
					"East St. Louis",
					"16766.125"
				],
				[
					"Ellisville",
					"50231.25"
				],
				[
					"ELMHURST",
					"158088.3"
				],
				[
					"Encino",
					"50215.5"
				],
				[
					"FALMOUTH",
					"68329.3571428571"
				],
				[
					"FARMINGTON",
					"201715.708333333"
				],
				[
					"FISHKILL",
					"55349.8125"
				],
				[
					"FLORENCE",
					"21889.75"
				],
				[
					"Florissant",
					"20417.6666666667"
				],
				[
					"FOREST HILLS",
					"121433.1875"
				],
				[
					"FRAMINGHAM",
					"149819.4"
				],
				[
					"Fullerton",
					"18994.625"
				],
				[
					"GARDEN CITY",
					"169725.696428571"
				],
				[
					"Gardena",
					"40400.875"
				],
				[
					"Gerald",
					"41767.625"
				],
				[
					"GLASTONBURY",
					"177969.559210526"
				],
				[
					"GLEN COVE",
					"131504.140625"
				],
				[
					"GLENDALE",
					"38488.5"
				],
				[
					"Granite City",
					"50098.125"
				],
				[
					"Greenville",
					"24940.875"
				],
				[
					"GREENWICH",
					"240230.579545455"
				],
				[
					"GUILDERLAND",
					"52714.9861111111"
				],
				[
					"GUILFORD",
					"67730.25"
				],
				[
					"GULF SHORES",
					"120559.625"
				],
				[
					"HADLEY",
					"79736.4791666667"
				],
				[
					"HAMDEN",
					"74808.0916666667"
				],
				[
					"HAMPDEN",
					"47923.8125"
				],
				[
					"HARTFORD",
					"2482.375"
				],
				[
					"HATFIELD",
					"32674.5"
				],
				[
					"HAVERHILL",
					"40891.2142857143"
				],
				[
					"Hermann",
					"52725.625"
				],
				[
					"HOPEWELL JCT",
					"201772.9375"
				],
				[
					"HOPEWELL JUNCTION",
					"57073.375"
				],
				[
					"Huntington Beach",
					"56539.375"
				],
				[
					"HYANNIS",
					"88055.1071428571"
				],
				[
					"Irvine",
					"40401.125"
				],
				[
					"ISLIP",
					"149503.708333333"
				],
				[
					"JAMAICA PLAIN",
					"60397.5178571429"
				],
				[
					"JERICHO",
					"176711"
				],
				[
					"Jerseyville",
					"69448.25"
				],
				[
					"Johnston City",
					"25629.5"
				],
				[
					"Jurupa Valley",
					"31153.8125"
				],
				[
					"KINGSTON",
					"52521.9821428571"
				],
				[
					"Kirkwood",
					"32333.25"
				],
				[
					"Laguna Hills",
					"24903.75"
				],
				[
					"LAKE GROVE",
					"133423.775"
				],
				[
					"LAKE PLACID",
					"34513.9791666667"
				],
				[
					"Lake St. Louis",
					"47760.5"
				],
				[
					"Lakewood",
					"41071.5"
				],
				[
					"LARCHMONT",
					"174790.520833333"
				],
				[
					"LATHAM",
					"69984.7943548387"
				],
				[
					"LEVITTOWN",
					"87960.25"
				],
				[
					"Long Beach",
					"47117.8125"
				],
				[
					"LONG ISLAND CITY",
					"213716.8125"
				],
				[
					"LONGMEADOW",
					"104341.291666667"
				],
				[
					"Los Angeles",
					"33429.75"
				],
				[
					"LOUDONVILLE",
					"93522.4166666667"
				],
				[
					"LOWELL",
					"47154.45"
				],
				[
					"MADISON",
					"65069.3295454545"
				],
				[
					"Malibu",
					"59595.125"
				],
				[
					"Manchester",
					"49368.0178571429"
				],
				[
					"MANCHESTER CENTER",
					"56369.4375"
				],
				[
					"Marina Del Rey",
					"42086.25"
				],
				[
					"Marthasville",
					"33885"
				],
				[
					"MASHPEE",
					"56574.3928571429"
				],
				[
					"MATTITUCK",
					"69290.875"
				],
				[
					"MAYNARD",
					"97356.96875"
				],
				[
					"MEDFORD",
					"36174.5"
				],
				[
					"MILFORD",
					"56284.3541666667"
				],
				[
					"MINEOLA",
					"268568.625"
				],
				[
					"Montgomery City",
					"23651.75"
				],
				[
					"NATICK",
					"115965.604166667"
				],
				[
					"NEEDHAM",
					"115589.7"
				],
				[
					"NEEDHAM HEIGHTS",
					"215118.625"
				],
				[
					"NEW BRITAIN",
					"41574.125"
				],
				[
					"NEW PALTZ",
					"50301.4444444444"
				],
				[
					"NEW WINDSOR",
					"56990.90625"
				],
				[
					"NEW YORK CITY",
					"95804.2142857143"
				],
				[
					"NEWBURGH",
					"97910.9347826087"
				],
				[
					"Newport Beach",
					"199654.25"
				],
				[
					"NEWTON",
					"100895.5"
				],
				[
					"NEWTON HIGHLANDS",
					"47905.5"
				],
				[
					"NISKAYUNA",
					"41498.09375"
				],
				[
					"NORTH BILLERICA",
					"37730.6875"
				],
				[
					"NORTH FALMOUTH",
					"30247.375"
				],
				[
					"NORTH GREENBUSH",
					"24136.75"
				],
				[
					"NORTH QUINCY",
					"202976.125"
				],
				[
					"NORTH YONKERS",
					"5403.25"
				],
				[
					"NORTHAMPTON",
					"80142.285"
				],
				[
					"NORWALK",
					"71189.7310606061"
				],
				[
					"O'Fallon",
					"79290"
				],
				[
					"OAK BLUFFS",
					"25879.3125"
				],
				[
					"ORANGE",
					"79283"
				],
				[
					"Owensville",
					"43036.125"
				],
				[
					"PITTSFIELD",
					"65131.8928571429"
				],
				[
					"PLAINVILLE",
					"46649.65625"
				],
				[
					"PLATTSBURG",
					"52804.75"
				],
				[
					"PLATTSBURGH",
					"59691.859375"
				],
				[
					"POUGHKEEPSIE",
					"84902.9416666667"
				],
				[
					"QUEENSBURY",
					"81022.0535714286"
				],
				[
					"QUINCY",
					"78819.75"
				],
				[
					"Rancho Cordova",
					"61356.25"
				],
				[
					"Red Bud",
					"53869.875"
				],
				[
					"REGO PARK",
					"158830.910714286"
				],
				[
					"RENSSELAER",
					"63367.125"
				],
				[
					"RIDGEFIELD",
					"120721.864583333"
				],
				[
					"RIVERHEAD",
					"109511.35"
				],
				[
					"ROCHESTER",
					"33283.734375"
				],
				[
					"Roseville",
					"55127"
				],
				[
					"Rowland Heights",
					"24142.125"
				],
				[
					"ROXBURY",
					"24373.9375"
				],
				[
					"S BURLINGTON",
					"68262.2"
				],
				[
					"S SETAUKET",
					"106043.25"
				],
				[
					"SAINT ALBANS",
					"45122.8333333333"
				],
				[
					"Saint Louis",
					"7709"
				],
				[
					"Salem",
					"49365.3125"
				],
				[
					"San Diego",
					"44007.25"
				],
				[
					"San Francisco",
					"99169.9375"
				],
				[
					"San Mateo",
					"59988.875"
				],
				[
					"San Pablo",
					"32187.75"
				],
				[
					"San Rafael",
					"23102"
				],
				[
					"Santa Barbara",
					"69579.875"
				],
				[
					"Santa Maria",
					"28388.5"
				],
				[
					"SARATOGA SPGS",
					"37789.375"
				],
				[
					"SARATOGA SPRINGS",
					"159419.25"
				],
				[
					"SAYVILLE",
					"61532.4583333333"
				],
				[
					"SCARSDALE",
					"178000.927083333"
				],
				[
					"SCHENECTADY",
					"29754.0833333333"
				],
				[
					"SEYMOUR",
					"27919.375"
				],
				[
					"Shiloh",
					"15273.125"
				],
				[
					"Shrewsbury",
					"31841.75"
				],
				[
					"SIMSBURY",
					"50267.375"
				],
				[
					"SLINGERLANDS",
					"31872"
				],
				[
					"Solvang",
					"37541.75"
				],
				[
					"SOUTH BURLINGTON",
					"62586.7784090909"
				],
				[
					"SOUTH SETAUKET",
					"48913.25"
				],
				[
					"SOUTH WINDSOR",
					"74136.453125"
				],
				[
					"SPRINGFIELD",
					"72107.8382352941"
				],
				[
					"ST. ALBANS",
					"25805.625"
				],
				[
					"St. Charles",
					"22725.1666666667"
				],
				[
					"ST. JAMES",
					"47422.625"
				],
				[
					"St. Louis",
					"22063.5178571429"
				],
				[
					"St. Peters",
					"17065"
				],
				[
					"STAMFORD",
					"228322.196428571"
				],
				[
					"Ste Genevieve",
					"77357.5"
				],
				[
					"STONY BROOK",
					"132124.8125"
				],
				[
					"Swansea",
					"50419"
				],
				[
					"SYOSSET",
					"140196.363636364"
				],
				[
					"TEWKSBURY",
					"67542.6363636364"
				],
				[
					"Torrance",
					"25460.125"
				],
				[
					"TROY",
					"34026.4375"
				],
				[
					"VAILS GATE",
					"43549.375"
				],
				[
					"Vallejo",
					"90503.25"
				],
				[
					"Vandalia",
					"27096.375"
				],
				[
					"Ventura",
					"37282.75"
				],
				[
					"VERNON",
					"77319.5"
				],
				[
					"VERNON ROCKVILLE",
					"169870.125"
				],
				[
					"VINEYARD HAVEN",
					"80903.375"
				],
				[
					"W Frankfort",
					"33449.625"
				],
				[
					"WABAN",
					"134802.0625"
				],
				[
					"Walnut Creek",
					"76076"
				],
				[
					"Warrenton",
					"24459.5"
				],
				[
					"Washington",
					"31692.5"
				],
				[
					"Waterloo",
					"18125.375"
				],
				[
					"WATERVLIET",
					"11570.625"
				],
				[
					"WAYLAND",
					"82911.9642857143"
				],
				[
					"Webster Groves",
					"27462.5"
				],
				[
					"WELLESLEY",
					"150964.506944444"
				],
				[
					"WELLESLEY HILLS",
					"44736.625"
				],
				[
					"WELLESLEY HLS",
					"50783.25"
				],
				[
					"Wentzville",
					"50686.25"
				],
				[
					"WEST CONCORD",
					"166762.125"
				],
				[
					"WEST HARTFORD",
					"56216.925"
				],
				[
					"WEST POINT",
					"160171.5"
				],
				[
					"WEST ROXBURY",
					"14128.6875"
				],
				[
					"WEST SIMSBURY",
					"5229.25"
				],
				[
					"WEST SPRINGFIELD",
					"220488.4375"
				],
				[
					"WESTFIELD",
					"68234.91875"
				],
				[
					"Westlake Village",
					"81572.25"
				],
				[
					"Westminster",
					"23841.125"
				],
				[
					"WESTPORT",
					"164095.641129032"
				],
				[
					"WHITE PLAINS",
					"190834.983333333"
				],
				[
					"Wildwood",
					"10843.5"
				],
				[
					"WILLIAMSVILLE",
					"62036.5"
				],
				[
					"WILTON",
					"100889.136363636"
				],
				[
					"WINCHESTER",
					"204492.875"
				],
				[
					"WINOOSKI",
					"41745.171875"
				],
				[
					"WOBURN",
					"218617.78125"
				],
				[
					"WOODBURY",
					"153773.479166667"
				],
				[
					"Woodland Hills",
					"88164.625"
				],
				[
					"WRENTHAM",
					"55766.3333333333"
				],
				[
					"WYNANTSKILL",
					"24566.25"
				],
				[
					"YARMOUTH PORT",
					"1130"
				],
				[
					"YONKERS",
					"93125.5"
				],
				[
					"Yorba Linda",
					"75329.875"
				]
			],
			"type": "bar",
			"header": [
				"city",
				"deposits"
			],
			"dimensions": [
				{
					"name": "city"
				}
			],
			"recordsTotal": 255,
			"recordsFiltered": 255
		}
	)
}
export function getSampleDataPie() {
	return (
		{
			"data": [
				[
					"city",
					"deposits"
				],
				[
					"ACTON",
					"136891.885416667"
				],
				[
					"ALBANY",
					"206241.244444444"
				],
				[
					"Alhambra",
					"35091.125"
				],
				[
					"ALLSTON",
					"73731.84375"
				],
				[
					"AMHERST",
					"78701.9456521739"
				],
			],
			"type": "bar",
			"header": [
				"city",
				"deposits"
			],
			"dimensions": [
				{
					"name": "city"
				}
			],
			"recordsTotal": 5,
			"recordsFiltered": 5
		}
	)
}

export const savedGraphParams = {
	"uiProps": {
		aspect: "fit",		// fit, 5:4, 4:3, 3:2, 16:10, 16:9
	},
  "stackProps": {
    "resizable": true,
    "draggable": true,
    "gridProps": [
      {
        "manualChange": true,
        "lockAspectRatio": false,
        "size": {
          "x": 0,
          "y": 0,
          "width": 0.5,
          "height": 0.9
        },
        "saveProps": {
          "graphType": "sunburst",
          "pivot": false,
          "saveProps": {
            "colorPaletteType": "custom",
            "customPalette": {
              "name": "newpalette",
              "loc": null,
              "interpolate": false,
              "colorRef": [
                {
                  "rgb": null,
                  "stretch": true,
                  "loc": "https://img.freepik.com/free-photo/portrait-lion-ai-generated_268835-4278.jpg?w=2000",
                  "opacity": 0.7,
                  "ref": "U113b50c5021c42aaa6517a0947814653"
                },
                {
                  "rgb": null,
                  "stretch": true,
                  "loc": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9XT89rHOJLr7fOg3wSlQLss0SVKd11QLerGhgXq2ZBB2IGGN1",
                  "opacity": 0.7,
                  "ref": "Ue113fc2d445bb68d523f45758975674e"
                },
                {
                  "rgb": null,
                  "stretch": true,
                  "loc": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Ours_brun_parcanimalierpyrenees_1.jpg",
                  "opacity": 0.7,
                  "ref": "U7c90b088cc130540064971855af28e41"
                },
                {
                  "rgb": "#bf6cc7",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#f7534d",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ffef76",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#3b56b5",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#fb90b0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#56b7f6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#0097a8",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#805bc2",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ff8962",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#c3e0a6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#8f6e63",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#e0e0e0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#9e9e9e",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#34474f",
                  "loc": null,
                  "opacity": 0.7
                }
              ],
              "hasDef": true
            },
            "graphType": "pie",
            "margin": {
              "top": 30,
              "right": 30,
              "bottom": 5,
              "left": 62.6390625
            },
            "colorPaletteOpacity": 0.7,
            "backgroundColor": {
              "rgb": "#97fcf3",
              "loc": null,
              "stretch": false,
              "opacity": "0.44"
            },
            "legendBackgroundColor": {
              "rgb": "#ffffff",
              "loc": null,
              "stretch": false,
              "opacity": 0.3333333333333333
            },
            "font": "12px Verdana, Geneva, sans-serif",
            "legendFont": "12px Verdana, Geneva, sans-serif",
            "legendFontStyle": "",
            "legendColor": "#434343",
            "isHorizontal": true,
            "autoMarginLeft": true,
            "sortOrder": [],
            "xAxisFont": "12px Verdana, Geneva, sans-serif",
            "xAxisFontStyle": "",
            "xAxisLabelColor": "#7E7F7F",
            "xAxisColor": "#DEE2E6",
            "xAxisStrokeWidth": 1,
            "yAxisFont": "12px Verdana, Geneva, sans-serif",
            "yAxisFontStyle": "",
            "yAxisLabelColor": "#7E7F7F",
            "yAxisColor": "#DEE2E6",
            "yAxisStrokeWidth": 1,
            "xAxisGridColor": "#DEE2E6",
            "xAxisGridStrokeWidth": 1,
            "yAxisGridColor": "#DEE2E6",
            "yAxisGridStrokeWidth": 1,
            "_bStagger": false,
            "hasXGrid": true,
            "selectorHeight": 50,
            "heightOverview": 40,
            "grouped": true,
            "minBarWidth": 10,
            "barOutlineWidth": 1,
            "barOutlineColor": {
              "rgb": "#e8eaed",
              "loc": null,
              "stretch": false,
              "opacity": 1
            },
            "barOutlineStrokeDashArray": "0",
            "_computedBarWidth": 30
          }
        },
        "baseId": "29f954d5-5c7e-4bf5-b440-a4df11b8c496_1bd5ab7e-5d3e-402a-992b-7ea51f0bf20b_228a91d3-7beb-476c-a299-b52c8a00b3c4_d818798d-7458-4644-9e44-664c0bd7a4df",
				"projectId": "0d415d37-e46c-4ee6-9cc4-c0bf34a6d3c3"
      },
      {
        "manualChange": true,
        "lockAspectRatio": false,
        "size": {
          "x": 0.55,
          "y": 0,
          "width": 0.4,
          "height": 0.5
        },
        "saveProps": {
          "graphType": "hbar",
          "pivot": false,
          "saveProps": {
            "colorPaletteType": "custom",
            "customPalette": {
              "name": "newpalette",
              "loc": null,
              "interpolate": false,
              "colorRef": [
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://img.freepik.com/free-photo/portrait-lion-ai-generated_268835-4278.jpg?w=2000",
                  "opacity": 0.7,
                  "ref": "U0e26824b4ae9e9a5cb36e99d5ffaf10e"
                },
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9XT89rHOJLr7fOg3wSlQLss0SVKd11QLerGhgXq2ZBB2IGGN1",
                  "opacity": 0.7,
                  "ref": "Ub82c008b0d2e37c112b2516789064ced"
                },
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Ours_brun_parcanimalierpyrenees_1.jpg",
                  "opacity": 0.7,
                  "ref": "Ubdf4e66ba7d6cf8d25c04598789adbed"
                },
                {
                  "rgb": "#bf6cc7",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#f7534d",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ffef76",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#3b56b5",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#fb90b0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#56b7f6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#0097a8",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#805bc2",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ff8962",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#c3e0a6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#8f6e63",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#e0e0e0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#9e9e9e",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#34474f",
                  "loc": null,
                  "opacity": 0.7
                }
              ],
              "hasDef": true
            },
            "graphType": "hbar",
            "margin": {
              "top": 30,
              "right": 30,
              "bottom": 5,
              "left": 44.77265625
            },
            "colorPaletteOpacity": 0.7,
            "backgroundColor": {
              "rgb": "#ffffff",
              "loc": null,
              "stretch": false,
              "opacity": 1
            },
            "legendBackgroundColor": {
              "rgb": "#ffffff",
              "loc": null,
              "stretch": false,
              "opacity": 0.3333333333333333
            },
            "font": "12px Verdana, Geneva, sans-serif",
            "legendFont": "12px Verdana, Geneva, sans-serif",
            "legendFontStyle": "",
            "legendColor": "#434343",
            "isHorizontal": true,
            "autoMarginLeft": true,
            "sortOrder": [],
            "xAxisFont": "12px Verdana, Geneva, sans-serif",
            "xAxisFontStyle": "",
            "xAxisLabelColor": "#7E7F7F",
            "xAxisColor": "#DEE2E6",
            "xAxisStrokeWidth": 1,
            "yAxisFont": "12px Verdana, Geneva, sans-serif",
            "yAxisFontStyle": "",
            "yAxisLabelColor": "#7E7F7F",
            "yAxisColor": "#DEE2E6",
            "yAxisStrokeWidth": 1,
            "xAxisGridColor": "#DEE2E6",
            "xAxisGridStrokeWidth": 1,
            "yAxisGridColor": "#DEE2E6",
            "yAxisGridStrokeWidth": 1,
            "_bStagger": false,
            "hasXGrid": true,
            "selectorHeight": 50,
            "heightOverview": 40,
            "grouped": false,
            "minBarWidth": 10,
            "barOutlineWidth": 1,
            "barOutlineColor": {
              "rgb": "#e8eaed",
              "loc": null,
              "stretch": false,
              "opacity": 1
            },
            "barOutlineStrokeDashArray": "0",
            "_computedBarWidth": 22.40625
          }
        },
        "baseId": "29f954d5-5c7e-4bf5-b440-a4df11b8c496_b4d68109-d53d-46a3-8371-08ad41e152cc_78e2aea3-a410-4476-abc7-a0aa06986869_83884ef9-531c-4535-9f5d-6919c803b1e9",
				"projectId": "0d415d37-e46c-4ee6-9cc4-c0bf34a6d3c3"
      },
      {
        "manualChange": true,
        "lockAspectRatio": false,
        "size": {
          "x": 0.55,
          "y": 0.5,
          "width": 0.45,
          "height": 0.5
        },
        "saveProps": {
          "graphType": "hbar",
          "pivot": false,
          "saveProps": {
            "colorPaletteType": "custom",
            "customPalette": {
              "name": "newpalette",
              "loc": null,
              "interpolate": false,
              "colorRef": [
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://img.freepik.com/free-photo/portrait-lion-ai-generated_268835-4278.jpg?w=2000",
                  "opacity": 0.7,
                  "ref": "U3b46b10d8ecce95fcd500b2a59559123"
                },
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9XT89rHOJLr7fOg3wSlQLss0SVKd11QLerGhgXq2ZBB2IGGN1",
                  "opacity": 0.7,
                  "ref": "Ubf762c1ada531601204867eb6f53294a"
                },
                {
                  "rgb": null,
                  "stretch": false,
                  "loc": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Ours_brun_parcanimalierpyrenees_1.jpg",
                  "opacity": 0.7,
                  "ref": "U8f3a6605a8f38171eb4b359e2e7a385a"
                },
                {
                  "rgb": "#bf6cc7",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#f7534d",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ffef76",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#3b56b5",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#fb90b0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#56b7f6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#0097a8",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#805bc2",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#ff8962",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#c3e0a6",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#8f6e63",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#e0e0e0",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#9e9e9e",
                  "loc": null,
                  "opacity": 0.7
                },
                {
                  "rgb": "#34474f",
                  "loc": null,
                  "opacity": 0.7
                }
              ],
              "hasDef": true
            },
            "graphType": "hbar",
            "margin": {
              "top": 30,
              "right": 30,
              "bottom": 5,
              "left": 44.77265625
            },
            "colorPaletteOpacity": 0.7,
            "backgroundColor": {
              "rgb": "#ffffff",
              "loc": null,
              "stretch": false,
              "opacity": 1
            },
            "legendBackgroundColor": {
              "rgb": "#ffffff",
              "loc": null,
              "stretch": false,
              "opacity": 0.3333333333333333
            },
            "font": "12px Verdana, Geneva, sans-serif",
            "legendFont": "12px Verdana, Geneva, sans-serif",
            "legendFontStyle": "",
            "legendColor": "#434343",
            "isHorizontal": true,
            "autoMarginLeft": true,
            "sortOrder": [],
            "xAxisFont": "12px Verdana, Geneva, sans-serif",
            "xAxisFontStyle": "",
            "xAxisLabelColor": "#7E7F7F",
            "xAxisColor": "#DEE2E6",
            "xAxisStrokeWidth": 1,
            "yAxisFont": "12px Verdana, Geneva, sans-serif",
            "yAxisFontStyle": "",
            "yAxisLabelColor": "#7E7F7F",
            "yAxisColor": "#DEE2E6",
            "yAxisStrokeWidth": 1,
            "xAxisGridColor": "#DEE2E6",
            "xAxisGridStrokeWidth": 1,
            "yAxisGridColor": "#DEE2E6",
            "yAxisGridStrokeWidth": 1,
            "_bStagger": false,
            "hasXGrid": true,
            "selectorHeight": 50,
            "heightOverview": 40,
            "grouped": false,
            "minBarWidth": 10,
            "barOutlineWidth": 1,
            "barOutlineColor": {
              "rgb": "#e8eaed",
              "loc": null,
              "stretch": false,
              "opacity": 1
            },
            "barOutlineStrokeDashArray": "0",
            "_computedBarWidth": 22.40625
          }
        },
        "baseId": "29f954d5-5c7e-4bf5-b440-a4df11b8c496_9e160aa9-cd51-4cc7-99c5-84ff50629f39_86026644-9932-4731-b1cb-063be944e563_83884ef9-531c-4535-9f5d-6919c803b1e9",
				"projectId": "0d415d37-e46c-4ee6-9cc4-c0bf34a6d3c3"
      }
    ]
  }
};