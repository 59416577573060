import { axisGGC } from './axisggc';
import $ from 'jquery';
import * as d3 from 'd3';
import GGCPrimitives from './GGCPrimitives';
import { keepCloning } from './paletteggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

export class areasplineGGC extends axisGGC {
    constructor(args) {
        super(args);

        this.superClass = 'areasplineGGC';
        
        this.selectorHeight = 50;
        this.heightOverview = 40;
        this.filler = true;
        this.line = false;
        this.stacked = 'no';
        this.defaultMarker = {
            mark: 'Circle',
            size: 4,
            markerColorRef: ''.toColorRef(),
            lineWidth: 2,
            lineStrokeDashArray: "5, 5",
            lineColorRef: ''.toColorRef(),
            outlineWidth: 0,
            outlineStrokeDashArray: "0",
            outlineColorRef: '#000000'.toColorRef()
        };

        this.active_link = "0";

        // Initialized on resize
        this.numBars = undefined;
        this.isScrollDisplayed = undefined;
        this.cur_data = undefined;
        this._dataRange = undefined;

        this.xscale = undefined;
        this.ntips = undefined;
        this.yscale = undefined;
        this.xAxis = undefined;
        this.yAxis = undefined;
        this.yAxisGrid = undefined;
        this.barsHolder = undefined;
        this._axisLabelHeight = undefined;
        this.svgId = undefined;

        this.focus = undefined;

        this.hasYGrid = true;
        this.barWidth = undefined;
        this._computedBarWidth = undefined;


        this.markers = undefined;   // type specifically checked later
        this.markersSvg = undefined;
        this.viewBoxAdjustHeight = 0;

        this._init(args);
    }
    getGraphType() {
        if (!this.fill && this.line) {
            return 'line';
        } else if (!this.fill && !this.line) {
            return 'spline';
        } else if (this.fill && !this.line) {
            return 'aspline';
        } else {
            return 'unknown';
        }
    }
    _init(args) {
        //2023 this._axis_init(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var axis = self._axis_saveProps();
        var marks;
        if (self.hasOwnProperty('valueNames') && self.valueNames) {
            marks = self.markers.slice(0, self.valueNames.length);
        } else {
            marks = self.markers;
        }
        var me = {
            stacked: self.stacked,
            hasYGrid: self.hasYGrid,
            selectorHeight: self.selectorHeight,
            heightOverview: self.heightOverview,
            fill: self.fill,
            line: self.line,
            markers: marks,
            _computedBarWidth: self._computedBarWidth,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight,
        };
        if (this._computedBarWidth !== this.barWidth)
            me.barWidth = this.barWidth;

        return { ...axis, ...me };
    }
    _initVariables(args) {
        var self = this;

        if (args.hasOwnProperty('selectorHeight'))
            this.selectorHeight = args.selectorHeight;

        if (args.hasOwnProperty('heightOverview'))
            this.heightOverview = args.heightOverview;

        if (args.hasOwnProperty('fill')) // fill the spline?
            this.filler = args.fill;

        if (args.hasOwnProperty('line'))
            this.line = args.line;

        if (args.hasOwnProperty('stacked'))
            this.stacked = args.stacked;


        if (args.hasOwnProperty('markers')) {
            if (Array.isArray(args.markers)) {
                this.markers = [];
                for (let i = 0; i < args.markers.length; i++) {
                    if (typeof (args.markers[i]) === 'object') {
                        var comboMarker = { ...self.defaultMarker, ...args.markers[i] };
                        this.markers.push(comboMarker);
                    } else {
                        this.markers.push(keepCloning(self.defaultMarker)); // TODO: may need deepcopy               
                    }
                }
            } else if (typeof (args.markers) === 'object') { // {mark:, color:, opacity: width:, strokeDashArray: [10, 3] }
                let comboMarker = { ...self.defaultMarker, ...args.markers };
                this.markers = [comboMarker];
            }
        }
        if (typeof (this.markers) === 'undefined') {
            this.markers = [keepCloning(self.defaultMarker)];
        }

        this.markersSvg = new Array(this.markers.length);

        if (args.hasOwnProperty('barWidth'))
            this.barWidth = args.barWidth;

        if (args.hasOwnProperty('_computedBarWidth'))
            this._computedBarWidth = args._computedBarWidth;


        if (args.hasOwnProperty('hasYGrid'))
            this.hasYGrid = args.hasYGrid;

        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }
    
    }
    resize(opts) {
        var self = this;

        let svgWidth, svgHeight;
        let width, height;
        let gonnaDraw = true;

        // We need to check this because if we don't draw, then we've set the outerWidth/Height to something
        // from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                gonnaDraw = false;
            }
        }

        if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
            const vbCoords = this.viewBox.split(' ');
            width = parseInt(vbCoords[2]);
            height = parseInt(vbCoords[3]);
            svgWidth = opts.width;
            svgHeight = opts.height + this.viewBoxAdjustHeight;
        } else {
            width = opts.width;
            height = opts.height;
        }

        self.outerWidth = width;
        self.outerHeight = height;

        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;
        if (self._pagingControl) {  // div id="<#_pagingControl>"
            this.origHeight -= $(self._pagingControl).height();
            this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        if (self._sortDivId) {    // _sortTableId GGC 2023
            this.origHeight -= $('#' + self._sortDivId).height();
            this.viewBoxAdjustHeight = -$('#' + self._sortDivId).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);

        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();
        if (this.hasOwnProperty('tooltip') && this.tooltip)
            this.tooltip.remove();
        // remove any leftover popovers
        //$('.popover').popover('hide');             2023 - TODO: replace .popover
        $('.popover').popover('hide');


        if (self.valueNames.length > self.markers.length) {
            var i = self.valueNames.length - self.markers.length;
            while (i) {
                self.markers.push(keepCloning(self.defaultMarker));
                self.markersSvg.push({});
                i--;
            }
        } else if (self.valueNames.length < self.markers.length) {
            self.markers = self.markers.slice(0, self.valueNames.length);
            self.markersSvg = self.markersSvg.slice(0, self.valueNames.length);
        }

        this._axisLabelHeight = this._textHeight('Yy', self.xAxisFont) * 1.5;

        this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City

        var valueMax = null;
        var valueMin = null;

        if (this.stacked === 'yes') {
            this.data.forEach(function (d, ix) {
                //var myLabel = self._trimEllipses(d[self.ixLabel]);
                var aggVal;
                d.values = [];
                for (let i = 0; i < self.valueNames.length; i++) {
                    let name = self.color.domain()[i];
                    let v = +d[self._valueIndex(name)];
                    if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                        if (i === 0) {
                            aggVal = v;
                        } else {
                            aggVal += v;
                        }
                    }
                    if (i === 0)
                        d.values.push({ myix: ix, vi: i, y0: 0, y1: aggVal });

                    else
                        d.values.push({ myix: ix, vi: i, y0: d.values[i - 1].y1, y1: aggVal });
                }
                if (!valueMax)
                    valueMax = aggVal;
                else if (aggVal > valueMax)
                    valueMax = aggVal;
                if (!valueMin)
                    valueMin = aggVal;
                else if (aggVal < valueMin)
                    valueMin = aggVal;
            });
        } else {
            this.data.forEach(function (d, ix) {
                //var myLabel = self._trimEllipses(d[self.ixLabel]);
                d.values = [];
                for (let i = 0; i < self.valueNames.length; i++) {
                    let name = self.color.domain()[i];
                    let v = +d[self._valueIndex(name)];
                    if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                        if (!valueMax)
                            valueMax = v;
                        else if (v > valueMax)
                            valueMax = v;
                        if (!valueMin)
                            valueMin = v;
                        else if (v < valueMin)
                            valueMin = v;
                    }
                    d.values.push({ myix: ix, vi: i, y0: 0, y1: v });
                }
            });
        }
        // Compute max length of valueMin and valueMax for margin.left
        //this.defaultFormat = d3.format(",.0f")  // 2023 https://stackoverflow.com/questions/13513177/display-only-whole-values-along-y-axis-in-d3-js-when-range-is-0-1-or-0-2
        var yAxisFormat = d3.format(",.0f");
        var xLen = self._textWidth(yAxisFormat(valueMax), self.yAxisFont);
        var mLen = self._textWidth(yAxisFormat(valueMin), self.yAxisFont);
        if (xLen > mLen) {
            mLen = xLen;
        }
        mLen += 16; // tick length + spacing (10). add spacing on left (6)

        self.margin.left = self.margin.left > mLen ? self.margin.left : mLen;


        this.width = this.origWidth - this.margin.left - this.margin.right - this.legendSpace - self.extraRightMargin;

        this.svgId = this._genId();

        if (!isRuntimeOnly()) {
            this.viewBox = [0, 0, self.origWidth, self.origHeight].join(' ');
            svgWidth = self.origWidth;
            svgHeight = self.origHeight;
        }
        this.svg = d3.select(this.container).append("svg")
            .attr('xmlns', "http://www.w3.org/2000/svg")
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr("viewBox", this.viewBox)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr('id', self.svgId);
    
        this.diagram = this.svg.append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this.color.domain(this.valueNames);

        this._colorDomainSet([{
            arrayName: 'markers',
            fields: ['markerColorRef', 'outlineColorRef', 'lineColorRef']
        }
        ]);

        self._fillBackground(this.svg);


        if (typeof self.barWidth === 'undefined') { // don't change if we are refreshing from edit
            if (typeof self._computedBarWidth != 'undefined' &&
                (typeof this._bStagger != 'undefined' && this._bStagger)) {
                this.barWidth = this._computedBarWidth;
            }
            else {
                // this.width is area to plot; this.data.length is # of bars
                let bwAll = this.width / this.data.length;
                // Now, do the labels fit into bwAll width?  If not, see if wrapping helps
                let maxLabelWidth = d3.max(this.data.map(function (d) {
                    return self._textWidth(d[self.ixLabel], self.xAxisFont ? self.xAxisFont : self.font) * 1.2;
                }));
                if (maxLabelWidth > bwAll) {        // have to wrap, ellipses, or stagger
                    self.barWidth = maxLabelWidth / 2;
                } else {
                    self.barWidth = bwAll;
                }
                /*
                self.barWidth = d3.max(this.data.map(function (d) {
                    return self._textWidth(self._trimEllipses(d[self.ixLabel]), self.xAxisFont ? self.xAxisFont : self.font);
                }
                )) * 1.2;
                */

                if (this.grouped) {
                    if (this.barWidth < this.minBarWidth * this.valueNames.length)
                        this.barWidth = this.minBarWidth * this.valueNames.length;
                }
                this._computedBarWidth = this.barWidth;            
            }
        }

        this.numBars = Math.round(this.width / self.barWidth);

        this.isScrollDisplayed = self.barWidth * this.data.length > this.width;

        this.height = self.origHeight - this.margin.top - this.margin.bottom - 
                (this.isScrollDisplayed ? 
                    this.selectorHeight : 
                    0) - 
                (this.categoryNames.length + 1) * self._axisLabelHeight; // +1 is in case xaxis stagger

        this.cur_data = self.data.slice(0, this.numBars);
        this._dataRange = [0, this.numBars];

        this.xscale = d3.scaleBand()
            .domain(self.cur_data.map(function (d, ix) { return ix; }))
            .range([0, this.width]);

        self.ntips = 0;
        for (let i = 0; i < self.valueNames.length; i++) {
            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                self.ntips++;
        }
        this.tooltipCreate(self.ntips);

        if (valueMin > 0) valueMin = 0;
        this.yscale = d3.scaleLinear()
            .domain([valueMin, valueMax])
            .range([this.height, 0]);

        const nDigits = Math.floor(Math.log10(valueMax)) + 1;

        const yAxisTicks = this.yscale.ticks().filter(tick => Number.isInteger(tick));

        this.xAxis = d3.axisBottom(this.xscale);
        this.yAxis = d3.axisLeft(this.yscale)
            .ticks(nDigits > 1 ? this.maxTicks : yAxisTicks.length)           // 2023 new ticks
            .tickFormat(yAxisFormat);
        this.yAxisGrid = d3.axisLeft(this.yscale).tickFormat('');


        this.cur_data = self.data.slice(0, this.numBars);
        this._dataRange = [0, this.numBars];

        let oldBStagger = this._bStagger;

        this._axis_groupLabels(0);

        this._axis_focusY();

        this._axis_drawXY();        // _bSTagger gets set here...
        if (!oldBStagger && this._bStagger) {
            this._axis_groupLabels(0);
        }

        this._axis_drawXY_edit();

        this.diagram.append('g')
            .attr('class', 'focus')
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this.barsHolder = this.diagram
            .append("g")
            .attr('class', 'barsHolder')
            .attr('transform', 'translate(0,0)');


        this._drawCurves();
        this._loadMarkerUrls();

        this._setBarsMouseEvents();

        this._base_makeLegend({ resize: true });

        this._checkScroller();

        this.checkEditMode();
    }
    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }
    checkEditMode() {
        var self = this;

        self._axis_checkEditMode();

        if (self.editMode) {
            let x = self.origWidth / 4;
            let y = Math.max(self.margin.top/2, 0);
            self._drawCircleEditColor({
                selector: self.diagram,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    colorRefProp: 'backgroundColor'
                }

            });

            y += 30;
            // Stacked?
            self._drawCircleEditBoolean({
                selector: self.diagram,
                kind: 'boolstack',
                name: 'stackyn',
                title: i18next.t("graph.edit.stacked"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    booleanProp: 'stacked'
                }

            });


            // Now, draw a box with self.valueNames.length horizontal lines w/ markers
            var minX = null;
            self.svg.selectAll(".legendGGC")
                .select(function (d, i) {
                    var x = +$(this).find('text').attr('x')
                    var lbl = $(this).find('text').html();
                    x -= (self._textWidth(lbl, self.legendFont));
                    if (minX === null)
                        minX = x;
                    else if (x < minX)
                        minX = x;
                });

            x = minX - self.margin.left;


            var width = 200;
            x -= (width + 20);
            //        x = (self.width/2) - width/2;
            var height = self.markers.length * 20;
            y = 0; // (self.height - height)/2;      // 'line' height for each sample line
            this.markersHolder = this.diagram.selectAll(".markersHolder")
                .data(['hi'])
                .enter()
                .append("g")
                .attr("class", "g " + this._editClass)
                .attr("transform", function (d) { return "translate(0,0)"; });

            // background for area
            this.markersHolder
                .append('rect')
                .attr('x', x)
                .attr('y', y)
                .attr('width', width)
                .attr('height', height)
                .style('fill', 'lightblue') // TODO? right color
                .style('fill-opacity', .2)
                .attr('class', this._editClass);

            var emarker = this.markersHolder.selectAll('.eachMarker')
                .data(this.markers)
                .enter().append("g")
                .attr("class", "g eachMarker")
                .attr("transform", function (d, i) { return "translate(0," + (y + i * 20) + ")"; });

            var mywidth = width - 24;
            var pts = [[x + 0, 20], [x + mywidth / 3, 0], [x + 2 * mywidth / 3, 20], [x + mywidth, 0]];
            var bezierF = d3.line()
                .curve(d3.curveMonotoneX)
                .x(function (d) { return d[0]; })
                .y(function (d) { return d[1]; });

            emarker
                .append('path')
                .attr('d', bezierF(pts))
                .attr('class', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return 'aelineClass' + lix;
                })
                .style('fill', 'none') // critical so it doesn't 'fake fill'
                .style('stroke', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getColorFromRef(self.markers[lix].lineColorRef, self._getFillColor(lix));
                })
                .style('stroke-opacity', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getOpacityFromRef(self.markers[lix].lineColorRef);
                })
                .style('stroke-width', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return self.markers[lix].lineWidth;
                })
                .style('stroke-dasharray', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return self.markers[lix].lineStrokeDashArray;
                });

            // Just to show we
            emarker
                .append('rect')
                .attr('class', function (d, lix) {
                    lix = self.legendClassArray.length - 1 - lix;
                    return 'aeRectClass' + lix;
                })
                .attr('x', x + width - 22)
                .attr('y', 0)
                .attr('width', 18)
                .attr('height', 18)
                .style('fill', function (d, lix) {
                    lix = self.legendClassArray.length - 1 - lix;
                    return self._getFillColor(lix);
                });

            emarker
                .append('circle')
                .attr('cx', x + width / 2)
                .attr('cy', 10)
                .attr('r', function (d, i) {
                    return 4; // self.markers[i].size;
                })
                .style('fill', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getColorFromRef(self.markers[lix].markerColorRef, self._getFillColor(lix));
                })
                .style('fill-opacity', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getOpacityFromRef(self.markers[lix].markerColorRef);
                })
                .style('stroke', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getColorFromRef(self.markers[lix].outlineColorRef);
                })
                .style('stroke-opacity', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return GGCPrimitives._getOpacityFromRef(self.markers[lix].outlineColorRef);
                })
                .style('stroke-width', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return self.markers[lix].outlineWidth;
                })
                .style('stroke-dasharray', function (d, lix) {
                    lix = self.markers.length - lix - 1;
                    return self.markers[lix].outlineStrokeDashArray;
                });


            // Now, need edit Line for each line  (width, pattern, color...)
            // And Edit line properties (held in markers array)
            for (let i = 0; i < self.markers.length; i++) {
                let lix = self.markers.length - i - 1;
                self._drawCircleEditOutline({
                    selector: self.diagram,
                    kind: 'asline',
                    title: i18next.t("graph.edit.line"),
                    x: x,
                    y: y + 12 + i * 20,
                    r: 12,
                    placement: 'bottom',
                    variables: {
                        strokeWidthProp: 'markers.' + lix + '.lineWidth',
                        strokeDashArrayProp: 'markers.' + lix + '.lineStrokeDashArray',
                        colorRefProp: 'markers.' + lix + '.lineColorRef'
                    }
                });
            }
            // Now, the shape/fill
            for (let i = 0; i < self.markers.length; i++) {
                let lix = self.markers.length - i - 1;
                self._drawCircleEditMarker({
                    selector: self.diagram,
                    kind: 'asmarker',
                    title: i18next.t("graph.edit.marker_outlin_fill"),
                    x: x + (width / 2) + 12,
                    y: y + 12 + i * 20,
                    r: 12,
                    placement: 'left',
                    variables: {
                        shapeNameProp: 'markers.' + lix + '.mark',
                        strokeWidthProp: 'markers.' + lix + '.outlineWidth',
                        strokeColorRefProp: 'markers.' + lix + '.outlineColorRef',
                        markerSizeProp: 'markers.' + lix + '.size',
                        colorRefProp: 'markers.' + lix + '.markerColorRef'
                    }
                });
            }



            emarker.exit().remove();

        }
    }

    _drawCurves() {
        var self = this;
        var pfunc;
        for (let i = 0; i < self.valueNames.length; i++) {
            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                if (self.filler) {
                    pfunc = d3.area()
                        .curve(d3.curveMonotoneX)
                        .x(function (d) {
                            return self.xscale(d.values[i].myix) + self.xscale.bandwidth() / 2;
                        })
                        .y0(function (d) {
                            return self.yscale(d.values[i].y0);
                        })
                        .y1(function (d) {
                            return self.yscale(d.values[i].y1);
                        });
                } else if (self.line) {
                    pfunc = d3.line()
                        .x(function (d) {
                            return self.xscale(d.values[i].myix) + self.xscale.bandwidth() / 2;
                        })
                        .y(function (d) {
                            return self.yscale(d.values[i].y1);
                        });
                } else {
                    pfunc = d3.line()
                        .curve(d3.curveMonotoneX)
                        .x(function (d) {
                            return self.xscale(d.values[i].myix) + self.xscale.bandwidth() / 2;
                        })
                        .y(function (d) {
                            return self.yscale(d.values[i].y1);
                        });
                }


                self.barsHolder.append('path')
                    .attr('d', pfunc(self.cur_data))
                    .attr("class", function (d) {
                        let classLabel = self._fixName(self.valueNames[i]); //remove spaces, etc.
                        return "class" + classLabel;
                    })
                    .style('fill', function (d) {
                        if (self.filler)
                            return self._getFillColor(i);

                        else
                            return 'none';
                    })
                    .style('fill-opacity', function (d) {
                        if (self.filler)
                            return self._getOpacity(i);

                        else
                            return 1.0;
                    })
                    .style('stroke', function (d) {
                        return GGCPrimitives._getColorFromRef(self.markers[i].lineColorRef, self._getFillColor(i));
                    })
                    .style('stroke-opacity', function (d) {
                        return GGCPrimitives._getOpacityFromRef(self.markers[i].lineColorRef);
                    })
                    .style('stroke-width', function (d) {
                        return self.markers[i].lineWidth;
                    })
                    .style('stroke-dasharray', function (d) {
                        return self.markers[i].lineStrokeDashArray;
                    })
                    .on('mouseover', function (event, d) {
                        if (self.editMode) return;
                        self.focus.style('opacity', 1);
                    })
                    .on('mousemove', function (event, d) {
                        if (!self.editMode) {
                            let coords = d3.pointer(event);
                            let x = coords[0];

                            // left-pad = step × paddingOuter × align × 2
                            var xpos = x; // - self.leftPadding;
                            var x0 = Math.floor(xpos / (self.xscale.step()));
                            if (x0 < 0) x0 = 0;
                            else if (x0 >= self.cur_data.length) x0 = self.cur_data.length - 1;

                            x0 += self.xscale.domain()[0];      // position in original array?

                            let fx = self.margin.left + self.xscale(x0) + self.xscale.bandwidth() / 2;

                            self.focus
                                .attr("x1", fx)
                                .attr("x2", fx)
                                .attr('y1', self.yscale(self.yscale.domain()[0]) + self.margin.top)
                                .attr('y2', self.yscale(self.yscale.domain()[1]) + self.margin.top);

                            var lix = 0;
                            let dix = x0 - self.xscale.domain()[0];
                            for (let i = 0; i < self.valueNames.length; i++) {
                                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                                    self.tooltipSetRow(self.ntips - lix - 1, self.valueNames[i], self.cur_data[dix].values[i].y1);
                                    lix++;
                                }
                            }
                            //v5 let tx = d3.event.offsetX;
                            //v5 let ty = d3.event.offsetY;
                            let tx = coords[0];
                            let ty = coords[1];
                            self.tooltipShow(tx + self.margin.left, (ty + self.margin.top));
                        }

                    })
                    .on('mouseout', function (d) {
                        if (!self.editMode) {
                            self.focus.style('opacity', 0);
                            self.tooltipHide();
                        }
                    });

                self._refreshMarker(i);

            }

        };

    }
    _setBarsMouseEvents() {
        var self = this;

        // Highlight the Category axis labels on mouseover
        this.diagram.select(".x.axis")
            .selectAll('.tick')
            .on("mouseover", function (event, d) {
                if (self.editMode) return;

                // TODO: if text() != attr('textFull'), then show tooltip w/ full text, on click generate event
                // else, do default display bolding.  this.getBoundingClientRect()  gets x, y,width,height
                var fullText = d3.select(this).select("text").attr('fullText');
                if (fullText !== d3.select(this).select("text").text()) {

                    var bnds = d3.select(this).node().getBBox();

                    self.diagram.append("text")
                        .attr("x", (bnds.x + bnds.width) / 2)
                        .attr("y", (bnds.y + bnds.height) / 2)
                        .attr("class", "xlabelGGC")
                        .text(fullText)
                        .style("font", this.font);

                    var tbx = self.diagram.select('.xlabelGGC').node().getBBox();

                    self.diagram.insert('rect', '.xlabelGGC')
                        .attr('x', tbx.x - 2)
                        .attr('y', tbx.y - 2)
                        .attr('width', tbx.width + 4)
                        .attr('height', tbx.height + 4)
                        .attr('class', 'xlabelBox')
                        .style('fill', 'white')
                        .style('stroke', 'blue')
                        .on('click', function () {
                            var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                            var tpsf_event = {
                                // eid:
                                // schema:
                                table: self.tableName,
                                field: self.categoryNames[ixC],
                                value: d3.select(this).select("text").attr('fullText') // text()
                            };

                            self.diagram.select(".xlabelBox").remove();
                            self.diagram.select(".xlabelGGC").remove();

                            self.categoryClick(tpsf_event);

                        });


                } else {
                    d3.select(this).attr('fill', 'blue');
                    d3.select(this).attr('font-weight', 'bold');
                    d3.select(this).style("cursor", "pointer");
                }
            })
            .on("mouseout", function () {
                if (self.editMode) return;

                d3.select(this).attr('fill', 'black');
                d3.select(this).attr('font-weight', 'normal');
                d3.select(this).style("cursor", "auto");

                self.diagram.select(".xlabelBox").remove();
                self.diagram.select(".xlabelGGC").remove();
            })
            .on("click", function () {
                if (self.editMode) return;

                var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                var tpsf_event = {
                    // eid:
                    // schema:
                    table: self.tableName,
                    field: self.categoryNames[ixC],
                    value: d3.select(this).select("text").attr('fullText') // text()
                };
                self.categoryClick(tpsf_event);
            });

    }
    _refreshMarker(i) {
        var self = this;

        if (self.markersSvg[i] && self.markersSvg[i].hasOwnProperty('svgMarker') && self.markersSvg[i].svgMarker.documentElement) {
            self.barsHolder.selectAll('.dot' + i).remove();
            self.barsHolder.selectAll('.dot' + i)
                .data(self.cur_data)
                .enter()
                .append('svg')
                .attr('x', function (d, ix) {
                    return self.xscale(d.values[i].myix) + self.xscale.bandwidth() / 2 - self.markers[i].size;
                })
                .attr('y', function (d) {
                    return self.yscale(d.values[i].y1) - self.markers[i].size;
                })
                .attr('width', self.markers[i].size * 2)
                .attr('height', self.markers[i].size * 2)
                .attr("class", function (d) {
                    let classLabel = self._fixName(self.valueNames[i]); //remove spaces
                    return "class" + classLabel + " dot" + i;
                })
                .append(() => self.markersSvg[i].svgMarker.documentElement.cloneNode(true));

            var virtWidth = self.markersSvg[i].svgMarker.documentElement.viewBox.baseVal.width;
            // range for outlineWidth = 0..5
            self.barsHolder.selectAll('.dot' + i)
                .selectAll('path')
                .style('fill', function (d) {
                    return GGCPrimitives._getColorFromRef(self.markers[i].markerColorRef, self._getFillColor(i));
                })
                .style('fill-opacity', function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.markers[i].markerColorRef);
                })
                .style('stroke', function (d) {
                    return GGCPrimitives._getColorFromRef(self.markers[i].outlineColorRef);
                })
                .style('stroke-opacity', function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.markers[i].outlineColorRef);
                })
                .style('stroke-width', function (d) {
                    return self.markers[i].outlineWidth * virtWidth / 20; // 0..5 == 0..virtWidth/4
                })
                .style('stroke-dasharray', function (d) {
                    return self.markers[i].outlineStrokeDashArray;
                })
                .attr("class", function (d) {
                    let classLabel = self._fixName(self.valueNames[i]); //remove spaces
                    return "class" + classLabel + " dot" + i;
                });

        } else {
            self.barsHolder.selectAll('.dot' + i)
                .data(self.cur_data)
                .enter()
                .append('circle')
                .attr('cx', function (d, ix) {
                    return self.xscale(d.values[i].myix) + self.xscale.bandwidth() / 2;
                })
                .attr('cy', function (d) {
                    return self.yscale(d.values[i].y1);
                })
                .attr('r', function (d) {
                    return 4; // self.markers[i].size;
                })
                //                    .attr('class', 'dot'+i)
                .style('fill', function (d) {
                    return GGCPrimitives._getColorFromRef(self.markers[i].markerColorRef, self._getFillColor(i));
                })
                .style('fill-opacity', function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.markers[i].markerColorRef);
                })
                .style('stroke', function (d) {
                    return GGCPrimitives._getColorFromRef(self.markers[i].outlineColorRef);
                })
                .style('stroke-opacity', function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.markers[i].outlineColorRef);
                })
                .style('stroke-width', function (d) {
                    return self.markers[i].outlineWidth;
                })
                .style('stroke-dasharray', function (d) {
                    return self.markers[i].outlineStrokeDashArray;
                })
                .attr("class", function (d) {
                    let classLabel = self._fixName(self.valueNames[i]); //remove spaces
                    return "class" + classLabel + " dot" + i;
                });
        }


    }
    _loadMarkerUrls() {
        var self = this;

        for (let i = 0; i < self.markers.length; i++) {
            var name = self.markers[i].mark;
            // Turns out we need to set fill, etc. at runtime because we *may* not have the urls for fill, etc. if they are not
            // real colors/rgb values.  Meaning, if they are .loc values, we have to set at runtime
            var loadIt = true;
            if (self.markersSvg[i] && self.markersSvg[i].hasOwnProperty('svgMarker')) {
                if (self.markersSvg[i].svgMarker.name === name) {
                    loadIt = false;
                }
            }
            if (loadIt) {
                if (typeof (self.markersSvg[i]) === 'undefined')
                    self.markersSvg[i] = { svgMarker: null };
                self.markersSvg[i].svgMarker = { name: name };
                d3.xml('https://qrcodegen.qr-answers.com/v1/colorsvg/?name=' + encodeURI(name))
                    .then(data => {
                        var width = data.documentElement.viewBox.baseVal.width;
                        var height = data.documentElement.viewBox.baseVal.height;
                        data.documentElement.viewBox.baseVal.x -= .20 * width;
                        data.documentElement.viewBox.baseVal.y -= .20 * height;
                        data.documentElement.viewBox.baseVal.width += .40 * width;
                        data.documentElement.viewBox.baseVal.height += .40 * height;

                        self.markersSvg[i].svgMarker.documentElement = data.documentElement;
                        self._refreshMarker(i);
                    });
            }
        }
    }
    refresh() {
        var self = this;
        var valueMax = null;
        var valueMin = null;

        this._colorDomainSet([{
            arrayName: 'markers',
            fields: ['markerColorRef', 'outlineColorRef', 'lineColorRef']
        }
        ]);


        if (this.stacked === 'yes') {
            this.data.forEach(function (d, ix) {
                var aggVal;
                d.values = [];
                for (let i = 0; i < self.valueNames.length; i++) {
                    let name = self.color.domain()[i];
                    let v = +d[self._valueIndex(name)];
                    if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                        if (i === 0) {
                            aggVal = v;
                        } else {
                            aggVal += v;
                        }
                    }
                    if (i === 0)
                        d.values.push({ myix: ix, vi: i, y0: 0, y1: aggVal });

                    else
                        d.values.push({ myix: ix, vi: i, y0: d.values[i - 1].y1, y1: aggVal });
                }
                if (!valueMax)
                    valueMax = aggVal;
                else if (aggVal > valueMax)
                    valueMax = aggVal;
                if (!valueMin)
                    valueMin = aggVal;
                else if (aggVal < valueMin)
                    valueMin = aggVal;
            });
        } else {
            this.data.forEach(function (d, ix) {
                d.values = [];
                for (let i = 0; i < self.valueNames.length; i++) {
                    let name = self.color.domain()[i];
                    let v = +d[self._valueIndex(name)];
                    if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                        if (!valueMax)
                            valueMax = v;
                        else if (v > valueMax)
                            valueMax = v;
                        if (!valueMin)
                            valueMin = v;
                        else if (v < valueMin)
                            valueMin = v;
                    }
                    d.values.push({ myix: ix, vi: i, y0: 0, y1: v });
                }
            });
        }

        if (valueMin > 0) valueMin = 0;
        this.yscale.domain([valueMin, valueMax]);

        var scrollBefore = this.isScrollDisplayed;

        this.isScrollDisplayed = self.barWidth * this.data.length > this.width;

        this.height = self.origHeight - this.margin.top - this.margin.bottom - (this.isScrollDisplayed ? this.selectorHeight : 0) - (this.categoryNames.length + 1) * self._axisLabelHeight;
        this.yscale.range([this.height, 0]); // if height changes because of isScrollDisplayed

        if (scrollBefore && !this.isScrollDisplayed) {
            self.diagram.selectAll('.overviewBar').remove();
            self.diagram.selectAll('.overviewMover').remove();
            self._axis_drawXY();
        } else if (!scrollBefore && this.isScrollDisplayed) {
            // need to show scroly area
            self._axis_drawXY();
            this._checkScroller();
        } else {
            self._axis_refresh();
        }

        self._axis_groupLabels(0);

        self.ntips = 0;
        for (let i = 0; i < self.valueNames.length; i++) {
            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                self.ntips++;
        }
        this.tooltipCreate(self.ntips);

        self.barsHolder.remove();

        self.barsHolder = self.diagram
            .append("g")
            .attr("class", "barsHolder")
            .attr("transform", function (d) { return "translate(0,0)"; });

        self._drawCurves();
        self._loadMarkerUrls();

        self._setBarsMouseEvents();

        self._base_makeLegend();

        if (self.editMode) {
            var emarker = this.markersHolder.selectAll('.eachMarker');

            for (let i = 0; i < self.markers.length; i++) {
                let lix = self.markers.length - i - 1;
                emarker
                    .selectAll('.aeRectClass' + lix)
                    .style('fill', function (d) {
                        //                        lix = self.legendClassArray.length - 1 - lix;
                        return self._getFillColor(lix);
                    });

                emarker
                    .selectAll('.aelineClass' + lix)
                    .style('stroke', function (d) {
                        return GGCPrimitives._getColorFromRef(self.markers[lix].lineColorRef, self._getFillColor(lix));
                    })
                    .style('stroke-opacity', function (d) {
                        return GGCPrimitives._getOpacityFromRef(self.markers[lix].lineColorRef);
                    })
                    .style('stroke-width', function (d) {
                        return self.markers[lix].lineWidth;
                    })
                    .style('stroke-dasharray', function (d) {
                        return self.markers[lix].lineStrokeDashArray;
                    });
            }

        }

    }
    _checkScroller() {
        var self = this;

        function display(event) {   // v6 added event
            //v5 var x = parseInt(d3.select(this).attr("x")), nx = x + d3.event.dx, w = parseInt(d3.select(this).attr("width")), f, nf;
            let x = parseInt(d3.select(this).attr("x"));
            let nx = x + event.dx;
            let w = parseInt(d3.select(this).attr("width")), f, nf;

            if (nx < 0) { // drug off the left (minimum)
                if (self._dataRange[0] === 0)
                    return;
                d3.select(this).attr("x", x);
                nf = 0;
            } else if (nx + w > Math.ceil(self.width)) { // drug off right
                if (self._dataRange[1] === self.data.length)
                    return;
                d3.select(this).attr("x", self.width - d3.select(this).attr("width"));
                nf = self.data.length - self.numBars;
            } else {
                d3.select(this).attr("x", nx);
                f = self.displayed(x);
                nf = self.displayed(nx);
                if (f === nf) return;
            }


            //            if ( nx < 0 || nx + w > Math.ceil(self.width) ) return;
            //            d3.select(this).attr("x", nx);
            //            f = self.displayed(x);
            //            nf = self.displayed(nx);
            //            if ( f === nf ) return;
            self.cur_data = self.data.slice(nf, nf + self.numBars);
            self._dataRange = [nf, nf + self.numBars];

            self.xscale.domain(self.cur_data.map(function (d, ix) {
                return nf + ix;
            }));

            self._axis_refresh();

            self.barsHolder.remove();

            self.barsHolder = self.diagram
                .append("g")
                .attr("class", "barsHolder")
                .attr("transform", function (d) { return "translate(0,0)"; });

            self._drawCurves();

            self._setBarsMouseEvents();

            self._axis_groupLabels(nf);

        };

        if (this.isScrollDisplayed) {
            // V5
            var xOverview = d3.scaleBand()
                .domain(self.data.map(function (d, ix) { return ix; }))
                .range([0, self.width], .2);
            var yOverview = d3.scaleLinear().range([self.heightOverview, 0]);
            yOverview.domain(self.yscale.domain());

            var subBars = self.diagram
                .append('g')
                .attr('class', 'overviewBar');

            //                .data(self.data);
            // Only draw the 'max' value of each values array that id 'active' (meanin not hidden)
            var pfunc;
            if (self.filler) {
                pfunc = d3.area()
                    .curve(d3.curveMonotoneX)
                    .x(function (d) {
                        return xOverview(d.values[0].myix) + xOverview.bandwidth() / 2;
                    })
                    .y0(function (d) {
                        let mymin = null;
                        for (let i = 0; i < d.values.length; i++) {
                            if (mymin == null)
                                mymin = d.values[i].y0;
                            else if (d.values[i].y0 < mymin)
                                mymin = d.values[i].y0;
                        }
                        return self.height + self.margin.bottom + (self.selectorHeight - self.heightOverview) + self._axisLabelHeight * (self.categoryNames.length + 1) + yOverview(mymin);
                    })
                    .y1(function (d) {
                        let mymax = null;
                        for (let i = 0; i < d.values.length; i++) {
                            if (mymax == null)
                                mymax = d.values[i].y1;
                            else if (d.values[i].y1 > mymax)
                                mymax = d.values[i].y1;
                        }
                        return self.height + self.margin.bottom + (self.selectorHeight - self.heightOverview) + self._axisLabelHeight * (self.categoryNames.length + 1) + yOverview(mymax);
                    });
            } else if (self.line) {
                pfunc = d3.line()
                    .x(function (d) {
                        return xOverview(d.values[0].myix) + xOverview.bandwidth() / 2;
                    })
                    .y(function (d) {
                        let mymax = null;
                        for (let i = 0; i < d.values.length; i++) {
                            if (mymax == null)
                                mymax = d.values[i].y1;
                            else if (d.values[i].y1 > mymax)
                                mymax = d.values[i].y1;
                        }
                        return self.height + self.margin.bottom + (self.selectorHeight - self.heightOverview) + self._axisLabelHeight * (self.categoryNames.length + 1) + yOverview(mymax);
                    });
            } else {
                pfunc = d3.line()
                    .curve(d3.curveMonotoneX)
                    .x(function (d) {
                        return xOverview(d.values[0].myix) + xOverview.bandwidth() / 2;
                    })
                    .y(function (d) {
                        let mymax = null;
                        for (let i = 0; i < d.values.length; i++) {
                            if (mymax == null)
                                mymax = d.values[i].y1;
                            else if (d.values[i].y1 > mymax)
                                mymax = d.values[i].y1;
                        }
                        return self.height + self.margin.bottom + (self.selectorHeight - self.heightOverview) + self._axisLabelHeight * (self.categoryNames.length + 1) + yOverview(mymax);
                    });
            }


            subBars.append('path')
                .attr('d', pfunc(self.data))
                .classed(self.filler ? 'overviewBar' : 'overviewBarNoFill', true);



            // V5
            self.displayed = d3.scaleQuantize()
                .domain([0, self.width])
                .range(d3.range(self.data.length));

            var ovWidth = Math.round(parseFloat(self.numBars * self.width) / self.data.length);
            // If the grabber is too small, make it bigger
            if (ovWidth < 10) ovWidth = 10;

            self.diagram.append("rect")
                .attr("transform", "translate(0, " + (self.height + self.margin.bottom + self._axisLabelHeight * (self.categoryNames.length + 1)) + ")")
                .attr("class", "overviewMover")
                .attr("x", 0)
                .attr("y", 0)
                .attr("height", self.selectorHeight)
                .attr("width", ovWidth)
                .attr("pointer-events", "all")
                .attr("cursor", "ew-resize")
                .call(d3.drag().on("drag", display));
        }
    }
}

//areasplineGGC.prototype = new axisGGC();

