import React from "react";
import {FaRegEye, FaRegEyeSlash, FaLock, FaLockOpen} from "react-icons/fa";
import { colors } from "../../utils/Utils";

// locked and onLock are optional to show the lock icons
const ViewHideIcon = ({checked, disabled=false, style={},
	label, onClick, eyeTitle=null,
	locked, onLockClick=null, lockTitle=null}) => {

	return (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...style }}>
			<div title={eyeTitle} style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }} disabled={disabled} onClick={disabled ? () => null : onClick}>
				{checked ? (
					<FaRegEye style={{ cursor: (disabled ? 'auto' : 'pointer'), color: (disabled ? 'gray' : colors.primary), marginRight: 4 }} />
				) : (
					<FaRegEyeSlash style={{ cursor: (disabled ? 'auto' : 'pointer'), color: (disabled ? 'gray' : colors.primary), marginRight: 4 }} />
				)}
			</div>
			{typeof onLockClick === 'function' ?
				<div title={lockTitle} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8 }} onClick={onLockClick}>
					{locked ? (
						<FaLock style={{ cursor: (disabled ? 'auto' : 'pointer'), color: (disabled ? 'gray' : colors.primary), marginRight: 4 }} />
					) : (
						<FaLockOpen style={{ cursor: (disabled ? 'auto' : 'pointer'), color: (disabled ? 'gray' : colors.primary), marginRight: 4 }} />
					)}
				</div>
			: <div style={{marginLeft: 27}}></div>
			}
				<span style={{ color: (disabled ? 'gray' : 'black'), paddingLeft: 8, verticalAlign: 'middle' }}>{label}</span>
		</div>
	)
}
export default ViewHideIcon;