
export const onCreateProjectByClientId = /* GraphQL */ `
  subscription OnCreateProjectByClientId($clientID: String!) {
    onCreateProjectByClientId(clientID: $clientID) {
      id
      clientID
      name
      abbreviation
      tags
      description
      recordStatus
      groupR
      groupCRUD
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProjectByClientId = /* GraphQL */ `
  subscription OnDeleteProjectByClientId($clientID: String!) {
    onDeleteProjectByClientId(clientID: $clientID) {
      id
      clientID
      name
      abbreviation
      tags
      description
      recordStatus
      groupR
      groupCRUD
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProjectByClientId = /* GraphQL */ `
  subscription OnUpdateProjectByClientId($clientID: String!) {
    onUpdateProjectByClientId(clientID: $clientID) {
      id
      clientID
      name
      abbreviation
      tags
      description
      recordStatus
      groupR
      groupCRUD
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignByClientId = /* GraphQL */ `
  subscription OnCreateCampaignByClientId($clientID: String!) {
    onCreateCampaignByClientId(clientID: $clientID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignByProjectId = /* GraphQL */ `
  subscription OnCreateCampaignByProjectId($projectID: String!) {
    onCreateCampaignByProjectId(projectID: $projectID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignByClientId = /* GraphQL */ `
  subscription OnDeleteCampaignByClientId($clientID: String!) {
    onDeleteCampaignByClientId(clientID: $clientID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteCampaignByProjectId = /* GraphQL */ `
  subscription OnDeleteCampaignByProjectId($projectID: String!) {
    onDeleteCampaignByProjectId(projectID: $projectID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignByClientId = /* GraphQL */ `
  subscription OnUpdateCampaignByClientId($clientID: String!) {
    onUpdateCampaignByClientId(clientID: $clientID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignByProjectId = /* GraphQL */ `
  subscription OnUpdateCampaignByProjectId($projectID: String!) {
    onUpdateCampaignByProjectId(projectID: $projectID) {
      id
      projectID
      clientID
      name
      abbreviation
      tags
      disposition
      schedule
      description
      settings
      startDate
      endDate
      recordStatus
      groupR
      groupCRUD
      automatic
      scheduleInfo
      createdAt
      updatedAt
    }
  }
`;
export const onResponseBySpecificQuestion = /* GraphQL */ `
  subscription OnResponseBySpecificQuestion($baseId: String!) {
    onResponseBySpecificQuestion(baseId: $baseId) {
      clientId
      projectId
      baseId
      answerId
      count
    }
  }
`;
export const onResponseByProjectId = /* GraphQL */ `
  subscription OnResponseByProjectId($projectId: String!) {
    onResponseByProjectId(projectId: $projectId) {
      clientId
      projectId
      baseId
      answerId
      count
    }
  }
`;
export const onCreateRQAByClientId = /* GraphQL */ `
  subscription OnCreateRQAByClientId($clientID: String!) {
    onCreateRQAByClientId(clientID: $clientID) {
      id
      campaignID
      projectID
      clientID
      questionAnswers
      recordStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRQAByClientId = /* GraphQL */ `
  subscription OnDeleteRQAByClientId($clientID: String!) {
    onDeleteRQAByClientId(clientID: $clientID) {
      id
      campaignID
      projectID
      clientID
      questionAnswers
      recordStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRQAByClientId = /* GraphQL */ `
  subscription OnUpdateRQAByClientId($clientID: String!) {
    onUpdateRQAByClientId(clientID: $clientID) {
      id
      campaignID
      projectID
      clientID
      questionAnswers
      recordStatus
      createdAt
      updatedAt
    }
  }
`;