import i18next from 'i18next';
import * as d3 from 'd3';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import { _genId } from './domutils';
import { graphBaseGGC } from './graphbaseggc';
import { isRuntimeOnly } from '../utils/Utils';

export class pivotGGC extends graphBaseGGC {
	constructor(args) {
		super(args);

		this.editMode = false;
		this.graphData = undefined;
		this.container = undefined;
		this.drawn = false;
		this.width = undefined;
		this.height = undefined;
		this.svg = undefined;
		this.diagram = undefined;
		this.table_id = undefined;
		this.enableSearching = true;
		this.stripe = true;
		this.scaleFonts = true;
		this.designWidth = undefined;
		this.designHeight = undefined;

		this.rowFont = this.font;		// 12px sans-serif
		this.rowFontStyle = '';
		this.rowFontColor = '#000000';
		this.headerFont = this.font;
		this.headerFontStyle = 'B';
		this.headerFontColor = '#000000'

		this._init(args);

	}
	_init(args) {
		this.editMode = false;
		this.graphData = null;

		this.outerContainer = args.container;	// myId

		var cid = 'Piv' + _genId();
		$(this.outerContainer).append('<div style="width: 100%; height: 100%; position:relative;" id="' + cid + '"></div>');
		this.container = '#' + cid;

		this._initVariables(args);
	}

	_initVariables(args) {
		if (args.hasOwnProperty('rowFont')) {
			this.rowFont = args.rowFont;
		}
		if (args.hasOwnProperty('rowFontStyle')) {
			this.rowFontStyle = args.rowFontStyle;
		}
		if (args.hasOwnProperty('rowFontColor')) {
			this.rowFontColor = args.rowFontColor;
		}
		if (args.hasOwnProperty('enableSearching')) {
			this.enableSearching = args.enableSearching;
		}
		if (args.hasOwnProperty('stripe')) {
			this.stripe = args.stripe;
		}
		if (args.hasOwnProperty('scaleFonts')) {
			this.scaleFonts = args.scaleFonts;
		}
		if (args.hasOwnProperty('designWidth')) {
			this.designWidth = args.designWidth;
		}
		if (args.hasOwnProperty('designHeight')) {
			this.designHeight = args.designHeight;
		}
		if (args.hasOwnProperty('headerFont')) {
			this.headerFont = args.headerFont;
		}
		if (args.hasOwnProperty('headerFontStyle')) {
			this.headerFontStyle = args.headerFontStyle;
		}
		if (args.hasOwnProperty('headerFontColor')) {
			this.headerFontColor = args.headerFontColor;
		}
		// NOTE: no need for viewBox here because the svg is only used for edit mode (not runtime)


	}
	_saveProps() {
		// searching: true|false
		// prdering: true | false
		// 
		return {
			rowFont: this.rowFont,
			rowFontStyle: this.rowFontStyle,
			rowFontColor: this.rowFontColor,
			scaleFonts: this.scaleFonts,
			enableSearching: this.enableSearching,
			stripe: this.stripe,
			designWidth: this.width,
			designHeight: this.height,
			headerFont: this.headerFont,
			headerFontStyle: this.headerFontStyle,
			headerFontColor: this.headerFontColor
		};
	}

	getGraphType() {
		return 'pivot';
	}
	
	_toggleEdit() {
		var self = this;
		self.editMode = !self.editMode;
		if (!self.editMode) {
			$('.popover').popover('hide');
			if (self.svg) {
				self.svg.remove();
				self.svg = null;
			}
		}
		self.resize({ width: $(self.outerContainer).width(), height: $(self.outerContainer).height() });

		return self.editMode;
	}
	getEditMode() {
		return this.editMode;
	}
	resize(opts) {
		var self = this;

		var width = opts.width;
		var height = opts.height;

		$('.popover').popover('hide');

		if (this.width !== width || this.height !== height) {
			this.width = width;
			this.height = height;
			this.drawPivot();
		}

		self.checkEditMode()
	}
	refresh(opts) {
		var self = this;
		self.drawPivot();
//		self.checkEditMode()
	}
	_refresh(kind) {
		this.refresh(kind);
		// check layering, we don't want to re-attach svg to something we remove
		this._bringEditToFront();
	}
	setData( data ) {
		// data.ajax.sampleData is routine to return graphData
		this.graphData = data.ajax.sampleData();
	}

  drawPivot = () => {
		const tableResponsiveExists = $('.table-responsive').length > 0;
		if (!tableResponsiveExists && typeof this.designWidth === 'undefined') {
			this.designHeight = $(this.outerContainer).height();
			this.designWidth = $(this.outerContainer).width();
		} else if (this.scaleFonts) {
			// if designWidth/designHeight is not width/height, then scale
			let designFontSize = this._getFontSizeFromFont(this.rowFont)
			//console.log(designFontSize);
			const oldRatio = this.designWidth / this.designHeight;
			const newRatio = this.width / this.height
			if (newRatio > oldRatio) {
				designFontSize = Math.round(100 * (this.width / this.designWidth) * designFontSize * this.height / this.designHeight) / 100;
				this.rowFont = designFontSize + 'px ' + this._getFontFamilyFromFont(this.rowFont);
			} else if (oldRatio > newRatio) {
				designFontSize = Math.round(100 * (this.height / this.designHeight) *  designFontSize * this.width / this.designWidth) / 100;
				this.rowFont = designFontSize + 'px ' + this._getFontFamilyFromFont(this.rowFont);
			}
			this.designHeight = this.height;
			this.designWidth = this.width;
		}

    $('.table-responsive').remove();

    try {
      this.table_id = "dt_" + _genId();
      let header = this.graphData.header.map((h) => {
        return { title: h };
      });
			// Can control some options here.  compact, hover,
			let cName = 'display cell-border table';

			if (this.stripe) {
				cName += ' stripe';
			}

//      $(this.container).append('<div class="table-responsive"><table id="' + this.table_id + '" class="' + cName + '" width="100%"></table></div>');
      $(this.container).prepend('<div class="table-responsive"><table id="' + this.table_id + '" class="' + cName + '" width="100%"></table></div>');
      var dtParams = {
        searching: this.enableSearching,
        data: this.graphData.data,
        columns: header,
        paging: false,
      }
			if (!this.stripe) {
				dtParams.stripeClasses = [];
			}

			new DataTable('#' + this.table_id, dtParams);


			this.applyStyling();

		} catch (err) {
			console.log(err);
		}

	}
	applyStyling = () => {
		if (this.headerFont) {
			$('#' + this.table_id + ' thead th').css('font', this.headerFont);
			$('#' + this.table_id + ' thead th').css('font-weight', this.headerFontStyle.indexOf('B') !== -1 ? 700 : 400)
			$('#' + this.table_id + ' thead th').css('text-decoration', this.headerFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');
			$('#' + this.table_id + ' thead th').css('font-style', this.headerFontStyle.indexOf('I') !== -1 ? 'italic' : 'normal');
			$('#' + this.table_id + ' thead th').css('color', this.headerFontColor);

		}
		$('#' + this.table_id + ' tbody tr').css('font', this.rowFont);
		$('#' + this.table_id + ' tbody tr').css('font-weight', this.rowFontStyle.indexOf('B') !== -1 ? 700 : 400)
		$('#' + this.table_id + ' tbody tr').css('text-decoration', this.rowFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');
		$('#' + this.table_id + ' tbody tr').css('font-style', this.rowFontStyle.indexOf('I') !== -1 ? 'italic' : 'normal');
		$('#' + this.table_id + ' tbody tr').css('color', this.rowFontColor);

	}

	checkEditMode = () => {
		var self = this;

		if (self.editMode) {
			
			if (self.svg) {
				self.svg.remove();
				self.svg = null;
			}

			let wid = $(self.outerContainer).width();
			let ht = $(self.outerContainer).height();

			if (!self.svg) {

				self.svg = d3.select(this.container).append("svg")
					.attr('xmlns', "http://www.w3.org/2000/svg")
					.attr('id', 'svg_' + self.table_id)
					.style('position', 'absolute')
					.attr('width', wid)
					.attr('height', ht)
					.attr("viewBox", [0, 0, wid, ht].join(' '))
					.style('top', 0)
					.style('left', 0);

				self.diagram = self.svg.append('g')
				  .attr('class', self._editClass)
					.on('click', function () {
						$('.popover').popover('hide');
					});

			}

				let x = 20;
				let y = 100;
				self._drawCircleEditText({
					selector: self.diagram,
					kind: 'pivtext',
					label: null,
					title: 'Font',
					drawLabel: false,
					x: x,
					y: y,
					r: 12,
					placement: 'top',
					variables: {
							fontProp: 'rowFont',
							styleProp: 'rowFontStyle',
							colorProp: 'rowFontColor'
					}
			});

			x = 40;
			// find first table -> thead -> tr -> th
			const firstTh = $('#' + this.table_id + ' thead tr th:first-child').offset();
			const trLoc = $('.table-responsive').offset();

			x = firstTh.left - trLoc.left + 20;	// +10 puts you on exact th border,
			y = firstTh.top - trLoc.top + 20;
			self._drawCircleEditText({
				selector: self.diagram,
				kind: 'phdrtext',
				label: null,
				title: 'Header Font',
				drawLabel: false,
				x: x,
				y: y,
				r: 12,
				placement: 'top',
				variables: {
						fontProp: 'headerFont',
						styleProp: 'headerFontStyle',
						colorProp: 'headerFontColor'
				}
		});

			//find: <div class="dataTables_filter"...> searching t/f
			// place boolean T/F checkbox in popover
			x = wid - 245;
			y = 24;

			self._drawCircleEditBoolean({
				selector: self.diagram,
				kind: 'boolsrch',
				name: 'srchyn',
				title: i18next.t("graph.edit.search"),
				x: x,
				y: y,
				r: 12,
				placement: 'right',
				variables: {
					booleanProp: 'enableSearching'
				}

			});


			// Cell border T/F

			// STriping T/F (colors?)  background-color of  class=odd or even

			y = $('#' + this.table_id).height() / 2;
			if (self.enableSearching) {
				y += 44;		// height of search..
			}

			self._drawCircleEditBoolean({
				selector: self.diagram,
				kind: 'boolstripe',
				name: 'stripeyn',
				title: i18next.t("graph.edit.stripe"),
				x: wid/2,
				y: y,
				r: 12,
				placement: 'bottom',
				variables: {
					booleanProp: 'stripe'
				}

			});

		}
	}


}
