import {useEffect, useState} from 'react';
import TreeAccordionView from './TreeAccordionView';
import TreeAccordionView2 from './TreeAccordionView2';
import { _genId } from '../../graphs/domutils';
import {FaPlusSquare} from "react-icons/fa";
import {AiOutlineNodeCollapse} from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/Utils';

const TreeViewAccordion = ({data, style, compactStyle, label, leafProp='leaf', onSelect=() => null}) => {
	const [filteredData, setFilteredData] = useState(data);
	const mySearchId = 's' + _genId();
	const [tvsId, setTvsId] = useState("tvsgcc"+_genId());
	const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation();

	useEffect(() => {
		setFilteredData(data);
	}, [data]);
	
	const defaultMatcher = (filterText, node) => {
		return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
	};
	
	const findNode = (node, filter, matcher) => {
		return matcher(filter, node) || // i match
				(node.children && // or i have decendents and one of them match
						node.children.length &&
						!!node.children.find(child => findNode(child, filter, matcher)));
	};
	
	const filterTree = (node, filter, matcher = defaultMatcher) => {
		// If im an exact match then all my children get to stay
		if (matcher(filter, node) || !node.children || !node.children.length) {
				return node;
		}
		// If not then only keep the ones that match or have matching descendants
		const filtered = node.children
				.filter(child => findNode(child, filter, matcher))
				.map(child => filterTree(child, filter, matcher));
		return Object.assign({}, node, {children: filtered});
	};
	
	
	const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
		let children = node.children;
		if (!children || children.length === 0) {
				return Object.assign({}, node, {toggled: false});
		}
		const childrenWithMatches = node.children.filter(child => findNode(child, filter, matcher));
		const shouldExpand = childrenWithMatches.length > 0;
		// If im going to expand, go through all the matches and see if thier children need to expand
		if (shouldExpand) {
				children = childrenWithMatches.map(child => {
						return expandFilteredNodes(child, filter, matcher);
				});
		}
		return Object.assign({}, node, {
				children: children,
				toggled: shouldExpand
		});
	};

	const onSearchKey = (e) => {
		let x = document.getElementById(mySearchId);
		let val = x.value.toLowerCase();
		if (val === "") {
			setFilteredData(data);
		} else {
			// Get tricky here... filter and only show those nodes with matching text..thru the chilren
			let filtered = filterTree(data, val);
			/*
			let filtered = data.filter((item) => {
				return item.name.toLowerCase().includes(val);
			});
			*/
			setFilteredData(filtered);
		}	
	}

	const handleClick = (e) => {
		if (document.getElementById(tvsId).contains(e.target)){
			// Clicked in box
		} else if (expanded) {
			setExpanded(false);
		}
	}
	
	useEffect(() => {
		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		}
	}, [expanded]);
	
	const dbClicked = (ev) => {
		setExpanded(true);
		ev.stopPropagation();
	}
	
	const onLocalSelect = (id) => {
		onSelect(id);
			setExpanded(false);
	}

	if (expanded) {
		return (
			<div id={tvsId} style={{ ...style }}>
				<div style={{ margin: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
					<input id={mySearchId} type="text" placeholder={t("search")} onKeyUp={onSearchKey}
						style={{
							width: '100%', height: '30px', borderRadius: 5, border: '1px solid #ccc', padding: 5
						}}
					/>
					<AiOutlineNodeCollapse style={{ 
						cursor: 'pointer', color:'black',
						marginLeft: 8, marginRight: 8
					}} 
						onClick={() => {
							// how to collapse TreeAccordionView
							let newDt = JSON.parse(JSON.stringify(data));
							setFilteredData(newDt);
						}}
					/>
				</div>

				<div style={{ overflowX: 'hidden', overflowY: 'auto', height: 'calc(100% - 50px)' }}>
					<TreeAccordionView2 level={0} item={filteredData} 
					leafProp={leafProp}  onSelect={onLocalSelect} />
				</div>
			</div>
		);
	} else {
		return (
			<div id={tvsId} style={{...compactStyle}}>
				<div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
				<FaPlusSquare style={{ cursor: 'pointer', color:colors.primary }}
					onClick={dbClicked}
				/>
				{label &&
					<div onClick={dbClicked} style={{cursor:"pointer", color: colors.primary, fontWeight: 'normal', marginLeft: 8}}>{label}</div>
				}
				</div>
			</div>
		)
	}
}

export default TreeViewAccordion;