
var _rootdom = null;
var _subdomain = null;
var _apiKey = null;
var _runtime = false;
var _qrcontest = false;

export const setIsQRContest = (qrcontest) => {
	_qrcontest = qrcontest;
}
export const isQRContest = () => {
	return _qrcontest;
}
export const setRuntimeOnly = (runtime) => {
	_runtime = runtime;
}
export const isRuntimeOnly = () => {
	return _runtime;
}

export const setRootDomain = (dom) => {
  _rootdom = dom;
}
export const getRootDomain = () => {
	return _rootdom;
}
export const isQRVote = () => {
	return _rootdom === 'qr-contest.com';
}

// https://dashboards.qr-answers.com   https://dev.dashboards.qr-answers.com
// or qr-contest...
export const setRootDomainFromHref = (href) => {

	let host;

	if (
		href.indexOf('.amplifyapp.com') !== -1 ||
		href.indexOf('localhost') !== -1 ||
		href.indexOf('192.168.7.106') !== -1
	) {
		const ix = href.indexOf('host=');		// e.g.  dev.dashboards.qr-answers.com or dashboards.qr-answers.com
		if (ix !== -1) {
			host = href.substring(ix + 5);
			let ixAnd = host.indexOf('&');
			if (ixAnd !== -1) {
				host = host.substring(0, ixAnd);
			}
		} else {
			host = "dashboards.qr-answers.com";		// or leave off dev (TESTING)
		}
	} else {
		let domain = (new URL(href));
		host = domain.hostname;
	}
	let splitArr = host.split('.');
	if (splitArr.length >=  2) {
		_rootdom = splitArr[splitArr.length - 2] + '.' + splitArr[splitArr.length - 1];
		if (_rootdom === 'amplify.com') {
			_rootdom = 'qr-answers.com';		// pick one...
		}
		if (splitArr.length > 2) {
			for (let i=0; i<splitArr.length - 2; i++) {
				if (i === 0) {
					_subdomain = splitArr[i];
				} else {
					_subdomain += '.' + splitArr[i];
				}
			}
		}
	}
}

export function debounce (fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

// QR-Answers
export const getRootDomainCased = () => {
  var dom = getRootDomain();
  if (dom === 'qr-answers.com') {
    return "QR-Answers";
  } else {
    return "QR-Contest";
  }
}

export const isDeveloperDomain = () => {
	if (_subdomain === 'dev.dashboards') {
		return true;
	} else {
		return false;
	}
}

export const setApiKey = (key) => {
	_apiKey = key;
}
export const getApiKey = () => {
	return _apiKey;
}

export const cvtFontSize = (size) => {
	return size;
}

export const applyAlpha = (color, alpha) => {
  const alpha256 = (alpha * 255).toFixed()
  const alphaBase16 = Number(alpha256).toString(16) // we're ensuring this is a number then converting
	const paddedAlpha = alphaBase16.length === 1 ? alphaBase16.padStart(1, 0) : alphaBase16  
	return color.concat('', paddedAlpha)
}

export 	const colors = {
	primary: '#F000B4',    // '#F000B4',
	background: 'white',
	accordion: '#dcdcdc',
	divider: '#dcdcdc',
	text:'#555555',
	darktext: '#000000',
	lighttext: '#a9a9a9',
	warning: "#FF6700",
	canvasBackground: '#EEEEEE',
}

export const Divider = (props) => {
	return (
		<div style={{ width: '100%', height: 1, backgroundColor: colors.divider, ...props.style }} />
	);
}

function padTwoDigits(num) {
  return num.toString().padStart(2, "0");
}

export function dateInYyyyMmDdHhMmSs(date, dateDiveder = "-") {
  return (
    [
      date.getFullYear(),
      padTwoDigits(date.getMonth() + 1),
      padTwoDigits(date.getDate()),
    ].join(dateDiveder) +
    " " +
    [
      padTwoDigits(date.getHours()),
      padTwoDigits(date.getMinutes()),
      padTwoDigits(date.getSeconds()),
    ].join(":")
  );
}

export function isHierarchicalChart (gtype) {
	return (gtype === 'sunburst' || gtype === 'treemap' || gtype === 'circlepack' 
	|| gtype === 'pie' || gtype === 'donut');
}
export function canSort(gtype) {
	return (
		gtype === 'bar' || gtype === 'sbar' || gtype === 'hbar' || gtype === 'shbar' ||
		gtype === 'line' || gtype === 'spline' || gtype === 'aspline'
	);
}
