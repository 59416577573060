import {useEffect, useRef, useState} from 'react';
import { colors } from '../../utils/Utils';

// icons: [{label: "Pic Chart", image: "https://imgcdn.prospectsforme.com/tstudio/bia/pie.svg", value: "pie"}...]
const IconPicker = ({icons, value=0, disabled, columns=3, onSelect=()=>null }) => {
	const [dimensions, setDimensions] = useState(null);
	const [cellSize, setCellSize] = useState(0);
	const containerRef = useRef(null);

	useEffect(() => {

	}, []);

	useEffect(() => {
		if (containerRef.current) {
			setDimensions({
				width: containerRef.current.offsetWidth,
				height: containerRef.current.offsetHeight
			});
			setCellSize((containerRef.current.offsetWidth / columns) - 18);
		}
	}, [icons, columns]);

	return (
		<div style={{ width: '100%', backgroundColor: 'white', boxShadow: '0 0 10 -6' }}>
			<div ref={containerRef} style={{ margin: 4, columns: `auto ${columns}` }}>
				{cellSize > 0 && icons.map((icon, i) => {
					if (icon.value === 'empty') {
						return (
							<div key={i} style={{
								cursor: 'auto',
								opacity: 0.0,
								margin: 4,
								display: 'inline-block',
								width: '100%',
								textAlign: 'center',
							}}
							/>
						)
					} else {
						return (
							<div key={i} style={{
								cursor: disabled ? 'auto' : 'pointer',
								opacity: disabled ? 0.5 : 1,
								margin: 4,
								display: 'inline-block',
								width: '100%',
								textAlign: 'center',
							}}
								onClick={() => {
									if (!disabled) {
										onSelect(icon.value)
									}
								}}
							>
								<div style={{
									padding: 5,
									display: 'inline-block',
									border: value === icon.value ? '1px solid '+colors.primary : '1px solid #f8f8f8',
								}}>
									<img alt={icon.value} title={icon.label} src={icon.image} style={{ width: cellSize, height: cellSize }} />
								</div>
							</div>
						)
					}
				}
				)}
			</div>
		</div>
	);
};

export default IconPicker;
