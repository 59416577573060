import * as d3 from 'd3';
import JSZip from 'jszip';
import { API } from 'aws-amplify';
import {getRootDomain} from './Utils';

export const getRemoteZip = async (url) => {

	return await fetch(url, {
		method: 'GET',
		headers: {
			Accept: 'application/zip',
		}
	})
		.then(response => {
			return response.arrayBuffer()
		})
		.then(data => {
			return JSZip.loadAsync(data)
		})
		.then(async zip => {
			// Filter the files to only include image files
			const tsvFiles = Object.keys(zip.files)
				.filter(filename => /\.(tsv)$/i.test(filename));

			const promises = [];
			const files = [];
			tsvFiles.forEach(async (filename, index) => {
				promises.push(zip.file(filename).async('string'));
				files.push(filename);
			});

			let fileList = {};
			const res = await Promise.all(promises);

			for (let i = 0; i < res.length; i++) {
				const filename = files[i];
				let fnShort = filename.substring(filename.lastIndexOf('/') + 1);
				fnShort = fnShort.replace('.tsv', '');
				fileList[fnShort] = d3.tsvParse(res[i]);
			}

			return { success: 'success', files: fileList }
		})
		.catch(error => {
			console.error(error)
			return { error: error.toString() }
		});
}

/*
export const getRemoteZip = async (url) => {
	try {
		let fileList = {};

		const unzipper = new fflate.Unzip();
		unzipper.register(fflate.inflateSync);	//  AsyncUnzipInflate);
		unzipper.onfile = async file => {
			//console.log("Got", file.name);
			if (file.originalSize) {
				console.log("Original size:", file.originalSize);
			}
			const rs = new ReadableStream({
				start(controller) {
					file.ondata = (err, dat, final) => {
						controller.enqueue(dat);
						if (final) controller.close();
					}
					file.start();
				}
			});
			const fString = await streamToString(rs);
			if (file.name.endsWith('.tsv')) {
				let fnShort = file.name.substring(file.name.lastIndexOf('/')+1);
				fnShort = fnShort.replace('.tsv', '');
				fileList[fnShort] = d3.tsvParse(fString);
			}
		}

		const res = await fetch(url, {
			headers: {
				Accept: '* /*',
			}
		});

		const reader = res.body.getReader();
		while (true) {
			const { value, done } = await reader.read();
			if (done) {
				unzipper.push(new Uint8Array(0), true);
				break;
			}
			unzipper.push(value);
		}

		return {success: 'success', files: fileList}
	} catch (err) {
		console.log(err);
		return {error: err.toString()}
	}
}
*/