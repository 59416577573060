import * as d3 from 'd3';
import $ from "jquery";
import GGCPrimitives from './GGCPrimitives';
import { axisGGC } from './axisggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

export class stackedBarGGC extends axisGGC {
    constructor(args) {
        super(args);

        this.selectorHeight = 50;
        this.heightOverview = 40;
        this.active_link = "0";
        this.grouped = false;
        this.minBarWidth = 10;
        this.origMinBarWidth = this.minBarWidth;
        this.barOutlineWidth = 1;
        this.barOutlineColor = '#E8EAED'.toColorRef();
        this.barOutlineStrokeDashArray = '0';
        this.hasYGrid = true;

        this.align = .5;        // evenly distribute
        //this.paddingInnerPixels = 0;
        this.leftPadding = 0;
        this.paddingInner = 0.05;
        this.paddingOuter = 0.025;


        this.y_orig = undefined;

        this.numBars = undefined;
        this.isScrollDisplayed = undefined;
        this.xscale = undefined;
        this.yscale = undefined;
        this.xAxis = undefined;
        this.yAxis = undefined;
        this.yAxisGrid = undefined;
        this.barsHolder = undefined;
        this.idx = undefined;
        this._axisLabelHeight = undefined;
        this.barWidth = undefined;
        this.cur_data = undefined;

        this.x1 = undefined;
        this.ntips = undefined;
        this.svgId = undefined;
        this._pickerId = undefined;
        this._computedBarWidth = undefined;
        this._dataRange = undefined;
        this.viewBoxAdjustHeight = 0;


        this._init(args);
    }
    getGraphType() {
        if (this.grouped)
            return 'bar'
        else
            return 'sbar';
    }
    _init(args) {
        //this._axis_initVariables(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var axis = self._axis_saveProps();
        var me = {
            hasYGrid: self.hasYGrid,
            selectorHeight: self.selectorHeight,
            heightOverview: self.heightOverview,
            grouped: self.grouped,
            minBarWidth: self.minBarWidth,
            barOutlineWidth: self.barOutlineWidth,
            barOutlineColor: self.barOutlineColor,
            barOutlineStrokeDashArray: self.barOutlineStrokeDashArray,
            _computedBarWidth: self._computedBarWidth,
            paddingInner: self.paddingInner,
            paddingOuter: self.paddingOuter,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight
        };
        if (this._computedBarWidth !== this.barWidth)
            me.barWidth = this.barWidth;

        return { ...axis, ...me };
    }
    _initVariables(args) {

        if (args.hasOwnProperty('selectorHeight'))
            this.selectorHeight = args.selectorHeight;

        if (args.hasOwnProperty('heightOverview'))
            this.heightOverview = args.heightOverview;

        if (args.hasOwnProperty('grouped'))
            this.grouped = args.grouped;
        if (args.hasOwnProperty('minBarWidth'))
            this.minBarWidth = args.minBarWidth;

        if (args.hasOwnProperty('barOutlineWidth'))
            this.barOutlineWidth = args.barOutlineWidth;

        if (args.hasOwnProperty('barOutlineColor')) {
            this.barOutlineColor = GGCPrimitives._argsToColorRef(args, 'barOutlineColor');
        }

        if (args.hasOwnProperty('barOutlineStrokeDashArray'))
            this.barOutlineStrokeDashArray = args.barOutlineStrokeDashArray;

        if (args.hasOwnProperty('barWidth'))
            this.barWidth = args.barWidth;

        if (args.hasOwnProperty('_computedBarWidth'))
            this._computedBarWidth = args._computedBarWidth;

        if (args.hasOwnProperty('hasYGrid'))
            this.hasYGrid = args.hasYGrid;

        if (args.hasOwnProperty('extraRightMargin')) {
            this.extraRightMargin = args.extraRightMargin;
        }
        if (args.hasOwnProperty('paddingInner'))
            this.paddingInner = args.paddingInner;
        if (args.hasOwnProperty('paddingOuter'))
            this.paddingOuter = args.paddingOuter;

        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }
    
    }
    resize(opts) {
        var self = this;

        let svgWidth, svgHeight;
        let width, height;
        let gonnaDraw = true;

        // We need to check this because if we don't draw, then we've set the outerWidth/Height to something
        // from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                gonnaDraw = false;
            }
        }

        if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
            const vbCoords = this.viewBox.split(' ');
            width = parseInt(vbCoords[2]);
            height = parseInt(vbCoords[3]);
            svgWidth = opts.width;
            svgHeight = opts.height + this.viewBoxAdjustHeight;
        } else {
            width = opts.width;
            height = opts.height;
        }

        self.outerWidth = width;
        self.outerHeight = height;

        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();
        // remove any leftover popovers
        $('.popover').popover('hide');

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;
        if (self._pagingControl) {
            this.origHeight -= $(self._pagingControl).height();
            this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        if (self._sortDivId) {    // _sortTableId
            this.origHeight -= $('#' + self._sortDivId).height();
            this.viewBoxAdjustHeight = -$('#' + self._sortDivId).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);

        this._axisLabelHeight = this._textHeight('Yg', this.xAxisFont) * 1.5; // 1.2 for non-canvas text

        this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City

        var valueMax = null;
        var valueMin = null;

        this.color.domain(this.valueNames);
        if (this.valueStatus.length === 0) {
            this.color.domain().forEach(function (item, index) {
                self.valueStatus.push(1);
            });
        }

        this.data.forEach(function (d, ix) {
            var myLabel = d[self.ixLabel];  // 2024-02-03 self._trimEllipses(d[self.ixLabel]);
            var y0 = 0;
            d.values = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                    let name = self.color.domain()[i];
                    let val = +d[self._valueIndex(name)];
                    if (self.grouped)
                        d.values.push({ myix: ix, vi: i, myLabel: myLabel, name: name, y0: y0, y1: val });

                    else
                        d.values.push({ myix: ix, vi: i, myLabel: myLabel, name: name, y0: y0, y1: y0 += +d[self._valueIndex(name)] });

                }
            }

            if (self.grouped) {
                d.total = d3.max(d.values.map(function (d) { return d.y1; }));
            } else {
                if (d.values.length > 0) {
                    d.total = d.values[d.values.length - 1].y1;
                } else {
                    d.total = 0;
                }
            }
            if (valueMax == null || d.total > valueMax)
                valueMax = d.total;
            if (valueMin == null || d.total < valueMin)
                valueMin = d.total;
        });

        if (valueMin > 0) valueMin = 0;
        // Compute max length of valueMin and valueMax for margin.left
        var xLen = self._textWidth(this._axis_doFormat(valueMax), self.yAxisFont);
        var mLen = self._textWidth(this._axis_doFormat(valueMin), self.yAxisFont);
        if (xLen > mLen) {
            mLen = xLen;
        }
        mLen += 16; // tick length + spacing (10). add spacing on left (6)

        self.margin.left = self.margin.left > mLen ? self.margin.left : mLen;

        this.width = self.origWidth - this.margin.left - this.margin.right - this.legendSpace - self.extraRightMargin;

        this.svgId = this._genId();
        // 2024-01-18 - if we are displaying in a Dashboard, then we need to:
        // set origWidth, origHeight to the saved design sizes of width/height above
        // then set this width/height below to the destination width/height
        // and add viewBox='0 0 origWidth origHeight' to the svg
        if (!isRuntimeOnly()) {
            this.viewBox = [0, 0, self.origWidth, self.origHeight].join(' ');
            svgWidth = self.origWidth;
            svgHeight = self.origHeight;
        }
        this.svg = d3.select(this.container).append("svg")
            .attr('xmlns', "http://www.w3.org/2000/svg")
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr("viewBox", this.viewBox)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr('id', self.svgId);


        this.diagram = this.svg.append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this._colorDomainSet(['barOutlineColor']);
        self._fillBackground(this.svg);


        if (typeof self.barWidth === 'undefined') { // don't change if we are refreshing from edit
            if (typeof self._computedBarWidth != 'undefined' &&
                (typeof this._bStagger != 'undefined' && this._bStagger)) {
                this.barWidth = this._computedBarWidth;
            }
            else {
                // this.width is area to plot; this.data.length is # of bars
                let bwAll = this.width / this.data.length;
                // Now, do the labels fit into bwAll width?  If not, see if wrapping helps
                let maxLabelWidth = d3.max(this.data.map(function (d) {
                    return self._textWidth(d[self.ixLabel], self.xAxisFont ? self.xAxisFont : self.font) * 1.2;
                }));
                if (maxLabelWidth > bwAll) {        // have to wrap, ellipses, or stagger
                    self.barWidth = maxLabelWidth / 2;
                } else {
                    self.barWidth = bwAll;
                }
                /*
                self.barWidth = d3.max(this.data.map(function (d) {
                    return self._textWidth(self._trimEllipses(d[self.ixLabel]), self.xAxisFont ? self.xAxisFont : self.font);
                }
                )) * 1.2;
                */

                if (this.grouped) {
                    if (this.barWidth < this.minBarWidth * this.valueNames.length)
                        this.barWidth = this.minBarWidth * this.valueNames.length;
                }
                this._computedBarWidth = this.barWidth;
            }
        }

        this.numBars = Math.round(this.width / self.barWidth);

        this.isScrollDisplayed = self.barWidth * this.data.length > this.width;

        this.height = self.origHeight - this.margin.top - this.margin.bottom - 
                (this.isScrollDisplayed ? 
                    this.selectorHeight : 
                    0) - 
                (this.categoryNames.length + 1) * self._axisLabelHeight; // +1 is in case xaxis stagger

        this.cur_data = self.data.slice(0, this.numBars);
        self._dataRange = [0, self.numBars];

        this.paddingOuter = Math.max(0.1, self.paddingInner / 2);
        this.xscale = d3.scaleBand()
            .domain(self.cur_data.map(function (d, ix) { return ix; }))
            .range([0, this.width])
            .paddingInner(self.paddingInner)    // GGCW
            .paddingOuter(this.paddingOuter)
            .align(self.align);


        if (this.grouped) {
            this.x1 = d3.scaleBand()
                .domain(self.valueNames)
                .range([0, self.xscale.bandwidth()])    // 2023/11
                .paddingInner(.05)       // self.paddingInner
                .paddingOuter(0)
                .align(self.align);
        }

        // NOTE: The below 2 calcs are identical
        // let this.paddingInnerPixels = this.xscale.step() * this.paddingInner;
        //this.paddingInnerPixels = this.xscale.step() - this.xscale.bandwidth();

        this.leftPadding = self.xscale.step() * self.paddingOuter * this.align * 2;


        self.ntips = 0;
        if (self.grouped) {
            self.ntips = 1;
        } else {
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    self.ntips++;
            }
        }
        this.tooltipCreate(self.ntips);

        // Maybe add 10% to valueMax to make room for legend
        this.yscale = d3.scaleLinear()
            .domain([valueMin, valueMax])
            .range([this.height, 0]);

        const nDigits = Math.floor(Math.log10(valueMax)) + 1;
        const yAxisTicks = this.yscale.ticks().filter(tick => Number.isInteger(tick));

        this.xAxis = d3.axisBottom(this.xscale);
        this.yAxis = d3.axisLeft(this.yscale)
            .ticks(nDigits > 1 ? this.maxTicks : yAxisTicks.length)           // 2023 new ticks
            .tickFormat(this._axis_doFormat);
        this.yAxisGrid = d3.axisLeft(this.yscale).tickFormat('');

        let oldBStagger = this._bStagger;

        this._axis_groupLabels(0);  // this checks _bStagger..which doesn't get set until later..

        this._axis_focusY();

        this._axis_drawXY();        // _bSTagger gets set here...
        if (!oldBStagger && this._bStagger) {
            this._axis_groupLabels(0);
        }

        this._drawBarsAndRects();

        this._setBarsMouseEvents();

        this._base_makeLegend({ resize: true });

        this._checkScroller();

        this._axis_drawXY_edit();
        this.checkEditMode();
    }
    //stackedBarGGC.prototype.closeEdit = function(self) {
    //    
    //}
    _update() {

        // Do we have a new valueMax
        let yStackMax = d3.max(this.data, function (d) { return d.total; });    // ggc ?
        let decimals = this.countDecimals(yStackMax)
        if (yStackMax < this.maxTicks) {
            this.format = this.defaultFormat
            this.y_axis.ticks(this.minTicks)
        }
        else {
            this.y_axis.ticks(this.maxTicks)
            if     (decimals < 3 ) this.format = this.defaultFormat;
            else if(decimals < 6 ) this.format = this._axis_toKilo;
            else if(decimals < 9 ) this.format = this._axis_toMega;
            else if(decimals < 12) this.format = this._axis_toGiga;
            else                   this.format = this._axis_toTerra;
        }

        this.diagram.transition()
            .duration(this.interval)
            .ease("linear")
            .call(this.y_axis);
    }

    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }
    checkEditMode() {
        var self = this;


        if (self.editMode) {
            let x = self.origWidth / 4;
            let y = Math.max(self.margin.top/2, 0);
            self._drawCircleEditColor({
                selector: self.diagram,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    colorRefProp: 'backgroundColor'
                }

            });

            // TODO: find a bar corner
            y = self.height / 2;
            x = self.width / 2;
            self._drawCircleEditBarWidth({
                selector: self.diagram,
                kind: 'baroutline',
                title: i18next.t("graph.edit.bar_gap_outline"),
                x: x,
                y: y,
                r: 12,
                placement: 'top',
                variables: {
                    barWidthProp: 'paddingInner',       // 2023/11 'barWidth',
                    strokeWidthProp: 'barOutlineWidth',
                    colorRefProp: 'barOutlineColor',
                    strokeDashArrayProp: 'barOutlineStrokeDashArray'
                }

            });

        }
        self._axis_checkEditMode();


    }
    refresh() {
        var self = this;

        var valueMax = null;
        var valueMin = null;

        // barWidth can change in 'edit' mode
        this.numBars = Math.round(this.width / self.barWidth);

        var scrollBefore = this.isScrollDisplayed;

        this.isScrollDisplayed = self.barWidth * this.data.length > this.width;

        this.height = self.origHeight - this.margin.top - this.margin.bottom - (this.isScrollDisplayed ? this.selectorHeight : 0) - (this.categoryNames.length + 1) * self._axisLabelHeight;
        this.yscale.range([this.height, 0]); // if height changes because of isScrollDisplayed

        if (scrollBefore && !this.isScrollDisplayed) {
            self.diagram.selectAll('.overviewBar').remove();
            self.diagram.selectAll('.overviewMover').remove();
            self._axis_drawXY();
        } else if (!scrollBefore && this.isScrollDisplayed) {
            // need to show scroly area
            self._axis_drawXY();
            this._checkScroller();
        } else {
            self._axis_refresh();
        }

        if (this.grouped) {
            this.x1 = d3.scaleBand()
                .domain(self.valueNames)
                .range([0, self.xscale.bandwidth()])    // 2023/11
                .paddingInner(.05)  // self.paddingInner)
                .paddingOuter(0)
                .align(self.align);
        }


        self._colorDomainSet(['barOutlineColor']); // really updates palette based on interpolate or not

        this.data.forEach(function (d, ix) {
            var myLabel = d[self.ixLabel];  // 2024-02-03 self._trimEllipses(d[self.ixLabel]);
            var y0 = 0;

            d.values = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                    let name = self.color.domain()[i];
                    let val = +d[self._valueIndex(name)];
                    if (self.grouped)
                        d.values.push({ myix: ix, vi: i, myLabel: myLabel, name: name, y0: y0, y1: val });

                    else
                        d.values.push({ myix: ix, vi: i, myLabel: myLabel, name: name, y0: y0, y1: y0 += +d[self._valueIndex(name)] });

                }
            }
            if (self.grouped) {
                d.total = d3.max(d.values.map(function (d) { return d.y1; }));
            } else {
                if (d.values.length > 0) {
                    d.total = d.values[d.values.length - 1].y1;
                } else {
                    d.total = 0;
                }
            }
            if (valueMax == null || d.total > valueMax)
                valueMax = d.total;
            if (valueMin == null || d.total < valueMin)
                valueMin = d.total;
        });
        if (valueMin > 0) valueMin = 0;

        this.cur_data = self.data.slice(0, this.numBars);
        self._dataRange = [0, this.numBars];

        self.paddingOuter = Math.max(0.1, self.paddingInner / 2);
        this.xscale = d3.scaleBand()
            .domain(self.cur_data.map(function (d, ix) { return ix; }))
            .range([0, this.width])
            .paddingInner(self.paddingInner)     // GGCW
            .paddingOuter(self.paddingOuter)
            .align(self.align);

        //this.paddingInnerPixels = this.xscale.step() - this.xscale.bandwidth();

        this.leftPadding = self.xscale.step() * self.paddingOuter * this.align * 2;

        this.xAxis = d3.axisBottom(this.xscale);

        this.yscale.domain([valueMin, valueMax]);

        self._axis_refresh(); // changes properties and redraws text
        self._axis_groupLabels(0); // changes props of groups and redraws text and lines

        self.ntips = 0;
        if (self.grouped) {
            self.ntips = 1;
        } else {
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    self.ntips++;
            }
        }
        this.tooltipCreate(self.ntips);

        this._drawBarsAndRects();

        //2023 rect.exit().remove();

        self._setBarsMouseEvents();

        self._base_makeLegend();

    }
    _drawBarsAndRects() {
        var self = this;

        if (this.grouped) {
            let vn = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    vn.push(self.valueNames[i]);
            }
            self.x1 = d3.scaleBand()
                .domain(vn)
                .range([0, self.xscale.bandwidth()])        // 2023/11
                .paddingInner(.05)  // self.paddingInner)
                .paddingOuter(0)

        }

        // just draw bars...
        if (self.barsHolder)
            self.barsHolder.remove();

        self.barsHolder = self.diagram.selectAll(".barsHolder")
            .data(self.cur_data)
            .enter()
            .append("g")
            .attr("class", "g")
            .attr("transform", function (d) { return "translate(0,0)"; });

        self.barsHolder.exit().remove();

        let rect = self.barsHolder.selectAll("rect");

        rect
            .data(function (d) {
                return d.values;
            })
            .enter()
            .append("rect")
            .attr("width", function (d) {
                if (self.grouped)
                    return self.x1.bandwidth();

                else
                    return self.xscale.bandwidth();
            })
            .attr("y", function (d) {
                return self.yscale(Math.max(0, d.y1));
            })
            .attr("x", function (d) {
                if (self.grouped)
                    return self.xscale(d.myix) + self.x1(d.name);

                else
                    return self.xscale(d.myix);
            })
            .attr("height", function (d) {
                return Math.abs(self.yscale(d.y0) - self.yscale(d.y1));
            })
            .attr("class", function (d) {
                let classLabel = self._fixName(d); //remove spaces
                return "class" + classLabel;
            })
            .style("fill", function (d) {
                return self._getFillColor(d.vi);
            })
            .style("opacity", function (d) {
                return self._getOpacity(d.vi);
            })
            .style("stroke-width", function (d) {
                return self.barOutlineWidth;
            })
            .style("stroke", function (d) {
                return GGCPrimitives._getColorFromRef(self.barOutlineColor);
            })
            .style("stroke-opacity", function (d) {
                return GGCPrimitives._getOpacityFromRef(self.barOutlineColor);
            })
            .style('stroke-dasharray', function (d) {
                return self.barOutlineStrokeDashArray;
            });

    }
    // barsHolder is off of diagram.  diagram is off of svg.  focus is off of svg (so add left-margin)
    // https://observablehq.com/@d3/d3-scaleband  shows bandWidth, padding, step, etc. graphically
    _setBarsMouseEvents() {
        var self = this;
        this.barsHolder.selectAll("rect")
            .on('mouseover', function () {
                if (self.editMode) {
                } else {
                    self.focus.style('opacity', 1);
                }
            })
            .on("mousemove", function (event, d) {
                if (self.editMode) {
                } else {

                    //v5 let x = d3.event.offsetX;
                    let coords = d3.pointer(event);
                    let x = coords[0];

                    // left-pad = step × paddingOuter × align × 2
                    var xpos = x - self.leftPadding;
                    var x0 = Math.floor(xpos / (self.xscale.step()));
                    if (x0 < 0) x0 = 0;
                    else if (x0 >= self.cur_data.length) x0 = self.cur_data.length - 1;

                    x0 += self.xscale.domain()[0];      // position in original array?

                    d3.select(this).attr("stroke", "blue").attr("stroke-width", 0.8);

                    if (self.grouped) {
                        var nsubs = 0;
                        for (let i = 0; i < self.valueNames.length; i++) {
                            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                                nsubs++;
                            }
                        }
                        var xpossub = x - self.xscale(x0);

                        var x0sub = Math.floor(xpossub / self.x1.step());
                        if (x0sub < 0) x0sub = 0;
                        else if (x0sub >= nsubs) x0sub = nsubs - 1;

                        let fx = self.margin.left  + self.xscale(x0) +  x0sub * self.x1.step() + self.x1.bandwidth() / 2;
                        if (self.x1.step() !== self.x1.bandwidth()) {
                            fx += (self.x1.step() - self.x1.bandwidth()) / 2;
                        }

                        self.focus
                            .attr("x1", fx)
                            .attr("x2", fx)
                            .attr('y1', self.yscale(self.yscale.domain()[0]) + self.margin.top)
                            .attr('y2', self.yscale(self.yscale.domain()[1]) + self.margin.top);
                        self.tooltipSetRow(0, d.name, (d.y1 - d.y0));
                    } else {
                        let fx = self.margin.left + self.xscale(x0) + self.xscale.bandwidth() / 2;

                        self.focus
                            .attr("x1", fx)
                            .attr("x2", fx)
                            .attr('y1', self.yscale(self.yscale.domain()[0]) + self.margin.top)
                            .attr('y2', self.yscale(self.yscale.domain()[1]) + self.margin.top);
                        var lix = 0;
                        x0 -= self.xscale.domain()[0];
                        for (let i = 0; i < self.valueNames.length; i++) {
                            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                                var delta = self.cur_data[x0].values[i].y1 - self.cur_data[x0].values[i].y0;
                                self.tooltipSetRow(self.ntips - lix - 1, self.valueNames[i], delta);
                                lix++;
                            }
                        }
                    }

                    // The tooltip is attached to the container, so we need to adjust the x,y
                    // toolTipShow adds the container offsets, so just offset by margins
                    //v5 let tx = d3.event.offsetX;
                    //v5 let ty = d3.event.offsetY;
                    let tx = coords[0];
                    let ty = coords[1];
                    self.tooltipShow(tx+self.margin.left, (ty+self.margin.top));

                }


            })
            .on("mouseout", function () {
                if (self.editMode) {
                } else {
                    self.focus.style('opacity', 0);
                    self.tooltipHide();
                    d3.select(this).attr("stroke", "pink").attr("stroke-width", 0.2);
                }
            });

        // Highlight the Category axis labels on mouseover
        this.diagram.select(".x.axis")
            .selectAll('.tick')
            .on("mouseover", function (d) {
                if (self.editMode) {
                } else {
                    // TODO: if text() != attr('textFull'), then show tooltip w/ full text, on click generate event
                    // else, do default display bolding.  this.getBoundingClientRect()  gets x, y,width,height
                    var fullText = d3.select(this).select("text").attr('fullText');
                    if (fullText !== d3.select(this).select("text").text()) {

                        var bnds = d3.select(this).node().getBBox();

                        self.diagram.append("text")
                            .attr("x", (bnds.x + bnds.width) / 2)
                            .attr("y", (bnds.y + bnds.height) / 2)
                            .attr("class", "xlabelGGC")
                            .text(fullText)
                            .style("font", this.font);

                        var tbx = self.diagram.select('.xlabelGGC').node().getBBox();

                        self.diagram.insert('rect', '.xlabelGGC')
                            .attr('x', tbx.x - 2)
                            .attr('y', tbx.y - 2)
                            .attr('width', tbx.width + 4)
                            .attr('height', tbx.height + 4)
                            .attr('class', 'xlabelBox')
                            .style('fill', 'white')
                            .style('stroke', 'blue')
                            .on('click', function () {
                                var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                                var tpsf_event = {
                                    // eid:
                                    // schema:
                                    table: self.tableName,
                                    field: self.categoryNames[ixC],
                                    value: d3.select(this).select("text").attr('fullText') // text()
                                };

                                self.diagram.select(".xlabelBox").remove();
                                self.diagram.select(".xlabelGGC").remove();

                                self.categoryClick(tpsf_event);

                            });


                    } else {
                        d3.select(this).select('text').attr('fill', 'blue');
                        d3.select(this).select('text').attr('font-weight', 'bold');
                        d3.select(this).style("cursor", "pointer");
                    }
                }
            })
            .on("mouseout", function () {
                if (self.editMode) {
                } else {
                    d3.select(this).select('text').attr('fill', self.xAxisLabelColor);
                    d3.select(this).select('text').attr('font-weight', 'normal');
                    d3.select(this).style("cursor", "auto");

                    self.diagram.select(".xlabelBox").remove();
                    self.diagram.select(".xlabelGGC").remove();
                }
            })
            .on("click", function () {
                if (self.editMode) {
                } else {
                    var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                    var tpsf_event = {
                        // eid:
                        // schema:
                        table: self.tableName,
                        field: self.categoryNames[ixC],
                        value: d3.select(this).select("text").attr('fullText') // text()
                    };
                    self.categoryClick(tpsf_event);
                }
            });

    }
    _checkScroller() {
        var self = this;

        function display(event) {       // v6 added event
            //v5 nx = x + d3.event.dx, 
            let x = parseInt(d3.select(this).attr("x"));
            let nx = x + event.dx;
            let w = parseInt(d3.select(this).attr("width")), f, nf;


            if (nx < 0) { // drug off the left (minimum)
                if (self._dataRange[0] === 0)
                    return;
                d3.select(this).attr("x", x);
                nf = 0;
            } else if (nx + w > Math.ceil(self.width)) { // drug off right
                if (self._dataRange[1] === self.data.length)
                    return;
                d3.select(this).attr("x", self.width - d3.select(this).attr("width"));
                nf = self.data.length - self.numBars;
            } else {
                d3.select(this).attr("x", nx);
                f = self.displayed(x);
                nf = self.displayed(nx);
                if (f === nf) return;
            }

            //            if ( nx < 0 || nx + w > Math.ceil(self.width) ) return;
            //            d3.select(this).attr("x", nx);
            //            f = self.displayed(x);
            //            nf = self.displayed(nx);
            //            if ( f === nf ) return;
            self.cur_data = self.data.slice(nf, nf + self.numBars);
            self._dataRange = [nf, nf + self.numBars];

            self.xscale.domain(self.cur_data.map(function (d, ix) {
                return nf + ix;
            }));

            self._xaxis_stagger();

            self.barsHolder.remove();

            self.barsHolder = self.diagram.selectAll(".barsHolder")
                .data(self.cur_data)
                .enter().append("g")
                .attr("class", "g")
                .attr("transform", function (d) { return "translate(0,0)"; });
            self.barsHolder.exit().remove();

            rect = self.barsHolder.selectAll("rect")
                .data(function (d) {
                    return d.values;
                })
                .enter().append("rect")
                .attr("width", function (d) {
                    if (self.grouped)
                        return self.x1.bandwidth();

                    else
                        return self.xscale.bandwidth();
                })
                .attr("y", function (d) {
                    return self.yscale(Math.max(0, d.y1));
                })
                .attr("x", function (d) {
                    if (self.grouped)
                        return self.xscale(d.myix) + self.x1(d.name);

                    else
                        return self.xscale(d.myix);
                })
                .attr("height", function (d) {
                    return Math.abs(self.yscale(d.y0) - self.yscale(d.y1));
                })
                .attr("class", function (d) {
                    let classLabel = self._fixName(d.name); //remove spaces
                    return "class" + classLabel;
                })
                .style("fill", function (d) {
                    return self._getFillColor(d.vi);
                })
                .style("opacity", function (d) {
                    return self._getOpacity(d.vi);
                })
                .style("stroke-width", function (d) {
                    return self.barOutlineWidth;
                })
                .style("stroke", function (d) {
                    return GGCPrimitives._getColorFromRef(self.barOutlineColor);
                })
                .style("stroke-opacity", function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.barOutlineColor);
                })
                .style('stroke-dasharray', function (d) {
                    return self.barOutlineStrokeDashArray;
                });

            rect.exit().remove();

            self._setBarsMouseEvents();

            self._axis_groupLabels(nf);

            self._bringEditToFront();

        };

        if (this.isScrollDisplayed) {
            // V5
            var xOverview = d3.scaleBand()
                .domain(self.data.map(function (d, ix) { return ix; }))
                .range([0, self.width])
                .paddingInner(self.paddingInner)    // GGCW
                .paddingOuter(Math.max(0.1, self.paddingInner / 2));

            let x1O;

            if (self.grouped) {
                x1O = d3.scaleBand()
                    .domain(self.valueNames)
                    .range([0, xOverview.bandwidth()])  // 2023/11
                    .paddingInner(self.paddingInner);
                }

            var yOverview = d3.scaleLinear().range([self.heightOverview, 0]);
            yOverview.domain(self.yscale.domain());

            var subBars = self.diagram.selectAll('.overviewBar')
                .data(self.data)
                .enter().append("g")
                .attr("class", "g")
                .attr("transform", function (d) { return "translate(0,0)"; });

            var rect = subBars.selectAll("rect");

            rect
                .data(function (d) {
                    return d.values;
                })
                .enter()
                .append("rect")
                .classed('overviewBar', true)
                .attr('height', function (d) {
                    return Math.abs(yOverview(d.y0) - yOverview(d.y1));
                })
                .attr('width', function (d) {
                    if (self.grouped)
                        return x1O.bandwidth();

                    else
                        return xOverview.bandwidth();
                })
                .attr('x', function (d) {
                    if (self.grouped)
                        return xOverview(d.myix) + x1O(d.name);

                    else
                        return xOverview(d.myix);
                })
                .attr('y', function (d) {
                    return self.height + self.margin.bottom + (self.selectorHeight - self.heightOverview) + self._axisLabelHeight * (self.categoryNames.length + 1)
                        + yOverview(Math.max(0, d.y1));
                })

            // V5
            self.displayed = d3.scaleQuantize()
                .domain([0, self.width])
                .range(d3.range(self.data.length));

            var ovWidth = Math.round(parseFloat(self.numBars * self.width) / self.data.length);
            // If the grabber is too small, make it bigger
            if (ovWidth < 10) ovWidth = 10;

            self.diagram.append("rect")
                .attr("transform", "translate(0, " + (self.height + self.margin.bottom + self._axisLabelHeight * (self.categoryNames.length + 1)) + ")")
                .attr("class", "overviewMover")
                .attr("x", 0)
                .attr("y", 0)
                .attr("height", self.selectorHeight)
                .attr("width", ovWidth)
                .attr("pointer-events", "all")
                .attr("cursor", "ew-resize")
                .call(d3.drag().on("drag", display));
        }
    }
}

//stackedBarGGC.prototype = new axisGGC();











