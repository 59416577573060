import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  /*
  .use({
    type: 'postProcessor',
    name: 'gregLog',
    process: function (value, key, options, translator) {
      return (<div title={key}>{value}</div>);
    }
  })
  */
	.use(resourcesToBackend((language, namespace) => {
		return import(`../locales/${language}.json`)}
		))
  .init({
    detection: {
      order: ['queryString', 'navigator'],
      lookupQueryString: 'lng',
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    //postProcess: ['gregLog']

  });
