import React from "react";
import {FaChevronUp, FaChevronDown} from "react-icons/fa";

const ListAccordion = ({expanded, title, titleStyle, outerStyle, 
	style, onPress, children, arrowColor='#555555', titleMargin=8 }) => {

	const left = false;

	return (
		<div style={{ ...outerStyle }}>
			<div style={{ backgroundColor: '#ffffff' }} onClick={onPress}>
				<div style={{ ...style, cursor: 'pointer', flexGrow: 1, position: 'relative', padding: 8, overflow: 'hidden' }}>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', pointerEvents: 'none'}}>
						<div style={{margin: titleMargin, flexGrow: 1, flexShrink: 1, flexBasis: 0}}>
							<div style={{...titleStyle, textAlign: 'left'}}>{title}</div>
						</div>
						<div style={{margin: titleMargin}}>
							{expanded ? <FaChevronUp style={{cursor: 'pointer', color: arrowColor}}/> : <FaChevronDown  style={{cursor: 'pointer', color: arrowColor}}/>}
						</div>
					</div>
				</div>
			</div>
      {expanded
        ? React.Children.map(children, (child) => {
            if (
              left &&
              React.isValidElement(child) &&
              !child.props.left &&
              !child.props.right
            ) {
              return React.cloneElement(child, {
                style: [{paddingLeft: 64}, child.props.style],
              });
            }

            return child;
          })
        : null}
			</div>
	)
}

export default ListAccordion;