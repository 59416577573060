/* eslint-disable no-param-reassign */
import { getOverflow } from './dom-utils';

// Determine the font-size to set on the element `el` that will
// allow the first child of that element to fill the maximum height
// and width without causing overflow
export default function getFillSize(el, minFontSize, maxFontSize, widthOnly = false, factor = 1) {
  // Make an initial guess at font-size that fits width
  let fontSize = Math.min(
    Math.max(Math.min(Number(el.offsetWidth) / (factor * 10), maxFontSize), minFontSize)
  );

  if (fontSize <= minFontSize) {
    fontSize = minFontSize;
    return fontSize;
  }

  const step = 1;
  let complete;
	let wasOverflow = false;

  while (!complete) {
    el.style.fontSize = `${fontSize}px`;
    const [overflowWidth, overflowHeight] = getOverflow(el);

    if (!widthOnly && (overflowHeight || overflowWidth)) {
      if (fontSize <= minFontSize) {
        fontSize = minFontSize;
        complete = true;
      }
      else {
				wasOverflow = true;
        fontSize -= step;
//GGC        complete = true;
      }
    }
    else if (widthOnly && overflowWidth) {
      fontSize -= step;
      complete = true;
    }
    else if (fontSize >= maxFontSize) {
      fontSize = maxFontSize;
      complete = true;
    }
    else if (!complete) {
			if (wasOverflow) {
				complete = true;
			} else {
      	fontSize += step;
			}
    }
  }
  return fontSize;
}
/* eslint-enable no-param-reassign */