import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

const D3Wrapper = forwardRef(({
	myId,
	style = {},
	myGraph,
	getGraphData,
}, ref) => {
	useImperativeHandle(ref, () => ({
		/*
		saveProps() {
			return myGraph._saveProps();
		},
		getAxisPropsOnly() {
      if (myGraph)
        if (typeof myGraph.getAxisPropsOnly === 'function') {
          return myGraph.getAxisPropsOnly();
        }
      return {};
    },
		getBasePropsOnly() {
      if (myGraph)
        if (typeof myGraph.getBasePropsOnly === 'function') {
          return myGraph.getBasePropsOnly();
        }
        else
				return {};
		},
		getPalettePropsOnly() {
      if (myGraph)
        if (typeof myGraph.getPalettePropsOnly === 'function') {
          return myGraph.getPalettePropsOnly();
        }
        else
				return {};
		},
    applyThemes(item) {
      if (myGraph) {
        if (item.containerProps) {
          this.setProperty(item.containerProps);
        }
        myGraph.applyThemes(item)
      }
    },
    toggleEdit() {
      if (myGraph) {
        return myGraph._toggleEdit();
      }
    },
		getEditMode() {
      if (myGraph) {
        return myGraph.getEditMode();
      } else {
        return false;
      }
    },
		setPaletteCustom(customPalette, domain) {		// useImage as Fill
			myGraph.colorPaletteType = 'custom';
			myGraph.customPalette = customPalette;
			if (graphType === 'treemap' || graphType === 'sunburst' || graphType === 'circlepack') {
				myGraph.hierPalette[0].type = 'custom';
				myGraph.hierDomain = domain;
			}
			// should cause redraw
		}
		*/
	}));

//	const [myGraph, setMyGraph] = useState(null);

	const [t] = useTranslation();

	// We pass the getGraphData function to the graph so that the React useCallback component
	// gets passed to the myGraph.  When the useCallback is updated with locations, campaigns, etc.
	// The myGraph needs the 'latest' one.  The way the GraphComponent is setup passes a static
	// reference to the sampleData property and it won't get updated because there was no 'component'
	// for React to tell that there is a new value.  So, we sneakily pass it to this fake wrapper
	// function so it gets updated at the myGraph level on change.
	if (myGraph && myGraph.ajax) {
		myGraph.ajax.sampleData = getGraphData;
	}

	return (
			<div id={myId}
				style={{ ...style }}
			>
				<div style={{ borderWidth: 1, color: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: 'lightgray', borderStyle: 'solid', width: '100%', height: '100%' }}>
					{t("loading")}
				</div>
			</div>
		);
	}
)

export default D3Wrapper;