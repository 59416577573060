import React, {useEffect} from 'react';
import { applyAlpha } from '../../utils/Utils';

const CircleLetter = ({char, borderColor = applyAlpha('#FFFFFF', .5), style={}, backgroundColor = null, textColor = 'black', onClick=()=>null}) => {
	useEffect(() => {

	}, [textColor]);

  return (
		<div
		onClick={onClick}
			style={
				{
					width: '35px',
					height: '35px',
					borderRadius: '35px',
					borderWidth: 2,
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					...style
				}
			}
		>
			<div
			style={{color: textColor, fontWeight: 'bold'}}>{char}</div>
		</div>
  );	
}

export default CircleLetter;