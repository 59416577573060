import React from "react";
import {FaCheckSquare, FaRegSquare} from "react-icons/fa";
import { colors } from "../../utils/Utils";

const LeftCheckbox = ({checked, label, onClick, disabled=false, style={}}) => {

	return (
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...style}} disabled={disabled} onClick={disabled ? ()=>null: onClick}>
				{checked ? (
					<FaCheckSquare style={{ cursor: (disabled ? 'auto' : 'pointer'), color:(disabled ? 'gray' : colors.primary), marginRight: 4 }} />
				) : (
					<FaRegSquare style={{ cursor: (disabled ? 'auto' : 'pointer'), color:(disabled ? 'gray' : colors.primary), marginRight: 4 }} />
				)}
        <span style={{color: (disabled ? 'gray' : 'black'), paddingLeft: 8, verticalAlign: 'middle'}}>{label}</span>
		</div>
	)
}
export default LeftCheckbox;