const tinycolor = require('tinycolor2');

class GGCPrimitives {
	static _getColorFromRef(colorRef, alt) {
			if (colorRef.hasOwnProperty('loc') && colorRef.loc) {
					return 'url(#' + colorRef.ref + ')';
			} else if (colorRef.hasOwnProperty('rgb')) {
					if (colorRef.rgb !== null) {
							return colorRef.rgb;
					}
			}
			
			if (alt !== 'undefined')
					return alt;
			else
					return 'black';		// 2023 was black no quotes
	}
	static _getOpacityFromRef(colorRef) {
			if (colorRef.hasOwnProperty('opacity') && colorRef.opacity) {
					return colorRef.opacity;
			} else
					return 1;        
	}
	static _argsToColorRef(args, prop) {
			var myColorRef = '#000000'.toColorRef();

			if (typeof(args[prop]) === 'string') {
					if (args[prop].match(/^http:|^https:/i)) {
							myColorRef = {rgb: null, loc: args[prop], stretch: false, opacity: 1.0};
					} else {
							var tc = new tinycolor(args[prop]).toHexString();
							myColorRef = {rgb: tc, loc: null, stretch: false, opacity: 1.0};
					}

			} else if (typeof(args[prop]) === 'object') {
					// object?  rgb:, loc:, stretch:, opacity: 
					myColorRef = {rgb: null, loc: null, stretch: false, opacity: 1.0};
					if (args[prop].hasOwnProperty('rgb') && args[prop].rgb)
							myColorRef.rgb = args[prop].rgb;
					else if (args[prop].hasOwnProperty('loc') && args[prop].loc)
							myColorRef.loc = args[prop].loc;
					if (args[prop].hasOwnProperty('stretch'))
							myColorRef.stretch = args[prop].stretch;
					if (args[prop].hasOwnProperty('opacity'))
							myColorRef.opacity = args[prop].opacity;
			}

			return myColorRef;        
	}
	static _getRGBAFromRef(colorRef) {
			var tc = new tinycolor(colorRef.rgb);
			tc.setAlpha(colorRef.opacity);
			return tc.toRgbString();
	}
}
export default GGCPrimitives;