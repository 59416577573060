import React from "react";
import { colors } from "../../utils/Utils";

const VerticalSizer = ({value, parentId, padding=0, onChange={}}) => {

	const onMouseMove = (e) => {
		e.preventDefault();
		let x = e.clientX;
		let bbox = document.getElementById(parentId).getBoundingClientRect();
		const width = bbox.right - bbox.left;
		if (x < bbox.left) {
			onChange(0);
		} else if (x > bbox.left + width) {
			onChange(100);
		} else {
			onChange(100 * (x - bbox.left) / width);
		}
	}

	const onMouseUp = (e) => {
		e.preventDefault();
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseup', onMouseUp);
	}

	if (!parentId || !document.getElementById(parentId)) {
		return null;
	}

	const parentRect = document.getElementById(parentId).getBoundingClientRect();
	const pHeight = parentRect.bottom - parentRect.top;

	return (
		<div style={{height: `calc(${pHeight}px + ${padding}px)`, bottom:0, position: 'absolute', top: 0, left:`${value}%`, width: '2px', backgroundColor: colors.primary}} 
		  onMouseOver={(e) => {
				e.preventDefault();
				e.target.style.cursor = 'col-resize';				
				e.target.style.width = '4px';
			}}
			onMouseOut={(e) => {
				e.preventDefault();
				e.target.style.cursor = 'auto';				
				e.target.style.width = '2px';
			}}
			onMouseDown={(e) => {
				e.preventDefault();
				e.stopPropagation();
				document.addEventListener('mousemove', onMouseMove);
				document.addEventListener('mouseup', onMouseUp);
			}}
		></div>
	)
}
export default VerticalSizer;