import {useEffect, useCallback, forwardRef, useImperativeHandle, useState} from "react";
import {createRoot} from 'react-dom/client';
import toast from 'react-hot-toast';
import { genCleanId } from "../../graphs/domutils";
import {FaRegCopy, FaTrash} from "react-icons/fa";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { colors } from "../../utils/Utils";

// data, header.  hidden is array of indeces starting at 1 to hide (like id position)
const OpenTable = forwardRef(({data, header, onRowClick, hidden=[], order=null, onDelete=()=>null}, ref) => {
	useImperativeHandle(ref, () => ({
		update() {
			table.columns.adjust();
		}
	}));

	const [table, setTable] = useState(null);
	const [myId, setMyIdj] = useState(genCleanId())
  const [t, i18n] = useTranslation();
	const [showDelete, setShowDelete] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	const [dlgPosi, setDlgPosi] = useState({right: 0, top: 0});

	function addBodyClassNameListener(className, callback) {
		var elem = document.body;	// elem.classList.contains(className)
		var tmr = window.setInterval(function () {
			if (elem.classList.contains(className)) {
				window.clearInterval(tmr);
				callback();
			}
		}, 10);
	}

	const handleMiniDlgClick = (e) => {
		const ele = document.getElementById(myId+"mdlg");
		if (ele && ele.contains(e.target)){

		} else {
			setShowDelete(false);
			window.removeEventListener('click', handleMiniDlgClick);
			e.preventDefault();
			e.stopPropagation();
		}
	};

	
//	const myId = 'dt_' + genCleanId();
	useEffect(() => {
		if (data.length > 0 && header.length > 0) {
			let defs = [];
			for (let i = 0; i < hidden.length; i++) {
				defs.push({ target: hidden[i], visible: false })
			}
			for (let i = 0; i < header.length; i++) {
				if (header[i].title === t("dlg.theme_header_liveurl")) {
					defs.push({
						targets: i,
						sorting: false,
						createdCell: (td, cellData, rowData, row, col) => {
							let root = createRoot(td);
							root.render(
								<FaRegCopy title={t("title.copy_dashboard_url")} style={{ color: colors.primary, marginLeft: 8, marginRight: 8, fontSize: 20, cursor: "pointer" }}
									onClick={(e) => {
										e.stopPropagation();
										navigator.clipboard.writeText(cellData);
										toast.success(t("msg.dashboard_url_copied"));
									}}
								/>
								)
						}
					})
				} else if (header[i].title === t("dlg.theme_header_thumbnail")) {
					defs.push({
						targets: i,
						sorting: false,
						createdCell: (td, cellData, rowData, row, col) => {
							let root = createRoot(td);
							if (cellData) {
							root.render(
								<img src={cellData} alt="pic" style={{ width: 100, height: 100, cursor: "pointer" }}
									onClick={(e) => {
									}}
								/>
							)
							} else {
								root.render(
									<div style={{ width: 100, height: 100, cursor: "pointer" }}
										onClick={(e) => {
										}}
									/>
								)
							}
						}
					})
				} else if (header[i].title === t("delete")) {
					defs.push({
						targets: i,
						sorting: false,
						createdCell: (td, cellData, rowData, row, col) => {
							let root = createRoot(td);
							root.render(
								<FaTrash title={t("delete")} style={{ color: colors.primary, marginLeft: 8, marginRight: 8, fontSize: 20, cursor: "pointer" }}
									onClick={(e) => {
										e.stopPropagation();
                    let targetBnds = e.target.getBoundingClientRect();
                    // Ask are you sure?
                    let parent = document.getElementById(myId + '_wrapper');
                    let parentBnds = parent.getBoundingClientRect();
                    let rt = targetBnds.left;
                    let tp = targetBnds.top;

										setDlgPosi({right: rt, top: tp});
										setDeleteRow(rowData);
										window.addEventListener('click', handleMiniDlgClick);
										setShowDelete(true);

                    
//										onDelete(rowData);
									}}
								/>
								)
						}
					})	
				}
			}


			var dtParams = {
				/*
				lengthChange: false,
				serverSide: true,
				processing: true,
				ordering: false,
				*/
				searching: true,
				data: data,
				columns: header,
				paging: false,
				scrollY: '100%',
				/*
				paging: true,
				scrollX: true,
				sScrollX: "100%"
				*/
				columnDefs: defs,
			}
			if (order) {
				dtParams.order = order;
			}

			if ($.fn.dataTable.isDataTable('#' + myId)) {
				table.destroy();
				$('#' + myId).empty();
			}
			$('#' + myId).dataTable(dtParams);
			const newTable = $('#' + myId).DataTable();
			$('#'+myId + ' tbody').on('click', 'tr', function () {
				const rowClick = newTable.row(this).data();
				onRowClick(rowClick);
			});


			setTable(newTable);

			// wait for dialog to be opened, then adjust the columns...tricky!
			addBodyClassNameListener("modal-open", () => {
				window.setTimeout(() => {
					newTable.columns.adjust().draw();
				}, 100);
			});

		}

	}, [data]);

	const cancelDel = () => {
		setShowDelete(false);
		console.log('cancel');
	}

	const performDel = async () => {
		setShowDelete(false);
		console.log('perform');
		const isDel = await onDelete(deleteRow);
		if (isDel) {
			const ixDel = data.findIndex((d) => d[0] === deleteRow[0]);
			if (ixDel !== -1) {
				table.row(ixDel).remove().draw();
			}
		}
	}

	return (
		<>
			{showDelete &&
				<div style={{zIndex: 100, position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, background: 'rgba(0, 0, 0, 0.3)' }}>
					<div id={myId+"mdlg"} style={{
						backgroundColor: '#FFFFFF', border: '1px solid black', position: 'absolute',
						top: dlgPosi.top, left: dlgPosi.right - 170
					}}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<h5 style={{padding: '10px', marginLeft: '20px', marginRight: '20px'}} class="modal-title">{t("dlg.question_are_you_sure")}</h5>
							<div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
								<button style={{marginLeft: 'auto', marginRight: 8}} type="button" class="button-qr" onClick={cancelDel}>{t('cancel')}</button>
								<button type="button" class="button-qr" onClick={performDel}>{t('delete')}</button>
							</div>
						</div>
					</div>
				</div>
			}

			<div className="table-responsive" style={{ width: '100%', height: '100%' }}>
				<table id={myId} className="hover" width="100%"></table>
			</div>
		</>
	)
})

export default OpenTable;