import React, { useEffect, useState } from 'react';
import { _genId } from '../../graphs/domutils';
import {txtColor, txtHighlightColor, txtDisabledColor, bgColor, bgHighlightColor} from '../constants'

const GGCMenu = ({onSelect, menuId=-1, displayable, expanded, menuList, onClick=()=>null}) => {
	const [pos, setPos] = useState({top: 30, left: 90});

	const _genMenuId = 'gzm' + _genId();
	const dispId = 'gzd' + _genId();

	// Open/Close menu
	const handleUserClick = (e) => {
		// e.pageX, e.pageY
		if (!expanded) {
			const myRect = e.target.getBoundingClientRect();
			setPos({top: myRect.bottom, left: myRect.left});
		} else {
		}
		e.stopPropagation();
		onClick(menuId);
	}

	const handleClick = (e) => {
		const ele = document.getElementById(_genMenuId);
		if (ele && ele.contains(e.target)){
			// Clicked in box
		} else if (expanded) {
			const ele = document.getElementById(dispId);
			if (ele) {
				ele.style.backgroundColor = bgColor;
				ele.style.color = txtColor;
			}
			onClick(-1);
		}
	}
	
	useEffect(() => {
		if (expanded) {
			window.addEventListener('click', handleClick);
			return () => {
				window.removeEventListener('click', handleClick);
			}
		}
	}, [expanded]);

	const closeAndUnhighlight = () => {
		const ele = document.getElementById(dispId);
		if (ele) {
			ele.style.backgroundColor = bgColor;
			ele.style.color = txtColor;
		}
		onClick(-1);	// maybe move 'outside'
	}

	const mouseEnter = (e) => {
		const me = menuList.find((itm) => {
			return itm.label === e.currentTarget.dataset.label
		});
		if (me && !me.disabled) {
			e.target.style.backgroundColor = bgHighlightColor;
			e.target.style.color = txtHighlightColor;
			e.target.style.fontWeight = 'bold';
		}
	}
	const mouseLeave = (e) => {
		const me = menuList.find((itm) => {
			return itm.label === e.currentTarget.dataset.label
		});
		if (me && !me.disabled) {
			e.target.style.backgroundColor = bgColor;
			e.target.style.color = txtColor;
			e.target.style.fontWeight = 'normal';
		}
	}

	const showDisplayable = () => {
		if (displayable) {
			let combinedStyle;
			if (expanded) {
				combinedStyle = {cursor: 'pointer', backgroundColor: bgHighlightColor, color: txtHighlightColor};
			} else {
				combinedStyle = {cursor: 'pointer', backgroundColor: bgColor, color: txtColor};
			}

			if (displayable.props.style) {
				const cStyle = JSON.parse(JSON.stringify(displayable.props.style));
				combinedStyle = Object.assign(cStyle, combinedStyle);
			}

			const myClone = React.cloneElement(displayable, {
				id:dispId,
				onClick:handleUserClick,
				style:combinedStyle
			});

			return myClone;

		} else {
			return null;
		}
	}

	return (
		<>
			{showDisplayable()}
			{expanded &&
				<div id={_genMenuId} style={{
					display: 'flex', flexDirection: 'column', alignItems: 'center',
					position: 'absolute', top: pos.top, left: pos.left,
					backgroundColor: bgColor, border: '1px solid #ccc', borderRadius: 5, zIndex: 1, width: 120
				}}>
					{menuList.map((item, i) => (
						<div key={i} style={{ 
							cursor: item.disabled ? 'auto' : 'pointer', 
							width: '100%', 
							paddingLeft: 20, paddingRight: 20, 
							backgroundColor: bgColor, 
							color: item.disabled ? txtDisabledColor : txtColor,
							fontSize: '.9em'
						}} 
						onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} 
							onClick={() => {
								if (!item.disabled) {
									closeAndUnhighlight();
									onSelect(item.value)
								}
							}}
						data-label={item.label}
						>{item.label}</div>
					))}
				</div>
			}
		</>
	)

}

export default GGCMenu;