var PIXEL = /^\d+(px)?$/i;
function getPixelValue(element, value) {
		if (PIXEL.test(value)) return parseInt(value);
		var style = element.style.left;
		var runtimeStyle = element.runtimeStyle.left;
		element.runtimeStyle.left = element.currentStyle.left;
		element.style.left = value || 0;
		value = element.style.pixelLeft;
		element.style.left = style;
		element.runtimeStyle.left = runtimeStyle;
		return value;
};

export function getElementHeight (elementId) {
	var element = document.getElementById(elementId);
	if (window.getComputedStyle) {
		var style = window.getComputedStyle(element, null);
		return parseInt(style.height);
	} else {
		return getPixelValue(element, element.currentStyle.height);
	}
}

export function getElementWidth (elementId) {
	const element = document.getElementById(elementId);
	if (window.getComputedStyle) {
		var style = window.getComputedStyle(element, null);
		return parseInt(style.width);
	} else {
		return getPixelValue(element, element.currentStyle.width);
	}
}

export function setElementHeight (elementId, height) {
	const element = document.getElementById(elementId);
	if (typeof height === 'function') height = height();
	if (typeof val === 'string') element.style.height = height;
	else element.style.height = height + 'px';
}

export function setElementWidth (elementId, width) {
	const element = document.getElementById(elementId);
	if (typeof width === 'function') width = width();
	if (typeof width === 'string') element.style.width = width;
	else element.style.width = width + 'px';
}

export function addEventListener(el, eventName, eventHandler, selector) {
  if (selector) {
    const wrappedHandler = (e) => {
      if (!e.target) return;
      const el = e.target.closest(selector);
      if (el) {
        eventHandler.call(el, e);
      }
    };
    el.addEventListener(eventName, wrappedHandler);
    return wrappedHandler;
  } else {
    const wrappedHandler = (e) => {
      eventHandler.call(el, e);
    };
    el.addEventListener(eventName, wrappedHandler);
    return wrappedHandler;
  }
}

export function _genId() {
	var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	};
	return "_" + (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
export function genCleanId() {
	var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	};
	return (S4() + S4() + S4() + S4() + S4() + S4());
}
