import * as d3 from 'd3';
import {graphBaseGGC} from './graphbaseggc';
import {legendTextWidth} from './clegend2';
import i18next from 'i18next';

export class axisGGC extends graphBaseGGC {
    constructor(args) {
        super(args);

        this.superClass = 'axisGGC';
        
        this.xAxisFont = this.font;
        this.xAxisFontStyle = '';
        this.xAxisLabelColor = '#7E7F7F';
        this.xAxisColor = '#DEE2E6';
        this.xAxisStrokeWidth = 1;
        this.yAxisFont = this.font;
        this.yAxisFontStyle = '';
        this.yAxisLabelColor = '#7E7F7F';
        this.yAxisColor = '#DEE2E6';
        this.yAxisStrokeWidth = 1;
        this.ellipses = 20; // 30 chars
        this.yAxisGridColor = '#DEE2E6';
        this.yAxisGridStrokeWidth = 1;
        this.xAxisGridColor = '#DEE2E6';
        this.xAxisGridStrokeWidth = 1;

        this.extraRightMargin = 0;

        this.editLeftMargin = undefined;
        this.editRightMargin = undefined;
        this.editExtraRightMargin = undefined;
        this.editTopMargin = undefined;
        this.editBottomMargin = undefined;
        this.focus = undefined;

        this._bStagger = undefined;

        this.defaultFormat = d3.format(",.0f")  // 2023 https://stackoverflow.com/questions/13513177/display-only-whole-values-along-y-axis-in-d3-js-when-range-is-0-1-or-0-2
        this.format = this.defaultFormat;
        this.minTicks = 1;
        this.maxTicks = 10;
        this.interval = 500;
        
        this._axis_doFormat = this._axis_doFormat.bind(this);

        this._axis_init(args);
    }
    _axis_init(args) {
        // 2023 this._base_init(args);
        this._axis_initVariables(args);
    }
    getAxisPropsOnly() {
        var self = this;
        var me = {
            xAxisFont: self.xAxisFont,
            xAxisFontStyle: self.xAxisFontStyle,
            xAxisLabelColor: self.xAxisLabelColor,
            xAxisColor: self.xAxisColor,
            xAxisStrokeWidth: self.xAxisStrokeWidth,
            yAxisFont: self.yAxisFont,
            yAxisFontStyle: self.yAxisFontStyle,
            yAxisLabelColor: self.yAxisLabelColor,
            yAxisColor: self.yAxisColor,
            yAxisStrokeWidth: self.yAxisStrokeWidth,
            ellipses: self.ellipses,
            xAxisGridColor: self.xAxisGridColor,
            xAxisGridStrokeWidth: self.xAxisGridStrokeWidth,
            yAxisGridColor: self.yAxisGridColor,
            yAxisGridStrokeWidth: self.yAxisGridStrokeWidth,
            _bStagger: self._bStagger,
            extraRightMargin: self.extraRightMargin,
        };
        return me;
    }
    applyThemes(item) {
        var self = this;
        if (item) {
            if (item.paletteProps) {
                self._palette_initVariables(item.paletteProps);
            }
            if (item.baseProps) {
                self._base_initVariables(item.baseProps);
            }
            if (item.axisProps) {
                self._axis_initVariables(item.axisProps);                
            }
            self.resize({ width: self.outerWidth, height: self.outerHeight });
        }
    }
    _axis_saveProps() {
        var self = this;

        var base = self._base_saveProps();
        var me = self.getAxisPropsOnly();
        return { ...base, ...me };
    }
    _axis_initVariables(args) {
        if (args.hasOwnProperty('xAxisFont'))
            this.xAxisFont = args.xAxisFont;

        if (args.hasOwnProperty('xAxisFontStyle'))
            this.xAxisFontStyle = args.xAxisFontStyle;

        if (args.hasOwnProperty('xAxisLabelColor'))
            this.xAxisLabelColor = args.xAxisLabelColor;

        if (args.hasOwnProperty('xAxisColor'))
            this.xAxisColor = args.xAxisColor;

        if (args.hasOwnProperty('xAxisStrokeWidth'))
            this.xAxisStrokeWidth = args.xAxisStrokeWidth;

        if (args.hasOwnProperty('yAxisFont'))
            this.yAxisFont = args.yAxisFont;

        if (args.hasOwnProperty('yAxisFontStyle'))
            this.yAxisFontStyle = args.yAxisFontStyle;

        if (args.hasOwnProperty('yAxisLabelColor'))
            this.yAxisLabelColor = args.yAxisLabelColor;

        if (args.hasOwnProperty('yAxisColor'))
            this.yAxisColor = args.yAxisColor;

        if (args.hasOwnProperty('yAxisStrokeWidth'))
            this.yAxisStrokeWidth = args.yAxisStrokeWidth;

        if (args.hasOwnProperty('ellipses'))
            this.ellipses = args.ellipses;

        if (args.hasOwnProperty('yAxisGridColor'))
            this.yAxisGridColor = args.yAxisGridColor;

        if (args.hasOwnProperty('yAxisGridStrokeWidth'))
            this.yAxisGridStrokeWidth = args.yAxisGridStrokeWidth;

        if (args.hasOwnProperty('xAxisGridColor'))
            this.xAxisGridColor = args.xAxisGridColor;

        if (args.hasOwnProperty('xAxisGridStrokeWidth'))
            this.xAxisGridStrokeWidth = args.xAxisGridStrokeWidth;

        if (args.hasOwnProperty('_bStagger'))
            this._bStagger = args._bStagger;

        if (args.hasOwnProperty('extraRightMargin'))
            this.extraRightMargin = args.extraRightMargin;

        // We must maintain a minimum margin setting for 'axis' graphs - so that the
        this.marginMin = { top: 30, right: 30, bottom: 10, left: 40 };

        if (this.margin.top < this.marginMin.top) this.margin.top = this.marginMin.top;
        if (this.margin.left < this.marginMin.left) this.margin.left = this.marginMin.left;
        if (this.margin.right < this.marginMin.right) this.margin.right = this.marginMin.right;
        if (this.margin.bottom < this.marginMin.bottom) this.margin.bottom = this.marginMin.bottom;

    }
    _trimEllipses(s) {
        if (s && s.length > this.ellipses)
            return s.substr(0, this.ellipses) + '...';

        else
            return s;
    }
    // trim based on actual pixel height
    _trimEllipsesLen(s, font, pixels) {
        var self = this;

        if (!s || s.length === 0) return '';

        var myLen = self._textWidth(s, font);
        if (myLen <= pixels)
            return s;
        else {
            // make fit
            var lenDots = self._textWidth('...', font);

            var mult = pixels / myLen; // say 80%
            var s1cLen = Math.floor(mult * s.length);
            if (s1cLen === 0)
                return s.substr(0, 1) + '...';

            while (true) {
                myLen = self._textWidth(s.substr(0, s1cLen), font);
                if (myLen <= pixels - lenDots)
                    return s.substr(0, s1cLen) + '...';
                else {
                    if (s1cLen <= 1)
                        return s.substr(0, 1) + '...';

                    else
                        s1cLen -= 2;
                }
            }
        }
    }
    _axis_drawXY() {
        var self = this;

        // Start drawing stuff
        this.diagram.selectAll('.x.axis').remove();
        this.diagram.selectAll('.y.axis').remove();
        this.diagram.selectAll('.x.axis-grid').remove();
        this.diagram.selectAll('.y.axis-grid').remove();


        // Adjust in case we need to stagger
        self._xaxis_stagger();
        // End Adjust
        // http://bl.ocks.org/jsl6906/a8a4dd54f3d8de6a7aae   grouped axis
        self._yaxis_add();

    }
    _yaxis_add() {
        var self = this;

        this.diagram.selectAll('.y.axis').remove();
        this.diagram.selectAll('.y.axis-grid').remove();

        if (self.horizontalGraph()) {
            this.diagram.append("g")
                .attr("class", "y axis")
                .style("font", this.yAxisFont)
                .style('font-weight', this.yAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
                .attr('text-decoration', this.yAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
                .attr("transform", "translate(0, 0)")
                .attr('stroke-width', this.yAxisStrokeWidth)
                .call(this.yAxis)
                .selectAll("text")
                .data(self.cur_data)
                .text(function (d) {
                    return self._trimEllipsesLen(d[self.ixLabel], self.yAxisFont, self.maxCharLen);
                })
                .attr('fullText', function (d) {
                    return d[self.ixLabel];
                })
                .style("text-anchor", "end");
        } else {
            this.diagram.append("g")
                .attr("class", "y axis")
                .attr('stroke-width', this.yAxisStrokeWidth)
                .call(this.yAxis)
                .style("font", this.yAxisFont)
                .style('font-weight', this.yAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
                .attr('text-decoration', this.yAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 6)
                .attr("dy", ".71em")
                .style("text-anchor", "end");
            if (this.hasOwnProperty('hasYGrid') && this.hasYGrid) {
                this.yAxisGrid.tickSize(-this.width);

                //this.yAxisGrid.ticks(this.yscale.ticks().length);
                // 2023 - whole numbers?
                const yAxisTicks = this.yscale.ticks().filter(tick => Number.isInteger(tick));
                this.yAxisGrid.tickValues(yAxisTicks);

                this.diagram.append("g")
                    .attr('class', 'y axis-grid')
                    .attr('stroke-width', this.yAxisGridStrokeWidth)
                    .call(this.yAxisGrid);
                this.diagram.selectAll('.y.axis-grid').selectAll('path').remove();
                this.diagram.selectAll('.y.axis-grid').select('g').filter(function (d, i) { return i === 0; }).remove();
            }
        }

        this.diagram.selectAll('.y.axis')
            .selectAll("text")
            .attr('fill', this.yAxisLabelColor);


        let skew = 0; // have to do this each time, as the skew 'stays' unless cleared...
        if (this.yAxisFontStyle.indexOf('I') !== -1)
            skew = self._italicSkewDegrees;

        this.diagram.selectAll('.y.axis').selectAll('g').selectAll('text')
            .attr("transform", function (d, i) {
                return " skewX(" + (i * 10 - skew) + ")";
            });

        this.diagram.selectAll('.y.axis').selectAll('g').selectAll('line')
            .style('stroke', self.yAxisColor);
        this.diagram.selectAll('.y.axis').selectAll('path')
            .style('stroke', self.yAxisColor);

        if (this.hasOwnProperty('hasYGrid') && this.hasYGrid) {
            this.diagram.selectAll('.y.axis-grid').selectAll('g').selectAll('line')
                .style('stroke', self.yAxisGridColor);
            //	this.diagram.selectAll('.y.axis-grid').selectAll('path')
            //	    .style('stroke', self.yAxisGridColor);
        }

    }
    _axis_refresh() {
        var self = this;

        self._yaxis_add();

        self._xaxis_stagger();

        // dont' need these if you call _axis_groupLabels after _axis_refresh
        // TODO: if vertical, use self.yAxisLabelColor, ..Font, FontStyle, strokeWidth
        for (let j = 0; j < self.ixGroups.length; j++) {
            var groups = this.diagram.selectAll("g.chartGroup" + j);

            groups.selectAll("text")
                .style('fill', self.xAxisLabelColor)
                .style('stroke', self.xAxisLabelColor)
                .style("font", self.xAxisFont)
                .style('font-weight', this.xAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
                .attr('text-decoration', this.xAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

            let skew = 0;
            if (this.xAxisFontStyle.indexOf('I') !== -1)
                skew = self._italicSkewDegrees;

            groups.selectAll('text')
                .attr("transform", function (d, i) {
                    return " skewX(" + (i * 10 - skew) + ")";
                });

            groups.selectAll('path')
                .attr('stroke', self.xAxisColor)
                .attr('stroke-width', self.xAxisStrokeWidth);
        }

        if (self.editMode) {
            self.editLeftMargin
                .attr("x1", self.margin.left)
                .attr("x2", self.margin.left)
                .attr('y1', 0)
                .attr('y2', self.origHeight);

        }

    }
    _xaxis_stagger() {
        var self = this;

        this.diagram.selectAll('.x.axis').remove();
        this.diagram.selectAll('.x.axis-grid').remove();

        if (self.horizontalGraph()) {
            this.diagram.append("g")
                .attr("class", "x axis")
                .attr('stroke-width', this.xAxisStrokeWidth)
                .call(this.xAxis)
                .style("font", this.xAxisFont)
                .style('font-weight', this.xAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
                .attr('text-decoration', this.xAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
                .attr("transform", "translate(0," + this.height + ")")
                .append("text")
                .style("text-anchor", "middle");
            if (this.hasOwnProperty('hasXGrid') && this.hasXGrid) {
                this.xAxisGrid.tickSize(this.height);

                //2023 whole numbers this.xAxisGrid.ticks(this.xscale.ticks().length);
                const xAxisTicks = this.xscale.ticks().filter(tick => Number.isInteger(tick));
                this.xAxisGrid.tickValues(xAxisTicks);

                this.diagram.append("g")
                    .attr('class', 'x axis-grid')
                    .attr('stroke-width', this.xAxisGridStrokeWidth)
                    .call(this.xAxisGrid);
                // The normal axis puts a line on bottom and right, remove it
                this.diagram.selectAll('.x.axis-grid').selectAll('path').remove();
                // Also, remove the 1st item in the .x.axis-grid <g> element, as it is the actual axis
                this.diagram.selectAll('.x.axis-grid').select('g').filter(function (d, i) { return i === 0; }).remove();

            }

        } else {
            this.diagram.append("g")
                .attr("class", "x axis")
                .style("font", this.xAxisFont)
                .style('font-weight', this.xAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
                .attr('text-decoration', this.xAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
                .attr("transform", "translate(0," + this.height + ")")
                .attr('stroke-width', this.xAxisStrokeWidth)
                .call(this.xAxis)
                .selectAll("text")
                .data(self.cur_data)
                .text(function (d) {    // 2024-02-03
                    if (self._textWidth(d[self.ixLable], this.xAxisFont) < self.barWidth) {
                        return d[self.ixLabel];
                    } else {
                        return self._trimEllipses(d[self.ixLabel]);
                    }
                })
                .attr('fullText', function (d) {
                    return d[self.ixLabel];
                })
                .style("text-anchor", "middle");
        }

        this.diagram.selectAll('.x.axis')
            .selectAll("text")
            .attr('fill', this.xAxisLabelColor);

        var skew = 0; // have to do this each time, as the skew 'stays' unless cleared...
        if (this.xAxisFontStyle.indexOf('I') !== -1)
            skew = self._italicSkewDegrees;
        this.diagram.selectAll('.x.axis').selectAll('g').selectAll('text')
            .attr("transform", function (d, i) {
                return " skewX(" + (i * 10 - skew) + ")";
            });

        this.diagram.selectAll('.x.axis').selectAll('g').selectAll('line')
            .style('stroke', self.xAxisColor);
        this.diagram.selectAll('.x.axis').selectAll('path')
            .style('stroke', self.xAxisColor);


        if (this.hasOwnProperty('hasXGrid') && this.hasXGrid) {
            this.diagram.selectAll('.x.axis-grid').selectAll('g').selectAll('line')
                .style('stroke', self.xAxisGridColor);
        }

        // All labels
        self._bStagger = false;

        try {
            var translateX = [];
            this.diagram.selectAll('.x.axis g')
                .select(function (d, i) {
                    let xlate = d3.select(this).attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
                    if (xlate.length === 3) {
                        translateX.push(+xlate[1]);
                    }
                });
            var labels = [];
            this.diagram.selectAll('.x.axis g text')
                .select(function (d, i) {
                    let lbl = d3.select(this).html();
                    labels.push({ label: lbl, width: legendTextWidth(lbl, self.xAxisFont) });
                });

            // Possibly adjust labels (if they don't fit, we stagger by TextHeight)
            if (labels.length === translateX.length) { // seems like they better
                for (let i = 0; i < labels.length; i++) {
                    if (i === labels.length - 1) {
                        ;
                    } else if (translateX[i] + labels[i].width / 2 > translateX[i + 1] - labels[i + 1].width / 2) {
                        self._bStagger = true;
                        break;
                    }
                }

                if (self._bStagger) {
                    var fntHeight = self._axisLabelHeight;
                    this.diagram.selectAll('.x.axis g')
                        .select(function (d, i) {
                            if (i % 2) {
                                //let line = d3.select(this).select('line');
                                //let y2 = +line.attr('y2') + fntHeight;
                                d3.select(this).select('line').attr('y2', fntHeight);
                                let txt = d3.select(this).select('text');
                                let xform = txt.attr('transform');
                                if (!xform) xform = "";
                                xform = "translate(0, " + (fntHeight - 2) + ")" + xform;
                                txt.attr('transform', xform);
                            } else {
                                d3.select(this).select('line').attr('y2', 6);
                                let txt = d3.select(this).select('text');
                                let xform = txt.attr('transform');
                                if (!xform) xform = "";
                                xform = "translate(0, 0)" + xform;
                                txt.attr('transform', xform);
                            }
                        });
                }

            }

        } catch (ex) {
            console.log(ex);
        }

        return self._bStagger;
    }
 
    _axis_doFormat(d, i) { return this.format(d,i) }
    _axis_toTerra(d) { return (Math.round(d / 10000000000) / 100) + "T" }
    _axis_toGiga(d) { return (Math.round(d / 10000000) / 100) + "G" }
    _axis_toMega(d) { return (Math.round(d / 10000) / 100) + "M" }
    _axis_toKilo(d) { return (Math.round(d / 10) / 100) + "k" }
    _axis_countDecimals(v) {
        var test = v, count = 0;
        while (test > 10) {
            test /= 10
            count++;
        }
        return count;
    }
    _axis_groupLabels(ixStart) {
        var self = this;

        // Group stuff for extra x axis labels
        this.ixGroups = [];
        for (var i = 0; i < this.categoryNames.length - 1; i++)
            this.ixGroups.push(i);
        this.ixGroups = this.ixGroups.reverse();
        this.chartGroups = [];
        function _sameGroup(d, arsub, j) {
            for (var k = j; k < self.ixGroups.length; k++) {
                if (d[self.ixGroups[k]] !== arsub[self.ixGroups[k]])
                    return false;
            }
            return true;
        }
        for (let j = 0; j < self.ixGroups.length; j++) {
            let arGroups = [];
            self.cur_data.forEach(function (d, i, array) {
                d.last = false;
                if (i === 0) {
                    d.first = true;
                } else if (!_sameGroup(d, array[i - 1], j)) {
                    d.first = true;
                }
                else
                    d.first = false;

                if (i === array.length - 1)
                    d.last = true;
                else if (!_sameGroup(d, array[i + 1], j))
                    d.last = true;

                if (d.first) arGroups.push({
                    group: d[self.ixGroups[j]],
                    start: self.horizontalGraph() ? self.yscale(ixStart + i) : self.xscale(ixStart + i)
                });

                if (d.last)
                    arGroups[arGroups.length - 1].end = (self.horizontalGraph() ? self.yscale(ixStart + i) : self.xscale(ixStart + i)) + self.barWidth;
            });

            this.chartGroups.push(arGroups);
        }

        for (var j = 0; j < self.categoryNames.length - 1; j++) {
            self.diagram.selectAll(".chartGroup" + j).remove();
        }

        // TODO: if horizontal, change xAxis to yAxis, also need to change position
        var mfi;
        if (self.horizontalGraph()) {
            mfi = {
                labelColor: self.yAxisLabelColor,
                font: self.yAxisFont,
                fontStyle: self.yAxisFontStyle,
                color: self.yAxisColor,
                strokeWidth: self.yAxisStrokeWidth
            };
        }
        else {
            mfi = {
                labelColor: self.xAxisLabelColor,
                font: self.xAxisFont,
                fontStyle: self.xAxisFontStyle,
                color: self.xAxisColor,
                strokeWidth: self.xAxisStrokeWidth
            };
        }


        for (let j = 0; j < self.ixGroups.length; j++) {
            var groups;

            let skew = 0;
            if (mfi.fontStyle.indexOf('I') !== -1)
                skew = self._italicSkewDegrees;

            if (self.horizontalGraph()) { // all horizontal need  this._yLabelWidth (width in pixels of y label + padding)
                groups = this.diagram.selectAll("g.chartGroup" + j)
                    .data(self.chartGroups[j])
                    .enter().append("g")
                    .attr("class", "chartGroup" + j)
                    .attr("transform", "translate(" +
                        (-(self._yLabelWidth + self._axisLabelHeight * j)) +
                        ", 0)");

                groups.append("text")
                    .attr('y', function (d) {
                        return 0;
                    })
                    //                .attr('dy', '1em')
                    .attr('fill', mfi.labelColor)
                    .attr('text-anchor', 'middle')
                    .text(function (d) {
                        return self._trimEllipsesLen(d.group, self.yAxisFont, d.end - d.start);
                    })
                    .attr('fullText', function (d) {
                        return d.group;
                    })
                    .style("font", mfi.font)
                    .style('font-weight', mfi.fontStyle.indexOf('B') !== -1 ? 700 : 400)
                    .attr('text-decoration', mfi.fontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
                    .attr('transform', function (d) {
                        return "translate(-" + ((self._axisLabelHeight * .25) + self._axisLabelAscent) + "," + ((d.start + d.end) / 2) + ") rotate(270) skewX(-" + skew + ")"; // font*1.5 is axisLabelHeight. so, we want half of 'gap'
                    });

                groups
                    .append("line")
                    .attr('x1', -self._axisLabelHeight)
                    .attr('x2', self._yLabelWidth)
                    .attr('y1', function (d, ix) {
                        if (ix === 0)
                            return d.start;

                        else
                            return d.start - .5 * self.padding;
                    })
                    .attr('y2', function (d, ix) {
                        if (ix === 0)
                            return d.start;

                        else
                            return d.start - .5 * self.padding;
                    })
                    .style('stroke', mfi.color)
                    .style('opacity', .5)
                    .style("stroke-width", 1);

                groups
                    .append("line")
                    .attr('x1', 0)
                    .attr('x2', 0)
                    .attr('y1', function (d, ix) {
                        if (ix === 0)
                            return d.start;

                        else
                            return d.start - .5 * self.padding;
                    })
                    .attr('y2', function (d, ix) {
                        return d.end; //  - .5*self.padding;
                    })
                    .style('stroke', mfi.color)
                    .style('opacity', .5)
                    .style("stroke-width", 1);

            } else {
                groups = this.diagram.selectAll("g.chartGroup" + j)
                    .data(self.chartGroups[j])
                    .enter().append("g")
                    .attr("class", "chartGroup" + j)
                    .attr("transform", "translate(0," +
                        (self.height + 9 + self._axisLabelHeight * (j + 1 + (self._bStagger ? 1 : 0))) + ")");
                // TODO: need to add     ^more room for tick + gap
                groups.append("text")
                    .attr('x', function (d) {
                        return (d.start + d.end) / 2;
                    })
                    .attr('dy', '1em')
                    .attr('fill', mfi.labelColor)
                    .attr('text-anchor', 'middle')
                    .text(function (d) {
                        return self._trimEllipses(d.group);
                    })
                    .attr('fullText', function (d) {
                        return d.group;
                    })
                    .style("font", mfi.font)
                    .style('font-weight', mfi.fontStyle.indexOf('B') !== -1 ? 700 : 400)
                    .attr('text-decoration', mfi.fontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

                groups.selectAll('text')
                    .attr("transform", function (d, i) {
                        return " skewX(" + (i * 10 - skew) + ")";
                    });

                // Brackets
                groups
                    .append("path")
                    .attr("d", function (d) {
                        var t = d3.select(this.parentNode).select("text").node().getBBox(),
                            //ttop = [t.x + t.width / 2, t.y];
                            ym = (t.y + t.height) / 2;
                        return "M " + d.start + " 0 v " + ym + " h " + (t.x - d.start - 4) + " M " + (t.x + t.width + 4) + " " + ym + " H " + d.end + " v " + (-ym);
                    })
                    .attr('stroke', mfi.color)
                    .attr("stroke-width", mfi.strokeWidth)
                    .attr("fill", "none");

            }


            groups
                .on("mouseover", function (event, d) {  // v6 added event
                    if (self.editMode) {
                    } else {
                        var fullText = d3.select(this).select("text").attr('fullText');
                        if (fullText && (fullText !== d3.select(this).select("text").text())) {

                            //v5 var mpos = d3.mouse(self.diagram.node());
                            var mpos = d3.pointer(event, self.diagram.node());    //v6

                            if (self.horizontalGraph()) {
                                self.diagram.append("text")
                                    .attr("x", mpos[0] + 3)
                                    .attr("y", mpos[1] - 18)
                                    .attr("class", "grplabelGGC")
                                    .text(fullText)
                                    .style("cursor", "pointer")
                                    .style("font", this.yAxisFont);
                            } else {
                                var bnds = d3.select(this).node().getBBox();
                                self.diagram.append("text")
                                    .attr("x", (bnds.x + bnds.width) / 2)
                                    .attr("y", (bnds.y + bnds.height) / 2)
                                    .attr("class", "grplabelGGC")
                                    .text(fullText)
                                    .style("cursor", "pointer")
                                    .style("font", this.yAxisFont);
                            }

                            var tbx = self.diagram.select('.grplabelGGC').node().getBBox();

                            self.diagram.insert('rect', '.grplabelGGC')
                                .attr('x', tbx.x - 2)
                                .attr('y', tbx.y - 2)
                                .attr('width', tbx.width + 4)
                                .attr('height', tbx.height + 4)
                                .attr('class', 'grplabelBox')
                                .style('fill', 'white')
                                .style('stroke', 'blue')
                                .style("cursor", "pointer")
                                .on('click', function () {
                                    var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                                    var tpsf_event = {
                                        // eid:
                                        // schema:
                                        table: self.tableName,
                                        field: self.categoryNames[ixC],
                                        value: d3.select(this).select("text").attr('fullText') // text()
                                    };

                                    self.diagram.select(".grplabelBox").remove();
                                    self.diagram.select(".grplabelGGC").remove();

                                    self.categoryClick(tpsf_event);
                                });


                        }

                        d3.select(this).select('text').attr('fill', 'blue');
                        d3.select(this).select('text').attr('font-weight', 'bold');
                        d3.select(this).style("cursor", "pointer");
                    }
                })
                .on("mouseout", function () {
                    if (self.editMode) {
                    } else {
                        d3.select(this).select('text').attr('fill', mfi.labelColor);
                        d3.select(this).select('text').attr('font-weight', 'normal');
                        d3.select(this).style("cursor", "auto");

                        self.diagram.select(".grplabelBox").remove();
                        self.diagram.select(".grplabelGGC").remove();

                    }
                })
                .on("click", function (event, d) {
                    if (self.editMode) {
                    } else {
                        var tpsf_event = {
                            // eid:
                            // schema:
                            table: self.tableName,
                            field: self.categoryNames[self.ixGroups.length - 1 - j],
                            value: d.group
                        };
                        self.categoryClick(tpsf_event);
                    }
                });

        }
    }
    _axis_drawXY_edit() {
        var self = this;

        if (self.editMode) {
            var xstart;

            var dragLeftMargin = d3.drag()
                .on('start', function (event, d) {  // v6 added event

                    // v5 xstart = d3.event.x;
                    xstart = event.x;
                    self.editLeftMargin
                        .attr("x1", xstart)
                        .attr("x2", xstart)
                        .attr('y1', 0)
                        .attr('y2', self.origHeight)
                        .attr('opacity', 1);

                })
                .on('drag', function (event, d) {   // v6 added event
                    //v5 var offs = d3.event.x - xstart;
                    var offs = event.x - xstart; // v6
                    if (offs > (self.origWidth / 2))
                        offs = self.origWidth / 2;
                    if (self.margin.left + offs < self.marginMin.left) offs = self.marginMin.left - self.margin.left;

                    self.editLeftMargin
                        .attr("x1", xstart + offs)
                        .attr("x2", xstart + offs)
                        .attr('y1', 0)
                        .attr('y2', self.origHeight);

                })
                .on('end', function (event, d) {    // v6 added event
                    self.editLeftMargin
                        .attr('opacity', 0);

                    //v5 var offs = d3.event.x - xstart;
                    var offs = event.x - xstart;
                    if (offs > (self.origWidth / 2))
                        offs = self.origWidth / 2;
                    if (self.margin.left + offs < self.marginMin.left) offs = self.marginMin.left - self.margin.left;

                    self.margin.left += offs;
                    self.resize({ width: self.outerWidth, height: self.outerHeight });

                });

            var dragRightMargin = d3.drag()
                .on('start', function (event, d) {  // v6 added event

                    // v5 xstart = d3.event.x;
                    xstart =  event.x;
                    self.editRightMargin
                        .attr("x1", xstart)
                        .attr("x2", xstart)
                        .attr('y1', 0)
                        .attr('y2', self.origHeight)
                        .attr('opacity', 1);

                })
                .on('drag', function (event, d) {   // v6 added event
                    //v5 var offs = d3.event.x - xstart;
                    var offs = event.x - xstart;
                    if (offs > (self.origWidth / 2))
                        offs = self.origWidth / 2;
                    if (self.margin.right - offs < self.marginMin.right) offs = self.margin.right - self.marginMin.right;

                    self.editRightMargin
                        .attr("x1", xstart + offs)
                        .attr("x2", xstart + offs)
                        .attr('y1', 0)
                        .attr('y2', self.origHeight);

                })
                .on('end', function (event, d) {        // v6 added event
                    self.editRightMargin
                        .attr('opacity', 0);

                    //v5 var offs = d3.event.x - xstart;
                    var offs = event.x - xstart;
                    if (offs > (self.origWidth / 2))
                        offs = self.origWidth / 2;
                    if (self.margin.right - offs < self.marginMin.right) offs = 0;

                    self.margin.right -= offs;
                    self.resize({ width: self.outerWidth, height: self.outerHeight });

                });

                
            var dragExtraRightMargin = d3.drag()
            .on('start', function (event, d) {      // v6 added event

                //v5 xstart = d3.event.x;
                xstart = event.x;
                self.editExtraRightMargin
                    .attr("x1", xstart)
                    .attr("x2", xstart)
                    .attr('y1', 0)
                    .attr('y2', self.origHeight)
                    .attr('opacity', 1);

            })
            .on('drag', function (event, d) {       // v6 added event
                //v5 var offs = d3.event.x - xstart;
                var offs = event.x - xstart;
                if (offs > (self.origWidth / 2))
                    offs = self.origWidth / 2;
                if (self.extraRightMargin - offs < 0) offs = 0;

                self.editExtraRightMargin
                    .attr("x1", xstart + offs)
                    .attr("x2", xstart + offs)
                    .attr('y1', 0)
                    .attr('y2', self.origHeight);

            })
            .on('end', function (event, d) {        // v6 added event
                self.editExtraRightMargin
                    .attr('opacity', 0);

                //v5 var offs = d3.event.x - xstart;
                var offs = event.x - xstart;
                if (offs > (self.origWidth / 2))
                    offs = self.origWidth / 2;
                if (self.extraRightMargin - offs < 0) offs = 0;

                self.extraRightMargin -= offs;
                self.resize({ width: self.outerWidth, height: self.outerHeight });

            });
            
            var dragTopMargin = d3.drag()
                .on('start', function (event, d) {      // v6 added event

                    //v5 xstart = d3.event.y;
                    xstart = event.y;
                    self.editTopMargin
                        .attr("x1", 0)
                        .attr("x2", self.origWidth)
                        .attr('y1', xstart)
                        .attr('y2', xstart)
                        .attr('opacity', 1);

                })
                .on('drag', function (event, d) {       // 
                    //v5 var offs = d3.event.y - xstart;
                    var offs = event.y - xstart;
                    if (offs > (self.origHeight / 2))
                        offs = self.origHeight / 2;
                    if (self.margin.top + offs < self.marginMin.top) offs = self.marginMin.top - self.margin.top;

                    self.editTopMargin
                        .attr("x1", 0)
                        .attr("x2", self.origWidth)
                        .attr('y1', xstart + offs)
                        .attr('y2', xstart + offs);

                })
                .on('end', function (event, d) {        // v6 added event
                    self.editTopMargin
                        .attr('opacity', 0);

                    //v5 var offs = d3.event.y - xstart;
                    var offs = event.y - xstart;
                    if (offs > (self.origHeight / 2))
                        offs = self.origHeight / 2;
                    if (self.margin.top + offs < self.marginMin.top) offs = self.marginMin.top - self.margin.top;

                    self.margin.top += offs;
                    self.resize({ width: self.outerWidth, height: self.outerHeight });

                });

            if (!self.horizontalGraph()) {
                self.editLeftMargin
                    .attr("x1", self.margin.left)
                    .attr("x2", self.margin.left)
                    .attr('y1', 0)
                    .attr('y2', self.origHeight)
                    .attr('opacity', 1)
                    .style('cursor', 'ew-resize')
                    .call(dragLeftMargin);
            }

            self.editRightMargin
                .attr("x1", self.origWidth - self.margin.right)
                .attr("x2", self.origWidth - self.margin.right)
                .attr('y1', 0)
                .attr('y2', self.origHeight)
                .attr('opacity', 1)
                .style('cursor', 'ew-resize')
                .call(dragRightMargin);

            self.editExtraRightMargin
                .attr("x1", self.width+self.margin.left) // self.origWidth - self.margin.right - self.extraRightMargin)
                .attr("x2", self.width+self.margin.left) // self.origWidth - self.margin.right - self.extraRightMargin)
                .attr('y1', 0)
                .attr('y2', self.origHeight)
                .attr('opacity', 1)
                .style('cursor', 'ew-resize')
                .call(dragExtraRightMargin);

            self.editTopMargin
                .attr("x1", 0)
                .attr("x2", self.origWidth)
                .attr('y1', self.margin.top)
                .attr('y2', self.margin.top)
                .attr('opacity', 1)
                .style('cursor', 'ns-resize')
                .call(dragTopMargin);

        } else {
            self.editLeftMargin
                .attr('opacity', 0);
            self.editRightMargin
                .attr('opacity', 0);
            self.editExtraRightMargin
                .attr('opacity', 0);
            self.editTopMargin
                .attr('opacity', 0);
            self.editBottomMargin
                .attr('opacity', 0);
        }

    }
    _axis_focusY() {
        var self = this;

        self.focus = self.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .attr('stroke', 'black')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0);

        this.editLeftMargin = this.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .style('stroke-width', 2)
            .attr('stroke', self.editColor)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0)
            .on("mouseover", function () {
                this.style.strokeWidth = 4;
            })
            .on("mouseout", function () {
                this.style.strokeWidth = 2;
            });

        this.editRightMargin = this.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .style('stroke-width', 2)
            .attr('stroke', self.editColor)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0)
            .on("mouseover", function () {
                this.style.strokeWidth = 4;
            })
            .on("mouseout", function () {
                this.style.strokeWidth = 2;
            });

        this.editExtraRightMargin = this.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .style('stroke-width', 2)
            .attr('stroke', self.editColor)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0)
            .on("mouseover", function () {
                this.style.strokeWidth = 4;
            })
            .on("mouseout", function () {
                this.style.strokeWidth = 2;
            });

            // The problem here is that these are drawn on top of the pencils.
        this.editTopMargin = this.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .style('stroke-width', 2)
            .attr('stroke', self.editColor)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0)
            .on("mouseover", function () {
                this.style.strokeWidth = 4;
            })
            .on("mouseout", function () {
                this.style.strokeWidth = 2;
            });

        this.editBottomMargin = this.svg
            .append('g')
            .append('line')
            .style('fill', 'none')
            .style('stroke-width', 2)
            .attr('stroke', self.editColor)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('opacity', 0)
            .on("mouseover", function (d) {
                this.style.strokeWidth = 4;
            })
            .on("mouseout", function (d) {
                this.style.strokeWidth = 2;
            });
    }
    _axis_checkEditMode(kind) {
        var self = this;

        // squiggles: https://codepen.io/lbebber/pen/KwGEQv?editors=1100
        if (self.editMode) {
            // pick middle yaxis and xaxis values, redraw them
            // Y Axis Labels
            var yticks = this.diagram.selectAll('.y.axis').selectAll('g').size();
            var myIx = Math.floor(yticks / 2);
            // e.g. "translate(0,130.3454203282969)"
            var trans = this.diagram.selectAll('.y.axis')
                .selectAll('g')
                .filter(function (d, i) { return i === myIx; })
                .attr('transform')
                .match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
            var x, y;
            if (trans.length === 3) {
                x = +trans[1];
                y = self.height;    // self.margin.top + (self.height - self.margin.top - self.margin.bottom)/yticks;

                var lbl = this.diagram.selectAll('.y.axis')
                    .selectAll('g')
                    .filter(function (d, i) { return i === myIx; })
                    .selectAll('text').html();
                x -= (this._textWidth(lbl, this.yAxisFont) / 2);

                self._drawCircleEditText({
                    selector: self.diagram,
                    kind: 'yaxislbl',
                    title: i18next.t("graph.edit.yaxis_label"),
                    label: lbl,
                    drawLabel: false,
                    x: x,
                    y: y,
                    r: 12,
                    placement: 'right',
                    variables: {
                        fontProp: 'yAxisFont',
                        styleProp: 'yAxisFontStyle',
                        colorProp: 'yAxisLabelColor'
                    },
                    open: (typeof kind !== 'undefined' && kind === 'yaxislbl') ? true : false

                });
            }

            // X Axis Labels
            var xticks = this.diagram.selectAll('.x.axis').selectAll('g').size();
            myIx = Math.floor(xticks / 2);

            trans = this.diagram.selectAll('.x.axis')
                .attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);

            if (trans.length === 3) {
                x = +trans[1];
                y = +trans[2];
            } else {
                x = 0;
                y = 0;
            }

            var xAxisY = y;
            var xAxisX = x;

            trans = this.diagram.selectAll('.x.axis')
                .selectAll('g')
                .filter(function (d, i) { return i === myIx; })
                .attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
            if (trans.length === 3) {
                // e.g. "140k"
                x += +trans[1]; // x
                y += +trans[2]; // y

                let lbl = this.diagram.selectAll('.x.axis').selectAll('g').filter(function (d, i) { return i === myIx; }).selectAll('text').html();
                y += +this.diagram.selectAll('.x.axis').selectAll('g').filter(function (d, i) { return i === myIx; }).selectAll('line').attr('y2');
                y += +this.diagram.selectAll('.x.axis').selectAll('g').filter(function (d, i) { return i === myIx; }).selectAll('text').attr('y');

                self._drawCircleEditText({
                    selector: self.diagram,
                    kind: 'xaxislbl',
                    title: i18next.t("graph.edit.xaxis_label"),
                    label: lbl,
                    drawLabel: false,
                    x: x,
                    y: y,
                    r: 12,
                    placement: 'top',
                    variables: {
                        fontProp: 'xAxisFont',
                        styleProp: 'xAxisFontStyle',
                        colorProp: 'xAxisLabelColor'
                    }
                });
            }

            // Now, draw the axis line edit circles
            myIx = xticks > 0 ? xticks - 1 : 0;
            trans = this.diagram.selectAll('.x.axis').selectAll('g').filter(function (d, i) { return i === myIx; })
                .attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
            if (trans.length === 3) {
                // e.g. "140k"
                x = self.width; //  xAxisX + +trans[1]; // x
                y = xAxisY + +trans[2]; // y

                self._drawCircleEditLine({
                    selector: self.diagram,
                    kind: 'xaxis',
                    title: i18next.t("graph.edit.xaxis_lines"),
                    x: x,
                    y: y,
                    placement: 'top',
                    variables: {
                        widthProp: 'xAxisStrokeWidth',
                        colorProp: 'xAxisColor'
                    }
                });

                if (self.hasOwnProperty('hasXGrid')) {
                    y -= 30;

                    self._drawCircleEditLine({
                        selector: self.diagram,
                        kind: 'xaxisgrid',
                        title: i18next.t("graph.edit.xaxis_grid_lines"),
                        x: x,
                        y: y,
                        placement: 'top',
                        variables: {
                            widthProp: 'xAxisGridStrokeWidth',
                            colorProp: 'xAxisGridColor'
                        }
                    });
                }
            }

            myIx = yticks > 0 ? yticks - 1 : 0;
            trans = this.diagram.selectAll('.y.axis').selectAll('g').filter(function (d, i) { return i === myIx; })
                .attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
            if (trans.length === 3) {
                // e.g. "140k"
                x = 0;  //  +trans[1]; // x
                y = 0;  // +trans[2]; // y

                self._drawCircleEditLine({
                    selector: self.diagram,
                    kind: 'yaxis',
                    title: i18next.t("graph.edit.yaxis_lines"),
                    x: x,
                    y: y,
                    placement: 'right',
                    variables: {
                        widthProp: 'yAxisStrokeWidth',
                        colorProp: 'yAxisColor'
                    }
                });
                if (self.hasOwnProperty('hasYGrid')) {
                    x += 30;
                    self._drawCircleEditLine({
                        selector: self.diagram,
                        kind: 'yaxisgrid',
                        title: i18next.t("graph.edit.yaxis_grid_lines"),
                        x: x,
                        y: y,
                        placement: 'right',
                        variables: {
                            widthProp: 'yAxisGridStrokeWidth',
                            colorProp: 'yAxisGridColor'
                        }
                    });

                }
            }



            // Draw legend edits
            self._baseEditMode();


        }

    }
}

//axisGGC.prototype = new graphBaseGGC();
