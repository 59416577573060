/* eslint-disable no-extend-native */
import * as d3 from 'd3';
import GGCPrimitives from './GGCPrimitives';
import $ from "jquery";
import { paletteGGC } from './paletteggc';
import "select2";
import "select2/select2.css";
import "../assets/css/fontpicker.css";
import "../assets/css/select2.css";
import i18next from 'i18next';

const tinycolor = require("tinycolor2");

// old data getter  tropare-graphs/api/graphs/controllers/graphController.js (genSQL)
// Color gradients:  https://docs.google.com/document/d/1L0ODwGBf7wVJKOQcuSL7XpSIOM4VqbGZ3l-VQ5N_s7g/edit#bookmark=id.tcifufgl8j6z
// Global Variable
export const biaColors = {
    _colorGradients: [
        ['#4D3016','#6A4B20','#85682B','#9D8938','#B1AB49','#C1D05E'],
        ['#4F3716','#6A4821','#875A2D','#A66B3A','#C77D49','#E98F5A'],
        ['#3A441B','#4E5925','#646E2F','#7C8539','#959B43','#AFB34D'],
        ['#14493E','#1F614F','#2E7A5F','#3F936E','#52AE7C','#69C989'],
        ['#14493E','#1B6157','#237B73','#2D9592','#3AB0B2','#4ACBD5'],
        ['#274554','#315E6E','#397788','#3F92A2','#45AEBC','#4ACBD5'],
        ['#274554','#3B5C71','#52738F','#6B8AAE','#88A2CE','#A8B9EE'],
        ['#45394E','#5A4F6B','#70688A','#8481AA','#979DCC','#A8B9EE'],
        ['#45394E','#614C67','#806081','#A2749B','#C688B4','#ED9CCD'],
        ['#5E323E','#7B4456','#985871','#B56E8E','#D184AD','#ED9CCD'],
        ['#5E323E','#7B414D','#98505C','#B7616A','#D67277','#F68483'],
        ['#60312A','#7C4039','#99504A','#B7615C','#D6726F','#F68483']
    ],        
    // teal, dkblue, aqua, whitegray, dkgray, ltgray
    _tropColors: ['#35B1CE', '#2F4066', '#50E3C2', '#F8F8F8', '#363B3C', '#ADAEB6'],
    _d3ColorsCategorical: [ 
                {name: "Troparé 12", colors: ['#2e599b', '#cea1ed', '#984444', '#d79c45', '#258677', '#3db2cc', '#da555a', '#f6a5a5', '#8a9356', '#70cbb4', '#9f54a6', '#9a9a9a'], loc: 'Tropare12'},
                {name: "Troparé 12 Dark", colors: ['#3F598C', '#B2A2D7', '#914C4C', '#D19C52', '#338271', '#3CB2CC', '#C95B5E', '#DCB2B2', '#90932F', '#98C5B9', '#955999', '#9D9D9D'], loc: 'Tropare12DK'},
		        {name: 'Troparé Alt', colors: ['#2BD0E1', '#ffb54b', '#5aba6b', '#bf6cc7', '#f7534d', '#ffef76', '#3b56b5', '#fb90b0', '#56b7f6', '#0097a8', '#805bc2', '#ff8962', '#c3e0a6', '#8f6e63', '#e0e0e0', '#9e9e9e', '#34474f'], loc: 'TropareAlt'},
                {name: 'Category 10', colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"] /*d3.schemeCategory10*/, loc: 'Category10'},
                 {name: 'Accent' , colors: ["#7fc97f", "#beaed4", "#fdc086", "#ffff99", "#386cb0", "#f0027f", "#bf5b17", "#666666"] /*d3.schemeAccent*/, loc: 'Accent'},
                 {name: 'Dark' , colors: ["#1b9e77", "#d95f02", "#7570b3", "#e7298a", "#66a61e", "#e6ab02", "#a6761d", "#666666"] /*d3.schemeDark2*/, loc: 'Dark2'},
                 {name: 'Paired' , colors: ["#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#b15928"] /*d3.schemePaired*/, loc: 'Paired'},
                 {name: 'Pastel 1' , colors: ["#fbb4ae", "#b3cde3", "#ccebc5", "#decbe4", "#fed9a6", "#ffffcc", "#e5d8bd", "#fddaec", "#f2f2f2"] /*d3.schemePastel1*/, loc: 'Pastel1'},
                 {name: 'Pastel 2' , colors: ["#b3e2cd", "#fdcdac", "#cbd5e8", "#f4cae4", "#e6f5c9", "#fff2ae", "#f1e2cc", "#cccccc"] /*d3.schemePastel2*/, loc:'Pastel2'},
                 {name: 'Set 1' , colors: ["#e41a1c", "#377eb8", "#4daf4a", "#984ea3", "#ff7f00", "#ffff33", "#a65628", "#f781bf", "#999999"] /*d3.schemeSet1*/, loc: 'Set1'},
                 {name: 'Set 2' , colors: ["#66c2a5", "#fc8d62", "#8da0cb", "#e78ac3", "#a6d854", "#ffd92f", "#e5c494", "#b3b3b3"] /*d3.schemeSet2*/, loc: 'Set2'},
                 {name: 'Set 3' , colors: ["#8dd3c7", "#ffffb3", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#ffed6f"]/*d3.schemeSet3*/, loc: 'Set3'},
                 {name: 'Tableau 10', colors: ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab"] /*d3.schemeTableau10*/, loc: 'Tableau10'}
    ],
    _d3ColorsCategoricalDefaultIx: 9,
    _d3ColorsInterpolate: [
        // Diverging
        {name: 'Brown-Green', interpolate: d3.interpolateBrBG, loc: 'BrBG'},
        {name: 'Purple-Green', interpolate: d3.interpolatePRGn, loc: 'PRGn'},
        {name: 'Pink-Green', interpolate: d3.interpolatePiYG, loc: 'PiYG'},
        {name: 'Purple-Orange',interpolate: d3.interpolatePuOr, loc: 'PuOr'},
        {name: 'Red-Blue',interpolate: d3.interpolateRdBu, loc: 'RdBu'},
        {name: 'Red-Grey',interpolate: d3.interpolateRdGy, loc: 'RdGy'},
        {name: 'Red-Yellow-Blue',interpolate: d3.interpolateRdYlBu, loc: 'RdYlBu'},
        {name: 'Red-Yellow-Green',interpolate: d3.interpolateRdYlGn, loc: 'RdYlGn'},
        {name: 'Spectral',interpolate: d3.interpolateSpectral, loc: 'Spectral'},
        // Sequential (Single Hue)
        {name: 'Blues',interpolate: d3.interpolateBlues, loc: 'Blues'},
        {name: 'Greens',interpolate: d3.interpolateGreens, loc: 'Greens'},
        {name: 'Greys',interpolate: d3.interpolateGreys, loc: 'Greys'},
        {name: 'Oranges',interpolate: d3.interpolateOranges, loc: 'Oranges'},
        {name: 'Purples',interpolate: d3.interpolatePurples, loc: 'Purples'},
        {name: 'Reds',interpolate: d3.interpolateReds, loc: 'Reds'},
        // Sequential (Multi-Hue)
        {name: 'Turbo',interpolate: d3.interpolateTurbo, loc: 'Turbo'},
        {name: 'Viridis',interpolate: d3.interpolateViridis, loc: 'Viridis'},
        {name: 'Inferno', interpolate: d3.interpolateInferno, loc: 'Inferno'},
        {name: 'Magma', interpolate: d3.interpolateMagma, loc: 'Magma'},
        {name: 'Plasma', interpolate: d3.interpolatePlasma, loc: 'Plasma'},
        {name: 'Cividis', interpolate: d3.interpolateCividis, loc: 'Cividis'},
        {name: 'Warm', interpolate: d3.interpolateWarm, loc: 'Warm'},
        {name: 'Cool', interpolate: d3.interpolateCool, loc: 'Cool'},
        {name: 'CubeHelix', interpolate: d3.interpolateCubehelixDefault, loc: 'Cubehelix'},
        {name: 'Blue-Green', interpolate: d3.interpolateBuGn, loc: 'BuGn'},
        {name: 'Blue-Purple', interpolate: d3.interpolateBuPu, loc: 'BuPu'},
        {name: 'Green-Blue', interpolate: d3.interpolateGnBu, loc: 'GnBu'},
        {name: 'Orange-Red', interpolate: d3.interpolateOrRd, loc: 'OrRd'},
        {name: 'Purple-Blue-Green', interpolate: d3.interpolatePuBuGn, loc: 'PuBuGn'},
        {name: 'Purple-Blue', interpolate: d3.interpolatePuBu, loc: 'PuBu'},
        {name: 'Red-Purple', interpolate: d3.interpolateRdPu, loc: 'RdPu'},
        {name: 'Yellow-Green-Blue', interpolate: d3.interpolateYlGnBu, loc: 'YlGnBu'},
        {name: 'Yellow-Green', interpolate: d3.interpolateYlGn, loc: 'YlGn'},
        {name: 'Yellow-Orange-Brown', interpolate: d3.interpolateYlOrBr, loc: 'YlOrBr'},
        {name: 'Yellow-Orange-Red', interpolate: d3.interpolateYlOrRd, loc: 'YlOrRd'},
        // Cyclical
        {name: 'Rainbow', interpolate: d3.interpolateRainbow, loc: 'Rainbow'},
        {name: 'Sinebow', interpolate: d3.interpolateSinebow, loc: 'Sinebow'}
    ],
    _d3ColorsInterpolateDefaultIx: 35,
    
    _graphFonts: [
'Arial, "Helvetica Neue", Helvetica, sans-serif',
'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif',
'"Helvetica Neue", Helvetica, Arial, sans-serif',
'"Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif',
'"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif',
'Cambria, Georgia, serif',
'Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif',
'Perpetua, Baskerville, "Big Caslon", "Palatino Linotype", Palatino, "URW Palladio L", "Nimbus Roman No9 L", serif',
'Georgia, Times, "Times New Roman", serif',
'Consolas, monaco, monospace',
'"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
'Tahoma, Verdana, Segoe, sans-serif',
'Verdana, Geneva, sans-serif',
'Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif',
'"Gill Sans", "Gill Sans MT", Calibri, sans-serif',
'"Century Gothic", CenturyGothic, AppleGothic, sans-serif',
'Candara, Calibri, Segoe, "Segoe UI", Optima, Arial, sans-serif',
'"Andale Mono", AndaleMono, monospace',
'Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif',
'Copperplate, "Copperplate Gothic Light", fantasy',
'Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif',
'"Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif',
'"Franklin Gothic Medium", "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif',
'Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif',
'"Calisto MT", "Bookman Old Style", Bookman, "Goudy Old Style", Garamond, "Hoefler Text", "Bitstream Charter", Georgia, serif'
        
    ],
    _markerShapes: [
        {name: 'Asterisk', loc: 'asterisk.svg'},
        {name: 'Bright Star', loc: 'bright-star.svg'},
        {name: 'Circle', loc: 'circle.svg'},
        {name: 'Ennegon', loc: 'ennegon.svg'},
        {name: 'Hexagon', loc: 'hexagon.svg' },
        {name: 'Nonagon', loc: 'nonagon.svg'},
        {name: 'Octagon', loc: 'octagon.svg'},
        {name: 'Oval', loc: 'oval.svg' },
        {name: 'Parallelogram', loc: 'parallelogram.svg' },
        {name: 'Pentagon', loc: 'pentagon.svg' },
        {name: 'Rhombus', loc: 'rhombus.svg'},
        {name: 'Rounded Bright Star', loc: 'rounded-brightstar.svg'},
        {name: 'Rounded Ennegon', loc: 'rounded-ennegon.svg'},
        {name: 'Rounded Hexagon', loc: 'rounded-hexagon.svg'},
        {name: 'Rounded Octagon', loc: 'rounded-octagon.svg'},
        {name: 'Rounded Pentagon', loc: 'rounded-pentagon.svg'},
        {name: 'Rounded Rectangle', loc: 'rounded-rectangle.svg'},
        {name: 'Rounded Square', loc: 'rounded-square.svg'},
        {name: 'Rounded Star', loc: 'rounded-star.svg'},
        {name: 'Rounded Triangle', loc: 'rounded-triangle.svg'},
        {name: 'Semi-Circle Down', loc: 'semicircle-down.svg'},
        {name: 'Semi-Circle Up', loc: 'semicircle-up.svg'},
        {name: 'Split Circle', loc: 'split-circle.svg'},
        {name: 'Square', loc: 'square.svg'},
        {name: 'Star 6', loc: 'star-6.svg'},
        {name: 'Star', loc: 'star.svg'},
        {name: 'Trapezium', loc: 'trapezium.svg'},
        {name: 'Triangle', loc: 'triangle.svg'},
        {name: 'Yield', loc: 'yield.svg'}                
    ]
    

};  // end biaColors
const _dashArrays = [
    {name: 'solid', pattern: "0", loc: 'da_none.png'},
    {name: 'dashed', pattern: "5, 5", loc: 'da_5c5.png'},
    {name: 'spread dashed', pattern: "5, 10", loc: 'da_5c10.png'},
    {name: 'long dashed', pattern: "10, 5", loc: 'da_10c5.png'},
    {name: 'short dashed', pattern: "5, 1", loc: 'da_5c1.png'},
    {name: 'dots', pattern: "1, 5", loc: 'da_1c5.png'},
    {name: 'short dots', pattern: "0.9", loc: 'da_0p9.png'},
    {name: 'dash dash dot', pattern: "15, 10, 5", loc: 'da_15c10c5.png'},
    {name: 'dash dot', pattern: "15, 10, 5, 10", loc: 'da_15c10c5c10.png'},
    {name: 'dash dot dash da da', pattern: "15, 10, 5, 10, 15", loc: 'da_15c10c5c10c15.png'},
    {name: 'short dash dot', pattern: "5, 5, 1, 5", loc: 'da_5c5c1c5.png'}
]

d3.selection.prototype.first = function() {
  return d3.select(this.nodes()[0]);
};
d3.selection.prototype.last = function() {
  var last = this.size() - 1;
  return d3.select(this.nodes()[last]);
};

String.prototype.right = function( qty )
{
return this.toString().slice( -( qty ) );
}
String.prototype.left = function( qty )
{
return this.toString().slice( 0, qty );
}
String.prototype.toColorRef = function() {
    var colorRef = {rgb: null, loc: null, ref: null, stretch: false, opacity: 1.0};
    if (this.toString().match(/^http:|^https:/i)) {
        colorRef.loc = this;
    } else {
        if (this.toString().length === 0)
            colorRef.rgb = null;
        else {
            var tc = new tinycolor(this.toString());
            colorRef.rgb = tc.toHexString();        // #FFFF20 e.g. 
            colorRef.opacity = tc.getAlpha();
        }
    }
    
    return colorRef;
}

var MeasureTextGGC = (function () {
    var canvas = document.createElement('canvas'),
        context = canvas.getContext('2d');

    /**
     * Measures the rendered width of arbitrary text given the font size and font face
     * @param {string} text The text to measure
     * @param {number} fontSize The font size in pixels
     * @param {string} fontFace The font face ("Arial", "Helvetica", etc.)
     * @returns {number} The width of the text
     **/
    function getWidth(text, font) {
        context.font = font;
        return context.measureText(text).width;
    }
    
    function getHeight(text, font) {
        context.font = font;
        var tm = context.measureText(text);
        return tm.actualBoundingBoxAscent + tm.actualBoundingBoxDescent; 
    }
    function getAscent(text, font) {
        context.font = font;
        var tm = context.measureText(text);
        return tm.actualBoundingBoxDescent; 
    }

    return {
        getWidth: getWidth,
        getHeight: getHeight,
        getAscent: getAscent
    };
})();

export class graphBaseGGC extends paletteGGC {
    constructor(args) {
        super(args);

        this.graphType = undefined; // set from setData()
        this.superClass = 'graphBaseGGC';

        this.editTabIndex = 0;
        this.$lastPopover = undefined;

        this.eid = undefined; // entity id
        // 2023-11 The 'old' graphing assumed arrays of data. The [0], [1], etc. values are the
        // category names. e.g. State, City.  Then we had the value names like population, income
        // so, the data would be: [Illinois, Chicago, 1000000, 50000] and the categoryNames would be
        // ['State', 'City'] and the valueNames would be ['Population', 'Income']
        // in 2023, I added extra values that can be 'between' the categories and values.  I call these
        // extraNames.  So, we can have [Illinois, Chicago, Cook County, 1000000, 50000] and the categoryNames would be
        // ['State', 'City'] and the extraNames would be ['County'] and the valueNames would be ['Population', 'Income']
        // That way, for things like gantt charts, we can put the 'id' in the extraNames and not have it graphed.
        // See _valueIndex below.
        this.categoryNames = undefined; // array
        this.extraNames = []; // array of items between categories and values.
        this.valueNames = undefined; // array
        this.valueNamesDuplicate = undefined;

        this.tableName = undefined; // assume single table
        this.data = undefined;
        // sortOrder is an array matching the data row length. We can can have multiple orders for dimension values
        // e.g. sort by state, then by zip.  However, if we sort by a 'value', then we need to pay attention
        // to how many dimensions we have.  if a single dimension, then can sort solely by the value column
        // If multiple dimensions, e.g. STATE,City you can sort by the value (which carries the end dimension w it), but
        // you can also sort by each dimension prior to the last.  So, in State, City you can sort by sales (which messees up the city
        // order. but you can also sort by State.  So, if we had Country,State,City - you can sort by sales, then you can also sort
        // by state and or country)
        this.initialSortOrder = [];
        this.sortOrder = [];
        this.canSort = true;
        this.showSorter = true;
        this.lockSorter = false;

//        this.font = '12px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';         // '12px Verdana, Geneva, sans-serif';
        this.font = '12px Verdana, Geneva, sans-serif';
        this.legendFont = this.font;
        this.legendColor = '#434343';
        this.legendFontStyle = '';
        this._italicSkewDegrees = 20;
        this._legTextOffset = -2;

        this.txtSorterFont = '12px Verdana, Geneva, sans-serif';
        this.txtSorterColor = "#000000";
        this.txtSorterFontStyle = 'normal';
        this.txtSorterFontWeight = 'normal';
        this.txtSorterDecoration = 'none'

        // set in _base_initVariables
        this.margin = undefined;
        this._autoMarginLeft = undefined;
        //this.colorPaletteOpacity = .7;
        this.container = undefined;

        this.backgroundColor = "#FFFFFF".toColorRef();
        this.legendBackgroundColor = "#FFFFFF55".toColorRef();     // "#55FFFFFF".toColorRef();

        this.svg = undefined; // 2023 don't see where set
        this.diagram = undefined; // 2023 don't see use

        this.outerContainer = 'body';

        this.popoverContainer = undefined;

        this.ixLabel = undefined; // setData

        this.valueStatus = [];
        this.legendClassArray = [];

        this.outerHeight = undefined;
        this.outerWidth = undefined;
        this.origWidth = undefined;
        this.origHeight = undefined;
        this.height = undefined;
        this.width = undefined;
        this.viewBox = undefined;

        this.tooltipId = undefined;
        this.tooltip = undefined;
        this._pagingControl = undefined;
        this._gotdata = undefined;
        this._legRowHeight = undefined;
        this._editRowHeight = undefined;
        this._lastDrawRange = undefined;
        this._lastScrollY = undefined;

        // Override on per-graph basis
        this.marginMin = { top: 0, right: 0, bottom: 0, left: 0 };
        this.isHorizontal = false;


        //this._googleFontApiKey = "AIzaSyC4GcdCrIP2-CDIG4cVVL4Qs05iIHDsQWI";
        this.legendSpace = 38; // 2px space, 18px marker, 2px space, 16px scroller
        this.legendShrink = 20;
        this.legendTextHidden = undefined;

        this._hiddenOpacity = 0.25;
        this._dontSetColorDomain = ['sunburst', 'treemap', 'circlepack', 'pie'];
        this._unsubscribes = {};

        this._sortDivId = undefined;
        this._sortIconId = undefined;
        this._sortTableId = undefined;

        this._base_initVariables(args);
    }
    /*
    _base_init(args) {

        this._palette_init(args);
    }
    */
    // call when saving...
    getBasePropsOnly() {
        var self = this;
        var me = {
            graphType: self.graphType,
            margin: self.margin,
            /*
            colorPaletteType: self.colorPaletteType,
            colorPaletteIndex: self.colorPaletteIndex,
            customPalette: self.customPalette,
            colorPaletteOpacity: self.colorPaletteOpacity,
            */
            backgroundColor: self.backgroundColor,
            legendBackgroundColor: self.legendBackgroundColor,
            font: self.font,
            legendFont: self.legendFont,
            legendFontStyle: self.legendFontStyle,
            legendColor: self.legendColor,
            isHorizontal: self.isHorizontal,
            autoMarginLeft: self._autoMarginLeft,
            sortOrder: self.sortOrder,
            canSort: self.canSort,
            showSorter: self.showSorter,
            txtSorterFont: self.txtSorterFont,
            txtSorterColor: self.txtSorterColor,
            txtSorterFontStyle: self.txtSorterFontStyle,
            txtSorterFontWeight: self.txtSorterFontWeight,
            txtSorterDecoration: self.txtSorterDecoration,
            lockSorter: self.lockSorter,
            viewBox: self.viewBox
        };
        return me;
    }
    _base_saveProps() {
        var self = this;

        var me = self.getBasePropsOnly();
        var pal = self._palette_saveProps();

        return { ...pal, ...me };
    }
    applyThemes(item) {
        var self = this;
        if (item) {
            if (item.paletteProps) {
                self._palette_initVariables(item.paletteProps);
            }
            if (item.baseProps) {
                self._base_initVariables(item.baseProps);
            }
            self.resize({ width: self.outerWidth, height: self.outerHeight });
        }
    }
    _base_initVariables(args) {
        if (args.hasOwnProperty('margin'))
            this.margin = args.margin;

        else
            this.margin = { top: 30, right: 30, bottom: 5, left: 50 };

        if (args.hasOwnProperty("autoMarginLeft")) {
            this._autoMarginLeft = args.autoMarginLeft;
            if (this._autoMarginLeft)
                this.margin.left = 40;
        }

        this.isHorizontal = false;


        if (args.hasOwnProperty('backgroundColor')) {
            this.backgroundColor = GGCPrimitives._argsToColorRef(args, 'backgroundColor');
        }

        if (args.hasOwnProperty('legendBackgroundColor')) {
            this.legendBackgroundColor = GGCPrimitives._argsToColorRef(args, 'legendBackgroundColor');
        }

        if (args.hasOwnProperty('font'))
            this.font = args.font;

        if (args.hasOwnProperty('legendFont'))
            this.legendFont = args.legendFont;

        if (args.hasOwnProperty('legendFontStyle'))
            this.legendFontStyle = args.legendFontStyle;

        if (args.hasOwnProperty('legendColor'))
            this.legendColor = args.legendColor;

        if (args.hasOwnProperty('italicSkewDegrees'))
            this._italicSkewDegrees = args.italicSkewDegrees;

        if (args.hasOwnProperty('container')) {
            this.outerContainer = args.container;
        }
        var cid = 'Cont' + this._genId();
        $(this.outerContainer).append('<div id="' + cid + '"></div>');
        this.container = '#' + cid;

        this.popoverContainer = document.getElementById(cid).closest('#surface');

        if (args.hasOwnProperty('categoryClick'))
            this.categoryClick = args.categoryClick;

        else
            this.categoryClick = function (a) { };

        if (args.hasOwnProperty('eid'))
            this.eid = args.eid;

        else
            this.eid = this._genId();

        if (args.hasOwnProperty('sortOrder')) { // array of -1 (desc) +1 (asc) 0 = use group as order
            this.sortOrder = args.sortOrder;
        }
        if (args.hasOwnProperty('canSort')) {
            this.canSort = args.canSort;
        }
        if (args.hasOwnProperty('showSorter')) {
            this.showSorter = args.showSorter;
        }
        if (args.hasOwnProperty('lockSorter')) {
            this.lockSorter = args.lockSorter;
        }

        if (args.hasOwnProperty('txtSorterFont')) {
            this.txtSorterFont = args.txtSorterFont;
        }
        if (args.hasOwnProperty('txtSorterColor')) {
            this.txtSorterColor = args.txtSorterColor;
        }
        if (args.hasOwnProperty('txtSorterFontStyle')) {
            this.txtSorterFontStyle = args.txtSorterFontStyle;
        }
        if (args.hasOwnProperty('txtSorterFontWeight')) {
            this.txtSorterFontWeight = args.txtSorterFontWeight;
        }
        if (args.hasOwnProperty('txtSorterDecoration')) {
            this.txtSorterDecoration = args.txtSorterDecoration;
        }


        this.legendClassArray = [];
        this.valueStatus = [];

        this.editMode = false;

    }
    // Special settings that require some finesse.
    setTxtSorterColor(color) {
        // Need to refresh sorter
        this.txtSorterColor = color;
        this._setupSorters();
    }
    setTxtSorterFontStyle(style) {
        this.txtSorterFontStyle = style;
        this._setupSorters();
    }
    setTxtSorterFontWeight(weight) {
        this.txtSorterFontWeight = weight;
        this._setupSorters();
    }
    setTxtSorterDecoration(decoration) {
        this.txtSorterDecoration = decoration;
        this._setupSorters();
    }
    setShowSorter(show) {
        this.showSorter = show;
        this.canSort = show;
        $('#'+this._sortDivId).empty();
        if (show) {
            this._setupSorters();        
        }
    }    
    setLockSorter(lock) {
        this.lockSorter = lock;
        $('#'+this._sortDivId).empty();
        this._setupSorters();        
    }

    horizontalGraph() {
        var self = this;
        if (self.hasOwnProperty('isHorizontal') && self.isHorizontal) {
            return true;
        } else {
            return false;
        }
    }
    _genCleanId() {
        return 'U' + this._genId().replace(/[-_]/g, '');
    }
    _genId() {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return "_" + (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
    setData(opts, darray) {
        var self = this;
        if (opts.hasOwnProperty('type')) {
            this.graphType = opts.type;
        }

        if (opts.hasOwnProperty('categoryNames')) {
            this.categoryNames = opts.categoryNames;
            this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City                
        }

        if (opts.hasOwnProperty('extraNames')) {
            this.extraNames = opts.extraNames;
        }

        if (opts.hasOwnProperty('valueNames')) {
            this.valueNames = opts.valueNames;
            this.setValueNamesDuplicate();
            this.color.domain(this.valueNames);
        }

        if (!opts.hasOwnProperty('tableName'))
            throw new Error('tablename is required');
        this.tableName = opts.tableName;

        if (opts.hasOwnProperty('ajax')) {
            this.ajax = opts.ajax;
        }
        else
            this._setData(darray);

        if (self._sortDivId) {
            $('#'+self._sortDivId).empty();
        }

        self._sortDivId = 'S' + self._genId();
        $(self.container).append('<div id="' + self._sortDivId + '"></div>');

        self._sortIconId = 'SI' + self._genId();

    }
    setValueNamesDuplicate() {
        let hasDup = false;
        this.valueNamesDuplicate = new Array(this.valueNames.length).fill(null);
        for (let i=0; i<this.valueNames.length; i++) {
            const ckVal = this.valueNames[i];
            for (let j=0; j<this.valueNames.length; j++) {
                if (i !== j) {
                    if (this.valueNames[j] === ckVal) {
                        hasDup = true;
                        this.valueNamesDuplicate[i] = ckVal;
                        this.valueNamesDuplicate[j] = ckVal;
                    }
                }
            }
        }
        if (hasDup) {
            for (let i=0; i<this.valueNames.length; i++) {
                if (this.valueNamesDuplicate[i] !== null) {
                    this.valueNames[i] = this.valueNames[i] + ' (' + i + ')';
                }
            }
        }
    }
    _setData(darray) {
        this.data = darray;
    }
    _addPagingControls() {
        var self = this;
        if (self._pagingControl) return;
        var uid = this._genId();
        self._pagingControl = '#bia_pageInfo_' + uid;

        var html = '<div style="width: 100%; position: relative; bottom: 0px;"><table id="bia_pageInfo_' + uid + '" style="width: 100%;">' +
            '<tr>' +
            '<td style="width:30%">' +
            '<div class="dataTables_info" id="bia_of_' + uid + '" role="status" aria-live="polite" style="float:left; margin-left: 10px; " >Showing x to y of z entries</div>' +
            '</td>' +
            '<td style="width:70%">' +
            '<div class="dataTables_paginate paging_simple_numbers col-sm-7" id="bia_paginate_' + uid + '" style="float: right; margin-right: 10px;">' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table></div>';

        $(this.outerContainer).append(html);

        return uid;
    }
    setupPaging(uid) {
        var self = this;
        // 
        // Enable paging with 'limit' size pages
        var npages = Math.ceil(self.ajax.data.recordsTotal / self.ajax.data.length);
        // id=bia_paginate is div
        $('#bia_paginate_'+uid).empty();
        var html = '<ul class="pagination" id="bia-page-ul-' + uid + '" style="float:right;">';
        html += '</ul>';
        $('#bia_paginate_' + uid).append(html);

        // <li class="page-item previous disabled"    Pevious
        // <li class="page-item "                     regular
        // <li class="page-item disabled"             ...
        // <li class="page-item next"                 Next
        //         tabindex="0">
        // add 'active' to class to make blue (like when using next/previous)
        var activePage = 0;
        if (self.ajax.data.hasOwnProperty('start')) {
            if (self.ajax.data.start !== 0)
                activePage = self.ajax.data.start / self.ajax.data.length;
        }

        function drawBiaPagi() {
            html = '';
            $('#bia-page-ul-'+uid).empty();
            if (npages > 6) {
                // use ...
                var cls;
                if (activePage === 0)
                    cls = ' disabled';

                else
                    cls = '';
                html += '<li class="page-item previous' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">Previous</a></li>';

                if (activePage === 0)
                    cls = ' active';

                else
                    cls = '';
                html += '<li class="page-item' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">1</a></li>';

                if (activePage > 4)
                    html += '<li class="page-item disabled pbc-' + uid + '"><a class="page-link" href="#">...</a></li>';
                else {
                    // linear run 2, 3, 4                                
                    for (let i = 1; i <= 4; i++) {
                        if (i === activePage)
                            cls = ' active';

                        else
                            cls = '';
                        html += '<li class="page-item' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">' + (i + 1) + '</a></li>';
                    }
                }

                // fill the middle
                if (activePage > 4 && activePage < npages - 3) {
                    for (let i = activePage - 1; i <= activePage + 1; i++) {
                        if (i === activePage)
                            cls = ' active';

                        else
                            cls = '';
                        html += '<li class="page-item' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">' + (i + 1) + '</a></li>';
                    }
                }

                if (activePage < npages - 3)
                    html += '<li class="page-item disabled pbc-' + uid + '"><a class="page-link" href="#">...</a></li>';
                else { // linear at end 101, 102, 103, 104, 105
                    for (let i = npages - 5; i < npages - 1; i++) {
                        if (i === activePage)
                            cls = ' active';

                        else
                            cls = '';
                        html += '<li class="page-item' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">' + (i + 1) + '</a></li>';
                    }

                }

                if (activePage === npages - 1)
                    cls = ' active';

                else
                    cls = '';
                html += '<li class="page-item' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">' + npages + '</a></li>';


                if (activePage === npages - 1)
                    cls = ' disabled';

                else
                    cls = '';
                html += '<li class="page-item next' + cls + ' pbc-' + uid + '"><a class="page-link" href="#">Next</a></li>';

            } else {
                html += '<li class="page-item previous disabled pbc-' + uid + '"><a class="page-link" href="#">Previous</a></li>';
                for (let i = 0; i < npages; i++) {
                    if (i === activePage)
                        html += '<li class="page-item active pbc-' + uid + '"><a class="page-link" href="#">' + (i + 1) + '</a></li>';

                    else
                        html += '<li class="page-item pbc-' + uid + '"><a class="page-link" href="#">' + (i + 1) + '</a></li>';
                }
                html += '<li class="page-item next disabled pbc-' + uid + '"><a class="page-link" href="#">Next</a></li>';
            }
            $('#bia-page-ul-' + uid).append(html);

            // fill in 'of'
            var maxi = ((activePage + 1) * self.ajax.data.length);
            if (maxi > self.ajax.data.recordsTotal)
                maxi = self.ajax.data.recordsTotal;
            $('#bia_of_'+uid).empty().text('Showing ' +
                (activePage*self.ajax.data.length+1) + ' to ' +
                maxi  + ' of ' +
                self.ajax.data.recordsTotal + ' entries');
            // events
            $('.pbc-' + uid + ' a').off('click').on('click', function(ev) {
                ev.preventDefault();
                if (!ev.target.parentElement.classList.contains('disabled')) {
                    var page = ev.target.text;
                    //                var active = $('.pbc-' + uid + '.active');
                    //                if (active.length > 0) {
                    //                    console.log(active.children()[0].text);
                    //                }
                    $('.pbc-'+uid).removeClass('active');

                    if (page === 'Previous') {
                        activePage--;
                        drawBiaPagi();
                    } else if (page === 'Next') {
                        activePage++;
                        drawBiaPagi();
                    } else {
                        ev.target.parentElement.classList.add('active');
                        activePage = +page - 1;
                        drawBiaPagi();
                    }


                    self.ajax.data.start = activePage * self.ajax.data.length;
                    self._getRefreshData();

                }
            });

        }
        $('#bia_pageInfo_' + uid).show();
        drawBiaPagi();


    }
    _getRefreshData() {
        var self = this;

        self._ajaxGetData(function (err, odata) {
            // On success
            var myData;
            if (self.ajax.hasOwnProperty('clientProc') && self.ajax.clientProc) {
                // 2023 was odata.data.slice(1), we removed header row
                myData = self.ajax.clientProc(odata.data, self.ajax.hasOwnProperty('clientProcParam') ? self.ajax.clientProcParam : null);
            }
            else
                myData = JSON.parse(JSON.stringify(odata.data));       // 2023 was slice(1)

            self._setData(myData);
            self.resize({ width: self.outerWidth, height: self.outerHeight });
        });

    }
    _ajaxGetData(callback) {
        const sortOrder = this.ajax.data.saveProps && this.ajax.data.saveProps.argprops && this.ajax.data.saveProps.argprops.hasOwnProperty('sortOrder') ? this.ajax.data.saveProps.argprops.sortOrder : this.initialSortOrder;
        var odata = this.ajax.sampleData(sortOrder);
        callback(null, odata);
    }
    _ajaxR(callback) {
        var self = this;
        $.ajax({
            url: this.ajax.url,
            type: this.ajax.type,
            cache: false,
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(this.ajax.data),
            retryCount: 0,
            retryLimit: 3,
            success: function (qdata, textStatus, jqXHR) {
                callback.apply(this, [null, qdata]);
                if (self.ajax.hasOwnProperty('callback') && typeof self.ajax.callback === 'function') {
                    self.ajax.callback.apply(this, [null]);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (this.retryCount <= this.retryLimit) {
                    this.retryCount++;
                    $.ajax(this);
                    return;
                } else {
                    var that = this;
                    if (self.ajax.hasOwnProperty('onError') && typeof self.ajax.onError === 'function') {
                        self.ajax.onError({ status: jqXHR.status, graphId: self.ajax.graphId }, function () {
                            $.ajax(that);
                        });
                    }
                }
            }
        });
    }
    /*
    ajax: {
        type: 'POST',
        url: '/gservices/v1/graphdata',
        data: gdata,
        clientProc: _decompressD3Data,
        clientProcParam: _dimensions.length
    },
    */
    _getData(fForce) {
        var self = this;

        if (typeof fForce === 'undefined' && self._gotdata) return;
        self._gotdata = true;

        if (self.ajax) {
            if (self.ajax.hasOwnProperty('type') &&
                self.ajax.hasOwnProperty('url') &&
                self.ajax.hasOwnProperty('data')) {

                // Get data
                //self.ajax.data.start = 0;
                //self.ajax.data.length = 1000;
                if (!self.ajax.data.hasOwnProperty('start'))
                    self.ajax.data.start = 0;
                if (!self.ajax.data.hasOwnProperty('length'))
                    self.ajax.data.length = 1000;

                // TODO: BUGBUG: we should move sortOrder off saveProps..
// 2024-02-01               if (!self.ajax.data.hasOwnProperty('saveProps'))
//                    self.ajax.data.saveProps = { argprops: { sortOrder: self.sortOrder } };

                self._ajaxGetData(function (err, odata) {
                    if (err) {
                        if (self.ajax.hasOwnProperty('callback') && typeof self.ajax.callback === 'function') {
                            self.ajax.callback.apply(this, [null]);
                        }
                    } else {
                        self._setGraphData(odata.type, odata.header, odata.dimensions);
                        // 2024 - try to sort here? setGraphData will set initialSortOrder
                        // try to compare lengths to see if category added, so reset
                        const sortOrder = self.sortOrder && self.sortOrder.length && self.sortOrder.length > 0 && self.sortOrder.length === self.initialSortOrder.length  ? self.sortOrder : self.initialSortOrder;
                        // Sort odata.data by sortOrder
                        //2024-02-05 self.sortOrder = sortOrder;
                        if (sortOrder && sortOrder.length > 0) {
                            // array of dimension `1 ASC` or `1 DESC`. 1 based off of position in data array
                            // so, if we have  ['1 ASC', '2 DESC'] that means sort by column 0 in data, then column 1
                            try {
                                var dataArray = [...odata.data];
                                var sortedArray = dataArray.sort((a, b) => {
                                    for (let i = 0; i < sortOrder.length; i++) {
                                        let sort = sortOrder[i];
                                        let sortCol = parseInt(sort.split(' ')[0]) - 1;
                                        let sortDir = sort.split(' ')[1];
                                        if (a[sortCol] < b[sortCol]) {
                                            return sortDir === 'ASC' ? -1 : 1;
                                        }
                                        if (a[sortCol] > b[sortCol]) {
                                            return sortDir === 'ASC' ? 1 : -1;
                                        }
                                    }
                                    return 0;
                                });

                                odata.data = sortedArray;

                            } catch (err) {
                                console.log(err);
                            }
                        }
                                                    
                        // On success
                        var myData;
                        if (self.ajax.hasOwnProperty('clientProc') && self.ajax.clientProc) {
                            // 2023 removed .slice(1)
                            myData = self.ajax.clientProc(odata.data, self.ajax.hasOwnProperty('clientProcParam') ? self.ajax.clientProcParam : null);
                        }
                        else
                            myData = JSON.parse(JSON.stringify(odata.data));       // 2023 we do this because graph adds .values to data array members

                        // setData
                        if (odata.data.length < odata.recordsTotal) {
                            self.ajax.data.recordsTotal = odata.recordsTotal;

                            var uid = self._addPagingControls();
                            self.setupPaging(uid);
                        }

                        self._setData(myData);

                        // resize
                        self.resize({ width: self.outerWidth, height: self.outerHeight });
                    }

                });

            }
        }

    }
    updateGraphData(odata) {
        var self = this;
        if (odata.hasOwnProperty('canSort')) {
            self.canSort = odata.canSort;
        }

        self._setGraphData(odata.type, odata.header, odata.dimensions);
        // On success
        var myData;
        if (self.ajax.hasOwnProperty('clientProc') && self.ajax.clientProc) {
            // 2023 removed .slice(1)
            myData = self.ajax.clientProc(odata.data, self.ajax.hasOwnProperty('clientProcParam') ? self.ajax.clientProcParam : null);
        }
        else
            myData = JSON.parse(JSON.stringify(odata.data));       // 2023 we do this because graph adds .values to data array members

        // setData
        if (odata.data.length < odata.recordsTotal) {
            self.ajax.data.recordsTotal = odata.recordsTotal;

            var uid = self._addPagingControls();
            self.setupPaging(uid);
        }

        self._setData(myData);

        // resize
        self.resize({ width: self.outerWidth, height: self.outerHeight });
    }
    _setGraphData(type, header, dimensions) {
        var self = this;

        if (header && dimensions) {
            if (self.superClass === 'old_pieGGC') {
                this.valueNames = header.slice(1);
                this.setValueNamesDuplicate();
                let lbl = '';
                for (let i = 0; i < dimensions.length; i++) {
                    if (i !== 0)
                        lbl += ', ';
                    lbl += dimensions[i].name;
                }
                this.categoryNames = [lbl];
            } else {
                //TODO: If there are duplicate value names (like their could be for qr-answers/contest, the domain
                // values for the color domain will not work correctly). We need to fix this!
                this.valueNames = header.slice(dimensions.length);
                this.setValueNamesDuplicate();                
                this.categoryNames = header.slice(0, dimensions.length);
            }


            this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City                
            if (this._dontSetColorDomain.indexOf(type) === -1) {
                this.color.domain(this.valueNames);
            }


            if (this.canSort) {
                // Default initial sort order to category names
                let initSort = [];
                for (let i = 0; i < this.categoryNames.length; i++) {
                    initSort.push((i + 1) + ' ASC')
                }

                self.initialSortOrder = initSort;
                self._setupSorters();
            }
            else if (self._sortDivId) {
                $('#'+self._sortDivId).empty();
                self._sortDivId = undefined;
            }


        } else {
            throw new Error('No header or dimensions');
        }
    }
    // 2024=  reworked this.  We know initialSortOrder is set to the categories.
    // If someone saved a 'sortOrder' in the past, then it will be set when this
    // routine is called.  If not, then we set
    _setupSorters() {
        var self = this;

        if (!this.showSorter) return;

        $('#'+self._sortDivId).empty();
        self._sortTableId = self._genId();
        var html = 
        '<table id="' + self._sortTableId + '" style="margin-bottom: 4px; margin-left: 8px;"><tr>' +
            '<td><div id="' + self._sortIconId+ '" style="background-image: url(' + self._imageCDN + 'sort.svg);  width: 20px; height: 20px; cursor: pointer; display: inline-block; background-size: contain"></div></td>' +
            '</tr></table>';

        $('#' + self._sortDivId).append(html);

        var cnameLen = this.categoryNames.length;
        var vnameLen = this.valueNames.length;

        let i;
        let val, sel;
        let newSort = []; // sortOrder
        let lockedLabels = [];  // parallel newSort, but has category/field names

            let sorterStyle = "margin-left: 4px; border-radius: 3px;";
            sorterStyle += "font-family: " + self._getFontFamilyFromFont(self.txtSorterFont) + ";";
            sorterStyle += "font-size: " + self._getFontSizeFromFont(self.txtSorterFont) + "px;";
            sorterStyle += "color: " + self.txtSorterColor + ";";
            sorterStyle += "font-style: " + self.txtSorterFontStyle + ";";
            sorterStyle += "font-weight: " + self.txtSorterFontWeight + ";";
            sorterStyle += "text-decoration: " + self.txtSorterDecoration + ";";
        
            // if not lockSorter, then we create a selection/option dropdown. If lockSorter, then we just
            // show the current sort order
            for (i = 0; i < cnameLen - 1; i++) {
                let myid = self._genId();
                
                html = '<td>';
                
                if (self.lockSorter) {

                } else {
                    html += '<select id="' + myid + '" style="' + sorterStyle + '">';
                }

                val = (i + 1) + ' ASC';
                if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' ASC');
                    }
                    newSort.push(val);
                    
                    sel = ' data-ix="' + i + '" selected';
                } else {
                    sel = ' data-ix="' + i + '"';
                }
                if (!self.lockSorter) {
                    html += '<option value="' + val + '"' + sel + '>' + self.categoryNames[i] + ' ASC</option>';
                }

                val = (i + 1) + ' DESC';
                if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' DESC');
                    }
                    newSort.push(val);
                    sel = ' data-ix="' + i + '" selected';
                } else {
                    sel = ' data-ix="' + i + '"';
                }
                if (!self.lockSorter) {
                    html += '<option value="' + val + '"' + sel + '>' + self.categoryNames[i] + ' DESC</option>';

                    html += '</select>';
                }

                if (newSort.length < i) {// we didn't find it above, so set as first one
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' ASC');
                    }
                    newSort.push((i + 1) + ' ASC');
                }

                if (self.lockSorter) {
                    html += '<div id="' + myid + '" style="' + sorterStyle + '">' + lockedLabels[lockedLabels.length-1] + '</div>';
                }

                html += '</td>';

                $('#' + self._sortTableId + ' tbody tr:last').append(html);
                if (!self.lockSorter) {
                    $('#' + myid).off('change').on('change', function () {
                        // this.value
                        var selected = $(this).find('option:selected');
                        var extra = selected.data('ix');
                        self.sortOrder[extra] = this.value;
                        for (let j=0; j<self.initialSortOrder.length; j++) {
                            if (!self.sortOrder[j]) {
                                self.sortOrder[j] = self.initialSortOrder[j];
                            }
                        }
                        if (self.ajax.data.hasOwnProperty('saveProps')) {
                            self.ajax.data.saveProps.argprops.sortOrder = self.sortOrder;
                        } else {
                            self.ajax.data.saveProps = { argprops: { sortOrder: self.sortOrder } };
                        }
                        self._getRefreshData();
                    });
                }
            }

            if (vnameLen > 0) {
                let myid = self._genId();

                html = '<td>'; 
                if (self.lockSorter) {

                } else {
                    html += '<select id="' + myid + '" style="' + sorterStyle + '">';
                }

                // categoryNames[cnameLen-1] ASC, DESC
                val = (i + 1) + ' ASC';
                if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' ASC');
                    }
                    newSort.push(val);
                    sel = ' data-ix="' + i + '" selected';
                } else {
                    sel = ' data-ix="' + i + '"';
                }
                if (!self.lockSorter) {
                    html += '<option value="' + val + '"' + sel + '>' + 
                    self.categoryNames[i] + 
                    ' ASC</option>';
                }

                val = (i + 1) + ' DESC';
                if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' DESC');
                    }
                    newSort.push(val);
                    sel = ' data-ix="' + i + '" selected';
                } else {
                    sel = ' data-ix="' + i + '"';
                }
                if (!self.lockSorter) {
                    html += '<option value="' + val + '"' + sel + '>' + 
                    self.categoryNames[i] + 
                    ' DESC</option>';
                }

                    // valueNames[0..vnameLen-1] ASC, DESC
                    for (let k = 0; k < vnameLen; k++) {
                        val = (cnameLen + k + 1) + ' ASC';
                        if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                            if (self.lockSorter) {
                                lockedLabels.push(self.valueNames[k] + ' ASC');
                            }
                            newSort.push(val);
                            sel = ' data-ix="' + i + '" selected';
                        } else {
                            sel = ' data-ix="' + i + '"';
                        }
                        if (!self.lockSorter) {
                            html += '<option value="' + val + '"' + sel + '>' + self.valueNames[k] + ' ASC</option>';
                        }

                        val = (cnameLen + k + 1) + ' DESC';
                        if (i < self.sortOrder.length && val === self.sortOrder[i]) {
                            if (self.lockSorter) {
                                lockedLabels.push(self.valueNames[k] + ' DESC');
                            }
                            newSort.push(val);
                            sel = ' data-ix="' + i + '" selected';
                        } else {
                            sel = ' data-ix="' + i + '"';
                        }
                        if (!self.lockSorter) {
                            html += '<option value="' + val + '"' + sel + '>' + self.valueNames[k] + ' DESC</option>';
                        }
                    }
                if (!self.lockSorter) {
                    html += '</select>';
                }
                

                if (newSort.length > 0 && newSort.length < i) {
                    // if we didn't find it above, so set as first one
                    if (self.lockSorter) {
                        lockedLabels.push(self.categoryNames[i] + ' ASC');
                    }
                    newSort.push((i + 1) + ' ASC');
                }

                if (self.lockSorter) {
                    html += '<div id="' + myid + '" style="' + sorterStyle + '">' + lockedLabels[lockedLabels.length-1] + '</div>';
                }

                html += '</td>';

                $('#' + self._sortTableId + ' tbody tr:last').append(html);

                if (!self.lockSorter) {
                    $('#' + myid).off('change').on('change', function () {
                        var selected = $(this).find('option:selected');
                        var extra = selected.data('ix');
                        self.sortOrder[extra] = this.value;
                        for (let j=0; j<self.initialSortOrder.length; j++) {
                            if (!self.sortOrder[j]) {
                                self.sortOrder[j] = self.initialSortOrder[j];
                            }
                        }
                        if (self.ajax.data.hasOwnProperty('saveProps')) {
                            self.ajax.data.saveProps.argprops.sortOrder = self.sortOrder;
                        } else {
                            self.ajax.data.saveProps = { argprops: { sortOrder: self.sortOrder } };
                        }
                        self._getRefreshData();
                    });
                }

            }

        self.sortOrder = newSort;
    }
    _refresh(kind) {
        this.refresh(kind);
        this._baseEditModeRefresh();
        this._bringEditToFront();
        this._fillBackground(this.svg);
    }
    _fixName(s) {
        var s1 = '';
        if (s) {
            for (let i = 0; i < s.length; i++) {
                let c = s.charAt(i);
                if ((c >= 'a' && c <= 'z') ||
                    (c >= 'A' && c <= 'Z') ||
                    (c >= '0' && c <= '9') ||
                    (c === '_' || c === '-')) {
                    s1 += c;
                }
            }
        }
        return s1;
    }
    // oddly, these have to be generated smallest to largest stop #
    _genLinearGradient(defs, ref, grad) {
        var lg = defs.append('linearGradient')
            .attr('id', ref);

        var percs = [];
        for (var v in grad) {
            if (grad.hasOwnProperty(v)) {
                var perc = v;
                percs.push({ perc: perc, c: grad[v] });
            }
        }
        percs.sort((a, b) => (a.perc > b.perc) ? 1 : -1);
        for (var i = 0; i < percs.length; i++) {
            lg.append('stop')
                .attr('offset', percs[i].perc)
                .attr('stop-color', percs[i].c);

        }


    }
    _genPattern(defs, me) {
        // Excellent reference: https://stackoverflow.com/quuestions/22883994/crop-to-fit-an-svg-pattern
        // stretch to fill
        var stretch = false;
        if (me.hasOwnProperty('stretch')) {
            stretch = me.stretch;
        }
        if (stretch) {
            defs.append("pattern")
                .attr("id", me.ref)
                .attr('width', 1)
                .attr('height', 1)
                .attr("patternContentUnits", "objectBoundingBox")
                .append("image")
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 1)
                .attr('height', 1)
                .attr('preserveAspectRatio', 'none')
                .attr("href", function (myd) {      // 2024-04-28 remove xlink per https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/xlink:href
                    return me.loc;
                });
        } else {

            defs.append("pattern")
                .attr("id", me.ref)
                .attr('width', 1)
                .attr('height', 1)
                .attr('preserveAspectRatio', 'xMidYMid slice')
                .attr("patternContentUnits", "objectBoundingBox")
                .attr('viewBox', '0 0 1 1')
                .append("image")
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 1)
                .attr('height', 1)
                .attr('preserveAspectRatio', 'xMidYMid slice')
                .attr("href", function (myd) {    // xlink
                    return me.loc;
                });
        }

    }
    _colorDomainSet(colorRefs) {
        // all are visible
        var self = this;

        if (this.valueStatus.length === 0) {
            this.color.domain().forEach(function (item, index) {
                self.valueStatus.push(1);
            });
        }

        var defs;
        if (self.svg.selectAll('defs').size() === 0) {
            defs = self.svg.insert("defs", ":first-child");

        //        defs = self.svg.append('defs');
        } else {
            defs = self.svg.select('defs');
            defs.selectAll('pattern').remove();
            defs.selectAll('linearGradient').remove();
        }

        if (typeof (colorRefs) !== 'undefined') {
            if (Array.isArray(colorRefs)) {
                for (let i = 0; i < colorRefs.length; i++) {
                    if (typeof (colorRefs[i]) === 'string') {
                        var me = self[colorRefs[i]]; // indirection so we get actual 'by reference' variable
                        if (me.hasOwnProperty('loc') && me.loc) {
                            me.ref = this._genCleanId();

                            self._genPattern(defs, me);
                        }
                    } else if (typeof (colorRefs[i]) === 'object') {
                        // {arrayName: 'markers', fields: ['outlineColorRef', 'lineColorRef']}
                        if (colorRefs[i].hasOwnProperty('arrayName') && colorRefs[i].hasOwnProperty('fields')) {
                            var arRefs = self[colorRefs[i].arrayName];
                            for (let j = 0; j < arRefs.length; j++) {
                                for (let k = 0; k < colorRefs[i].fields.length; k++) {
                                    let me = arRefs[j][colorRefs[i].fields[k]];
                                    if (me.hasOwnProperty('loc') && me.loc) {
                                        me.ref = this._genCleanId();

                                        self._genPattern(defs, me);
                                    }
                                }
                            }
                        } else if (colorRefs[i].hasOwnProperty("linearGradient")) {
                            // linearGradient: 'mapPalette'  is an array of objects {type, index,gradient,opacity,<add ref>}
                            let arRefs = self[colorRefs[i].linearGradient];
                            for (let j = 0; j < arRefs.length; j++) {
                                let me = arRefs[j];
                                if (me.type === 'interpolate') {
                                    var gradient = {
                                        0.25: biaColors._d3ColorsInterpolate[self.mapPalette[j].index].interpolate(0.25),
                                        0.55: biaColors._d3ColorsInterpolate[self.mapPalette[j].index].interpolate(0.55),
                                        0.85: biaColors._d3ColorsInterpolate[self.mapPalette[j].index].interpolate(0.85),
                                        1.0: biaColors._d3ColorsInterpolate[self.mapPalette[j].index].interpolate(1.0)
                                    };
                                    me.ref = this._genCleanId();
                                    self._genLinearGradient(defs, me.ref, gradient);
                                } else if (me.type === 'gradient') {
                                    me.ref = this._genCleanId();
                                    self._genLinearGradient(defs, me.ref, me.gradient);
                                } // else?
                            }

                        }
                    }
                }
            }
        }

        if (typeof (this.backgroundColor) === 'object') {
            if (this.backgroundColor.loc) {
                this.backgroundColor.ref = this._genCleanId();
                let me = this.backgroundColor;
                self._genPattern(defs, me);
            }
        }

        if (typeof (this.legendBackgroundColor) === 'object') {
            if (this.legendBackgroundColor.loc) {
                this.legendBackgroundColor.ref = this._genCleanId();
                let me = this.legendBackgroundColor;
                self._genPattern(defs, me);
            }
        }


        // For each 'custom' color, we need to append to the defs.
        if (self.colorPaletteType === 'custom' && self.customPalette.hasDef) {

            for (let i = 0; i < self.customPalette.colorRef.length; i++) {
                if (self.customPalette.colorRef[i].loc) {
                    if (!self.customPalette.colorRef[i].ref)        // GGC 2023/11/04 treemap
                        self.customPalette.colorRef[i].ref = this._genCleanId();

                    let me = self.customPalette.colorRef[i];

                    self._genPattern(defs, me);

                    // to use:  .attr('fill', 'url(#' + self.customPalette.colorRef[i].ref + '))
                }
            }
        } else if (self.colorPaletteType === 'interpolate') {

            // need to set 'range' values of 'color' if we are interpolated
            // these are used for all 'regular' grpahs, not maps
            var colors = [];
            var n = self.valueStatus.length;
            for (let i = 0; i < n; i++) {
                if (i === 0)
                    colors.push(biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(0));

                else
                    colors.push(biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(i / (n - 1)));
            }

            self.color.range(colors);
        } else if (self.colorPaletteType === 'gradient') {
            let colors = [];
            var gd = self.getGradientData(); // from gradientStops

            let n = self.valueStatus.length % 256;
            if (n === 0 && self.valueStatus.length > 0)
                n = 256;
            for (let i = 0; i < n; i++) {
                if (i === 0)
                    colors.push(new tinycolor({ r: gd[0], g: gd[1], b: gd[2] }).toHexString()); // RGBA 
                else {
                    var ix = 4 * Math.floor(255 * i / (n - 1));
                    colors.push(new tinycolor({ r: gd[ix], g: gd[ix + 1], b: gd[ix + 2] }).toHexString());
                }
            }

            self.color.range(colors);
        }

        return defs;
    }
    // 
    //  .attr('fill', 'url(#' + self.customPalette.colorRef[i].ref + '))
    //  .attr('fill', '#abcdef')
    //
    _getFillColor(ix) {
        var self = this;

        if (self.colorPaletteType === 'custom') {
            let localIx = ix % self.customPalette.colorRef.length; // scope to palette length
            if (self.customPalette.colorRef[localIx].loc) {
                return 'url(#' + self.customPalette.colorRef[localIx].ref + ')';
            } else {
                return self.customPalette.colorRef[localIx].rgb;
            }
        } else if (self.colorPaletteType === 'categorical') {
            let localIx = ix % biaColors._d3ColorsCategorical[self.colorPaletteIndex].colors.length;
            return biaColors._d3ColorsCategorical[self.colorPaletteIndex].colors[localIx];
        } else if (self.colorPaletteType === 'interpolate') {
            let localIx = ix % self.valueStatus.length;
            if (localIx === 0)
                return biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(0);

            else
                return biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(localIx / (self.valueStatus.length - 1));
        } else if (self.colorPaletteType === 'gradient') {
            let gd = self.getGradientData(); // from gradientStops
            let localIx = (ix % self.valueStatus.length) % 256;
            if (localIx === 0)
                return new tinycolor({ r: gd[0], g: gd[1], b: gd[2] }).toHexString();
            else {
                var ixx = 4 * Math.floor(255 * localIx / ((self.valueStatus.length % 256) - 1));
                return new tinycolor({ r: gd[ixx], g: gd[ixx + 1], b: gd[ixx + 2] }).toHexString();
            }


        } else {
            return '#0000FF'; // should never happen
        }

    }
    _getOpacity(ix) {
        var self = this;

        if (self.colorPaletteType === 'custom') {
            var localIx = ix % self.customPalette.colorRef.length; // scope to palette length
            if (self.customPalette.colorRef[localIx].hasOwnProperty('opacity'))
                return self.customPalette.colorRef[localIx].opacity;
        } else if (self.colorPaletteType === 'categorical') {
            return self.colorPaletteOpacity;
        } else if (self.colorPaletteType === 'interpolate') {
            return self.colorPaletteOpacity;
        }

        return 1.0;
    }
    _base_drawLegend(offset, nLegs) {
        var self = this;

        self._lastDrawRange = { offset: offset, legs: nLegs };

        if (self.legsurround) {
            self.svg.selectAll(".legsurround").remove();
        }

        self.svg.selectAll('.legendOuterBox').remove();

        self.svg.selectAll('.legendShrink').remove();

        var xoff = self.extraRightMargin || 0;
        if (self.margin.right < 20)
            xoff -= (20 - self.margin.right);

        self.svg.append('svg')
            .attr('class', 'legendShrink')
            .attr('x', self.width + self.margin.left + 3 + xoff)
            .attr('y', self.margin.top)
            .attr('width', 16)
            .attr('height', 16)
            .html(self.legendTextHidden ? self._getLegendShow() : self._getLegendHide())
            .on("click", function (event, d) {
                if (self.editMode) {
                } else {
                    self.legendTextHidden = !self.legendTextHidden;
                    self.legend.selectAll("text")
                        .style('display', self.legendTextHidden ? 'none' : 'block');

                    var bbox = self.legsurround.node().getBBox();
                    self.svg.selectAll('.legendOuterBox')
                        .attr("x", bbox.x - 4)
                        .attr("y", bbox.y - 4)
                        .attr("width", bbox.width + 8)
                        .attr("height", bbox.height + 8);

                    $(this).html(self.legendTextHidden ? self._getLegendShow() : self._getLegendHide());
                }
            });

        // so we can get surrounding rectangle
        self.legsurround = self.svg.selectAll(".legsurround")
            .data([1])
            .enter().append("g")
            .attr("class", "legsurround")
            .attr("transform", function (d, i) { return "translate(0,0)"; });

        self.legend = self.legsurround.selectAll(".legendGGC")
            .data(self.color.domain().slice().reverse().slice(offset, nLegs))
            .enter().append("g")
            .attr("class", function (d) {
                return "legendGGC";
            })
            .attr("transform", function (d, i) { return "translate(0," + (self.margin.top + self.legendShrink + i * self._legRowHeight) + ")"; });

        self.legend.exit().remove();

        self.legend.append("rect")
            .attr("x", self.width + self.margin.left + 2 + xoff)
            .attr("width", 18)
            .attr("height", (self._legRowHeight - 2))
            .attr("fill", function (d, lix) {
                lix = self.legendClassArray.length - 1 - lix - offset;
                return self._getFillColor(lix);
            })
            .style("opacity", function (d, lix) {
                lix = self.legendClassArray.length - 1 - lix - offset;
                return self.valueStatus[lix];
            })
            .attr("id", function (d, i) {
                return "_sbid" + self._fixName(d);
            })
            .on("mouseover", function (event) {
                if (self.editMode) {
                }
                else {
                    const e = self.legend.selectAll('rect').nodes();
                    let lix = e.indexOf(this);

                    d3.select(this).style("cursor", "pointer");

                    lix = self.legendClassArray.length - 1 - lix - offset;
                    if (self.valueStatus[lix] === 1) {
                        // Active. dim all 1 values to .5 (except this one)
                        self.legendClassArray.slice(offset, nLegs).forEach(function (d, i) {
                            if (i !== lix) {
                                if (self.valueStatus[i] === 1) {
                                    self.svg.select("#_sbid" + self.legendClassArray[i]).style("opacity", 0.5);
                                    self.svg.selectAll(".class" + self.legendClassArray[i]).style("opacity", 0.5);
                                    self.valueStatus[i] = 0.5;
                                }
                            }
                        });
                    }
                }

            })
            .on("mouseout", function (event) {
                if (self.editMode) {
                }
                else {
                    d3.select(this).style("cursor", "auto");
                    self.legendClassArray.slice(offset, nLegs).forEach(function (d, i) {
                        if (self.valueStatus[i] === 0.5) {
                            self.svg.select("#_sbid" + self.legendClassArray[i]).style("opacity", 1);
                            self.svg.selectAll(".class" + self.legendClassArray[i]).style("opacity", 1);
                            self.valueStatus[i] = 1;
                        }
                    });

                }
            })
            .on("click", function (event, d) {
                if (self.editMode) {
                } else {
                    const e = self.legend.selectAll('rect').nodes();
                    let lix = e.indexOf(this);

                    lix = self.legendClassArray.length - 1 - lix - offset;
                    if (self.valueStatus[lix] === 1) { // hide it
                        self.valueStatus[lix] = self._hiddenOpacity; // 0.25;
                        self.svg.select("#_sbid" + self.legendClassArray[lix]).style("opacity", self._hiddenOpacity); // 0.25);
                        self.svg.selectAll(".class" + self.legendClassArray[lix]).style("opacity", 0);
                        self._refresh();
                    } else if (self.valueStatus[lix] === self._hiddenOpacity /*0.25*/) { // hidden
                        self.valueStatus[lix] = 1;
                        self.svg.select("#_sbid" + self.legendClassArray[lix]).style("opacity", 1);
                        self._refresh();
                        self.svg.selectAll(".class" + self.legendClassArray[lix]).style("opacity", 1);
                    }
                }
            })
            .append('title')
            .text(function (d) { return d; });

        // If we don't have text answers (or if we have duplicates in the valueNames), then the color domain
        // would be messed up (we need to fix it where it gets set). Let's call this 'duplicates' (since no text
        // answers are duplicates of '').  The legend would show duplicates or potentially no value if the 
        // text is blank.  So, what to do? We could fix setting valueNames to 'Answer 1', 'Answer 2', etc. But
        // we could also just show the color (so set random text in valueNames, but catch the fact we did that here.
        // Or, we could show an image if the answer had an image.  Where would that be?
        self.legend.append("text")
            .attr("x", self.width + this.margin.left + xoff) //  this.legendSpace - 24)
            .attr("y", self._legRowHeight - 1-self._legTextOffset)           // (self._legRowHeight - 2))
            //        .attr("dy", ".35em")
            .style("text-anchor", "end")
            .text(function (d, ix) {
                if (self.valueNamesDuplicate[ix] !== null) {
                    return self.valueNamesDuplicate[ix];
                } else {
                    return d; 
                }
            })
            //        .attr('stroke', self.legendColor)  moved to style so can specify both stroke and fill to match
            //        .style("stroke", self.legendColor)
            .style("fill", self.legendColor)
            .style("font", self.legendFont)
            .style('font-weight', self.legendFontStyle.indexOf('B') !== -1 ? 700 : 400)
            .attr('text-decoration', self.legendFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
            .style('display', self.legendTextHidden ? 'none' : 'block');

        if (this.legendFontStyle.indexOf('I') !== -1) {
            self.legend.selectAll('text')
                .attr("transform", function (d, i) {
                    return " skewX(" + (i * 10 - self._italicSkewDegrees) + ")";
                });
        }

        try {
            var bbox = self.legsurround.node().getBBox();
            self.svg.append("rect")
                .attr("x", bbox.x - 4)
                .attr("y", bbox.y - 4)
                .attr("width", bbox.width + 8)
                .attr("height", bbox.height + 8)
                .style("fill", function (d) {
                    if (self.legendBackgroundColor.rgb)
                        return self.legendBackgroundColor.rgb;

                    else
                        return 'url(#' + self.legendBackgroundColor.ref + ')';
                })
                .attr('opacity', self.legendBackgroundColor.opacity)
                .attr("class", function (d) {
                    return "legendOuterBox";
                });
        } catch (e) {
        }

        //    d3.selectAll('.legsurround')
        self.svg.selectAll(".legsurround")
            .select(function (d, i) {
                d3.select(this).raise();
            });
    }
    _base_makeLegend(opts) {
        var self = this;
        var isResize = (opts && opts.hasOwnProperty('resize') && opts.resize);

        self.legendClassArray = [];
        self.color.domain().slice().forEach(function (d, ix) {
            self.legendClassArray.push(self._fixName(d));
        });


        var nLegs = self.color.domain().length;
        self._legRowHeight = 18;
        var txtHeight = this._textHeight('YM', this.legendFont);
        if (txtHeight > self._legRowHeight) self._legRowHeight = txtHeight;
        self._legRowHeight += 2;
        self._legTextOffset = (self._legRowHeight - txtHeight) / 2;

        var nFit = Math.floor((self.height - self.legendShrink) / self._legRowHeight);

        self._isLegendScroll = nLegs > nFit;

        var rng = { offset: 0, legs: Math.min(nFit, nLegs) };
        if (!isResize && self._lastDrawRange) {
            rng = self._lastDrawRange;
        }

        self._base_drawLegend(rng.offset, rng.legs);


        function legDisplay(event) {        // v6 added event
            if (self.editMode) return;

            //v5 var y = parseInt(d3.select(this).attr('y')), ny = y + d3.event.dy, h = parseInt(d3.select(this).attr('height')), f, nf;
            let y = parseInt(d3.select(this).attr('y'));
            let ny = y + event.dy;
            let h = parseInt(d3.select(this).attr('height')), f, nf;

            if (ny < 0 || ny + h > Math.ceil(self.height - self.legendShrink)) return;
            d3.select(this).attr('y', ny);
            self._lastScrollY = ny;
            f = self._legDisplayed(y);
            nf = self._legDisplayed(ny);

            if (f === nf) return;

            self._base_drawLegend(nf, nf + nFit);
        }


        if (this._isLegendScroll) {

            self._legDisplayed = d3.scaleQuantize()
                .domain([0, self.height - self.legendShrink])
                .range(d3.range(self.legendClassArray.length));

            var ovHeight = Math.round(parseFloat(nFit * (self.height - self.legendShrink)) / self.legendClassArray.length);
            if (ovHeight < 10) ovHeight = 10;

            self.svg.selectAll('.legOverviewMover').remove();

            var yPos = 0;
            if (!isResize && self._lastScrollY)
                yPos = self._lastScrollY;

            self.svg.append("rect")
                .attr("transform", "translate(" + (self.width + self.margin.left + 22) + ", " + (self.margin.top + self.legendShrink) + ")")
                .attr("class", "overviewMover legOverviewMover")
                .attr("x", 0)
                .attr("y", yPos)
                .attr("height", ovHeight)
                .attr("width", 16)
                .attr("pointer-events", "all")
                .attr("cursor", "ns-resize")
                .call(d3.drag().on("drag", legDisplay));
        }
    }
    _fillBackground(sel) {
        var self = this;

        if (typeof (self.backgroundColor) === 'object') {
            // draw rect and fill w/ backgroundColor
            var bg = sel.selectAll('.classBackgroundGGC');
            if (bg.size() === 0) {

                var rect;
                if (self.svg.selectAll('defs').size() === 0)
                    rect = self.svg.insert("rect", ":first-child");

                else
                    rect = self.svg.insert('rect', 'defs + *');

                rect
                    .attr("width", self.origWidth)
                    .attr("y", 0)
                    .attr("x", 0)
                    .attr("height", self.origHeight)
                    .attr("class", function (d) {
                        return "classBackgroundGGC";
                    })
                    .style("fill", function (d) {
                        if (self.backgroundColor.rgb)
                            return self.backgroundColor.rgb;

                        else
                            return 'url(#' + self.backgroundColor.ref + ')';
                    })
                    .attr('opacity', self.backgroundColor.opacity);
            } else {
                bg
                    .style('fill', function (d) {
                        if (self.backgroundColor.rgb)
                            return self.backgroundColor.rgb;

                        else
                            return 'url(#' + self.backgroundColor.ref + ')';
                    })
                    .attr('opacity', self.backgroundColor.opacity);
            }
        }
    }
    /*
    graphBaseGGC.prototype.fontTest = function() {
        var self = this;
        
            var legend = self.svg.selectAll(".fontGGC")
                .data(biaColors._graphFonts)
                .enter().append("g")
                .attr("transform", function(d, i) { return "translate(100," + (self.margin.top + i * 20) + ")"; });
    
            legend.append("text")
                .attr("x", 0)
                .attr("y", 9)
                .attr("dy", ".35em")
                .text(function(d) { return d; })
                .style("font", function(d) {
                    return '12px ' + d;
            })
        
    }
    */
    _baseEditModeRefresh() {
        var self = this;
        if (this.editMode) {
            if (self._editRowHeight !== self._legRowHeight) {
                self.svg.selectAll('.legEditCircs').remove();

                self._baseEditMode(true);
            }
        }
    }
    _baseEditMode(bSkipMiddle) {
        var self = this;
        if (this.editMode) {
            var ixMiddle = Math.floor((self.svg.selectAll(".legendGGC").size()) / 2);
            var lastOne = self.svg.selectAll(".legendGGC").size() - 1;

            self.svg.selectAll(".legendGGC")
                .select(function (d, i) {
                    // g 
                    //            var trans = $(this).attr('transform').match(/translate\((\d+\.?\d*),(\d+\.?\d*)\)/)
                    //            if (trans.length == 3) {
                    //                var x = +trans[1];
                    //                var y = +trans[2];
                    {
                        let x = 0;
                        let y = 0;
                        let rx = +$(this).find('rect').attr('x');
                        let wid = +$(this).find('rect').attr('width');
                        let r = 1.2 * (wid / 2); // 20% bigger
                        x = x + rx + wid;
                        y = y + wid / 2;

                        self._editRowHeight = self._legRowHeight;

                        y += (self.margin.top + self.legendShrink + i * self._legRowHeight);

                        self._drawCircleEditFill({
                            selector: self.svg,
                            kind: 'legbox',
                            title: d + i18next.t("graph.edit.space_fill"),
                            x: x,
                            y: y,
                            r: r,
                            class: 'legEditCircs',
                            placement: 'left',
                            ordinal: self.legendClassArray.length - 1 - i,
                            variables: {
                                paletteTypeProp: 'colorPaletteType',
                                paletteIndexProp: 'colorPaletteIndex',
                                customPaletteProp: 'customPalette',
                                paletteOpacityProp: 'colorPaletteOpacity' // if palette vs. custom
                            }
                        });


                        if (!bSkipMiddle && i === ixMiddle) {
                            var lbl = $(this).find('text').html();
                            var myx = x - (self._textWidth(lbl, self.legendFont) + wid);
                            self._drawCircleEditText({
                                selector: self.svg,
                                kind: 'legend',
                                title: i18next.t("graph.edit.legend_text"),
                                label: null,
                                drawLabel: false,
                                x: myx,
                                y: y,
                                r: r,
                                placement: 'left',
                                variables: {
                                    fontProp: 'legendFont',
                                    styleProp: 'legendFontStyle',
                                    colorProp: 'legendColor'
                                }
                            });
                        }

                        // legend Background
                        if (!bSkipMiddle && i === lastOne) {
                            let lbl = $(this).find('text').html();
                            let myx = x - ((self._textWidth(lbl, self.legendFont) + wid) / 2); //  - 5;
                            let myy = y + (self._textHeight(lbl, self.legendFont));
                            self._drawCircleEditColor({
                                selector: self.svg,
                                kind: 'lbgcolor',
                                title: i18next.t("graph.edit.legend_background"),
                                x: myx,
                                y: myy,
                                r: 12,
                                placement: 'left',
                                variables: {
                                    colorRefProp: 'legendBackgroundColor'
                                }

                            });
                        }

                    }
                    return this;

                });

        }
    }
    _textWidth(s, fnt) {
        return MeasureTextGGC.getWidth(s, (typeof (fnt) !== 'undefined') ? fnt : this.font);
        /*
            var fontSizer = document.getElementById('fontSizer');
            fontSizer.textContent = s;
            fontSizer.style.font = (typeof(fnt) !== 'undefined') ? fnt : this.font;
            return (fontSizer.clientWidth + 1);
            */
    }
    _textHeight(s, fnt) {
        return MeasureTextGGC.getHeight(s, (typeof (fnt) !== 'undefined') ? fnt : this.font);
        /*
            var fontSizer = document.getElementById('fontSizer');
            fontSizer.textContent = s;
            fontSizer.style.font = (typeof(fnt) !== 'undefined') ? fnt : this.font;
            return (fontSizer.clientHeight + 1);
            */
    }
    _textAscent(s, fnt) {
        return MeasureTextGGC.getAscent(s, (typeof (fnt) !== 'undefined') ? fnt : this.font);
    }
    _valueIndex(s) {
        var self = this;
        return self.categoryNames.length + self.extraNames.length + self.valueNames.indexOf(s);
    }
    // Get the *real* index of the passed item considering the ones with 0.25 are ignored
    // so, if valueStatus = [1, 0.25, 1] and we pass iReduced as 1, we return 2. Because:
    // first 1 is 0, 0.25 is ignored, second 1 is then 1 position
    _valueIndexFromI(iReduced) {
        var self = this;

        let iret = 0;
        for (let i = 0; i < this.valueStatus.length; i++) {
            if (this.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                if (iret === iReduced) return i;
                else {
                    iret++;
                }
            }
        }
        // this should never get here
        return this.valueStatus.length - 1;
    }
    tooltipCreate(rows) {
        var self = this;

        if (this.hasOwnProperty('tooltip') && this.tooltip) {
            this.tooltip.remove();
        }
        this.tooltipId = 't' + this._genId();

        var html = '<table class="genggc-tooltip"><tbody>';

        html += '<tr class="genggc-tooltip-title" style="display:none; border-style: none;">' +
            '<th colspan="2"></th></tr>';

        for (let i = 0; i < rows; i++) {
            html += '<tr class="genggc-tooltip-name">' +
                '<td class="genggc-name"></td>' +
                '<td class="genggc-value"></td></tr>';
        }

        html += '</tbody></table>';


        /* can not embed HTML in svg
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'div');
        wrapper.setAttribute('id', self.tooltipId);
        wrapper.setAttribute('class', 'genggc-tooltip-container');
        wrapper.style.display = "none";
        wrapper.innerHTML = html;
        
        this.tooltip = this.svg.append(wrapper);
        */

        this.tooltip = d3.select(this.container)
            .append('div')
            .attr('class', 'genggc-tooltip-container')
            .attr('id', self.tooltipId)
            .style('display', 'none')
            .html(html);

    }
    tooltipSetRow(ix, d, txt, optColor) {
        var self = this;
        var cColor;
        if (typeof (optColor) !== 'undefined')
            cColor = optColor;

        else
            cColor = self.color(d);

        // ix=zero base. we added title, so add 2
        var myrow = self.tooltip.select('tr:nth-child(' + (ix + 2) + ')');
        myrow.select('.genggc-name')
            .html('<span class="genggc-bg"></span>' + d);
        myrow.select('.genggc-bg')
            .style('background', cColor);
        myrow.select('.genggc-value')
            .text(txt);

    }
    tooltipSetTitle(title) {
        var self = this;

        var titlerow = self.tooltip.select('.genggc-tooltip-title');
        titlerow.select('th')
            .html('<span class="genggc-title">' + title + '</span>');
        titlerow.style('display', 'block');
    }
    tooltipShow(x, y) {
        var self = this;

        const containerRect = document.getElementById(self.container.substring(1)).getBoundingClientRect();
        const svgRect = self.svg.node().getBoundingClientRect();
        const yoffset =svgRect.top - containerRect.top;
        const xoffset = svgRect.left - containerRect.left;

        d3.select('#' + self.tooltipId)
            .style('display', 'block')
            .style('top', (y + yoffset) + 'px')
            .style('left', (x + xoffset) + 'px');
    }
    tooltipHide() {
        var self = this;
        d3.select('#' + self.tooltipId)
            .style('display', 'none');

    }
    _generateLocalFontOptions() {
        var data = [];


        biaColors._graphFonts.forEach(function (d, ix) {
            var myName = '';
            myName = d.split(',')[0].replace(/"/gi, '');

            data.push({
                id: ix,
                html: '<div style="font-family:' + d.replace(/"/gi, "'") + '">' + myName + '</div>',
                text: myName
            }
            );
        });

        return data;

    }
    // 12px "Helvetica Neue", Helvetica, Arial, sans-serif
    _getFontSizeFromFont(fnt) {
        // returns 12px part. 2024-01-17 could be 11.23px 
        //old return parseInt(fnt.substr(0, fnt.indexOf(' ')), 10).toString();
        return parseFloat(fnt);
    }
    _getFontFamilyFromFont(fnt) {
        return fnt.substr(fnt.indexOf(' ') + 1);
    }
    _getFontNameFromFont(fnt) {
        var fam = fnt.substr(fnt.indexOf(' ') + 1);
        return fam.split(',')[0].replace(/["']/gi, '');
    }
    _removeFontStyle(current, s) {
        if (typeof (current) === 'undefined') current = '';

        return current.replace(new RegExp(s, 'g'), '');
    }
    _addFontStyle(current, s) {
        if (typeof (current) === 'undefined') current = '';

        return this._removeFontStyle(current, s) + s;
    }
    _rangePicker(name, mini, maxi, step, optAddStyle) {
        var dispStyle = 'inline-block; vertical-align: middle';
        var width = ' width: 5em;';

        if (typeof (optAddStyle) !== 'undefined') {
            dispStyle = optAddStyle;
            if (optAddStyle.indexOf('width:') !== -1)
                width = '';
        }

        return '<input class="' + name + '" type="range" min="' + mini + '" max="' + maxi + '" step="' + step + '" value="' + maxi + '" style="margin-left: 8px;' + width + ' display: ' + dispStyle + '">';
    }
    // title, label, x, y, [placement, drawLabel]
    _drawCircleEditText(args) {
        var self = this;
        var kind, title, lbl, x, y, place;
        var drawLabel;
        var selector;
        var openIt;
        let disableItalic = false;

        if (args.hasOwnProperty('open') && args.open) {
            openIt = true;
        } else {
            openIt = false;
        }

        if (!args.hasOwnProperty('selector')) {
            throw new Error('_drawCircleEditText: must pass selector');
        }
        selector = args.selector;

        if (!args.hasOwnProperty('kind')) {
            throw new Error('_drawCircleEditText: must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title;

        else
            title = 'none';

        if (args.hasOwnProperty('label'))
            lbl = args.label;

        else
            lbl = null;
        if (!args.hasOwnProperty('x')) {
            throw new Error('_drawCircleEditText: must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error('_drawCircleEditText: must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else {
            place = 'right';
        }
        if (args.hasOwnProperty('drawLabel'))
            drawLabel = args.drawLabel;

        else
            drawLabel = true;

        if (args.hasOwnProperty('disable')) {
            if (args.disable.indexOf('italic') !== -1)
                disableItalic = true;            
        }

        var kindClass = self._nameToClassSafe(kind) + '_editCircle';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            if (!lbl)
                r = 12;

            else
                r = 1.5 * (this._textWidth(lbl, this.yAxisFont) / 2);
        }

        self._drawPencil(selector, x, y, r);
        var circ = selector.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 't' + self._genCleanId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        //    wrapper.setAttribute('data-trigger', 'focus');    
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);


        if (!self._pickerId)
            self._pickerId = this._genId().replace(/[^a-z0-9]/gi, '');
        var popSel = '#font-popover-' + self._genCleanId();

        $('#' + myId).popover({
            container: self.popoverContainer,  // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '" style="display: flex; flex-direction: row; align-content:center;">' +
                // Google Fonts API       '<div id="font-picker-' + self._pickerId + '" style="margin-right: 4px;"></div>' + 
                (args.hasOwnProperty('insertContentBefore') ? args.insertContentBefore : '') +
                '<input type="text" id="font-picker-' + self._pickerId + '" style="margin-right: 4px; width: 15em;">' +
                self._rangePicker('fontSizePicker', 6, 30, 1) +
                '<input type="checkbox" style="margin-left: 8px; align-self: center" name="fpbold" id="fpbold"><div style="align-self:center; margin-left: 4px; width: 1em;"><strong>B</strong></div>' +
                (disableItalic ? '' : 
                '<input type="checkbox" style="margin-left: 8px; align-self: center" name="fpitalic" id="fpitalic"><div style="align-self: center; margin-left: 4px; width: 1em;"><i>I</i></div>') +
                '<input type="checkbox" style="margin-left: 0x; align-self: center" name="fpunderline" id="fpunderline"><div style="align-self: center; margin-left: 4px; width: 1em;"><u>U</u></div>' +
                '<input class="fontcolorpicker form-control input-md" />' +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t("cancel") + '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {
            //        <div id="font-picker"></div>
            $(popSel + " .fontSizePicker").bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    self[vars.fontProp] = $(popSel + ' .fontSizePicker').val() + 'px ' + self._getFontFamilyFromFont(self[vars.fontProp]);
                    self._refresh(kind);
                }
            });

            $(popSel + ' .fontcolorpicker').spectrum({
                readonly: false,
                showInput: true,
                showInitial: true,
                clickoutFiresChange: false,
                chooseText: i18next.t("save"),
                change: function (color) {
                    $(popSel + ' .fontcolorpicker').data('changed', true);
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        self[vars.colorProp] = color ? color.toHexString() : '';
                        self._refresh(kind);
                    }
                },
                move: function (color) {
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        self[vars.colorProp] = color ? color.toHexString() : '';
                        self._refresh(kind);
                    }
                },
                hide: function (e, c) {
                    if (!$(popSel + ' .fontcolorpicker').data('changed')) {
                        self._restoreTempProp(kind, vars.colorProp);
                        self._refresh();
                    }
                }

            });
            /* Google Fonts API
            self.fontPicker = new FontPicker(self._googleFontApiKey, 'Open Sans',
                                             {sort: 'trending',
                                              limit: 50,
                                             pickerId: self._pickerId},
                                                function(f) {
                // https://stackoverflow.com/questions/32525421/dynamically-load-web-font/32526247
                                                    console.log(f);
                                                }
                                            );
            */
            var myFonts = self._generateLocalFontOptions();
            $('#font-picker-' + self._pickerId).select2({
                data: myFonts,
                escapeMarkup: function (markup) {
                    return markup;
                },
                formatResult: function (data) {
                    return data.html;
                },
                formatSelection: function (data) {
                    return data.html;
                },
                initSelection: function (element, callback) {
                    var id = $(element).val();
                    if (id !== "") {
                        var i;
                        for (i = 0; i < myFonts.length; i++) {
                            if (myFonts[i].text === id) {
                                callback(myFonts[i]);
                                break;
                            }
                        }
                    }
                }
            });
            $('#font-picker-' + self._pickerId)
                .on('change', function (e) {
                    if (args.hasOwnProperty('variables')) {
                        if (e.val !== undefined) {
                            var vars = args.variables;
                            self[vars.fontProp] = $(popSel + ' .fontSizePicker').val() + 'px ' + biaColors._graphFonts[+e.val].replace(/"/gi, "'");
                            self._refresh(kind);
                        }
                    }
                });

            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;

                if (!openIt)
                    self._saveTempProps(kind, vars);

                $('#font-picker-' + self._pickerId).select2('val', self._getFontNameFromFont(self[vars.fontProp]));
                $(popSel + ' .fontSizePicker').val(parseInt(self._getFontSizeFromFont(self[vars.fontProp]), 10));
                $(popSel + ' .fontcolorpicker').spectrum('set', self[vars.colorProp]);

                $('#fpbold').prop('checked', self[vars.styleProp].indexOf('B') !== -1);
                $('#fpitalic').prop('checked', self[vars.styleProp].indexOf('I') !== -1);
                $('#fpunderline').prop('checked', self[vars.styleProp].indexOf('U') !== -1);

                // No sense in tracking if we aren't changing vars...
                $('#fpbold').change(function () {
                    if (this.checked) {
                        self[vars.styleProp] = self._addFontStyle(self[vars.styleProp], 'B');
                    } else {
                        self[vars.styleProp] = self._removeFontStyle(self[vars.styleProp], 'B');
                    }
                    self._refresh(kind);
                });
                $('#fpitalic').change(function () {
                    if (this.checked) {
                        self[vars.styleProp] = self._addFontStyle(self[vars.styleProp], 'I');
                    } else {
                        self[vars.styleProp] = self._removeFontStyle(self[vars.styleProp], 'I');
                    }
                    self._refresh(kind);
                });
                $('#fpunderline').change(function () {
                    if (this.checked) {
                        self[vars.styleProp] = self._addFontStyle(self[vars.styleProp], 'U');
                    } else {
                        self[vars.styleProp] = self._removeFontStyle(self[vars.styleProp], 'U');
                    }
                    self._refresh(kind);
                });


            }

            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);



        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {

            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);

            $(document).off('click.' + popSel.substring(1)).on('click.' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;
                $('#' + myId).popover('hide');
            });

        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);
            $(popSel + ' .fontcolorpicker').spectrum('hide');
            $(popSel).remove();
            self.$lastPopover = null;

            $(document).off('click.' + popSel.substring(1));

        });




        if (lbl && drawLabel) {
            selector.append('text')
                .attr('class', self._editClass)
                .attr('x', x)
                .attr('y', y)
                .attr('dy', '.35em')
                .style("text-anchor", "middle")
                .text(lbl);
        }

        if (args.hasOwnProperty('open') && args.open) {
            $('#' + myId).popover('toggle');
        }


    }
    // 3 2 1 will yield:  3s3s3s2s2s
    // 3 2 2 will yield: 6ss6ss6ss4ss4ss
    // 3 2 3 will yield: 9sss9sss9sss6sss6sss
    // 3 1 1 will yield: 3s3s3s1s1s
    _makeDashArray(dl, dp, rm) {
        if (dl === 0 || dp === 0 || rm === 0) return "0";
        var dw = 5;
        var spw = 2;

        var patt = '';
        for (let i = 0; i < dl; i++) {
            if (i !== 0) patt += ' ';
            patt += (dw * rm);
            patt += ' ' + (spw * rm);
        }
        for (let i = 0; i < dp; i++) {
            patt += ' ' + (spw * rm);
            patt += ' ' + (spw * rm);
        }

        return patt;
    }
    _dashFromPattern(pat) {
        var ar = pat.split(' ');
        if (ar.length === 1) return ar[0];

        return Math.floor(+ar[0] / +ar[ar.length - 1]);
    }
    _spaceFromPattern(pat) {
        var ar = pat.split(' ');
        if (ar.length > 1) {
            return Math.floor(ar.length / 2) - this._dashFromPattern(pat);
        }
        else
            return 0;

    }
    _multFromPattern(pat) {
        var ar = pat.split(' ');
        if (ar.length > 1)
            return +ar[ar.length - 1];

        else
            return 0;
    }
    _drawCircleEditOutline(args) {
        var self = this;

        if (!self._pickerDashArrayId) {
            self._pickerDashArrayId = 'dar' + this._genId().replace(/[^a-z0-9]/gi, '');
        }

        // was 17em to 236px;
        var sContent = self._rangePicker('strokeWidthPicker', 0, 30, 1) +
            '<select id="' + self._pickerDashArrayId + '" style="align-self: center; display: inline-block; margin-left: 8px; width: 236px;"></select>'  +
            '<div style="display: inline-block; width: 6px"></div>';

        if (args.hasOwnProperty('insertContentBefore'))
            args.insertContentBefore += sContent;

        else
            args.insertContentBefore = sContent;

        var myCB = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                $(popSel + ' .strokeWidthPicker').bind('input', function () {
                    assignVarFromPath(self, vars.strokeWidthProp, $(popSel + ' .strokeWidthPicker').val());
                    if (args.hasOwnProperty('refresh'))
                        args.refresh();

                    self._refresh(args.kind);
                });

                $(popSel + ' .strokeWidthPicker').val(resolveVarFromPath(self, vars.strokeWidthProp));

                $('#' + self._pickerDashArrayId).empty();
                $('#' + self._pickerDashArrayId).append(self._generateStrokeDashArrayOptions());
                $('#' + self._pickerDashArrayId).iconpickerggc({
                    columns: 1,
                    inheritOriginalWidth: true,
                    inheritOriginalDisplay: true,
                    itemHeight: '2em',
                    labelTag: '<div/>',
                    labelClass: 'gcBGDiv',
                    labelBuilder: function (sel, d) {
                        // d.background_image="url(http://path.com/tstudio/bia/Magma.png)"  
                        var w1 = sel.parent().width();
                        var w2 = sel.parent().find('.button').width();
                        sel.css('width', w1 - w2);
                        if (d.background_image) {
                            sel.css('background-image', d.background_image);
                            sel.css('background-size', '100% 100%');
                            sel.text('');
                        } else {
                            sel.css('background-image', '');
                            sel.css('background-size', '');
                            sel.text(d.text);
                        }
                        sel.css('height', '2em');
                    },
                });
                $('#' + self._pickerDashArrayId).on('change', function (event) {
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        let idx = +$('#' + self._pickerDashArrayId).val();
                        if (idx < _dashArrays.length) {
                            assignVarFromPath(self, vars.strokeDashArrayProp, _dashArrays[idx].pattern);
                        } else {
                        }
                    }
    
                    self._refresh(args.kind);
                });

                // Correct?
                var icp = $('#' + self._pickerDashArrayId).data('iconpickerggc');
                let idx;
                let pdap = resolveVarFromPath(self, vars.strokeDashArrayProp);
                if (!pdap || pdap.length === 0) {
                    idx = 0;
                } else {
                    idx = _dashArrays.findIndex((itm) => itm.pattern === pdap);
                    if (idx === -1) {
                        idx = 0;
                    }
                }
                icp.select(idx);

            }
        };

        if (typeof (args.insertedCallback) === 'function') {
            var refCB = args.insertedCallback;
            var localCB = function (me, popSel) {
                myCB(me, popSel);
                refCB(me, popSel);
            };
            args.insertedCallback = localCB;
        } else {
            args.insertedCallback = myCB;
        }

        self._drawCircleEditColor(args);

    }
    _generateShapeOptions() {
        var self = this;
        var _html = '';

        biaColors._markerShapes.forEach(function (d, ix) {
            _html += '<option value="' + d.name + '" bgimage="url(' + self._imageCDN  + d.loc + ')">' + d.name + '</option>';
        });


        return _html;
    }
    // strokeWidthProp
    // strokeColorRefProp (no image, just color)
    // colorRefProp (for fill, including images)
    _drawCircleEditMarker(args) {
        var self = this;

        var sContent = '<select class="shapePicker" style="display: inline-block; width: 5em;"></select>' +
            self._rangePicker('strokeWidthPicker', 0, 5, 1, 'inline-block; width: 2em; vertical-align: middle;') +
            '<input class="colorpicker form-control input-md" />' +
            self._slashDiv() +
            self._rangePicker('markerSizePicker', 0, 30, 1, 'inline-block; width: 4em; vertical-align: middle;');

        if (args.hasOwnProperty('insertContentBefore'))
            args.insertContentBefore += sContent;

        else
            args.insertContentBefore = sContent;

        var myInsertCB = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;

                $(popSel + ' .shapePicker').empty();
                $(popSel + ' .shapePicker').append(self._generateShapeOptions());
                $(popSel + ' .shapePicker').iconpickerggc({
                    columns: 3,
                    inheritOriginalWidth: true,
                    inheritOriginalDisplay: true,
                    itemHeight: '1.5em',
                    labelTag: '<div/>',
                    labelClass: 'gcsBGDiv',
                    labelBuilder: function (sel, d) {
                        // d.background_image="url(http://path.com/tstudio/bia/Magma.png)"  
                        var w1 = sel.parent().width();
                        var w2 = sel.parent().find('.button').width();
                        sel.css('width', w1 - w2);
                        if (d.background_image) {
                            sel.css('background-image', d.background_image);
                            sel.css('background-size', '100% 100%');
                            sel.text('');
                        } else {
                            sel.css('background-image', '');
                            sel.css('background-size', '');
                            sel.text(d.text);
                        }
                        sel.css('height', '2em');
                    },
                });
                $(popSel + ' .shapePicker').on('change', function (event) {
                    let loc = $(popSel + ' .shapePicker').val();
                    // TODO: index in 'shapes' array...
                    // Load shape, set stroke-width, stroke, 
                    assignVarFromPath(self, vars.shapeNameProp, loc);

                    self._refresh(args.kind);
                });
                $(popSel + ' .shapePicker').data('iconpickerggc').select(resolveVarFromPath(self, vars.shapeNameProp));


                $(popSel + ' .strokeWidthPicker').bind('input', function () {
                    assignVarFromPath(self, vars.strokeWidthProp, $(popSel + ' .strokeWidthPicker').val());
                    if (args.hasOwnProperty('refresh'))
                        args.refresh();

                    self._refresh(args.kind);
                });
                $(popSel + ' .markerSizePicker').bind('input', function () {
                    assignVarFromPath(self, vars.markerSizeProp, $(popSel + ' .markerSizePicker').val());
                    if (args.hasOwnProperty('refresh'))
                        args.refresh();

                    self._refresh(args.kind);
                });

                $(popSel + ' .colorpicker').spectrum({
                    readonly: false,
                    showInput: true,
                    clickoutFiresChange: false,
                    showInitial: true,
                    chooseText: i18next.t("save"),
                    change: function (color) {
                        $(popSel + ' .colorpicker').data('changed', true);
                        if (args.hasOwnProperty('variables')) {
                            var vars = args.variables;
                            assignVarFromPath(self, vars.strokeColorRefProp + '.loc', null);
                            assignVarFromPath(self, vars.strokeColorRefProp + '.ref', null);
                            assignVarFromPath(self, vars.strokeColorRefProp + '.rgb', color ? color.toHexString() : '');
                            self._refresh(args.kind);
                        }
                    },
                    move: function (color) {
                        if (args.hasOwnProperty('variables')) {
                            var vars = args.variables;
                            assignVarFromPath(self, vars.strokeColorRefProp + '.loc', null);
                            assignVarFromPath(self, vars.strokeColorRefProp + '.ref', null);
                            assignVarFromPath(self, vars.strokeColorRefProp + '.rgb', color ? color.toHexString() : '');
                            self._refresh(args.kind);
                        }
                    },
                    hide: function (e, c) {
                        if (!$(popSel + ' .colorpicker').data('changed')) {
                            self._restoreTempProp(args.kind, vars.strokeColorRefProp + '.loc');
                            self._restoreTempProp(args.kind, vars.strokeColorRefProp + '.ref');
                            self._restoreTempProp(args.kind, vars.strokeColorRefProp + '.rgb');
                            self._refresh();
                        }
                    }
    
                });
                $(popSel + ' .strokeWidthPicker').val(resolveVarFromPath(self, vars.strokeWidthProp));
                $(popSel + ' .colorpicker').spectrum('set', resolveVarFromPath(self, vars.strokeColorRefProp + '.rgb'));
                $(popSel + ' .markerSizePicker').val(resolveVarFromPath(self, vars.markerSizeProp));

            }
        };
        if (typeof (args.insertedCallback) === 'function') {
            var refInsertCB = args.insertedCallback;
            var localInsertCB = function (me, popSel) {
                myInsertCB(me, popSel);
                refInsertCB(me, popSel);
            };
            args.insertedCallback = localInsertCB;
        } else {
            args.insertedCallback = myInsertCB;
        }

        var myHiddenCB = function (me, popSel) {
            $(popSel + ' .colorpicker').spectrum('hide');
        };
        if (typeof (args.hiddenCallback) === 'function') {
            var reHiddenCB = args.hiddenCallback;
            var localHiddenCB = function (me, popSel) {
                myHiddenCB(me, popSel);
                reHiddenCB(me, popSel);
            };
            args.hiddenCallback = localHiddenCB;
        } else {
            args.hiddenCallback = myHiddenCB;
        }


        self._drawCircleEditColor(args);

    }
    _drawCircleEditLine(args) {
        var self = this;
        var kind, title, x, y, place;
        var selector;

        if (!args.hasOwnProperty('selector')) {
            throw new Error('_drawCircleEditLine: must pass selector');
        }
        selector = args.selector;

        if (!args.hasOwnProperty('kind')) {
            throw new Error('_drawCircleEditLine: must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title;

        else
            title = 'none';

        if (!args.hasOwnProperty('x')) {
            throw new Error('_drawCircleEditLine: must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error('_drawCircleEditText: must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else
            place = 'right';


        var kindClass = self._nameToClassSafe(kind) + '_editCircle';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            r = 12;
        }

        self._drawPencil(selector, x, y, r);
        var circ = selector.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 'f' + self._genCleanId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        //    wrapper.setAttribute('data-trigger', 'focus');    
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);
        var popSel = '#axis-popover-' + self._genCleanId();

        $('#' + myId).popover({
            container: self.popoverContainer,     // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '">' +
                self._rangePicker('strokeWidthPicker', 0, 30, 1) +
                '<input class="fontcolorpicker form-control input-md" />' +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t('cancel') + '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {
            $(popSel + ' .strokeWidthPicker').bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    self[vars.widthProp] = $(popSel + ' .strokeWidthPicker').val();
                    self._refresh(kind);
                }
            });

            $(popSel + ' .fontcolorpicker').spectrum({
                readonly: false,
                showInput: true,
                clickoutFiresChange: false,
                showInitial: true,
                chooseText: i18next.t("save"),
                change: function (color) {
                    $(popSel + ' .fontcolorpicker').data('changed', true);
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        self[vars.colorProp] = color ? color.toHexString() : '';
                        self._refresh(kind);
                    }
                },
                move: function (color) {
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        self[vars.colorProp] = color ? color.toHexString() : '';
                        self._refresh(kind);
                    }
                },
                hide: function (e, c) {
                    if (!$(popSel + ' .fontcolorpicker').data('changed')) {
                        self._restoreTempProp(kind, vars.colorProp);
                        self._refresh();
                    }
                }
            });
            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;

                self._saveTempProps(kind, vars);

                // obj,  'widthProp', 'colorProp'
                $(popSel + ' .strokeWidthPicker').val(self[vars.widthProp]);
                $(popSel + ' .fontcolorpicker').spectrum('set', self[vars.colorProp]);
            }
            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);

        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {

            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);

            $(document).off('click.' + popSel.substring(1)).on('click.' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;
                $('#' + myId).popover('hide');
            });

        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);
            $(popSel + ' .fontcolorpicker').spectrum('hide');
            $(popSel).remove();
            self.$lastPopover = null;

            $(document).off('click.' + popSel.substring(1));

        });


    }
    _drawCircleEditBase(args) {
        var self = this;
        var kind, x, y, place, title;
        var selector;

        if (!args.hasOwnProperty('selector')) {
            throw new Error('_drawCircleEditBase: must pass selector');
        }
        selector = args.selector;

        if (!args.hasOwnProperty('kind')) {
            throw new Error('_drawCircleEditBase: must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title;

        else
            title = 'none';
        if (!args.hasOwnProperty('x')) {
            throw new Error('_drawCircleEditBase: must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error('_drawCircleEditBase: must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else
            place = 'right';

        var kindClass = self._nameToClassSafe(kind) + '_editBase';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            r = 12;
        }

        self._drawPencil(selector, x, y, r);
        var circ = selector.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 'l' + self._genCleanId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);

        var popSel = '#' + kind + '-popover-' + self._genCleanId();

        $('#' + myId).popover({
            container: self.popoverContainer, // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '">' +
                (args.hasOwnProperty('insertContentBefore') ? args.insertContentBefore : '') +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t('cancel')+ '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {

            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                self._saveTempProps(kind, vars);
            }

            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);

        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {

            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);
            $(document).off('click.' + popSel.substring(1)).on('click.' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;

                $('#' + myId).popover('hide');
            });

        }).on('shown.bs.popover', function () {
            if (self.editMode) {    // this can happen  on new double click to edit
                var rt = document.getElementById(popSel.substring(1)).getBoundingClientRect();
                var ht = rt.bottom - rt.top;
                $(popSel + ' .pieSlash').attr('height', ht);
            }
        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);

            $(popSel).remove();
            self.$lastPopover = null;
            $(document).off('click.' + popSel.substring(1));
        });


    }
    _drawCircleEditBoolean(args) {
        var self = this;

        var sContent = 
        '<input type="radio" name="' + args.name + '" value="yes">' + i18next.t("yes") + '&nbsp;' +
        '<input type="radio" name="' + args.name + '" value="no">' + i18next.t("no") + '&nbsp;';

        args.insertContentBefore = sContent;

        args.insertedCallback = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                //$('input[name=stackyn][value="' + me[vars.booleanProp] + '"]').prop('checked', true);
                $('input[name='+args.name+'][value="' + (self[vars.booleanProp] ? 'yes' : 'no') + '"]').prop('checked', true);
                $('input[type=radio][name='+args.name+']').change(function () {
                    self[vars.booleanProp] = this.value === 'no' ? false : true;
                    self._refresh();
                });
            }


        };

        self._drawCircleEditBase(args);

    }
    _drawCircleEditColor(args) {
        var self = this;
        var kind, x, y, place, title;
        var selector;

        if (!args.hasOwnProperty('selector')) {
            throw new Error('_drawCircleEditColor: must pass selector');
        }
        selector = args.selector;

        if (!args.hasOwnProperty('kind')) {
            throw new Error('_drawCircleEditColor: must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title;

        else
            title = 'none';
        if (!args.hasOwnProperty('x')) {
            throw new Error('_drawCircleEditColor: must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error('_drawCircleEditColor: must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else
            place = 'right';

        var kindClass = self._nameToClassSafe(kind) + '_editCircle';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            r = 12;
        }

        self._drawPencil(selector, x, y, r);
        var circ = selector.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 'l' + self._genCleanId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);


        var myUrl = self._imageCDN + 'addimage.svg';
        var popSel = '#pie-popover-' + self._genCleanId();

        $('#' + myId).popover({
            container: self.popoverContainer,     // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '" style="display: flex; flex-direction: row; align-content:center;">' +
                (args.hasOwnProperty('insertContentBefore') ? args.insertContentBefore : '') +
                '<input class="fillcolorpicker form-control input-md"/>' +
                self._rangePicker('opacityCustomPicker transg', 0, 1, .11) +
                '<input class="urlPicker" type="image" src="' + myUrl + '" height="20" style="width: 23px; height: 18px; margin-right: 5px; margin-left: 0; margin-top: 0">' +
                '<input type="checkbox" name="piecbstretch" id="piecbstretch" style="margin-left: 8px; align-self: center">' +
                '<div id="piecblabel" style="align-self: center; margin-left: 8px;">' + i18next.t("stretch") + '</div>' +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t("cancel") + '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {
            $(popSel + " .opacityCustomPicker").bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    assignVarFromPath(self, vars.colorRefProp + '.opacity', $(popSel + " .opacityCustomPicker").val());
                    self._refresh(kind);
                }
            });

            $(popSel + ' .fillcolorpicker').spectrum({
                allowEmpty: true,
                readonly: false,
                clickoutFiresChange: false,
                showInput: true,
                chooseText: i18next.t("save"),
                change: function (color) {
                    $(popSel + ' .fillcolorpicker').data('changed', true);
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;

                        $('#piecbstretch').attr('disabled', true);
                        $('#piecblabel').css('color', 'grey');

                        $(popSel + ' .urlPicker').urlentryggc('set', '');
                        assignVarFromPath(self, vars.colorRefProp + '.loc', null);
                        assignVarFromPath(self, vars.colorRefProp + '.ref', null);
                        assignVarFromPath(self, vars.colorRefProp + '.rgb', color ? color.toHexString() : '');

                        self._refresh(kind);
                    }
                },
                move: function (color) {
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;

                        $('#piecbstretch').attr('disabled', true);
                        $('#piecblabel').css('color', 'grey');

                        $(popSel + ' .urlPicker').urlentryggc('set', '');
                        assignVarFromPath(self, vars.colorRefProp + '.loc', null);
                        assignVarFromPath(self, vars.colorRefProp + '.ref', null);
                        assignVarFromPath(self, vars.colorRefProp + '.rgb', color ? color.toHexString() : '');

                        self._refresh(kind);
                    }
                },
                hide: function (e, c) {
                    if (!$(popSel + ' .fillcolorpicker').data('changed')) {
                        self._restoreTempProp(args.kind, vars.colorRefProp + '.loc');
                        self._restoreTempProp(args.kind, vars.colorRefProp + '.ref');
                        self._restoreTempProp(args.kind, vars.colorRefProp + '.rgb');
                        self._refresh();
                    }
                }

            });


            $(popSel + ' .urlPicker').urlentryggc({
                cancelText: i18next.t("cancel"),
                chooseText: i18next.t("save"),
                change: function (u) {
                    if (u.length > 0 && args.hasOwnProperty('variables')) {
                        var vars = args.variables;

                        $(popSel + ' .fillcolorpicker').spectrum('set', '');
                        $('#piecbstretch').removeAttr('disabled');
                        $('#piecblabel').css('color', 'black');

                        assignVarFromPath(self, vars.colorRefProp + '.rgb', null);
                        assignVarFromPath(self, vars.colorRefProp + '.ref', null);
                        assignVarFromPath(self, vars.colorRefProp + '.loc', u);

                        self._refresh(kind);
                    }
                }
            });

            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                self._saveTempProps(kind, vars);

                var colorRef = resolveVarFromPath(self, vars.colorRefProp);

                $(popSel + " .opacityCustomPicker").val(colorRef.opacity);

                if (colorRef.hasOwnProperty('loc') && colorRef.loc) { // image
                    $('#piecbstretch').removeAttr('disabled');
                    $('#piecbstretch').prop('checked', colorRef.hasOwnProperty('stretch') ? colorRef.stretch : false);
                    $('#piecblabel').css('color', 'black');
                    $(popSel + ' .urlPicker').urlentryggc('set', colorRef.loc);
                } else {
                    $('#piecbstretch').prop('checked', false);
                    $('#piecbstretch').attr('disabled', true);
                    $('#piecblabel').css('color', 'grey');
                    $(popSel + ' .fillcolorpicker').spectrum('set', colorRef.rgb);
                }
                $('#piecbstretch').change(function () {
                    assignVarFromPath(self, vars.colorRefProp + '.stretch', this.checked);
                    self._refresh(kind);
                });

            }

            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);

        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {

            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);
            $(document).off('click.' + popSel.substring(1)).on('click.' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;

                $('#' + myId).popover('hide');
            });

        }).on('shown.bs.popover', function () {
            if (self.editMode) {    // this can happen  on new double click to edit
                var rt = document.getElementById(popSel.substring(1)).getBoundingClientRect();
                var ht = rt.bottom - rt.top;
                $(popSel + ' .pieSlash').attr('height', ht);
            }
        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);

            $(popSel + ' .fillcolorpicker').spectrum('hide');
            $(popSel).remove();
            self.$lastPopover = null;
            $(document).off('click.' + popSel.substring(1));
        });


    }
    _drawCircleEditBarWidth(args) {
        var self = this;

        var sContent = self._rangePicker('barWidthPicker', 0.0, 1.0, .1) +      // minv, maxv, 1) +
            self._slashDiv();

        args.insertContentBefore = sContent;

        args.insertedCallback = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                $(popSel + ' .barWidthPicker').bind('input', function () {
                    self[vars.barWidthProp] = +$(popSel + ' .barWidthPicker').val();
                    self._refresh(args.kind);
                });

                $(popSel + ' .barWidthPicker').val(self[vars.barWidthProp]);
            }
        };

        self._drawCircleEditOutline(args);
    }

    //
    _generateStrokeDashArrayOptions() {
        var self = this;
        var _html = '';

        _dashArrays.forEach(function (d, ix) {
            _html += '<option value="' + ix + '" bgimage="url(' + self._imageCDN + d.loc + ')">' + d.name + '</option>';

        });

        return _html;
    }

    _generateColorPaletteOptions() {
        var self = this;
        var _html = '';

        biaColors._d3ColorsCategorical.forEach(function (d, ix) {
            _html += '<option value="' + ix + '" bgimage="url(' + self._imageCDN + d.loc + '.png)">' + d.name + '</option>';

        });

        var ixStart = biaColors._d3ColorsCategorical.length;

        biaColors._d3ColorsInterpolate.forEach(function (d, ix) {
            _html += '<option value="' + (ixStart + ix) + '" bgimage="url(' + self._imageCDN + d.loc + '.png)">' + d.name + '</option>';
        });

        //TODO: put bgimage of "Custom" here...
        _html += '<option value="' + (biaColors._d3ColorsCategorical.length + biaColors._d3ColorsInterpolate.length) + '">Custom</option>';

        return _html;
    }
    _generateColorPaletteInterpolateOptions() {
        var self = this;
        var _html = '';

        biaColors._d3ColorsInterpolate.forEach(function (d, ix) {
            _html += '<option value="' + (ix) + '" bgimage="url(' + self._imageCDN + d.loc + '.png)">' + d.name + '</option>';
        });

        _html += '<option value="' + (biaColors._d3ColorsInterpolate.length) + '">' + i18next.t("graph.edit.gradient") + '</option>';

        return _html;
    }
    _switchToCustomPalette(vars) {
        var self = this;

        $('#' + self._pickerPaletteId).prop('selectedIndex',
            biaColors._d3ColorsCategorical.length + biaColors._d3ColorsInterpolate.length).iconpickerggc('refresh');

        // Copy current palette to custom.colorRef[]; set> name, loc, interpolate
        var custom = {};
        custom.name = 'newpalette';
        custom.loc = null;
        custom.interpolate = false;
        custom.colorRef = [];
        let indx = self[vars.paletteIndexProp];

        if (self[vars.paletteTypeProp] === 'categorical') {
            for (let i = 0; i < biaColors._d3ColorsCategorical[indx].colors.length; i++)
                custom.colorRef.push({ rgb: biaColors._d3ColorsCategorical[indx].colors[i], loc: null, opacity: self.colorPaletteOpacity });

        } else if (self[vars.paletteTypeProp] === 'interpolate') {
            let n = self.valueStatus.length;
            for (let i = 0; i < n; i++) {
                if (i === 0)
                    custom.colorRef.push({ rgb: biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(0), loc: null, opacity: self.colorPaletteOpacity });

                else
                    custom.colorRef.push({ rgb: biaColors._d3ColorsInterpolate[self.colorPaletteIndex].interpolate(i / (n - 1)), loc: null, opacity: self.colorPaletteOpacity });
            }
        } else if (self[vars.paletteTypeProp] === 'gradient') {
            var gd = self.getGradientData();
            let n;
            n = self.valueStatus.length % 256;
            if (n === 0 && self.valueStatus.length > 0)
                n = 256;
            for (let i = 0; i < n; i++) {
                if (i === 0)
                    custom.colorRef.push({ rgb: new tinycolor({ r: gd[0], g: gd[1], b: gd[2] }).toHexString(), loc: null, opacity: self.colorPaletteOpacity });
                else {
                    var ix = 4 * Math.floor(255 * i / (n - 1));
                    custom.colorRef.push({ rgb: new tinycolor({ r: gd[ix], g: gd[ix + 1], b: gd[ix + 2] }).toHexString(), loc: null, opacity: self.colorPaletteOpacity });
                }
            }
        }

        self[vars.paletteTypeProp] = 'custom';
        self[vars.customPaletteProp] = custom;
    }
    _slashDiv(selClass) {
        return '<div style="height:100%; width:10px; display: inline-block; margin-left: 8px; margin-right: 6px; vertical-align:middle">' +
            '<svg class="' + selClass + '" width="10" height="30">' +
            '<line x1="0" x2="100%" y1="100%" y2="0" stroke="black" stroke-width="1"></line>' +
            '</svg>' +
            '</div>';

    }
    _drawCircleEditFill(args) {
        var self = this;
        var _smodule = '_drawCircleEditFill: ';
        var kind, x, y, place, title;
        var selector;
        var ordinal; // could be bigger than color table length...
        var gClass;

        if (!args.hasOwnProperty('selector')) {
            throw new Error(_smodule + 'must pass selector');
        }
        selector = args.selector;
        if (!args.hasOwnProperty('ordinal')) {
            throw new Error(_smodule + 'must pass ordinal');
        }
        ordinal = args.ordinal; // index into palette

        if (!args.hasOwnProperty('kind')) {
            throw new Error(_smodule + 'must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title + ' Palette/Custom';

        else
            title = 'Palette/Custom';

        if (!args.hasOwnProperty('x')) {
            throw new Error(_smodule + 'must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error(_smodule + 'must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else
            place = 'right';

        if (args.hasOwnProperty('class'))
            gClass = args.class;

        else
            gClass = 'g_editCircle';

        var kindClass = self._nameToClassSafe(kind) + '_editCircle';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            r = 12;
        }

        var g = selector.append('g')
            .attr('class', gClass + ' ' + self._editClass);

        self._drawPencil(g, x, y, r);
        var circ = g.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 'l' + self._genId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        //    wrapper.setAttribute('data-trigger', 'focus');    
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);
        if (!self._pickerPaletteId)
            self._pickerPaletteId = 'pal' + this._genId().replace(/[^a-z0-9]/gi, '');
        if (!self._pickerUrlId)
            self._pickerUrlId = 'url' + this._genId().replace(/[^a-z0-9]/gi, '');

        var myUrl = self._imageCDN + 'addimage.svg';
        var popSel = '#fill-popover-' + self._genCleanId();

        $('#' + myId).popover({
            container: self.popoverContainer,     // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '" style="display: flex; flex-direction: row; align-content:center;">' +
                '<select id="' + self._pickerPaletteId + '" style="display: inline-block; width: 12em;"></select>' +
                self._rangePicker('opacityPalettePicker transg', 0, 1, .1) +
                self._slashDiv('fillSlash') +
                '<input class="fillcolorpicker form-control input-md" style="margin-left: 8px;" />' +
                self._rangePicker('opacityCustomPicker transg', 0, 1, .1) +
                '<input id="' + self._pickerUrlId + '" type="image" src="' + myUrl + '" width="30px" height="20px" style="width: 23px; height: 18px; margin-right: 5px; margin-left: 0; margin-top: 0">' +
                '<input type="checkbox" name="fillcbstretch" id="fillcbstretch" style="margin-left: 8px; margin-right:4px; align-self: center">' +
                '<div id="fillcblabel" style="align-self: center;">' + i18next.t('stretch') + '</div>' +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t('cancel') + '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {
            $(popSel + " .opacityPalettePicker").bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    self[vars.paletteOpacityProp] = $(popSel + " .opacityPalettePicker").val();
                    self._refresh(kind);
                }
            });
            $(popSel + " .opacityCustomPicker").bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    // *must* be 'custom' palette, so we are on 'ordinal'th one
                    let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                    self[vars.customPaletteProp].colorRef[myOrdinal].opacity = $(popSel + " .opacityCustomPicker").val();

                    self._refresh(kind);
                }
            });

            $('#' + self._pickerPaletteId).empty();
            $('#' + self._pickerPaletteId).append(self._generateColorPaletteOptions());
            $('#' + self._pickerPaletteId).iconpickerggc({
                columns: 1,
                inheritOriginalWidth: true,
                itemHeight: '2em',
                labelTag: '<div/>',
                labelClass: 'gcBGDiv',
                labelBuilder: function (sel, d) {
                    // d.background_image="url(http://path.com/tstudio/bia/Magma.png)"  
                    var w1 = sel.parent().width();
                    var w2 = sel.parent().find('.button').width();
                    sel.css('width', w1 - w2);
                    if (d.background_image) {
                        sel.css('background-image', d.background_image);
                        sel.css('background-size', '100% 100%');
                        sel.text('');
                    } else {
                        sel.css('background-image', '');
                        sel.css('background-size', '');
                        sel.text(d.text);
                    }
                    sel.css('height', '2em');
                },
            });

            $('#' + self._pickerPaletteId).on('change', function (event) {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    let idx = +$('#' + self._pickerPaletteId).val();
                    if (idx < biaColors._d3ColorsCategorical.length) {
                        self[vars.customPaletteProp] = {};
                        self[vars.paletteTypeProp] = 'categorical';
                        self[vars.paletteIndexProp] = idx;
                        //                    $(popSel +' .fillcolorpicker').spectrum('set', biaColors._d3ColorsCategorical[idx].colors[ordinal]);
                        $(popSel + ' .fillcolorpicker').spectrum('set', self._getFillColor(ordinal));
                        $(popSel + ' .opacityPalettePicker').removeAttr('disabled');
                        $(popSel + ' .opacityCustomPicker').attr('disabled', true);

                    } else if (idx < biaColors._d3ColorsCategorical.length + biaColors._d3ColorsInterpolate.length) {
                        self[vars.customPaletteProp] = {};
                        self[vars.paletteTypeProp] = 'interpolate';
                        self[vars.paletteIndexProp] = idx - biaColors._d3ColorsCategorical.length;
                        $(popSel + ' .fillcolorpicker').spectrum('set', self._getFillColor(ordinal));

                        $(popSel + ' .opacityPalettePicker').removeAttr('disabled');
                        $(popSel + ' .opacityCustomPicker').attr('disabled', true);

                    } else {
                    }
                }

                self._refresh(kind);
            });

            var sel = $('#' + self._pickerPaletteId).data('iconpickerggc');
            sel.elements.outerWrapper.css('margin-right', '4px');
            sel.elements.outerWrapper.css('display', 'inline-block');

            $(popSel + ' .fillcolorpicker').spectrum({
                allowEmpty: true,
                readonly: false,
                showInput: true,
                chooseText: i18next.t("save"),
                change: function (color) {
                    $(popSel + ' .fillcolorpicker').data('changed', true);

                    $(popSel + ' .opacityPalettePicker').attr('disabled', true);
                    $(popSel + ' .opacityCustomPicker').removeAttr('disabled');

                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        if (self[vars.paletteTypeProp] !== 'custom') {
                            // TODO: Warn first?
                            self._switchToCustomPalette(vars);
                            let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                            $(popSel + ' .opacityCustomPicker').val(self[vars.customPaletteProp].colorRef[myOrdinal].hasOwnProperty('opacity') ? self[vars.customPaletteProp].colorRef[myOrdinal].opacity : 1.0);
                        }

                        $('#fillcbstretch').attr('disabled', true);
                        $('#fillcblabel').css('color', 'grey');

                        $('#' + self._pickerUrlId).urlentryggc('set', '');

                        let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                        self[vars.customPaletteProp].colorRef[myOrdinal].loc = null;
                        self[vars.customPaletteProp].colorRef[myOrdinal].ref = null;
                        self[vars.customPaletteProp].colorRef[myOrdinal].rgb = color ? color.toHexString() : '';

                        self._refresh(kind);
                    }
                },
                move: function (color) {
                    $(popSel + ' .opacityPalettePicker').attr('disabled', true);
                    $(popSel + ' .opacityCustomPicker').removeAttr('disabled');

                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        if (self[vars.paletteTypeProp] !== 'custom') {
                            self._switchToCustomPalette(vars);
                            let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                            $(popSel + ' .opacityCustomPicker').val(self[vars.customPaletteProp].colorRef[myOrdinal].hasOwnProperty('opacity') ? self[vars.customPaletteProp].colorRef[myOrdinal].opacity : 1.0);
                        }

                        $('#fillcbstretch').attr('disabled', true);
                        $('#fillcblabel').css('color', 'grey');

                        $('#' + self._pickerUrlId).urlentryggc('set', '');

                        let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                        self[vars.customPaletteProp].colorRef[myOrdinal].loc = null;
                        self[vars.customPaletteProp].colorRef[myOrdinal].ref = null;
                        self[vars.customPaletteProp].colorRef[myOrdinal].rgb = color ? color.toHexString() : '';

                        self._refresh(kind);
                    }
                },
                hide: function (e, c) {
                    if (!$(popSel + ' .fillcolorpicker').data('changed')) {
                        self._restoreTempProp(args.kind, vars.paletteTypeProp);
                        self._restoreTempProp(args.kind, vars.customPaletteProp);
                        self._refresh();
                    }
                }

            });

            $('#' + self._pickerUrlId).urlentryggc({
                cancelText: i18next.t("cancel"),
                chooseText: i18next.t("save"),
                change: function (u) {
                    if (u.length > 0) {
                        $(popSel + ' .opacityPalettePicker').attr('disabled', true);
                        $(popSel + ' .opacityCustomPicker').removeAttr('disabled');
                        if (args.hasOwnProperty('variables')) {
                            var vars = args.variables;

                            if (self[vars.paletteTypeProp] !== 'custom') {
                                // TODO: Warn first?
                                self._switchToCustomPalette(vars);
                                let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                                $(popSel + ' .opacityCustomPicker').val(self[vars.customPaletteProp].colorRef[myOrdinal].hasOwnProperty('opacity') ? self[vars.customPaletteProp].colorRef[myOrdinal].opacity : 1.0);
                            }
                            $('#fillcbstretch').removeAttr('disabled');
                            $('#fillcblabel').css('color', 'black');

                            $(popSel + ' .fillcolorpicker').spectrum('set', '');
                            self[vars.customPaletteProp].hasDef = true;

                            let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                            self[vars.customPaletteProp].colorRef[myOrdinal].rgb = null;
                            self[vars.customPaletteProp].colorRef[myOrdinal].ref = null;
                            self[vars.customPaletteProp].colorRef[myOrdinal].loc = u;

                            self._refresh(kind);
                        }
                    }
                }
            });

            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                self._saveTempProps(kind, vars);
                var cp = self[vars.paletteTypeProp];
                var indx = self[vars.paletteIndexProp];
                var cust = self[vars.customPaletteProp];

                $('#fillcbstretch').prop('checked', false);
                $('#fillcbstretch').attr('disabled', true);
                $('#fillcblabel').css('color', 'grey');

                var icp = $('#' + self._pickerPaletteId).data('iconpickerggc');

                if (cp === 'categorical') {
                    // select indx value in .iconpickerggc
                    icp.select(indx);
                    $(popSel + ' .fillcolorpicker').spectrum('set', self._getFillColor(ordinal));
                    $(popSel + ' .opacityPalettePicker').val(self[vars.paletteOpacityProp]);
                    $(popSel + ' .opacityCustomPicker').attr('disabled', true);

                } else if (cp === 'interpolate') {
                    // select indx+biaColors._d3ColorsCategorical.length value in .iconpickerggc
                    icp.select(indx + biaColors._d3ColorsCategorical.length);
                    $(popSel + ' .fillcolorpicker').spectrum('set', self._getFillColor(ordinal));
                    $(popSel + ' .opacityPalettePicker').val(self[vars.paletteOpacityProp]);
                    $(popSel + ' .opacityCustomPicker').attr('disabled', true);
                } else if (cp === 'custom') {
                    // Clear .iconpickerggc
                    icp.select(biaColors._d3ColorsCategorical.length + biaColors._d3ColorsInterpolate.length);


                    let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;

                    // cust.name, cust.colorRef[ {rgb: , loc:, ref:<id>, opacity: stretch:}, cust.loc, cust.interpolate, 
                    if (cust.colorRef[myOrdinal].hasOwnProperty('ref') && cust.colorRef[myOrdinal].ref) { // image
                        $('#fillcbstretch').removeAttr('disabled');
                        $('#fillcbstretch').prop('checked', cust.colorRef[myOrdinal].hasOwnProperty('stretch') ? cust.colorRef[myOrdinal].stretch : false);
                        $('#fillcblabel').css('color', 'black');

                        $('#' + self._pickerUrlId).urlentryggc('set', cust.colorRef[myOrdinal].loc);
                    } else {
                        $(popSel + ' .fillcolorpicker').spectrum('set', cust.colorRef[myOrdinal].rgb);
                    }

                    $(popSel + ' .opacityPalettePicker').attr('disabled', true);
                    $(popSel + ' .opacityCustomPicker').removeAttr('disabled');
                    $(popSel + ' .opacityCustomPicker').val(cust.colorRef[myOrdinal].hasOwnProperty('opacity') ? cust.colorRef[myOrdinal].opacity : 1.0);
                }
                $('#fillcbstretch').change(function () {
                    let myOrdinal = ordinal % self[vars.customPaletteProp].colorRef.length;
                    cust.colorRef[myOrdinal].stretch = this.checked;
                    self._refresh(kind);
                });

            }
            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);

        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {

            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);
            $(document).off('click.' + popSel.substring(1)).on('click.fill-' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;

                $('#' + myId).popover('hide');
            });
        }).on('shown.bs.popover', function () {
            if (self.editMode) {
                var rt = document.getElementById(popSel.substring(1)).getBoundingClientRect();
                var ht = rt.bottom - rt.top;
                $(popSel + ' .fillSlash').attr('height', ht);
            }

        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);
            $(popSel + ' .fillcolorpicker').spectrum('hide');
            $(popSel).remove();
            self.$lastPopover = null;
            $(document).off('click.' + popSel.substring(1));
        });


    }
    /*
      variables: {
          paletteTypeProp: 'mapPaletteType.'+ordinal,      // 'interpolate', 'gradient'
          paletteIndexProp: 'mapPaletteIndex.'+ordinal,    // index in cat or interp
          customPaletteProp: 'mapGradientPalette.'+ordinal,  // if above is 'gradient'
          paletteOpacityProp: 'mapPaletteOpacity.'+ordinal // if palette vs. custom
        }
    */
    _drawCircleEditPalette(args) {
        var self = this;
        var _smodule = '_drawCircleEditPalette';
        var kind, x, y, place, title;
        var selector;
        var gClass;

        if (!args.hasOwnProperty('selector')) {
            throw new Error(_smodule + 'must pass selector');
        }
        selector = args.selector;

        if (!args.hasOwnProperty('kind')) {
            throw new Error(_smodule + 'must pass kind');
        }
        kind = args.kind;

        if (args.hasOwnProperty('title'))
            title = args.title + ' Palette/Custom';

        else
            title = 'Palette/Custom';

        if (!args.hasOwnProperty('x')) {
            throw new Error(_smodule + 'must pass x');
        }
        x = args.x;
        if (!args.hasOwnProperty('y')) {
            throw new Error(_smodule + 'must pass y');
        }
        y = args.y;
        if (args.hasOwnProperty('placement'))
            place = args.placement;

        else
            place = 'right';

        if (args.hasOwnProperty('class'))
            gClass = args.class;

        else
            gClass = 'g_editCircle';

        var kindClass = self._nameToClassSafe(kind) + '_editCircle';

        var r;
        if (args.hasOwnProperty('r'))
            r = args.r;
        else {
            r = 12;
        }

        var g = selector.append('g')
            .attr('class', gClass + ' ' + self._editClass)
            .attr('pointer-events', 'all');

        self._drawPencil(g, x, y, r);
        var circ = g.append('circle')
            .attr('cx', x+r)
            .attr('cy', y-r)
            .attr('r', r)
            .attr('stroke', 'blue')
            .attr('fill', self.editColor)
            .style('opacity', 0);

        var circNode = circ.nodes()[0];
        // Need svg namespace, or contained circle disappears
        var wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'a');
        wrapper.setAttribute('tabindex', self.editTabIndex);
        self.editTabIndex++;
        var myId = 'l' + self._genCleanId();
        wrapper.setAttribute('id', myId);
        wrapper.setAttribute('class', kindClass + ' ' + self._editClass);
        wrapper.setAttribute('role', 'button');
        wrapper.setAttribute('data-toggle', 'popover');
        //    wrapper.setAttribute('data-trigger', 'focus');    
        circNode.parentNode.insertBefore(wrapper, circNode);
        wrapper.appendChild(circNode);
        if (!self._pickerPaletteId)
            self._pickerPaletteId = 'pal' + this._genId().replace(/[^a-z0-9]/gi, '');
        if (!self._pickerUrlId)
            self._pickerUrlId = 'url' + this._genId().replace(/[^a-z0-9]/gi, '');

        var popSel = '#mpal-popover-' + self._genCleanId();

        var grads = '0.25 <input data-goffset=".25" class="fillcolorpicker form-control input-md" />' +
            '0.55 <input data-goffset=".55" class="fillcolorpicker form-control input-md" />' +
            '0.85 <input data-goffset=".85" class="fillcolorpicker form-control input-md" />' +
            '1.0 <input data-goffset="1" class="fillcolorpicker form-control input-md" />';

        if (args.hasOwnProperty('variables')) {
            var vars = args.variables;
            var cp = resolveVarFromPath(self, vars.paletteTypeProp);
            if (cp === 'gradient') {
                var grad = resolveVarFromPath(self, vars.customPaletteProp);
                var vals = [];
                for (var v in grad) {
                    if (grad.hasOwnProperty(v)) {
                        vals.push(v);
                    }
                }
                grads = '';
                vals.sort();
                for (var i = 0; i < vals.length; i++) {
                    grads += vals[i] + ' <input data-goffset="' + vals[i] + '" class="fillcolorpicker form-control input-md" />';
                }
            }
        }

        $('#' + myId).popover({
            container: self.popoverContainer,     // self.container,
            'max-width': '100%',
            title: title,
            sanitize: false,
            html: true,
            trigger: 'manual',
            placement: place,
            content: '<div id="' + popSel.substring(1) + '">' +
                '<select id="' + self._pickerPaletteId + '" style="display: inline-block; width: 12em;"></select>' +
                self._slashDiv('mpalSlash') +
                // gradient here
                grads +
                self._rangePicker('opacityPalettePicker transg', 0, 1, .1) +
                '<span class="ugc-cancel" style="margin-left: 8px;">' + i18next.t("cancel") + '</span>' +
                '</div>'
        }).on('inserted.bs.popover', function () {
            $(popSel + " .opacityPalettePicker").bind('input', function () {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    assignVarFromPath(self, vars.paletteOpacityProp, $(popSel + ' .opacityPalettePicker').val());
                    self._refresh(kind);
                }
            });

            $('#' + self._pickerPaletteId).empty();
            $('#' + self._pickerPaletteId).append(self._generateColorPaletteInterpolateOptions());
            $('#' + self._pickerPaletteId).iconpickerggc({
                columns: 1,
                inheritOriginalWidth: true,
                itemHeight: '2em',
                labelTag: '<div/>',
                labelClass: 'gcBGDiv',
                labelBuilder: function (sel, d) {
                    // d.background_image="url(http://path.com/tstudio/bia/Magma.png)"  
                    var w1 = sel.parent().width();
                    var w2 = sel.parent().find('.button').width();
                    sel.css('width', w1 - w2);
                    if (d.background_image) {
                        sel.css('background-image', d.background_image);
                        sel.css('background-size', '100% 100%');
                        sel.text('');
                    } else {
                        sel.css('background-image', '');
                        sel.css('background-size', '');
                        sel.text(d.text);
                    }
                    sel.css('height', '2em');
                },
            });

            $('#' + self._pickerPaletteId).on('change', function (event) {
                if (args.hasOwnProperty('variables')) {
                    var vars = args.variables;
                    let idx = +$('#' + self._pickerPaletteId).val();
                    if (idx < biaColors._d3ColorsInterpolate.length) {
                        assignVarFromPath(self, vars.customPaletteProp, {});
                        assignVarFromPath(self, vars.paletteTypeProp, 'interpolate');
                        assignVarFromPath(self, vars.paletteIndexProp, idx);

                        $(popSel + ' .fillcolorpicker').each(function (index, element) {
                            var offset = parseFloat($(this).attr('data-goffset'));
                            var colr = biaColors._d3ColorsInterpolate[idx].interpolate(offset);
                            $(this).spectrum('set', colr);
                        });

                        //                    self[vars.customPaletteProp] = {};
                        //                    self[vars.paletteTypeProp] = 'interpolate';
                        //                    self[vars.paletteIndexProp] = idx; 
                    } else {
                        // Custom...                    
                    }
                }

                self._refresh(kind);
            });

            var sel = $('#' + self._pickerPaletteId).data('iconpickerggc');
            sel.elements.outerWrapper.css('margin-right', '4px');
            sel.elements.outerWrapper.css('display', 'inline-block');

            $(popSel + ' .fillcolorpicker').spectrum({
                allowEmpty: true,
                readonly: false,
                showInput: true,
                chooseText: i18next.t("save"),
                change: function (color) {
                    if (args.hasOwnProperty('variables')) {
                        var vars = args.variables;
                        if (resolveVarFromPath(self, vars.paletteTypeProp) !== 'gradient') {
                            assignVarFromPath(self, vars.paletteTypeProp, 'gradient');
                            assignVarFromPath(self, vars.customPaletteProp, {});
                            $('#' + self._pickerPaletteId).data('iconpickerggc').select(biaColors._d3ColorsInterpolate.length);
                        }
                        var pal = {};
                        // {0.25: 'red', 0.55: 'blue', etc.}
                        $(popSel + ' .fillcolorpicker').each(function (index, element) {
                            var offset = parseFloat($(this).attr('data-goffset'));
                            var cval = $(this).spectrum('get');
                            pal[offset] = cval ? cval.toHexString() : '#000000';
                        });
                        assignVarFromPath(self, vars.customPaletteProp, pal);

                        self._refresh(kind);
                    }
                }
            });

            $(popSel + ' .ugc-cancel').off('click').on('click', function () {
                $('#' + myId).popover('hide');
                self._restoreTempProps(kind);
            });

            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                self._saveTempProps(kind, vars);
                var cp = resolveVarFromPath(self, vars.paletteTypeProp); // self[vars.paletteTypeProp];
                var indx = resolveVarFromPath(self, vars.paletteIndexProp); // self[vars.paletteIndexProp];
                //var cust = resolveVarFromPath(self, vars.customPaletteProp); //self[vars.customPaletteProp];

                var icp = $('#' + self._pickerPaletteId).data('iconpickerggc');

                if (cp === 'interpolate') {
                    icp.select(indx);
                    $(popSel + ' .fillcolorpicker').each(function (index, element) {
                        var offset = parseFloat($(this).attr('data-goffset'));
                        var colr = biaColors._d3ColorsInterpolate[indx].interpolate(offset);
                        $(this).spectrum('set', colr);
                    });
                } else if (cp === 'gradient') {
                    // Clear .iconpickerggc
                    icp.select(biaColors._d3ColorsInterpolate.length);

                    var grad = resolveVarFromPath(self, vars.customPaletteProp);
                    $(popSel + ' .fillcolorpicker').each(function (index, element) {
                        var offset = parseFloat($(this).attr('data-goffset'));
                        $(this).spectrum('set', grad[offset]);
                    });
                }
                $(popSel + ' .opacityPalettePicker').val(resolveVarFromPath(self, vars.paletteOpacityProp)); // self[vars.paletteOpacityProp]);

            }
            if (typeof (args.insertedCallback) === 'function')
                args.insertedCallback(self, popSel);

        }).on('click', function (ev) {
            if (self.editMode) {
                $('#' + myId).popover('toggle');
                ev.stopPropagation();
            }
        }).on('show.bs.popover', function (foo) {
            if (self.$lastPopover) {
                self.$lastPopover.popover('hide');
            }
            self.$lastPopover = $('#' + myId);
            $(document).off('click.' + popSel.substring(1)).on('click.' + popSel.substring(1), function (ev) {
                // if popover is not in parents somewhere, hide
                if ($(ev.target).closest('.popover').length > 0)
                    return;

                $('#' + myId).popover('hide');
            });
        }).on('shown.bs.popover', function () {
            if (self.editMode) {    // this can happen  on new double click to edit
                var rt = document.getElementById(popSel.substring(1)).getBoundingClientRect();
                var ht = rt.bottom - rt.top;
                $(popSel + ' .mpalSlash').attr('height', ht);
            }
        }).on('hide.bs.popover', function () {
            if (typeof (args.hiddenCallback) === 'function')
                args.hiddenCallback(self, popSel);
            $(popSel + ' .fillcolorpicker').spectrum('hide');
            $(popSel).remove();
            self.$lastPopover = null;
            $(document).off('click.' + popSel.substring(1));
        });


    }
    _getLegendHide() {
    return '<svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 994.81 964.51"><defs><style>.d {fill: #f0f; stroke-width: 0px;}</style></defs><g id="c" data-name="a"><path class="d" d="M732.53,453.15L417.11,137.73c-17.57-17.57-17.57-46.07,0-63.64l60.91-60.91c17.57-17.57,46.07-17.57,63.64,0l439.97,439.97c17.57,17.57,17.57,46.07,0,63.64l-434.55,434.55c-17.57,17.57-46.07,17.57-63.64,0l-60.91-60.91c-17.57-17.57-17.57-46.07,0-63.64l310.01-310.01c17.57-17.57,17.57-46.07,0-63.64h-.01Z"/><rect class="d" y="44.54" width="358.8" height="176.89" rx="45" ry="45"/><rect class="d" y="393.81" width="607.9" height="176.89" rx="45" ry="45"/><rect class="d" y="743.09" width="362.41" height="176.89" rx="45" ry="45"/></g></svg>';
    }
    _getLegendShow() {
        return '<svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 995.81 964.52"><defs><style>.d {fill: #f0f;stroke-width: 0px;}</style></defs><g id="c" data-name="Layer 1"><path class="d" d="M667.21,453.15l315.42-315.42c17.57-17.57,17.57-46.07,0-63.64l-60.91-60.91c-17.57-17.57-46.07-17.57-63.64,0l-439.97,439.97c-17.57,17.57-17.57,46.07,0,63.64l434.55,434.55c17.57,17.57,46.07,17.57,63.64,0l60.91-60.91c17.57-17.57,17.57-46.07,0-63.64l-310.01-310.01c-17.57-17.57-17.57-46.07,0-63.64Z"/><rect class="d" x="0" y="393.76" width="358.8" height="176.89" rx="45" ry="45"/><rect class="d" x="1" y="44.57" width="607.9" height="176.89" rx="45" ry="45"/><rect class="d" x=".98" y="742.48" width="607.9" height="176.89" rx="45" ry="45"/></g></svg>';
    }
    _getRightArrow() {
        return '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.004 492.004" ><g><path d="M20,0 h490 a20,20 0 0 1 20,20 v490 a20,20 0 0 1 -20,20 h-490 a20,20 0 0 1 -20,-20 v-490 a20,20 0 0 1 20,-20 z" fill="#50E3C2"/><path fill="white" d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12 c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028 c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265 c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/></g></svg>';
//        return '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.004 492.004" ><g><path d="M20,0 h490 a20,20 0 0 1 20,20 v490 a20,20 0 0 1 -20,20 h-490 a20,20 0 0 1 -20,-20 v-490 a20,20 0 0 1 20,-20 z" fill="#F000B4"/><path fill="white" d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12 c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028 c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265 c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/></g></svg>';
    }
    _getLeftArrow() {
        return '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492 492" ><g><path d="M20,0 h490 a20,20 0 0 1 20,20 v490 a20,20 0 0 1 -20,20 h-490 a20,20 0 0 1 -20,-20 v-490 a20,20 0 0 1 20,-20 z" fill="#50E3C2"/><path fill="white" d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"/></g></svg>';
        //return '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492 492" ><g><path d="M20,0 h490 a20,20 0 0 1 20,20 v490 a20,20 0 0 1 -20,20 h-490 a20,20 0 0 1 -20,-20 v-490 a20,20 0 0 1 20,-20 z" fill="#F000B4"/><path fill="white" d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"/></g></svg>';
    }
}

//graphBaseGGC.prototype = new paletteGGC();


// self.markers[3].outline =   resolveVarFromPath(self, 'markers.3.outline')
export function resolveVarFromPath(obj, path) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj)
}

export function assignVarFromPath(obj, path, val) {
    var ar = path.split('.');
    var prev = obj;
    for (let i=0; i<ar.length-1; i++) {
        if (!prev.hasOwnProperty(ar[i])) {
            if (ar[i+1].match(/[0-9]+/)) {
                prev[ar[i]] = [];
            } else {
                prev[ar[i]] = {};
            }
        }
        prev = prev[ar[i]];
    }
    prev[ar[ar.length-1]] = val;
}





