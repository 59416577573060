import { API } from "aws-amplify"
import { 
	campaignByProject, 
	campaignByClient,
	questionByProject,
	projectByClient, 
	apiDashboardBySub,
	apiDashboardByClient,
 } from '../queries';
 import { isDeveloperDomain, setApiKey } from "./Utils";
import toast from "react-hot-toast";
import i18next from 'i18next';
import { colors, dateInYyyyMmDdHhMmSs } from "./Utils";

export const listCampaignsForProject = async (id, nextToken = null, items = []) => {
	try {
		const res = await API.graphql({query: campaignByProject, variables: {projectID: id, nextToken: nextToken}});
		let campaigns = res.data.campaignByProject;
		items = [...items, ...campaigns.items.map(item => ( {id: item.id, name: item.name }))];

		if (campaigns.nextToken) {
			return listCampaignsForProject(id, campaigns.nextToken, items);
		} else {
			return items;
		}

		} catch (err) {
			console.log('listCampaignsForProject', err);
			return null;
		}
	}
	export const listCampaignsForClient = async (id, nextToken = null, items = []) => {

	try {
		const res = await API.graphql({ query: campaignByClient, variables: { clientID: id, nextToken: nextToken } });
		let camps = res.data.campaignByClient;
		items = [...items, ...camps.items.map(item => ({ id: item.id, name: item.name, projectID: item.projectID }))];

		if (camps.nextToken) {
			return listCampaignsForClient(id, camps.nextToken, items);
		} else {
			return items;
		}

	} catch (err) {
		console.log('listCampaignsForClient', err);
		return null;
	}
}
export const listQuestionsForProject = async (id, nextToken = null, items = []) => {

	try {
		const res = await API.graphql({ query: questionByProject, variables: { projectID: id, nextToken: nextToken } });
		let quests = res.data.questionByProject;
		items = [...items, ...quests.items.map(item => ({ id: item.id, name: item.text, projectID: item.projectID }))];

		if (quests.nextToken) {
			return listQuestionsForProject(id, quests.nextToken, items);
		} else {
			return items;
		}

	} catch (err) {
		console.log('listQuestionsForProject', err);
		return null;
	}
}

export const listProjects = async (id, nextToken = null, items = []) => {

	try {
	const res = await API.graphql({query: projectByClient, variables: {clientID: id, nextToken: nextToken}});
	let projects = res.data.projectByClient;
	items = [...items, ...projects.items.map(item => ( {id: item.id, name: item.name }))];

	if (projects.nextToken) {
		return listProjects(id, projects.nextToken, items);
	} else {
		return items;
	}

	} catch (err) {
		console.log('listProjects', err);
		return null;
	}
}
export const listResponseQA = async (id, clientId) => {
	try {
		const rqas = await API.get('apiDashboard', `/v1/rqa/campaign/${id}?clientId=${clientId}`);
		if (rqas.success) {
			// List of responseQuestionAnswers objs (id, questionAnswers). Sort by questionAnswers.location
			rqas.data.sort((a, b) => {
				if (a.questionAnswers.location.name < b.questionAnswers.location.name) {
					return -1;
				} else if (a.questionAnswers.location.name > b.questionAnswers.location.name) {
					return 1;
				}
				return 0;
			});

			return rqas.data;
		}
	} catch (err) {
		console.log('listResponseQA', err);
	}
	return null;
}

export const deleteTreeViewRQA = (rqa, oldTree) => {
	let cid = rqa.campaignID;
	let tree = JSON.parse(JSON.stringify(oldTree));
	let cFound = tree.children.flatMap(proj => proj.children).find(camp => camp.id === cid);
	if (cFound && rqa.questionAnswers.location) {
		let locFound = cFound.children.find(loc => loc.id === rqa.questionAnswers.location.id);
		if (locFound) {	// location found. is this id already there?
			let qIx = locFound.children.findIndex(q => q.id === rqa.id);
			if (qIx !== -1) {
				locFound.children.splice(qIx, 1);
			}
		}
	}
	return tree;
}

export const updateTreeViewRQA = (rqa, oldTree) => {
	let cid = rqa.campaignID;
	let tree = JSON.parse(JSON.stringify(oldTree));

	let cFound = tree.children.flatMap(proj => proj.children).find(camp => camp.id === cid);
	if (cFound) {
		let locFound = cFound.children.find(loc => loc.id === rqa.questionAnswers.location.id);
		if (locFound) {	// location found. is this id already there?
			let qFound = locFound.children.find(q => q.id === rqa.id);
			if (qFound) {
				qFound.name = rqa.questionAnswers.question.text;
				qFound.answers = rqa.questionAnswers.answers.map((ans) => {
					let rVal = {};
					if (ans.text) {
						rVal.text = ans.text;
					}
					if (ans.imageUrl) {
						rVal.imageUrl = ans.imageUrl;
					}
					return rVal;
				})
			} else {	// new one..
				locFound.children.push({
					id: rqa.id,				// rqa.questionAnswers.question.id,
					name: rqa.questionAnswers.question.text,
					answers: rqa.questionAnswers.answers.map((ans) => {
						let rVal = {};
						if (ans.text) {
							rVal.text = ans.text;
						}
						if (ans.imageUrl) {
							rVal.imageUrl = ans.imageUrl;
						}
						return rVal;
					}),
					children: [],
					leaf: true,
				})
			}
		} else {
			cFound.children.push({
				id: rqa.questionAnswers.location.id,
				name: rqa.questionAnswers.location.name,
				children: [
					{
						id: rqa.id,		// rqa.questionAnswers.question.id,
						name: rqa.questionAnswers.question.text,
						answers: rqa.questionAnswers.answers.map((ans) => {
							let rVal = {};
							if (ans.text) {
								rVal.text = ans.text;
							}
							if (ans.imageUrl) {
								rVal.imageUrl = ans.imageUrl;
							}
							return rVal;
						}),
						children: [],
						leaf: true,
					}
				]
			});
		}
	}	// if the campaign is not found, that would be weird given you have to create a campaign *before* you create an rqa
	  // so, we don't need to handle that case - it would get handled in the subscription to campaigns.
	return tree;
}

const ITEM_FG = "#ffffff";

export const getCompleteTreeView = async (clientId) => {
	let tree = {
    id: "projects",
    name: 'Projects',
		style: {backgroundColor: colors.primary, color: ITEM_FG, width: '100%'},
    children: []
	}

	const projects = await listProjects(clientId);
	if (projects && projects.length > 0) {
		tree.children = projects.map((proj) => {
			return {
				id: proj.id,
				name: proj.name,
				children: [
					{
						id: "questions",
						name: i18next.t("uc_questions"),
						style: {backgroundColor: colors.primary, color: ITEM_FG, width: '100%'},
						children: []
					},
					{
						id: "campaigns",
						name: i18next.t("uc_campaigns"),
						style: {backgroundColor: colors.primary, color: ITEM_FG, width: '100%'},
						children: []
					},
				]
			}
		});
		// listQuestionsForProject and put in children[0].children
		for (let i=0; i<tree.children.length; i++) {
			let pid = tree.children[i].id;
			let quests = await listQuestionsForProject(pid);
			if (quests && quests.length > 0) {
				tree.children[i].children[0].children = quests.map((quest) => {
					return {
						id: quest.id,
						type: 'question',
						name: quest.name,
						children: [],
						leaf: true,
					}
				});
			}
		}

		const campaigns = await listCampaignsForClient(clientId);
		if (campaigns && campaigns.length > 0) {
			campaigns.forEach((camp) => {
				let proj = tree.children.find((p) => {
					return p.id === camp.projectID;
				}
				);
				if (proj) {		// proj.children are 'Question' and 'Campaigns'
					proj.children[1].children.push({
						id: camp.id,
						type: 'campaign',
						name: camp.name,
						children: []
					});
				}
			}
			);

			for (let i = 0; i < campaigns.length; i++) {
				let cid = campaigns[i].id;
				let respQA = await listResponseQA(cid, clientId);
				if (respQA && respQA.length > 0) {
					for (let j = 0; j < respQA.length; j++) {
						let rqa = respQA[j];

						let cFound = tree.children.flatMap(proj => proj.children[1].children).find(camp => camp.id === cid);
						if (cFound) {
							let locFound = cFound.children.find(loc => loc.id === rqa.questionAnswers.location.id);
							if (locFound) {
								locFound.children.push({
									id: rqa.id,				// rqa.questionAnswers.question.id,
									name: rqa.questionAnswers.question.text,
									type: 'assignment',
									answers: rqa.questionAnswers.answers.map((ans) => {
										let rVal = {};
										if (ans.text) {
											rVal.text = ans.text;
										}
										if (ans.imageUrl) {
											rVal.imageUrl = ans.imageUrl;
										}
										return rVal;
									}),
									children: [],
									leaf: true,
								})
							} else {
								cFound.children.push({
									id: rqa.questionAnswers.location.id,
									type: 'location',
									name: rqa.questionAnswers.location.name,
									children: [
										{
											id: rqa.id,		// rqa.questionAnswers.question.id,
											name: rqa.questionAnswers.question.text,
											type: 'assignment',
											answers: rqa.questionAnswers.answers.map((ans) => {
												let rVal = {};
												if (ans.text) {
													rVal.text = ans.text;
												}
												if (ans.imageUrl) {
													rVal.imageUrl = ans.imageUrl;
												}
												return rVal;
											}),
											children: [],
											leaf: true,
										}
									]
								});
							}

						}
					}
				}
			}
		}
	}

	return tree;
}

export const deleteThemeRecord = async (id, clientId) => {

	try {
		if (isDeveloperDomain()) {
			const res = await API.del('apiDashboard', `/v1/dashtheme/${id}?clientId=${clientId}`);

			if (res.success) {
				return true;
			} else if (res.error) {
				toast.error(res.error);
			}
		}
	} catch (err) {
		console.log(err);
	}
	return false;
}

export const deleteDashboardRecord = async (id, clientId) => {

	try {
		if (isDeveloperDomain()) {
			const res = await API.del('apiDashboard', `/v1/dashboarddelete/${id}?clientId=${clientId}`);

			if (res.success) {
				return true;
			} else if (res.error) {
				toast.error(res.error);
			}
		}
	} catch (err) {
		console.log(err);
	}
	return false;
}

export const getDashboardRecord = async (id) => {
	try {
		let res;
		if (isDeveloperDomain()) {
			res = await API.get('apiDashboard', `/v1/dashboardget/${id}`);
		} else {
			res = await API.get('apiDashboard', `/v1/dashboard/${id}`);
		}

		if (res.success) {
			return res.data;
		} else if (res.error) {
			toast.error(res.error);
		}
	} catch (err) {
		console.log('getDashboardRecord error', err);
	}
	return null;
}

export const updateDashboardRecord = async (dbId, clientId, dashboardName, baseProjs, toSave) => {

	try {
		const baseIds = baseProjs.map((bp) => {
			return bp.baseId;
		});
		const projectIds = baseProjs.map((bp) => {
			return bp.projectId;
		});

		const res = await API.put('apiDashboard', `/v1/dashboardupdate/${dbId}`, {
			body: {
				clientId: clientId,
				name: dashboardName,
				baseIds: baseIds,
				projectIds: projectIds,
				props: toSave,
			}
		})

		console.log('updateDashboardRecord res: ', res)
		return res;
	} catch (err) {
		console.log('updateDashboardRecord error: ', err)
		return {error: err};
	}
}

export const createDashboardRecord = async (clientId, dashboardName, baseProjs, toSave) => {

	try {
		const baseIds = baseProjs.map((bp) => {
			return bp.baseId;
		});
		const projectIds = baseProjs.map((bp) => {
			return bp.projectId;
		});

		const res = await API.post('apiDashboard', '/v1/dashboardcreate', {
			body: {
				clientId: clientId,
				name: dashboardName,
				baseIds: baseIds,
				projectIds: projectIds,
				props: toSave,
			}
		})

		console.log('createDashboardRecord res: ', res)
		return res;
	} catch (err) {
		console.log('createDashboardRecord error: ', err)
		return {error: err};
	}
}
	
export const getMyDashboardKey = async (csub) => {
	try {
		const res = await API.get('apiDashboard', `/v1/dashboardkey/${csub}`);
		if (res.success) {
			setApiKey(res.data);
			return res.data;
		}
	} catch (err) {
		console.log('getMyDashboardKey error', err);
	}
	return null;
}

export const getAPIDashboards = async (sub, nextToken = null, items = []) => {

//	try {
		const res = await API.graphql({
			query: apiDashboardBySub,
			variables: { sub: sub, nextToken: nextToken },
			filter: {
				recordStatus: {
					eq: "active"
				}
			}
		});

		let list = res.data.apiDashboardBySub;
		items = [...items, ...list.items.map(item => ({ 
			id: item.id, 
			name: item.name, 
			username: item.username, 
			updatedAt: item.updatedAt, 
			projectID: item.projectID }))];

		if (list.nextToken) {
			return getAPIDashboards(sub, list.nextToken, items);
		} else {
			return items;
		}

		/*
	} catch (err) {
		console.log('getAPIDashboards', err);
		return null;
	}
	*/
}

export const getDashboardTheme = async (clientId, themeId) => {
	try {
		const res = await API.get('apiDashboard', `/v1/dashtheme/${themeId}/?cid=${clientId}`);
		if (res.success) {
			return res.data;
		} else {
			throw res.error;
		}
	} catch (err) {
		console.log('getDashboardTheme', err);
		throw err;
	}
}

// saveAs sets throwError to false so that it returns an empty list
export const getDashboardThemesByClient = async (clientId, throwError) => {
	try {
		const res = await API.get('apiDashboard', `/v1/dashthemes/?cid=${clientId}`);

		if (res.success) {
			if (throwError && res.data.length === 0) {
				throw new Error(i18next.t("error.no_themes_found"));
			} else {
				return res.data;
			}
		} else {
			throw res.error;
		}
	} catch (err) {
		console.log('getDashboardThemesByClient', err);
		throw err;
	}
}

export const getAPIDashboardsByClient = async (clientId, nextToken = null, items = []) => {

		try {
			const res = await API.graphql({
				query: apiDashboardByClient,
				variables: { clientID: clientId, nextToken: nextToken },
				filter: {
					recordStatus: {
						eq: "active"
					}
				}
			});
	
			let list = res.data.apiDashboardByClient;
			items = [...items, ...list.items.map(item => ({ 
				id: item.id, 
				sub: item.sub, 
				name: item.name, 
				username: item.username, 
				updatedAt: item.updatedAt, 
				projectID: item.projectID }))];
	
			if (list.nextToken) {
				return getAPIDashboardsByClient(clientId, list.nextToken, items);
			} else {
				return items;
			}
	
		} catch (err) {
			console.log('getAPIDashboards', err);
			throw err;
		}
	}
	

// return:  [{id: <clientId>, name: <company or name>}...]
export async function getClientNamesFromList(clientIds) {
	try {
		// change to dashboard...replicated in apiMaint
		var response = await API.post('apiDashboard', `/v1/clients`,
			{
				body: clientIds
			});

		if (response.success) {
			var clist = [];
			for (var ix=0; ix<response.clients.length; ix++) {
				var name = response.clients[ix].name;
				if (response.clients[ix].hasOwnProperty('company')) {
					name = response.clients[ix].company;
				}
				clist.push( {id: response.clients[ix].id, name: response.clients[ix].owner ? i18next.t("lbl.owner", {name: name}) : name, owner: response.clients[ix].owner});
			}
			return clist;
		}
	}
	catch (e) {
		console.log('getClientNamesFromList', e);
	}

	return null;
		/*
	return [
		{id: "2583ae83-ec67-41c0-a837-f277e8440328", name:"Gordo's Client"},
		{id: "1c52c529-60b6-420c-b27d-a380efeec6d0", name:"SOFIT Client"}
	]
	*/
}


export const updateThemeRecord = async (
	clientId, name, oid, containerProps, axisProps, baseProps, paletteProps, base64Img
) => {

	try {
		const res = await API.put('apiDashboard', `/v1/dashtheme/${oid}`, {
			body: {
				clientId: clientId,
				id: oid,
				name: name,
				containerProps: containerProps,
				axisProps: axisProps,
				baseProps: baseProps,
				paletteProps: paletteProps,
				img: base64Img,
			}
		})

		console.log('updateThemeRecord res: ', res)
		return res;
	} catch (err) {
		console.log('updateThemeRecord error: ', err)
		return {error: err};
	}
}

export const createThemeRecord = async (
	clientId, name, oid, containerProps, axisProps, baseProps, paletteProps, base64Img
) => {

	try {

		const res = await API.post('apiDashboard', '/v1/dashthemecreate', {
			body: {
				clientId: clientId,
				name: name,
				containerProps: containerProps,
				axisProps: axisProps,
				baseProps: baseProps,
				paletteProps: paletteProps,
				img: base64Img,
			}
		})

		console.log('createThemeRecord res: ', res)
		return res;
	} catch (err) {
		console.log('createThemeRecord error: ', err)
		return {error: err};
	}
}
	