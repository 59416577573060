import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';
import reportWebVitals from './reportWebVitals';
import { MyAuthStorage } from './utils/MyAuthStorage';
import { Authenticator } from '@aws-amplify/ui-react';
import {isDeveloperDomain, getRootDomain, setRootDomainFromHref} from "./utils/Utils";
import amplifyConfig from './amplify-config';
import amplifyConfigDev from './amplify-config-dev';
import './localization/i18n-instance';
import i18n from "i18next";
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MyPreview } from './components/DnD/DnDLabel';

const root = ReactDOM.createRoot(document.getElementById('root'));

setRootDomainFromHref(window.location.href);

i18n.changeLanguage();

var rtDomain;
// eslint-disable-next-line no-restricted-globals
rtDomain = getRootDomain();

if (isDeveloperDomain()) {
  if (rtDomain === 'qr-contest.com') {
    amplifyConfigDev.userPoolWebClientId = '1u249p34vildta74i4ovvtcg2j';
  }
  Amplify.configure(amplifyConfigDev);

  Auth.configure({
    storage: MyAuthStorage,
    clientMetadata: {
      domain: rtDomain,
    }
  });
} else {
  Amplify.configure(amplifyConfig);
}


if (isDeveloperDomain()) {
  /*
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  */
  // https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode

  root.render(
    <Authenticator.Provider>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <App />
        <MyPreview />
      </DndProvider>
    </Authenticator.Provider>
  );
} else {
  root.render(
    <App />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
