import { memo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { DnDLabel } from './DnDLabel'
import { useTranslation } from 'react-i18next';
import { ItemTypes } from './DnDItemTypes';

export const dropAreaPadding = 10;

const style = {
  height: '98px',
  width: '100px',
  marginRight: '16px',
  marginBottom: '8px',
  color: 'white',
  padding: `${dropAreaPadding}px`,
  textAlign: 'center',
  lineHeight: 'normal',
}
export const DropArea = memo(function DropArea({
  border='1px solid #CECECE',
  accept,
  droppedItems,
  onDrop,
}) {
	const containerRef = useRef(null);

	const [t] = useTranslation();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (item, monitor) => {
			let yOff = monitor.getClientOffset().y - containerRef.current.getBoundingClientRect().top;
			onDrop(item, monitor.getDifferenceFromInitialOffset(), yOff);
		},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = '#FFFFFF'
  if (isActive) {
    backgroundColor = '#E8E8E8'
  } else if (canDrop) {
    backgroundColor = '#F8F8F8'
  }
  return (
		<div ref={containerRef} style={{position: 'relative'}}>
    <div ref={drop} style={{ ...style, border: border, backgroundColor }} data-testid="droparea">
			{droppedItems && droppedItems.map((itm, ix) => {
				return (
					<DnDLabel
						key={ix}
						name={itm.name}
						type={ItemTypes.LABEL}
					/>
				)

			})}

    </div>
		</div>
  )
})
