const amplifyConfigDev = {
	"aws_appsync_graphqlEndpoint": "https://sy35eokcbzcrvjomxqrx3xgawy.appsync-api.us-east-1.amazonaws.com/graphql",
	"aws_appsync_region": "us-east-1",
	"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
	Auth: {
		identityPoolId: 'us-east-1:3979054d-467b-45ba-8191-8db0ea28cf5f',
		region: 'us-east-1',
		userPoolId: 'us-east-1_xSAVqgaTw',
		userPoolWebClientId: '6p42gd6c5v9faltc0fs1hslfnr',
		mandatorySignIn: false,
	},
	API: {
    endpoints: [
      {
        name: "apiDashboard",
        endpoint: "https://286543zore.execute-api.us-east-1.amazonaws.com/devp"
      },
      {
        name: "apiPDF",
        endpoint: "https://zcspi4rpw6.execute-api.us-east-1.amazonaws.com/devp"
      }
    ]
  }
}
export default amplifyConfigDev;