import * as d3 from 'd3';
import $ from "jquery";
import { graphBaseGGC, biaColors } from './graphbaseggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

const tinycolor = require("tinycolor2");

// Didn't use this, but good source for padding: https://d3-graph-gallery.com/graph/treemap_custom.html
export class treemapGGC extends graphBaseGGC{
    constructor(args) {
        super(args);

        this.hierPalette = null;

        this.format = undefined;
        this.ixValue = undefined;
        this.hierarchyData = undefined;

        this._title = undefined;
        this._rect = undefined;
        this._clipPath = undefined;
        this._text = undefined;

        this.hierFont = this.font;
        this.hierFontColor = '#FFFFFF';
        this.hierFontStyle = '';
        this.padding = 1;
        this._italicSkewDegrees = 20;

        this.minVal = 0;        // set later.
        this.maxVal = 1;

        this.backgroundColor = '#27344f'.toColorRef();
        this.viewBoxAdjustHeight = 0;


        this._init(args);
    }
    getGraphType() {
        return  'treemap';
    }
    _init(args) {
        //this._base_init(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var base = self._base_saveProps();
        var me = {
            hierPalette: self.hierPalette,
            hierFont: self.hierFont,
            hierFontColor: self.hierFontColor,
            hierFontStyle: self.hierFontStyle,
            hierDomain: self.hierDomain,
            padding: self.padding,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight
        };

        return { ...base, ...me };
    }
    _initVariables(args) {
        if (args.hasOwnProperty('hierPalette'))
            this.hierPalette = args.hierPalette;

        if (!Array.isArray(this.hierPalette)) {
            this.hierPalette = null;
        }

        if (this.hierPalette == null) {
            this.hierPalette = [];
            var me = { type: 'interpolate', index: biaColors._d3ColorsInterpolateDefaultIx, opacity: 0.7, gradient: {} };
            this.hierPalette.push(me);
        }
        if (args.hasOwnProperty('hierFont'))
            this.hierFont = args.hierFont;
        if (args.hasOwnProperty('hierFontColor'))
            this.hierFontColor = args.hierFontColor;
        if (args.hasOwnProperty('hierFontStyle'))
            this.hierFontStyle = args.hierFontStyle;
        if (args.hasOwnProperty('hierDomain'))
            this.hierDomain = args.hierDomain;
        if (args.hasOwnProperty('padding'))
            this.padding = args.padding;
        
        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }
    }
    // Overrides base class
    setData(opts, dimhier) {

        if (opts.hasOwnProperty('valueNames')) {
            this.valueNames = opts.valueNames;
            this.color.domain(this.valueNames);
        }

        if (opts.hasOwnProperty('categoryNames')) {
            this.categoryNames = opts.categoryNames;
            this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City         

        }
        
        this.tableName = opts.tableName;

        this.data = null;

        this.format = d3.format(",d");

        if (opts.hasOwnProperty('ajax')) {
            this.ajax = opts.ajax;
        }
        else
            this._setData(dimhier);

    }
    upCaseFirst(str) {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return '';
        }
    }
    _setData(dimhier) {
        this.hierarchyData = dimhier;
    }
    uid(spre) {
        let id = this._genId();
        return spre + id;
    }
    resize(opts) {
        var self = this;

        let width, height;
        let gonnaDraw = true;

        // We need to check this because if we don't draw, then we've set the outerWidth/Height to something
        // from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                gonnaDraw = false;
            }
        }


        if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
            const vbCoords = this.viewBox.split(' ');
            width = parseInt(vbCoords[2]);
            height = parseInt(vbCoords[3]);
            this.svgWidth = opts.width;
            this.svgHeight = opts.height + this.viewBoxAdjustHeight;
        } else {
            width = opts.width;
            height = opts.height;
        }

        this.outerHeight = height;
        this.outerWidth = width;

        if (typeof (this.hierarchyData) === 'undefined' || !this.hierarchyData || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;
        if (self._pagingControl) {
            this.origHeight -= $(self._pagingControl).height();
            this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);

        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();

        this.margin.left = this.margin.top = this.margin.right = this.margin.bottom = 0;

        this.width = this.origWidth - this.margin.left - this.margin.right;
        this.height = this.origHeight - this.margin.top - this.margin.bottom;

        if (!isRuntimeOnly()) {
            this.viewBox = [0, 0, this.origWidth, this.origHeight].join(' ');
            this.svgWidth = this.origWidth;
            this.svgHeight = this.origHeight;
        }

        this.computeTreemap(true);

        this._refresh();

        this.checkEditMode();
    }
    computeTreemap(isNew) {
        let self = this;

        const childAccFn = ([ key, value ]) => value.size && Array.from(value)
        this.root = d3.hierarchy([null, self.hierarchyData], childAccFn)  // dimhier)
            .sum(([key, value]) => value)
            .sort((a, b) => b.value - a.value);

        self.minVal = d3.min(self.root.leaves(), d => d.data[1]/*.value*/);
        self.maxVal = d3.max(self.root.leaves(), d => d.data[1]/*.value*/);


        this.data = d3.treemap()
            .tile(d3.treemapSquarify)
            .size([self.width, self.height])
            .paddingTop(self._getFontSizeFromFont(self.hierFont) * 1.2)
            .paddingRight(this.padding)
            .paddingInner(this.padding)
            .round(true)(self.root);

        this.data.data[0] = this.upCaseFirst(this.categoryNames[0]);


        if (isNew) {
            this.svg = d3.select(this.container).append("svg")
                .attr("width", this.svgWidth)
                .attr("height", this.svgHeight)
                .attr('viewBox', this.viewBox)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .style('font', this.font);
        } else {
            this.svg.empty();
        }

       self.diagram = self.svg.append("g")

    }
    reCalc(kind) {
        this._refresh(kind);
    }
    refresh() {
        
        if (!this.root.hasOwnProperty('children')) {
            return;
        }

        this._colorDomainSet();

        this._fixhierPalette();

        this.computeTreemap(false);

        this._drawTreeMap();

    }
    wrapText(selection, fontSize) {  
        selection.each(function () {
          const node = d3.select(this);
          const rectWidth = +node.attr('data-width');
          let word;
          const words = node.text().split(' ').reverse();
          let line = [];
          const x = node.attr('x');
          const y = node.attr('y');
          let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
          let lineNumber = 0;
          while (words.length > 1) {
            word = words.pop();
            line.push(word);
            tspan.text(line.join(' '));
            const tspanLength = tspan.node().getComputedTextLength();
            if (tspanLength > rectWidth && line.length !== 1) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = addTspan(word);
            }
          }
          
          addTspan(words.pop());
      
          function addTspan(text) {
            lineNumber += 1;
            return (
              node
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', `${lineNumber * fontSize}px`)
                .text(text)
            );
          }
        });
      }
     

    _drawTreeMap() {
        var self = this;

        if (this._title)
            this._title.remove();
        if (this._rect)
            this._rect.remove();
        if (this._clipPath)
            this._clipPath.remove();
        if (this._text)
            this._text.remove();
        if (this._grptitles)
            this._grptitles.remove();
        if (this._vals)
            this._vals.remove();
    
        if (!this.root.hasOwnProperty('children')) {
            return;
        }

        var opacity = d3.scaleLinear()
            .domain([self.minVal, self.maxVal])
            .range([.5,1])

        this._rect = self.diagram
            .selectAll("rect")
            .data(self.root.leaves())
            .enter()
            .append("rect")
              .attr('x', function (d) { return d.x0; })
              .attr('y', function (d) { return d.y0; })
              .attr('width', function (d) { return d.x1 - d.x0; })
              .attr('height', function (d) { return d.y1 - d.y0; })
              .style("stroke", "black")
              .style("fill", function(d){ return self.color(d.data[0]/*.name*/)} )
              .style("opacity", function(d){ return opacity(d.data[1]/*.value*/)})

  
        let fntHeight = self._getFontSizeFromFont(self.hierFont);
              // and to add the text labels
         this._text = self.diagram
            .selectAll("text")
            .data(self.root.leaves())
            .enter()
            .append("text")
                .attr('data-width', function (d) { return d.x1 - d.x0; })
              .attr("x", function(d){ return d.x0+5})    // +10 to adjust position (more right)
              .attr("y", function(d){ return d.y0+fntHeight})    // +20 to adjust position (lower)
              .text(function(d){ return d.data[0]/*.name*/ })
              .style("font", self.hierFont)
              .style("fill",self.hierFontColor)
              .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
              .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
              .call(self.wrapText, fntHeight)
          
          // and to add the text labels
         this._vals = self.diagram
            .selectAll("vals")
            .data(self.root.leaves())
            .enter()
            .append("text")
            .attr('data-width', function (d) { return d.x1 - d.x0; })
            .attr("x", function(d){ return d.x0+5})    // +10 to adjust position (more right)
              .attr("y", function(d){ return d.y0+fntHeight*2})    // +20 to adjust position (lower)
              .text(function(d) { 
                return d.data[1]/*.value*/;
            })
              .style("font", self.hierFont)
              .style("fill",self.hierFontColor)
              .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
              .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
              .call(self.wrapText, fntHeight)
          
          // Add title for the groups
         this._grptitles = self.diagram
            .selectAll("titles")
            .data(self.root.descendants().filter(function(d){return d.depth<=(self.categoryNames.length-1)}))
            .enter()
            .append("text")
              .attr("x", function(d){ return d.x0+5})
              .attr("y", function(d){ return d.y0+fntHeight})
              .text(function(d){ return d.data[0]/*.name*/ })
              .style("fill",self.hierFontColor)
              .style("font", self.hierFont)
              .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
              .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');
          
    }
    _drawTreeMapHier() {
        var self = this;

        if (this._title)
            this._title.remove();
        if (this._rect)
            this._rect.remove();
        if (this._clipPath)
            this._clipPath.remove();
        if (this._text)
            this._text.remove();
        if (this._grptitles)
            this._grptitles.remove();
        if (this._vals)
            this._vals.remove();
        if (this._nodes)
            this._nodes.remove();

        this._nodes = self.svg.selectAll("g")
            .data(self.root.descendants())
            .enter()
            .append('g')
            .attr('transform', function(d) {return 'translate(' + [d.x0, d.y0] + ')'})
          
          this._nodes
            .append('rect')
            .attr('width', function(d) { return d.x1 - d.x0; })
            .attr('height', function(d) { return d.y1 - d.y0; })
            .attr("fill", d => { 
                while (d.depth > 1) 
                    d = d.parent; 
                //return "url(#" + self.customPalette.colorRef[0].ref + ")";
                return self.color(d.data[0]/*.name*/); 
            })
            .attr("fill-opacity", self.hierPalette[0].opacity)

          
          this._nodes
            .append('text')
            .attr('dx', 4)
            .attr('dy', 14)
            .text(function(d) {
              return d.data[0]/*.name*/;
            })
            .style("fill",self.hierFontColor)
            .style("font", self.hierFont)
            .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
            .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

    }
    _drawTreeMapOld() {
        var self = this;

        if (this._title)
            this._title.remove();
        if (this._rect)
            this._rect.remove();
        if (this._clipPath)
            this._clipPath.remove();
        if (this._text)
            this._text.remove();
        if (this._grptitles)
            this._grptitles.remove();

        // NOTE, the below slice(2) assumes the 'base' name of the data is dash '-', so it would start with '-/CA ...'
        this._title = this.diagram.append("title")
            .text(d => `${d.ancestors().reverse().map(d => d.data[0]/*.name*/).join("/").slice(2)}\n${self.format(d[1]/*.value*/)}`);

        // the problem here is if we have fat outline, the fill underlaps it, so we need to draw the outline rectangle
        // then fill it 'inside' those dimensions
        this._rect = this.diagram.append("rect")
            .attr("id", d => (d.leafUid = self.uid("leaf")).id)
            .attr("fill", d => { 
                while (d.depth > 1) 
                    d = d.parent; 
                //return "url(#" + self.customPalette.colorRef[0].ref + ")";
                return self.color(d.data[0]/*.name*/); 
            })
            .attr("fill-opacity", self.hierPalette[0].opacity)
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        this._clipPath = this.diagram.append("clipPath")
            .attr("id", d => (d.clipUid = self.uid("clip")).id)
            .append("use")
            .attr("href", d => d.leafUid.href);    // xlink


        this._grptitles = this.diagram.append("text")
        .attr("clip-path", d => d.clipUid)
        .attr("text-anchor", "start")
        .attr("x", 3)
        .attr("y", (d, i, nodes) => `-1em`)
        .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
        .text(function(d) {
            while (d.depth > 1) 
                d = d.parent;
            return d.data[0]/*.name*/;
        })
        .style("fill",function(d){ return self.color(d.data[0]/*.name*/)})
        .style("font", self.hierFont)
        .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
        .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

            // 2024 - add 'value' name to the end of the text
            // no name here. check that.
        this._text = this.diagram.append("text")
            .attr("clip-path", d => d.clipUid)
            .selectAll("tspan")
            .data(d => d.data && d.data[0]/*.name*/ ? d.data[0]/*.name*/.split(/(?=[A-Z][^A-Z])/g).concat(self.format(d[1]/*.value*/)) : '')
            .join("tspan")
            .attr("text-anchor", "start")
            .attr("x", 3)
            .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 2.1 + i * 0.9}em`)
            .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
            .text(d => d)
            .style("fill",self.hierFontColor)
            .style("font", self.hierFont)
            .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
            .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

        if (this.hierFontStyle.indexOf('I') !== -1) {
            this.diagram.selectAll('tspan')
                .style("font-style", "italic");
        }


    }
    _fixhierPalette() {
        var self = this;

        if (this.data.hasOwnProperty('children')) {
            // adding type === 'custom' check on hierPalette lets the editor override it
            if (this.hierPalette[0].type === 'custom' && this.colorPaletteType === 'custom') {
                const kids = this.hierDomain;   // set via useImagesAsFill in GraphComponent
                let custColors = [];
                for (let i=0; i<kids.length; i++) {
                    if (self.customPalette.colorRef[i].loc) {
                        custColors.push( 'url(#' + self.customPalette.colorRef[i].ref + ')' );
                    } else {
                        custColors.push( self.customPalette.colorRef[i].rgb );
                    }
                }
                this.color = d3.scaleOrdinal()
                    .domain(kids)
                    .range(custColors)
                    /*
                opacity = d3.scaleLinear()    in our case for votes, we don't have children..
                        .domain([minval, maxval])
                        .range([0.5, 1])
                */

            } else if (this.hierPalette[0].type === 'interpolate') {
                let qtz = d3.quantize(
                    biaColors._d3ColorsInterpolate[this.hierPalette[0].index].interpolate,
                    this.data.children.length + 1);
                this.color = d3.scaleOrdinal(qtz);  
                /* seems we should d3.scaleOrdinal()
                    .domain(["boss1", "boss2", "boss3"])
                    .range([...colors...])
                    then opacity = d3.scaleLinear()    in our case for votes, we don't have children..
                        .domain([minval, maxval])
                        .range([0.5, 1])
                */
            }
            else if (this.hierPalette[0].type === 'gradient') {
                self.gradientStops = this.hierPalette[0].gradient;
                self.gradientData = null; // to reset
                var gd = self.getGradientData();
                var interp = function (d) {
                    // 0= gd[0], gd[1], gd[2]
                    var ix = 4 * Math.floor(d * 255);
                    return new tinycolor({ r: gd[ix], g: gd[ix + 1], b: gd[ix + 2] }).toHexString();
                };
                this.color = d3.scaleOrdinal(
                    d3.quantize(
                        interp,
                        this.data.children.length + 1)
                );
            } 
        }

    }
    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }
    _drawCircleEditPaddingWidth(args) {
        var self = this;

        var sContent = self._rangePicker('paddingWidthPicker', 0, 20, 1) + 
            self._slashDiv();

        args.insertContentBefore = sContent;

        args.insertedCallback = function (me, popSel) {
            if (args.hasOwnProperty('variables')) {
                var vars = args.variables;
                $(popSel + ' .paddingWidthPicker').bind('input', function () {
                    self[vars.paddingWidthProp] = +$(popSel + ' .paddingWidthPicker').val();
                    self.reCalc(args.kind);
                });

                $(popSel + ' .paddingWidthPicker').val(self[vars.paddingWidthProp]);
            }
        };

        self._drawCircleEditColor(args);
    }

    checkEditMode() {
        var self = this;
        if (self.editMode) {
            var x = 0;
            var y = self.origHeight / 2;

            self._drawCircleEditPaddingWidth({       // _drawCircleEditColor({
                selector: self.svg,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.padding_background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    paddingWidthProp: 'padding',
                    colorRefProp: 'backgroundColor'
                }

            });

            x += (2 * self.origWidth / 3);
            y = 40;
            self._drawCircleEditPalette({
                selector: self.svg,
                kind: 'treem',
                title: i18next.t("graph.edit.treemap_fill"),
                x: x,
                y: y,
                r: 12,
                class: 'treeEditCircs',
                placement: 'auto',
                ordinal: 0,
                variables: {
                    paletteTypeProp: 'hierPalette.0.type',
                    paletteIndexProp: 'hierPalette.0.index',
                    customPaletteProp: 'hierPalette.0.gradient',
                    paletteOpacityProp: 'hierPalette.0.opacity'
                }
            });

            x = 40;
            y = 20;
            self._drawCircleEditText({
                selector: self.svg,
                kind: 'treelbl',
                label: null,
                title: i18next.t("graph.edit.tree_labels"),
                drawLabel: false,
                x: x,
                y: y,
                r: 12,
                placement: 'top',
                variables: {
                    fontProp: 'hierFont',
                    styleProp: 'hierFontStyle',
                    colorProp: 'hierFontColor'
                }
            });

        }
    }
}

//treemapGGC.prototype = new graphBaseGGC();

