import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Container = styled.dialog`
  min-width: 400px;
  border-radius: 4px;
  border: 1px solid #F000B4;
  width: 80%;
  height: 75%;

  ::backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
`;
const SmallContainer = styled.dialog`
  min-width: 400px;
  border-radius: 4px;
  border: 1px solid #F000B4;
  width: 80%;
  height: 25%;

  ::backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
`;


const isClickInsideRectangle = (e /*: MouseEvent*/, element /*: HTMLElement*/) => {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
};

/*
type Props = {
  title: string;
  isOpened: boolean;
  onProceed: () => void;
  onClose: () => void;
  children: React.ReactNode;
};
*/

const DialogModal = ({
  title,
  buttons,
  isOpened,
  onProceed,
  onClose,
  children,
  isSmall=false,
}/*: Props*/) => {
  const ref = useRef/*<HTMLDialogElement>*/(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (isOpened) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
  }, [isOpened]);

	/*
  const proceedAndClose = () => {
    onProceed();
    onClose();
  };
	*/

  const renderContent = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
          <h4>{title}</h4>
        </div>

        {children}

        <div style={{ marginTop: 16, display: 'flex', marginLeft: 'auto', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', width: 'fit-content' }}>
            {buttons && buttons.length > 0 && buttons.map((button, i) => {
              return (
                <button key={i} className="button-34" style={{
                  width: `${100 / buttons.length}%`,
                  marginLeft: 8,
                  marginRight: 8,
                  whiteSpace: 'nowrap'

                }} onClick={button.onClick}>{button.label}</button>
              )
            })}
            {(!buttons || buttons.length === 0) &&
              <button className="button-34" style={{ marginLeft: 'auto' }} onClick={onClose}>{t("cancel")}</button>
            }
          </div>

        </div>
      </div>
    )
  }

  if (isSmall) {
    return (
      <SmallContainer
        ref={ref}
        onCancel={onClose}
        onClick={(e) =>
          ref.current && !isClickInsideRectangle(e, ref.current) && onClose()
        }
      >
        {renderContent()}
      </SmallContainer>
    );
  } else {
    return (
      <Container
        ref={ref}
        onCancel={onClose}
        onClick={(e) =>
          ref.current && !isClickInsideRectangle(e, ref.current) && onClose()
        }
      >
        {renderContent()}
      </Container>
    );
  }
};

export default DialogModal;