import * as d3 from 'd3';
import $ from "jquery";
import { graphBaseGGC } from './graphbaseggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

export class circlepackGGC extends graphBaseGGC {
	constructor(args) {
		super(args);

		this.radius = undefined;
		this.format = undefined;
		this.ixValue = undefined;

		this._path = undefined;
		this._label = undefined;
		this._parent = undefined;
		this.hierFont = this.font;
		this.hierFontColor = '#FFFFFF';
		this.hierFontStyle = '';

		this.outerCircleBackground = '#DDDDDD'.toColorRef();
		this.viewBoxAdjustHeight = 0;

		this._init(args);
	}
	getGraphType() {
		return 'circlepack';
	}
	_init(args) {
		this._initVariables(args);
	}
	_saveProps() {
		var self = this;

		var base = self._base_saveProps();
		var me = {
			hierFont: self.hierFont,
			hierFontColor: self.hierFontColor,
			hierFontStyle: self.hierFontStyle,
			hierDomain: self.hierDomain,
			outerCircleBackground: self.outerCircleBackground,
			viewBoxAdjustHeight: self.viewBoxAdjustHeight,
		};

		return { ...base, ...me };
	}
	_initVariables(args) {

		// Put any if (args.hasOwnProperty('prop')) checks here...
		if (args.hasOwnProperty('hierFont'))
			this.hierFont = args.hierFont;
		if (args.hasOwnProperty('hierFontColor'))
			this.hierFontColor = args.hierFontColor;
		if (args.hasOwnProperty('hierFontStyle'))
			this.hierFontStyle = args.hierFontStyle;
		if (args.hasOwnProperty('outerCircleBackground'))
			this.outerCircleBackground = args.outerCircleBackground;
		if (args.hasOwnProperty('hierDomain'))
			this.hierDomain = args.hierDomain;

		if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
			this.viewBox = args.viewBox;
			if (args.hasOwnProperty('viewBoxAdjustHeight')) {
				this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
			}
		}

	}
    // Overrides base class
	setData(opts, dimhier) {

		if (opts.hasOwnProperty('categoryNames')) {
			this.categoryNames = opts.categoryNames;
			this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City                
		}

		if (opts.hasOwnProperty('extraNames')) {
			this.extraNames = opts.extraNames;
		}

		/*
		if (opts.hasOwnProperty('valueNames')) {
			this.valueNames = opts.valueNames;
			this.color.domain(this.valueNames);
		}
		*/


		if (!opts.hasOwnProperty('tableName'))
			throw new Error('tablename is required');
		this.tableName = opts.tableName;


		if (opts.hasOwnProperty('ajax')) {
			this.ajax = opts.ajax;
		}
		else
			this._setData(dimhier);

		this.format = d3.format(",d");

	}
	_setData(dimhier) {
			
			this.hierarchyData = dimhier;

			const childAccFn = ([ key, value ]) => value.size && Array.from(value)
			const root = d3.hierarchy([null, dimhier], childAccFn)  // dimhier)
					.sum(([key, value]) => value)
					.sort((a, b) => b.value - a.value);
			this.data = d3.partition()
					.size([2 * Math.PI, root.height + 1])(root);

	}
	resize(opts) {
		var self = this;


		let width, height;
		let gonnaDraw = true;

		// We need to check this because if we don't draw, then we've set the outerWidth/Height to something
		// from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
		if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
				if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
						gonnaDraw = false;
				}
		}


		if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
				const vbCoords = this.viewBox.split(' ');
				width = parseInt(vbCoords[2]);
				height = parseInt(vbCoords[3]);
				this.svgWidth = opts.width;
				this.svgHeight = opts.height + this.viewBoxAdjustHeight;
		} else {
				width = opts.width;
				height = opts.height;
		}


		this.outerHeight = height;
		this.outerWidth = width;

		if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
			if (this.ajax) {
				this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
			}
			if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
				return;
			}
		}

		this.origWidth = self.outerWidth;
		this.origHeight = self.outerHeight;
		if (self._pagingControl) {
			this.origHeight -= $(self._pagingControl).height();
			this.viewBoxAdjustHeight = -$(self._pagingControl).height();
		}
		//2023 GGC $(this.outerContainer).height(this.outerHeight);

		if (this.hasOwnProperty('svg') && this.svg)
			this.svg.remove();

		this.margin.left = this.margin.top = this.margin.right = this.margin.bottom = 0;
		this.margin.right = 50;

		this.width = this.origWidth - this.margin.left - this.margin.right;
		this.height = this.origHeight - this.margin.top - this.margin.bottom;

		self.pack = d3.pack()
			.size([this.width, this.height])
			.padding(3);

		if (!isRuntimeOnly()) {
			this.viewBox = [0, 0, this.origWidth, this.origHeight].join(' ');
			this.svgWidth = this.origWidth;
			this.svgHeight = this.origHeight;
		}

		this.svg = d3.select(this.container).append("svg")
			.attr("width", this.svgWidth)
			.attr("height", this.svgHeight)
			.attr('viewBox', this.viewBox)
			.attr("preserveAspectRatio", "xMidYMid meet")
			.style('font', this.hierFont)
			.attr('text-anchor', 'middle');


		// Take the 1st tier of names and make that the domain
		this.setupColorDomainFromHierarchy();

		this._colorDomainSet(['outerCircleBackground']);
		this._fillBackground(this.svg);


		this.refresh();

		//        this._base_makeLegend();
		this.checkEditMode();


	}

	setupColorDomainFromHierarchy() {

		let self = this;

		if (!this.data.hasOwnProperty('children')) {
			return;
		}

		let newBaseNames = [];
		for (let i = 0; i < this.data.children.length; i++) {
			newBaseNames.push(this.data.children[i].data[0]/*.name*/);
		}

		// 1st, if exists, adjust valueStatus to match baseNames.  So, if we have valueStatus
		// [1, 1, .025] and current baseNames are ['midtown', 'beltline', 'river street']
		// and we are moving to ['midtown', 'river street'] - we need to align the valueStatus to be
		// [1, 0.25]
		if (self.valueStatus && self.baseNames) {
			let newValueStatus = [];
			for (let i = 0; i < newBaseNames.length; i++) {
				let ixOld = self.baseNames.findIndex((itm) => itm === newBaseNames[i])
				if (ixOld !== -1) {
					newValueStatus.push(self.valueStatus[ixOld]);
				}
			}
			self.valueStatus = newValueStatus;
		}

		this.baseNames = newBaseNames;
		this.color.domain(newBaseNames);
	}

	_drawCirclePack() {
		var self = this;

		if (self._title)
			self._title.remove();
		if (self._circle)
			self._circle.remove();
		if (self._text)
			self._text.remove();
		if (self._rects)
			self._rects.remove();

		if (!this.data.hasOwnProperty('children')) {
			return;
		}

		self._title = this.diagram.append('title')
			.text(d => `${d.ancestors().map(d => d.data[0]/*.name*/).reverse().join("\n")}\n${i18next.t("graph.lbl.votes")} ${this.format(d.value)}`);

		self._circle = this.diagram.append('circle')
			.attr("class", d => {
				return d.depth === 1 ? 'innerCircle' : ''
			})
			.attr("fill", d => {
				if (d.depth === 0) {
					if (self.outerCircleBackground.rgb)
						return self.outerCircleBackground.rgb;

					else
						return 'url(#' + self.outerCircleBackground.ref + ')';
				}
				while (d.depth > 1)
					d = d.parent;
				let ix = self.baseNames.indexOf(d.data[0]/*.name*/);
				return self._getFillColor(ix);
//				return self.color(d.data[0]/*.name*/);
			})
			.attr("fill-opacity", function (d) {
				if (d.depth === 0) {
					return self.outerCircleBackground.opacity;
				} else {
					while (d.depth > 1)
						d = d.parent;
					let ix = self.baseNames.indexOf(d.data[0]/*.name*/);
					return self._getOpacity(ix);
				}
			})
			.attr("stroke", d => d.children ? "#bbb" : null)
			.attr("r", d => d.r);

		// Add a label to leaf nodes.
		this._text = this.diagram
			.filter(d => !d.children && d.r > 10)
			.append("text")
			.style("fill", self.hierFontColor)
			.style("font", self.hierFont)
			.style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
			.attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
			.attr("clip-path", d => `circle(${d.r})`);

		// Add a tspan for each CamelCase-separated word.
		this._text.selectAll()
			.data(d => {
				return d.data[0]/*.name*/.split(/(?=[A-Z][a-z])|\s+/g)
			})
			.join("tspan")
			.attr("x", 0)
			.attr("y", (d, i, nodes) => `${i - nodes.length / 2 + 0.35}em`)
			.text(d => d)
			.style("fill", self.hierFontColor)
			.style("font", self.hierFont)
			.style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
			.attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');


		// Add a tspan for the node’s value.			
		this._text.append("tspan")
			.attr("x", 0)
			.attr("y", d => `${d.data[0]/*.name*/.split(/(?=[A-Z][a-z])|\s+/g).length / 2 + 0.35}em`)
			.attr("fill-opacity", 0.7)
			.text(d => this.format(d.data[1]/*.value*/));

	}

	getFilteredData() {
		let self = this;

		let filteredData = this.data;

		// self.baseNames line up with self.valueStatus. e.g.
		// ['Soup Dumplings', 'Nashville Hot Chicken', 'Pulled Pork Nachos']
		// [0.25, 0.5, 0.5]   - we clicked on the legend for Nashvill Hot Chicken, so it changes the
		// opacity value to 0.25 (hidden).  So, filter that out here.
		if (self.valueStatus && self.baseNames && self.valueStatus.length === self.baseNames.length) {
				let filtered = false;
				for (let i = 0; i < self.valueStatus.length; i++) {
						if (self.valueStatus[i] === this._hiddenOpacity) {
								filtered = true;
						}
				}

				if (filtered) {
						let filteredDim = new d3.InternMap(self.hierarchyData);
						for (let i = 0; i < self.valueStatus.length; i++) {
								if (self.valueStatus[i] === this._hiddenOpacity) {
										filteredDim.delete(self.baseNames[i]);
								}
						}

						const childAccFn = ([key, value]) => value.size && Array.from(value)
						const root = d3.hierarchy([null, filteredDim], childAccFn)  // dimhier)
								.sum(([key, value]) => value)
								.sort((a, b) => b.value - a.value);

						filteredData = d3.partition()
								.size([2 * Math.PI, root.height + 1])(root);
				}

		}
		return filteredData;
}

	refresh() {
		let self = this;

		if (!this.data.hasOwnProperty('children')) {
			return;
	}

		if (this.diagram)
			this.diagram.remove();

		this.root = self.pack(this.getFilteredData())

		this.diagram = this.svg.append('g')
			.selectAll()
			.data(this.root.descendants())
			.join('g')
			.attr('transform', d => `translate(${d.x},${d.y})`);

		this.setupColorDomainFromHierarchy();

		this._colorDomainSet(['outerCircleBackground']);
		this._fillBackground(this.svg);

		this._base_makeLegend({ resize: true });

		this._drawCirclePack();
	}
	toggleEdit() {
		var self = this;
		return self._toggleEdit();
	}
	checkEditMode() {
		var self = this;

		if (self.editMode) {
			let x = 20;
			let y = 30;
			self._drawCircleEditColor({
				selector: self.svg,
				kind: 'bgcolor',
				title: i18next.t("graph.edit.background"),
				x: x,
				y: y,
				r: 12,
				placement: 'right',
				variables: {
					colorRefProp: 'backgroundColor'
				}

			});

			x = self.width / 2;
			self._drawCircleEditColor({
				selector: self.svg,
				kind: 'bgcolor',
				title: i18next.t("graph.edit.circle_background"),
				x: x,
				y: y,
				r: 12,
				placement: 'right',
				variables: {
					colorRefProp: 'outerCircleBackground'
				}

			});

			// find a circle of  class 'insideCircle'
			let circ = self.svg.select('.innerCircle');
			// find parent <g> of that circle, extract transform translate
			let pg = circ.node().closest('g');
			let t = pg.getAttribute('transform');
			// parse out x,y
			let xy = t.split(',');
			let px = parseInt(xy[0].split('(')[1]);
			let py = parseInt(xy[1].split(')')[0]);

			x = px;
			y = py;
			self._drawCircleEditText({
				selector: self.svg,
				kind: 'sunlbl',
				label: null,
				title: i18next.t("graph.edit.labels"),
				drawLabel: false,
				x: x,
				y: y,
				r: 12,
				placement: 'top',
				disable: ['italic'],
				variables: {
					fontProp: 'hierFont',
					styleProp: 'hierFontStyle',
					colorProp: 'hierFontColor'
				}
			});
			self._baseEditMode();

		}
	}
}

