import { useEffect, useState } from 'react';
import CircleLetter from '../CircleLetter/CircleLetter';
import { _genId } from '../../graphs/domutils';
import { colors } from '../../utils/Utils';
import {txtColor, txtHighlightColor, bgColor, bgHighlightColor} from '../constants'

// pass itemm.value = null if it is a diver/label
const UserMenu = ({onSelect, username, menuList}) => {
	const [expanded, setExpanded] = useState(false);
	const [pos, setPos] = useState({top: 30, right: 90});


	const menuId = 'um' + _genId();

	const handleUserClick = (e) => {
		// e.pageX, e.pageY
		if (!expanded) {
			setPos({top: e.pageY, right: window.innerWidth - e.pageX});
		}
		setExpanded(!expanded);
		e.stopPropagation();
	}

	const handleClick = (e) => {
		const ele = document.getElementById(menuId);
		if (ele && ele.contains(e.target)){
			// Clicked in box
		} else if (expanded) {
			setExpanded(false);
		}
	}
	
	useEffect(() => {
		if (expanded) {
			window.addEventListener('click', handleClick);
			return () => {
				window.removeEventListener('click', handleClick);
			}
		}
	}, [expanded]);

	const onLocalSelect = (value) => {
		setExpanded(false);
		if (value) {
			onSelect(value);
		}
	}
	
	const mouseEnter = (e) => {
		e.target.style.backgroundColor = bgHighlightColor;
		e.target.style.color = txtHighlightColor;
		e.target.style.fontWeight = 'bold';
	}
	const mouseLeave = (e) => {
		e.target.style.backgroundColor = bgColor;
		e.target.style.color = txtColor;
		e.target.style.fontWeight = 'normal';
	}

	return (
		<>
			<CircleLetter onClick={handleUserClick} style={{ cursor: 'pointer' }} char={username.substring(0, 1).toUpperCase()} backgroundColor={colors.primary} textColor={'white'} />
			{expanded &&
				<div id={menuId} style={{
					display: 'flex', flexDirection: 'column', alignItems: 'center',
					position: 'absolute', top: pos.top, right: pos.right,
					backgroundColor: bgColor, border: '1px solid #ccc', borderRadius: 5,
					paddingTop: 5, paddingBottom: 5,
					width: 140, zIndex: 1
				}}>
					{menuList.map((item, i) => (
						<div key={i} style={{
							color: txtColor,
							backgroundColor: bgColor,
							paddingLeft: 20,
							paddingRight: 20,
							width: '100%',
							cursor: 'pointer',
							fontSize: '.9em'
						}}
							onMouseEnter={item.value ? mouseEnter : null}
							onMouseLeave={item.value ? mouseLeave : null}
							onClick={() => onLocalSelect(item.value)}>
							{item.label}
						</div>
					))}
				</div>
			}
		</>
	)

}

export default UserMenu;