import React, {useImperativeHandle, useRef, forwardRef, useState, useEffect} from "react";
import $ from 'jquery';
import { Rnd } from "react-rnd";
import './assets/css/rnd.css';
import {isDeveloperDomain} from "./utils/Utils";
import * as d3 from 'd3';
import { NodeBuilderFlags } from "typescript";

var selectedHandles = {
	topLeft: "resizer top-left",
	topRight: "resizer top-right",
	bottomLeft: "resizer bottom-left",
	bottomRight: "resizer bottom-right",
	left: "resizer-left",
	right: "resizer-right",
	top: "resizer-top",
	bottom: "resizer-bottom",
};
// gridProps is an array of size, lockedAspect, etc. for each child. However, the
// size is in percentage of overall space, so need to translate to pixels
const QRGridLayout = forwardRef(({style, graphComponents, cascadeRefresh, developer, 
	events, resizable, draggable, gridProps, notifyContainer=()=>null}, ref) => {
	useImperativeHandle(ref, () => ({
		resetAll() {
			// loop throu setrefs and call resetAll
			if (setRefs && setRefs.size > 0) {
				for (let i = 0; i < setRefs.size; i++) {
					setRefs.get(i).resetAll();
				}
				setRefs.clear();
			}
		},
		saveProps() {
			// childProps[i].size x, y, width, height are in pixels. make them percentages
			// of the container width and height
			var newProps = childProps.map((child, i) => {
				var newChild = JSON.parse(JSON.stringify(child));
				newChild.size.x = child.size.x / dimensions.width;
				newChild.size.y = child.size.y / dimensions.height;
				newChild.size.width = child.size.width / dimensions.width;
				newChild.size.height = child.size.height / dimensions.height;
				newChild.saveProps = setRefs.get(i).saveProps();
				newChild.baseId = graphComponents[i].props.baseId;
				newChild.leafType = child.leafType;
				newChild.projectId = graphComponents[i].props.projectId;
				return newChild;
			});


			return newProps;
		},
		getOuterId() {
			if (selectedItem !== -1)
				return setRefs.get(selectedItem).getOuterId();
			else
				return '';
		},
		getContainerPropsOnly() {
			if (selectedItem !== -1)
				return setRefs.get(selectedItem).getContainerPropsOnly();
			else
				return {};
		},
		getAxisPropsOnly() {
			if (selectedItem !== -1)
				return setRefs.get(selectedItem).getAxisPropsOnly();
			else
				return {};
		},
		getBasePropsOnly() {
			if (selectedItem !== -1)
				return setRefs.get(selectedItem).getBasePropsOnly();
			else
				return {};
		},
		getPalettePropsOnly() {
			if (selectedItem !== -1)
				return setRefs.get(selectedItem).getPalettePropsOnly();
			else
				return {};
		},
		applyThemes(item) {
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).applyThemes(item);
			}
		},
		toggleSelectedEditMode() {
			if (selectedItem !== -1) {
				let isEdit = setRefs.get(selectedItem).toggleEdit();
				if (isEdit) {
					setDerivedDraggable(false);
					setDerivedResizable(false);
				} else {
					setDerivedDraggable(draggable);
					setDerivedResizable(resizable);
				}
			}
		},
		getSelectedIsEditing() {
			if (selectedItem !== -1) {
				return setRefs.get(selectedItem).getEditMode();
			}
			return false;
		},
		useImagesAsFill() {
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).useImagesAsFill();
			}
		},
		setGridSize(width, height) {		// 0=disable, 0.1=10%, 0.25=25% max is .25 (4 quads)
			if (width === 0 || height === 0) {
				setGridStopHeight(0);
				setGridStopWidth(0);
			} else if (width >= 1 && width <= 25 && height > 1 && height < 25) {
				setGridStopHeight(height/100);
				setGridStopWidth(width/100);
			}
		},
		getPivot() {
			if (selectedItem !== -1) {
				return setRefs.get(selectedItem).getPivot();
			}
			return false;
		},
		getCellBackgroundColor() {
			if (selectedItem !== -1) {
				return setRefs.get(selectedItem).getCellBackgroundColor();
			}
			return '#FFFFFF';
		},
		setCellBackgroundColor(color) {
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).setCellBackgroundColor(color);
			}
		},
		getProperty(arProps) {
			if (selectedItem !== -1) {
				return setRefs.get(selectedItem).getProperty(arProps);
			} else {
				return {};
			}
		},
		setProperty(hasNV) {
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).setProperty(hasNV);
			}
		},
		setSelectedItem(ix) {
			selectItemLocal(ix);
		},
		manualSizeChange(ix, size) {
			let oldSC = [...sizeWasChanged];
			let found = oldSC.findIndex((sc) => sc._uid === childProps[ix]._uid);
			if (found !== -1) {
				oldSC[found].size = size;
			} else {
				oldSC.push({_uid: childProps[ix]._uid, size: size});
			}
			setSizeWasChanged(oldSC);
		},
		autoLayout() {
			// dimensions.height .width are size
			if (!childProps || childProps.length === 0) return;

			if (childProps.length > 1) {
				let nitems = childProps.length;
				let hierarchyData = {name: '-', children:[]};
				for (let i=0; i<nitems; i++) {
					hierarchyData.children.push({name: `${i}`, value: 1})
				}
				let root = d3.hierarchy(hierarchyData)
					.sum(d => d.value)
					.sort((a, b) => b.value - a.value);

				let data = d3.treemap()
				.tile(d3.treemapSquarify)
				.size([dimensions.width, dimensions.height])
				.paddingInner(10)
				.round(true)(root);
				let newCProps = [...childProps];
				for (let i=0; i<data.children.length; i++) {
					const node = data.children[i];
					newCProps[i].size.x = node.x0;
					newCProps[i].size.y = node.y0;
					newCProps[i].size.width = node.x1 - node.x0;
					newCProps[i].size.height = node.y1 - node.y0;
					newCProps[i].manualChange = true;
					repositionChild(i, newCProps[i]);	
				}
				setChildProps(newCProps);


			}
		},
		isDataEmpty() {
			if (selectedItem !== -1) {
				return setRefs.get(selectedItem).isDataEmpty();
			} else {
				return false;
			}
		},
		generateSampleData(){
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).generateSampleData();
			}		
		},

	}));

	const containerRef = useRef(null);
	const setRefs = useRef(new Map()).current;
	const [timeStamp, setTimeStamp] = useState(0.0);
	const [selectedItem, setSelectedItem] = useState(-1);	// index of sel item
	const [childProps, setChildProps] = useState([]);	// index of sel item
	const [dimensions, setDimensions] = useState({width: 0, height: 0});
	const [lastUsedDimensions, setLastUsedDimensions] = useState({width: 0, height: 0});
	const [gridRenders, setGridRenders] = useState({});
	const [gridStopWidth, setGridStopWidth] = useState(.05);		// percentages
	const [gridStopHeight, setGridStopHeight] = useState(.05);
	const [derivedDraggable, setDerivedDraggable] = useState(developer && draggable);
	const [derivedResizable, setDerivedResizable] = useState(developer && resizable);
	const [renderableChildren, setRenderableChildren] = useState([]);
	const [sizeWasChanged, setSizeWasChanged] = useState([]);

	useEffect(() => {
		if (containerRef.current) {
				setDimensions({
					width: containerRef.current.offsetWidth,
					height: containerRef.current.offsetHeight
				});
		}
	}, [graphComponents, cascadeRefresh]);

	/*
	const iWasResize = () => {
		if (lastUsedDimensions.width !== dimensions.width || lastUsedDimensions.height !== dimensions.height) {
			return true;
		} else {
			return false;
		}
	}
	*/

useEffect(() => {
	if (dimensions.width && dimensions.height) {
		if (gridProps) {

			let newCs;

			const gpIds = childProps.map((ce) => ce._uid);
			const spIds = gridProps.map((sp) => sp._uid);
			const toRemove = gpIds.filter((gpId) => !spIds.includes(gpId));
			const toAdd = spIds.filter((spId) => !gpIds.includes(spId));
			if (toRemove.length === 0 && toAdd.length === 0) {

				//if (iWasResize())
				 {
					// This is the case where we are getting resized, but *may* have already been manually resized in this
					// uncontrolled component, so we need to update childProps to reflect the new dimensions
					// So, find x, y, width, height as percentages of lastUsedDimensions and then multiply by new dimensions
					const newChildProps = childProps.map((child, i) => {
						let myProps = {
							_uid: child._uid,
							baseId: child.baseId,
							manualChange: child.manualChange,
							lockAspectRatio: child.lockAspectRatio,
							leafType: child.leafType,
							size: {
								x: child.size.x / lastUsedDimensions.width * dimensions.width,
								y: child.size.y / lastUsedDimensions.height * dimensions.height,
								width: child.size.width / lastUsedDimensions.width * dimensions.width,
								height: child.size.height / lastUsedDimensions.height * dimensions.height,
							}
						};
						return myProps;
					});
					setLastUsedDimensions({ width: dimensions.width, height: dimensions.height });
					setChildProps(newChildProps);
				}
	
				return;
			}
			newCs = [...childProps];
			let gPropsIx = [];
			if (toRemove.length > 0) {
				for (let i = 0; i < newCs.length; i++) {
					if (toRemove.includes(newCs[i]._uid)) {
						gPropsIx.push(i);
					}
				}
			}
			for (let i = gPropsIx.length - 1; i >= 0; i--) {
				newCs.splice(gPropsIx[i], 1);
			}
			// now, add new ones
			for (let i = 0; i < toAdd.length; i++) {
				const ix = gridProps.findIndex((sp) => sp._uid === toAdd[i]);
				const gridProp = gridProps[ix];
				let myProps = {
					_uid: gridProp._uid,
					baseId: gridProp.baseId,
					manualChange: gridProp.manualChange || false,
					lockAspectRatio: gridProp.lockAspectRatio || false,
					leafType: gridProp.leafType || 'assignment',
					size: {
						x: gridProp.size.x * dimensions.width,
						y: gridProp.size.y * dimensions.height,
						width: gridProp.size.width * dimensions.width,
						height: gridProp.size.height * dimensions.height,
					}
				};
				newCs.push(myProps);
			}
			setLastUsedDimensions({ width: dimensions.width, height: dimensions.height });
			setChildProps(newCs);

		}
	}

}, [graphComponents, dimensions]);

useEffect(() => {
	if (sizeWasChanged && sizeWasChanged.length > 0) {
		let newCProps = [...childProps];
		let hasChange = false;
		for (let i=0; i<sizeWasChanged.length; i++) {
			let changedC = newCProps.findIndex((cp) => cp._uid === sizeWasChanged[i]._uid);
			if (changedC !== -1) {
				hasChange = true;
				newCProps[changedC].size.x = sizeWasChanged[i].size.x * dimensions.width;
				newCProps[changedC].size.y = sizeWasChanged[i].size.y * dimensions.height;
				newCProps[changedC].size.width = sizeWasChanged[i].size.width * dimensions.width;
				newCProps[changedC].size.height = sizeWasChanged[i].size.height * dimensions.height;
			}
		}
		if (hasChange) {
			setChildProps(newCProps);
		}

		setSizeWasChanged([]);
	}

}, [sizeWasChanged]);

	// we have graphComponents and gridProps (parallel) on _uid
	
	useEffect(() => {
		let rends;
		if (gridRenders) {
			const gpIds = Object.keys(gridRenders);
			const spIds = childProps.map((sp) => sp._uid);
			const toRemove = gpIds.filter((gpId) => !spIds.includes(gpId));
			const toAdd = spIds.filter((spId) => !gpIds.includes(spId));
			if (toRemove.length === 0 && toAdd.length === 0) {
				// ChildProps changed, but no add/remove.  Just update the gridRenders
				rends = {...gridRenders};
				for (let i=0; i<childProps.length; i++) {
					rends[childProps[i]._uid] = rends[childProps[i]._uid] + 1;
				}
				setGridRenders(rends);
				return;
			}
			rends = {...gridRenders};
			for (let i=0; i<toRemove.length; i++) {
				delete rends[toRemove[i]];
			}
			for (let i=0; i<toAdd.length; i++) {
				rends[toAdd[i]] = Math.floor(Math.random() * 1000);
			}			
		} else {
			rends = childProps.reduce(function(map,obj) {
				map[obj._uid] = Math.floor(Math.random() * 1000)
				return map;
			}, {});
		}

		setGridRenders(rends);

	}, [childProps]);
	

	useEffect(() => {
		if (graphComponents && graphComponents.length > 0 && childProps && childProps.length === graphComponents.length) {
			let rcs;
			if (renderableChildren) {
				// see if we are adding new (which are in childProps but not in renderableChildren)
				const gpIds = renderableChildren.map((ce) => ce.props.uid);
				const spIds = childProps.map((sp) => sp._uid);
				const toRemove = gpIds.filter((gpId) => !spIds.includes(gpId));
				const toAdd = spIds.filter((spId) => !gpIds.includes(spId));
				if (toRemove.length === 0 && toAdd.length === 0) {
					// Loop through all renderableChildren and check [i].props.rerender against current value. if not same
					// then we need to update the renderableChildren
					let toRefresh = [];
					for (let i=0; i<renderableChildren.length; i++) {
						let uid = renderableChildren[i].props.uid;
						if (renderableChildren[i].props.rerender !== gridRenders[uid]) {
							toRefresh.push(i);
						}
					}
					if (toRefresh.length > 0) {
						rcs = [...renderableChildren];
						for (let i=0; i<toRefresh.length; i++) {
							const ix = childProps.findIndex((sp) => sp._uid === renderableChildren[toRefresh[i]].props.uid);
							const gridProp = childProps[ix];
		
							rcs[toRefresh[i]] = React.cloneElement(graphComponents[toRefresh[i]], {
								uid: gridProp._uid,
								cascadeRefresh: cascadeRefresh,
								rerender: gridRenders[gridProp._uid],
								notifyContainer: gridNotifyContainer,
								ref: node => {
									return !node
										? setRefs.delete(toRefresh[ix])
										: setRefs.set(toRefresh[ix], node);
								}
							});
						}
						setRenderableChildren(rcs);
					}


					return;
				}
				rcs = [...renderableChildren];
				let gPropsIx = [];
				if (toRemove.length > 0) {
					for (let i = 0; i < rcs.length; i++) {
						if (toRemove.includes(rcs[i].props.uid)) {
							gPropsIx.push(i);
						}
					}
				}
				// Need to update setRefs if we removed something... e.g. add 0, 1, 2. remove 1. need to set 2 to 1
				// In other words, whatever is left, just make them go 0...n-1
				for (let i = gPropsIx.length - 1; i >= 0; i--) {
					rcs.splice(gPropsIx[i], 1);
					setRefs.delete(gPropsIx[i]);
				}
				if (gPropsIx.length > 0) {
					// Need to linearize the keys of the setRefs.  e.g. {0: node, 1: node, 2: node} becomes {0: node, 1: node(formerly 2)} if we delete 2
					let vals = [];
					setRefs.forEach((v, k) => {
						vals.push(v);
					});
					setRefs.clear();
					for (let i=0; i<vals.length; i++) {
						setRefs.set(i, vals[i]);
					}
				}

				// now, add new ones
				for (let i = 0; i < toAdd.length; i++) {
					const ix = childProps.findIndex((sp) => sp._uid === toAdd[i]);
					const gridProp = childProps[ix];
					rcs.push(
						React.cloneElement(graphComponents[ix], {
							uid: gridProp._uid,
							rerender: gridRenders[gridProp._uid],	// rerender needs to be set 1st?
							cascadeRefresh: cascadeRefresh,
							notifyContainer: gridNotifyContainer,
							ref: node => {
								return !node
									? setRefs.delete(ix)
									: setRefs.set(ix, node);
							}
						})
					)
				}
	
			} else {
				rcs = graphComponents.map((child, i) => {
					return (
						React.cloneElement(child, {
							uid: childProps[i]._uid,
							rerender: gridRenders[childProps[i]._uid],
							cascadeRefresh: cascadeRefresh,
							notifyContainer: gridNotifyContainer,
							ref: node => {
								return !node
									? setRefs.delete(i)
									: setRefs.set(i, node);
							}
						})
					)
				})
			}

			setRenderableChildren(rcs);
		}

	}, [childProps, graphComponents, gridRenders]);

	const repositionChild = (i, newChild) => {
		let sizePercent = {
			x: newChild.size.x / dimensions.width,
			y: newChild.size.y / dimensions.height,
			width: newChild.size.width / dimensions.width,
			height: newChild.size.height / dimensions.height,
		};
		notifyContainer({type: "resize", index: i, size: sizePercent});
	}

	const gridNotifyContainer = (msg) => {
		switch (msg.type) {
			case "contextmenu":
				msg.index = selectedItem;
				notifyContainer(msg);
				break;
			default:
				notifyContainer(msg);
				break;
		}
	}

	const resizeChild = (i, newChild) => {
		let oldRends = {...gridRenders};
		oldRends[newChild._uid] += 1;
		setGridRenders(oldRends);
		let sizePercent = {
			x: newChild.size.x / dimensions.width,
			y: newChild.size.y / dimensions.height,
			width: newChild.size.width / dimensions.width,
			height: newChild.size.height / dimensions.height,
		};
		notifyContainer({type: "resize", index: i, size: sizePercent});
	};

	const selectItemLocal = (itm) => {

		if (selectedItem !== itm) {
			if (selectedItem !== -1) {
				setRefs.get(selectedItem).setSelected(false);
			}
			if (itm !== -1) {
				setRefs.get(itm).setSelected(true);
			}
		}

		let ix = itm;

		if (itm === -1) {
			notifyContainer({type: "hasImages", value: false});
		} else {
			let ansHasImg = setRefs.get(ix).getHasImages();
			notifyContainer({type: "hasImages", value: ansHasImg});
		}

		if (events && events['item.selected']) {
			if (itm === -1) {
				events['item.selected'](null, null, itm);
			} else {
				events['item.selected'](graphComponents[ix].props, setRefs.get(itm), itm);
			}
		}
		setSelectedItem(ix);
	}
	
	const clampX = (x) => {
		if (gridStopWidth === 0) return x;

		if (dimensions.width > dimensions.height) {
			const rndFactY = Math.round(gridStopHeight * dimensions.height);
			const mody = rndFactY * Math.round((x / rndFactY));
			return mody;
		}

		const rndFactX = Math.round(gridStopWidth * dimensions.width);
		const modx = rndFactX * Math.round((x / rndFactX));
		return modx;
	}
	const clampY = (y) => {
		if (gridStopHeight === 0) return y;

		if (dimensions.width < dimensions.height) {
			const rndFactX = Math.round(gridStopWidth * dimensions.width);
			const modx = rndFactX * Math.round((y / rndFactX));
			return modx;
		}
		const rndFactY = Math.round(gridStopHeight * dimensions.height);
		const mody = rndFactY * Math.round((y / rndFactY));
		return mody;
	}

	var ts;

	const resize_all = {
		bottom: true,
		bottomLeft: true,
		bottomRight: true,
		left: true,
		right: true,
		top: true,
		topLeft: true,
		topRight: true,
	};

	const resize_none = {
		bottom: false,
		bottomLeft: false,
		bottomRight: false,
		left: false,
		right: false,
		top: false,
		topLeft: false,
		topRight: false,
	};

	// This disables the nub on the button of the side resizers that overlayed into the circles
	$('.resizer-left').css('bottom', '10px');
	$('.resizer-left').css('height', '');
	$('.resizer-right').css('bottom', '10px');
	$('.resizer-right').css('height', '');
	// 2024-01-06 try to fix placement...
	$('.resizer-bottom').css('bottom', '0px');
	$('.resizer-right').css('right', '0px');
	$('.resizer-left').css('left', '0px');
	$('.resizer-top').css('top', '0px');
			
	var gridStyle = {};
	if (isDeveloperDomain()) {
		if (gridStopWidth > 0 && gridStopHeight > 0) {
			if (dimensions.width > dimensions.height) {
				gridStyle = {
					backgroundImage:
						`repeating-linear-gradient(#ffffff 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ffffff 0 1px, transparent 1px 100%)`,
					backgroundSize: `${Math.round(gridStopWidth * dimensions.height)}px ${Math.round(gridStopHeight * dimensions.height)}px`
				}
			} else {
				gridStyle = {
					backgroundImage:
						`repeating-linear-gradient(#ffffff 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ffffff 0 1px, transparent 1px 100%)`,
					backgroundSize: `${Math.round(gridStopWidth * dimensions.width)}px ${Math.round(gridStopHeight * dimensions.width)}px`
				}
			}
		}
	}

	// if all renderableChildren have a childProps entry, then we can render
	/*
	const renderableKidsHaveProps = () => {
		if (renderableChildren.length === 0) {
			return false;
		}

		for (let i=0; i<renderableChildren.length; i++) {
			const fnd = childProps.find((cp) => {
				return cp._uid === renderableChildren[i].props.uid;
			}
			);
			if (!fnd) {
				return false;
			}
		}
		return true;
	}
	*/
	return (
		<div ref={containerRef} onMouseDown={(ev) => {
			// Only do this if not in 'edit mode'. if in 'edit mode', could cancel it if click...
			if (selectedItem !== -1 && (setRefs && setRefs.get(selectedItem))) {
				if (setRefs.get(selectedItem).getEditMode()) {
					notifyContainer({type: "toggleEdit"});
				} else {
					selectItemLocal(-1);
				}
			}
		}}
			style={{
				width: '100%',
				height: '100%',
				...style,
				...gridStyle
			}}>
			<div style={{ width: '100%', height: '100%' }}>
				{renderableChildren.length > 0 && renderableChildren.map((child, ix) => {
					let i = childProps.findIndex((cp) => {
						return cp._uid === renderableChildren[ix].props.uid;
					}
					);
					if (i === -1) {
						return null;
					}
					
					return (
						<Rnd
							key={i}
							style={{zIndex: 1}}
							disableDragging={(!derivedDraggable)}
							enableResizing={(derivedResizable && i === selectedItem) ? resize_all : resize_none}
							resizeHandleClasses={i === selectedItem ? selectedHandles : {}}
							bounds={'parent'}
							size={{ width: childProps[i].size.width, height: childProps[i].size.height }}
							position={{ x: childProps[i].size.x, y: childProps[i].size.y }}
							onMouseDown={(ev) => {
								if (!derivedDraggable && !derivedResizable) {	// edit mode
									ts = ev.timeStamp;
									if (isDeveloperDomain() && ts - timeStamp < 350) {
										notifyContainer({type: "toggleEdit"});
									}
									setTimeStamp(ev.timeStamp);
									ev.stopPropagation();
								} else {
									ts = ev.timeStamp;
									if (isDeveloperDomain() && ts - timeStamp < 350) {
										notifyContainer({type: "toggleEdit"});
									}
									setTimeStamp(ev.timeStamp);
									selectItemLocal(i);
									if (typeof (TouchEvent) == 'undefined' || !(ev instanceof TouchEvent)) {
										ev.stopPropagation();
									}
								}
							}}
							onTouchStart={(ev) => {
								selectItemLocal(i);
								//	ev.stopPropagation();
							}}
							onDrag={(e, d) => {
								if (Math.abs(d.deltaX) > 2 || Math.abs(d.deltaY) > 2) {
									let newCProps = [...childProps];
									newCProps[i].size.x = clampX(d.x);
									newCProps[i].size.y = clampY(d.y);
									newCProps[i].manualChange = true;
									setChildProps(newCProps);
									repositionChild(i, newCProps[i]);
								}
							}}
							onDragStop={(e, d) => {

								if (d.deltaX !== 0 || d.deltaY !== 0) {
									let newCProps = [...childProps];
									newCProps[i].size.x = clampX(d.x);
									newCProps[i].size.y = clampY(d.y);
									newCProps[i].manualChange = true;
									setChildProps(newCProps);
									repositionChild(i, newCProps[i]);
								}
							}}
							onResizeStop={(e, direction, ref, delta, position) => {

								let newCProps = [...childProps];
								newCProps[i].size.x = clampX(position.x);
								newCProps[i].size.y = clampY(position.y);
								newCProps[i].size.width = clampX(ref.offsetWidth);
								newCProps[i].size.height = clampY(ref.offsetHeight);
								newCProps[i].manualChange = true;
								setChildProps(newCProps);
								resizeChild(i,  newCProps[i]);
							}}
							onResize={(e, direction, ref, delta, position) => {

								let newCProps = [...childProps];
								newCProps[i].size.x = clampX(position.x);
								newCProps[i].size.y = clampY(position.y);
								newCProps[i].size.width = clampX(ref.offsetWidth);
								newCProps[i].size.height = clampY(ref.offsetHeight);
								newCProps[i].manualChange = true;
								setChildProps(newCProps);
								resizeChild(i, newCProps[i]);
							}}
							lockAspectRatio={childProps[i].lockAspect}
						>
							{child}
						</Rnd>
					)
				})}
			</div>
		</div>
	);
}
)

export default QRGridLayout;