import * as d3 from 'd3';
import $ from 'jquery';
import { resolveVarFromPath, assignVarFromPath } from './graphbaseggc';
import {biaColors} from './graphbaseggc';
//const { biaColors } = require('./graphbaseggc');
const tinycolor = require("tinycolor2");

export class paletteGGC {
    constructor(args) {
        this.editMode = undefined;
        this.editDiv = undefined;
        this._editClass = undefined;
        this.editColor = undefined;
        this.canEdit = undefined;

        this.color = undefined;
        this.colorPaletteType = undefined;
        this.colorPaletteIndex = undefined;
        this.customPalette = undefined;
        this.colorPaletteOpacity = .7;
        this.gradientStops = undefined;
        this.gradientData = undefined;

        this._imageCDN = 'https://qranswers-cdn.s3.amazonaws.com/qrgraph/';

        this._tempProps = [];

        this._palette_init(args);
    }
    getPalettePropsOnly() {
        return this._palette_saveProps();
    }
    _palette_saveProps() {
        var self = this;
        return {
            colorPaletteType: self.colorPaletteType,
            colorPaletteIndex: self.colorPaletteIndex,
            colorPaletteOpacity: self.colorPaletteOpacity,
            customPalette: self.customPalette,
            gradientStops: self.gradientStops
        };
    }
    _palette_init(args) {
        var self = this;
        self._palette_initVariables(args);
    }
    _palette_initVariables(args) {
        var ad3Scale = d3.scaleOrdinal;

        if (args.hasOwnProperty('editClass'))
            this._editClass = args.editClass;

        else
            this._editClass = 'editCtrlGGC';

        if (args.hasOwnProperty('editColor'))
            this.editColor = args.editColor;

        else
            this.editColor = '#F000B4';

        if (args.hasOwnProperty('canEdit'))
            this.canEdit = args.canEdit;

        else
            this.canEdit = true;

        if (args.hasOwnProperty("d3scale")) {
            if (args.d3scale === 'scaleQuantize')
                ad3Scale = d3.scaleQuantize;
            else if (args.d3scale === 'scaleOrdinal')
                ad3Scale = d3.scaleOrdinal;
            else if (args.d3scale === 'scaleLinear')
                ad3Scale = d3.scaleLinear;
        }

        if (typeof (ad3Scale) !== 'function')
            ad3Scale = d3.scaleOrdinal;

        this.colorPaletteType = null;
        this.color = null;
        
        if (args.hasOwnProperty('colorPaletteOpacity'))
            this.colorPaletteOpacity = args.colorPaletteOpacity;

        if (args.hasOwnProperty('colorPaletteType')) {
            if (args.colorPaletteType === 'categorical') {
                this.colorPaletteType = args.colorPaletteType;
                this.colorPaletteIndex = args.colorPaletteIndex;
                this.customPalette = {};
                this.color = ad3Scale().range(biaColors._d3ColorsCategorical[this.colorPaletteIndex].colors);
            } else if (args.colorPaletteType === 'interpolate') {
                this.colorPaletteType = args.colorPaletteType;
                this.colorPaletteIndex = args.colorPaletteIndex;
                this.customPalette = {};
                this.color = ad3Scale();
            } else if (args.colorPaletteType === 'custom') {
                this.colorPaletteType = args.colorPaletteType;
                this.customPalette = args.customPalette; // TODO: check this?
                var colorMap = [];
                for (let i = 0; i < this.customPalette.colorRef.length; i++) {
                    if (typeof (this.customPalette.colorRef[i]) === 'string') {
                        if (this.customPalette.colorRef[i].match(/^http:|^https:/i)) {
                            colorMap.push('#000000'); // placeholder
                        } else {
                            var tc = new tinycolor(this.customPalette.colorRef[i]).toHexString();
                            colorMap.push(tc);
                        }
                    } else if (typeof (this.customPalette.colorRef[i]) === 'object') {
                        var myrgb = '#000000';
                        if (this.customPalette.colorRef[i].hasOwnProperty('rgb') && this.customPalette.colorRef[i].rgb)
                            myrgb = this.customPalette.colorRef[i].rgb;
                        colorMap.push(myrgb);
                    } else {
                        throw new Error('graphbaseggc: incorrect args.color format');
                    }
                }

                this.color = ad3Scale().range(colorMap);

            } else if (args.colorPaletteType === 'gradient') {
                this.colorPaletteType = args.colorPaletteType;
                this.gradientStops = args.gradientStops;
                this.customPalette = {};
                this.color = ad3Scale();
            }
        } else {
            this.colorPaletteType = 'categorical';
            this.colorPaletteIndex = biaColors._d3ColorsCategoricalDefaultIx;
            this.customPalette = {};
            this.color = ad3Scale().range(biaColors._d3ColorsCategorical[this.colorPaletteIndex].colors);
        }
    }
    // if called w/ no stops, use self.gradientStops else use passed gdStopsIn
    getGradientData(gdStopsIn) {
        var self = this;

        if (typeof (gdStopsIn) === 'undefined') {
            if (self.gradientData) return self.gradientData;
        }

        var paletteCanvas = document.createElement('canvas');
        var paletteCtx = paletteCanvas.getContext('2d');

        paletteCanvas.width = 256;
        paletteCanvas.height = 1;

        var gradient = paletteCtx.createLinearGradient(0, 0, 256, 1);
        var gdStops;
        if (typeof (gdStopsIn) === 'undefined')
            gdStops = self.gradientStops;

        else
            gdStops = gdStopsIn;

        for (var key in gdStops) {
            gradient.addColorStop(key, gdStops[key]);
        }

        paletteCtx.fillStyle = gradient;
        paletteCtx.fillRect(0, 0, 256, 1);

        var gdOut = paletteCtx.getImageData(0, 0, 256, 1).data;
        if (typeof (gdStopsIn) === 'undefined') {
            self.gradientData = gdOut;
        }

        return gdOut;
    }
    getEditMode() {
        return this.editMode;
    }
    _toggleEdit() {
        var self = this;

        if (self.canEdit) {
            // CLose all popups here.
            $('.popover').popover('hide');
            console.log('toggleEdit');

            self.editMode = !self.editMode;
            self.resize({ width: self.outerWidth, height: self.outerHeight });
        }

        return self.editMode;
    }
    _nameToClassSafe(name) {
        return name.replace(/[^a-z0-9]/g, function (s) {
            var c = s.charCodeAt(0);
            if (c === 32) return '-';
            if (c >= 65 && c <= 90) return '_' + s.toLowerCase();
            return '__' + ('000' + c.toString(16)).slice(-4);
        });
    }
    _drawPencil(selector, x, y, r) {
        var self = this;

        var pencilSize = 24;
        var width = pencilSize + "px";
        var height = pencilSize + "px";
        //x = Math.floor(x + r * Math.cos(30 * Math.PI / 180)) + 'px';
        y = /*Math.floor(y - r * Math.sin(30 * Math.PI / 180))*/ y - pencilSize + 'px';
        selector.append('svg')
            .attr('class', self._editClass)
            .attr('x', x)
            .attr('y', y)
            .attr('width', width)
            .attr('height', height)
            .html(this._editPencil({ x: '0px', y: '0px', width: width, height: height }));

    }
    _editIconHtml(edit) {
        var self = this;
        var fill = edit ? self.editColor : 'white';
        var stroke = edit ? 'black' : 'black';

        return '<svg height="20px" viewBox="0 -1 401.52289 401" width="20px" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="' + fill + '" /><path stroke="' + stroke + '" d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/><path stroke="' + stroke + '" d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/></svg>';
    }
    _editPencil(args) {
        var self = this;
        var x, y, width, height, /* stroke,*/ fill;

        width = "20px";
        height = "20px";
        x = 0;
        y = 0;
        //stroke = self.editColor;
        fill = self.editColor;

        if (typeof (args) !== 'undefined') {
            if (args.hasOwnProperty('x'))
                x = args.x;
            if (args.hasOwnProperty('y'))
                y = args.y;
            if (args.hasOwnProperty('width'))
                width = args.width;
            if (args.hasOwnProperty('height'))
                height = args.height;
            //if (args.hasOwnProperty('stroke'))
            //    stroke = args.stroke;
            if (args.hasOwnProperty('fill'))
                fill = args.fill;
        }

        return '<svg height="' + height + '" viewBox="0 0 73.06 73.06" x="' + x + '" y="' + y + '" width="' + width + '" xmlns="http://www.w3.org/2000/svg"><defs><style>.b {fill:#fff}.b, .c{stroke-width: 0px;}.c {fill:' + fill + '}</style></defs><path class="c" d="M66.86,3.28c-4.38-4.38-11.47-4.38-15.85,0L6.57,47.72c-.3.3-.52.68-.64,1.1L.09,69.91c-.24.86,0,1.79.64,2.43.64.63,1.56.88,2.43.64l21.09-5.84c.41-.11.79-.33,1.1-.64l44.43-44.43c4.37-4.38,4.37-11.47,0-15.85l-2.92-2.92Z"/><rect class="c" x="10.33" y="28.72" width="51.43" height="16.59" transform="translate(-15.62 36.33) rotate(-45)"/><path class="b" d="M66.26,18.53l-2.64,2.64-11.73-11.73,2.64-2.64c2.43-2.43,6.37-2.43,8.8,0l2.92,2.92c2.43,2.43,2.43,6.37,0,8.81Z"/><rect class="b" x="10.46" y="28.85" width="51.18" height="16.34" transform="translate(-15.62 36.33) rotate(-45)"/></svg>';
    }
    _bringEditToFront() {
        if (this.editMode) {
            d3.selectAll('.' + this._editClass)
                .select(function (d, i) {
                    d3.select(this).raise();
                });
        }
    }
    _saveTempProps(kind, vars) {
        var self = this;

        var props = {};
        for (const property in vars) {
            //        if (typeof (self[vars[property]]) !== 'undefined')
            if (typeof (resolveVarFromPath(self, vars[property])) !== 'undefined')
                props[vars[property]] = keepCloning(resolveVarFromPath(self, vars[property]));
        }

        self._tempProps[kind] = props;
    }
    _restoreTempProp(kind, prop) {
        var self = this;

        if (self._tempProps.hasOwnProperty(kind)) {
            var props = self._tempProps[kind];

            if (props.hasOwnProperty(prop)) {
                if (typeof (props[prop]) !== 'undefined') {
                    assignVarFromPath(self, prop, keepCloning(props[prop]));
                }
            }
        }
    }

    _restoreTempProps(kind) {
        var self = this;

        if (self._tempProps.hasOwnProperty(kind)) {
            var props = self._tempProps[kind];

            for (const property in props) {
                if (typeof (props[property]) !== 'undefined')
                    assignVarFromPath(self, property, keepCloning(props[property]));
            }

            self._refresh();
        }
    }
}

export const keepCloning = (objectpassed) => {
    if (objectpassed === null || typeof objectpassed !== 'object') {
        return objectpassed;
    }
    // give temporary-storage the original obj's constructor
    var temporarystorage = objectpassed.constructor(); 
    for (var key in objectpassed) {
        temporarystorage[key] = keepCloning(objectpassed[key]);
    }
    
    return temporarystorage;
}

