// https://github.com/scholtzan/d3-gantt/blob/master/src/d3-gantt.js
// binning over time period (not gantt) https://stackoverflow.com/questions/19408296/how-can-i-sum-binned-time-series-using-d3-js
// calendar view https://observablehq.com/@d3/calendar/2?collection=@d3/d3-time
import * as d3 from 'd3';
import $ from "jquery";
import GGCPrimitives from './GGCPrimitives';
import { axisGGC } from './axisggc';
import { getDatabaseVotes } from '../utils/localdb';
import {RangeSlider} from './RangeSlider';

/* Hierarchical traversal of data
var svg = d3.select('#chart').append('svg')
   .attr('width', 100)
   .attr('height', 100);

var data = [
    {node : 1, subProperties : ["A", "B", "C"]},
    {node : 2, subProperties : ["D", "E", "F"]}
];

var mainGroups = svg.selectAll('g.main')
   .data(data)
   .enter()
   .append('g')
   .classed('main', true);

mainGroups.selectAll('g.sub')
   .data(function(d) { return d.subProperties; })
   .enter()
   .append('g')
   .classed('sub', true);


	 Our data doesn't have to be hierarchical. could just make an array and order it correctly
	 so that the containing Project is first, then Campaigns, then Locations, then Questions.
	 Each would have a 'type' and then the values vs. parent/child
   [{type: 'Project', name: 'Sample Project Conference', id:<>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
	  {type: 'Campaign', name: 'New Campaign', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		{type: 'Location', name: 'Meeting Room 201a', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		{type: 'Question', name: 'What topic would you like to cover today?', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		{type: 'Location', name: 'Meeting Room 201b', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		{type: 'Question', name: 'What topic would you like to cover today?', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		{type: 'Question', name: 'What up?', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
	  {type: 'Campaign', name: 'Campaign 2', id: <>, start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
		next Project, etc.
		Then color each type differently.

*/
export class ganttGGC extends axisGGC {
	constructor(args) {
		super(args);

		this.superClass = 'ganttGGC';

		this.ganttLabels = undefined;
		this.ganttTables = undefined;


		this.selectorHeight = 100;
		this.widthOverview = 40;
		this.active_link = "0";

		this.minBarWidth = 10;
		this.origMinBarWidth = this.minBarWidth;
		this.barOutlineWidth = 1;
		this.barOutlineColor = '#E8EAED'.toColorRef();
		this.barOutlineStrokeDashArray = '0';
		this.hasYGrid = true;
		this.padding = 0;
		this.sparkLine = false;

		this.isHorizontal = true;		// testing..

		this.y_orig = undefined;

		this.numBars = undefined;
		this.isYScrollDisplayed = undefined;
		this.xDisplayed = undefined;
		this.yDisplayed = undefined;

		this.xscale = undefined;
		this.yscale = undefined;
		this.xAxis = undefined;
		this.yAxisGrid = undefined;
		this.yAxis = undefined;
		this.barsHolder = undefined;
		this.idx = undefined;
		this._axisLabelHeight = undefined;
		this.barWidth = undefined;
		this.cur_data = undefined;

		this.date_range = undefined;

		this.xScrollerStart = null;
		this.timeTickDistance = 50;
		this.inerval = undefined;
		this.dateTimeFormat = d3.timeFormat("%Y/%m/%d %H:%M");

		this._yLabelWidth = undefined;

		this.yAxisColumnMargin = 10;
		this.yAxisColumnOffsets = undefined;
		this.padding = 2;

		this.y1 = undefined;
		this.ntips = undefined;
		this.svgId = undefined;

		this.extraRightMargin = 60;
		this.timeIntervalName = 'Hour';
		this.timeIntervalValue = 1;

		this._init(args);
	}
	getGraphType() {
		return 'gantt';
	}
	_init(args) {
		this._initVariables(args);
	}
	_saveProps() {
		var self = this;

		var axis = self._axis_saveProps();
		var me = {
			hasYGrid: self.hasYGrid,
			selectorHeight: self.selectorHeight,
			widthOverview: self.widthOverview,
			minBarWidth: self.minBarWidth,
			barOutlineWidth: self.barOutlineWidth,
			barOutlineColor: self.barOutlineColor,
			barOutlineStrokeDashArray: self.barOutlineStrokeDashArray,
			_computedBarWidth: self._computedBarWidth,
		};
		if (this._computedBarWidth !== this.barWidth)
			me.barWidth = this.barWidth;

		return { ...axis, ...me };
	}
	_initVariables(args) {
		
		this._autoMarginLeft = true;

		if (args.hasOwnProperty('selectorHeight'))
			this.selectorHeight = args.selectorHeight;

		if (args.hasOwnProperty('widthOverview'))
			this.widthOverview = args.widthOverview;

		if (args.hasOwnProperty('minBarWidth'))
			this.minBarWidth = args.minBarWidth;

		if (args.hasOwnProperty('barOutlineWidth'))
			this.barOutlineWidth = args.barOutlineWidth;

		if (args.hasOwnProperty('barOutlineColor')) {
			this.barOutlineColor = GGCPrimitives._argsToColorRef(args, 'barOutlineColor');
		}

		if (args.hasOwnProperty('barOutlineStrokeDashArray'))
			this.barOutlineStrokeDashArray = args.barOutlineStrokeDashArray;

		if (args.hasOwnProperty('barWidth'))
			this.barWidth = args.barWidth;

		if (args.hasOwnProperty('_computedBarWidth'))
			this._computedBarWidth = args._computedBarWidth;

		if (args.hasOwnProperty('hasYGrid'))
			this.hasYGrid = args.hasYGrid;

	}

	_setData(dGantt) {
		this.data = dGantt.data;
		this.ganttLabels = dGantt.labels;	// Projects, Campaigns, Locations, Questions
		this.ganttTables = dGantt.tables;
		this.ganttDuration = dGantt.duration;	// start, end, duration values for entirety of data
		this.date_range = [this.ganttDuration.start, this.ganttDuration.end];

		this.valueNames = this.ganttLabels;	// not really, but use it for color domain
		
	}

	_trimEllipses(s) {
		if (s && s.length > this.ellipses)
			return s.substr(0, this.ellipses) + '...';

		else
			return s;
	}

	resize(opts) {
		var self = this;

		var width = opts.width;
		var height = opts.height;

		self.outerWidth = width;
		self.outerHeight = height;

		if (typeof (this.data) === 'undefined' || !this.data) {
			if (this.ajax)
				this._getData();
			return;
		}

		if (this.hasOwnProperty('svg') && this.svg)
			this.svg.remove();
		// remove any leftover popovers
		$('.popover').popover('hide');

		this.origWidth = self.outerWidth;
		this.origHeight = self.outerHeight;

		this._axisLabelHeight = this._textHeight('Yg', this.yAxisFont) * 2.5;
		this._axisLabelAscent = this._textAscent('g', this.yAxisFont);

		if (typeof self.barWidth === 'undefined') { // don't change if we are refreshing from edit
			self.barWidth = self._textHeight('Yg', self.yAxisFont ? self.yAxisFont : self.font) * 2.0;

			this._computedBarWidth = this.barWidth;
		}

		// reduce height by bottom label size.
		this.topOffset = this.margin.top + self.barWidth;

		self.xAxisLabelHeight = self._textWidth(this.dateTimeFormat(new Date("2023-11-07T19:21:00Z")), self.xAxisFont ? self.xAxisFont : self.font);
		self.xAxisLabelHeight *= Math.sin(45 * Math.PI / 180);
		this.height = self.origHeight - this.margin.top - 
				this.margin.bottom - 
				self.barWidth -		// room for top labels for columns
				self.xAxisLabelHeight - 
				self.selectorHeight;


		this.numBars = Math.round(this.height / self.barWidth);

		self._computeWidthAndScroll();

		// timeMillisecond, timeSecond, timeMinute, timeHour, timeDay, timeWeek, timeMonth, timeYear
		// timeMonday, timeTuesday, timeWednesday, timeThursday, timeFriday, timeSaturday, timeSunday
		this.interval = self.getInterval();

	
		this.svgId = this._genId();
		this.svg = d3.select(this.container).append("svg")
			.attr('xmlns', "http://www.w3.org/2000/svg")
			.attr("width", self.origWidth)
			.attr("height", self.origHeight)
			.attr('id', self.svgId);


		this.diagram = this.svg.append("g")
			.attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

		this.color.domain(this.valueNames);
		this._colorDomainSet(['barOutlineColor']);
		self._fillBackground(this.svg);

		// We can fit this.numBars items, so we need to padd cur_data if it is less than numBars
		this.cur_data = self.data.slice(0, this.numBars);	// vertical scroll
		if (this.cur_data.length < this.numBars) {
			let numToAdd = this.numBars - this.cur_data.length;
			for (let i = 0; i < numToAdd; i++) {
				this.cur_data.push({ name: '', type: '' });
			}
		}

		this._dataRange = [0, this.numBars];
		this.ixLabel = 'type';

		let fitArray = [];
		for (let i=0; i<this.numBars; i++) {
			fitArray.push(i);
		}
		this.yscale = d3.scaleBand()
			.domain(fitArray.map(function (d, ix) { return ix; }))
			.range([0, this.height]);

		this.padding = 2;

		//
		self.ntips = 1;		// just the duration for the Project, Campaign etc. (only 1)
		this.tooltipCreate(self.ntips);


		this.xscale = d3.scaleTime()
			.domain(this.date_range)
			.range([0, this.width])
			.clamp(true);

		this.yAxis = d3.axisLeft(this.yscale).tickSize(0);
		this.yAxisGrid = d3.axisLeft(this.yscale).tickFormat('');

		this.xAxis = d3.axisBottom(this.xscale)
			.tickFormat(d3.timeFormat("%Y/%m/%d %H:%M"))
//			.ticks(this.interval);


		this._axis_focusY();
		this._axis_drawXY();
		this._axis_drawXY_edit();

		this.draw_bars();

		this._setBarsMouseEvents();

		this._base_makeLegend({ resize: true });

		this._checkYScroller();

		this.addOverview();

		var kind;
		if (typeof opts !== 'undefined') {
			if (opts.hasOwnProperty('kind')) {
				kind = opts.kind;
			}
		}
		this.checkEditMode(kind);
	}

	refresh(kind) {

		this.draw_bars();

		this._setBarsMouseEvents();

	}

	getIntervalMinMax () {
		switch (this.timeIntervalName) {
			case 'Millisecond':
				return {min: 1, max: 2000};
			case 'Second':
				return {min: 1, max: 120};
			case 'Minute':
				return {min: 1, max: 120};
			case 'Day':
				return {min: 1, max: 730};
			case 'Week':
				return {min: 1, max: 104};
			case 'Month':
				return {min: 1, max: 24};		// arbitrary so can look at 18, etc.
			case 'Year':
				return {min: 1, max: 50};
			case 'Hour':
			default:
				return {min: 1, max: 48};
		}
	}

	getInterval() {
		let iv;
		switch (this.timeIntervalName) {
			case 'Millisecond':
				iv = d3.timeMillisecond.every(this.timeIntervalValue);
				break;
			case 'Second':
				iv = d3.timeSecond.every(this.timeIntervalValue);
				break;
			case 'Minute':
				iv = d3.timeMinute.every(this.timeIntervalValue);
				break;
			case 'Hour':
			default:
				iv = d3.timeHour.every(this.timeIntervalValue);
				break;
			case 'Day':
				iv = d3.timeDay.every(this.timeIntervalValue);
				break;
			case 'Week':
				iv = d3.timeWeek.every(this.timeIntervalValue);
				break;
			case 'Month':
				iv = d3.timeMonth.every(this.timeIntervalValue);
				break;
			case 'Year':
				iv = d3.timeYear.every(this.timeIntervalValue);
				break;
		}

		return iv;
	}
	updateInterval() {
		let self = this;

		let ivNew = self.getInterval();		
		let start = ivNew(self.ganttDuration.start);
		let maxEnd = ivNew(self.ganttDuration.end);

		self.resize({ width: self.outerWidth, height: self.outerHeight });
	}

	resetInterval() {
		let self= this;

		// timeIntervalName
		this.timeIntervalName = 'Hour';

		// timeIntervalValue
		this.timeIntervalValue = 1;


		self.resize({ width: self.outerWidth, height: self.outerHeight });
	}


	addSlider() {
		let self = this;

		if (self.sliderContainer)
			self.sliderContainer.remove();

		self.sliderContainer = d3.select(self.container)
			.append('div')
			.attr("class", "slider-container")
			.style("position", "absolute")
			.style("border-radius", "5px")
			.style('background-color', '#30363E')
			.style('left', self.margin.left + 'px')
			.style('top', (self.height + self.margin.top + self.xAxisLabelHeight + self.barWidth) + 'px')
			.style('width', self.width + 'px')
			.style('height', self.selectorHeight + 'px')

		new RangeSlider()
		.container('.slider-container')
		.svgWidth(self.width-20)
		.svgHeight(self.selectorHeight)
		.data(self.data)
		.accessor(d=>d.start)
		.onBrush(d=>{
			// d.range[0] = start, d.range[1] = end
			self.date_range = [...d.range];

			this.xscale.domain(self.date_range);

			this._axis_drawXY();
			this._axis_drawXY_edit();
	
			self.draw_bars();

			self._setBarsMouseEvents();

			self._bringEditToFront();

		})
		.render()
	
	
	}

	addOverview() {
		let self = this;

		const lbls = ['Start', 'End'];
		self.diagram.selectAll('.timeOverview').remove();

		self.diagram.selectAll('.timeOverview')
			.data(lbls)
			.enter()
			.append('text')
			.attr("class", "timeOverview")
			.attr('x', -self.yAxisColumnOffsets[0])
			.attr('y', function (d, ix) {
				return self.height + (ix + 1) * self._textHeight('Yg', self.yAxisFont) * 1.2;
			})
			.text(function (d, ix) {
				return d + ': ' + self.dateTimeFormat(self.ganttDuration[ix === 0 ? 'start' : 'end']);
			})
			.style("font", this.yAxisFont)
			.style('font-weight', this.yAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
			.attr('text-decoration', this.yAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
			.style("text-anchor", "start");


		// Show time interval dropdown and value
		let timeIntervals = ['Millisecond', 'Second', 'Minute', 'Hour', 'Day', 'Week', 'Month', 'Year'];
		d3.select(self.container).selectAll('.selectIntervalValue').remove();
		d3.select(self.container).selectAll('.selectInterval').remove();
		d3.select(self.container).selectAll('.resetInterval').remove();

		const {min, max} = self.getIntervalMinMax();

		let ivInput = d3.select(self.container)
			.append('input')
			.attr("class", "selectIntervalValue")
			.attr('type', 'number')
			.attr('value', self.timeIntervalValue)
			.attr('min', min)
			.attr('max', max)
			.style("position", "absolute")
			.style('left', self.selectorHeight + 'px')
			.style('top', (self.height + self.margin.top + self._textHeight('Yg', self.yAxisFont) * 3.6) + 'px')
			.style('width', '120px')
			.style('height', '20px');

		d3.select('.selectIntervalValue').on('change', function () {
			let checkV = d3.select(this).property('value');
			if (checkV < min) {
				checkV = min;
				alert("Minimum value is " + min);
			} else if (checkV > max) {
				checkV = max;
				alert("Maximum value is " + max);
			}
			self.timeIntervalValue = checkV;

			self.updateInterval();

			});

		let selLeft = self.selectorHeight + ivInput.node().getBoundingClientRect().width + 10;

		let dropDown = d3.select(self.container)
			.append('select')
			.attr("class", "selectInterval")
			.style("position", "absolute")
			.style('left', selLeft + 'px')
			.style('top', (self.height + self.margin.top + self._textHeight('Yg', self.yAxisFont) * 3.6) + 'px')
			.style('width', '120px')
			.style('height', '20px');
		
		dropDown.selectAll("option")
			.data(timeIntervals)
			.enter()
			.append('option')
			.text(function (d) { return d; })
			.attr("value", function (d) { return d; })
			.attr('selected', function (d) { return d === self.timeIntervalName ? 'selected' : null; });

		d3.select('.selectInterval').on('change', function () {
			self.timeIntervalName = d3.select(this).property('value');
			self.timeIntervalValue = 1;

			self.updateInterval();
		});

		d3.select(self.container)
			.append('input')
			.attr("class", ".resetInterval")
			.attr('type', 'button')
			.attr('value', 'Reset')
			.style("position", "absolute")
			.style('left', self.selectorHeight + 'px')
			.style('top', (self.height + self.margin.top + self._textHeight('Yg', self.yAxisFont) * 5.6) + 'px')
			.style('width', '80px')
			.style('height', (2.5*self._textHeight('Yg', self.yAxisFont))+'px')
			.on('click', function(ev) {
				self.resetInterval();
			})

			this.addSlider();
	}

	checkEditMode(kind) {
		var self = this;

		self._axis_checkEditMode(kind);

		if (self.editMode) {
			let x = self.origWidth / 8;
			let y = self.margin.top;
			self._drawCircleEditColor({
				selector: self.diagram,
				kind: 'bgcolor',
				title: 'Background',
				x: x,
				y: y,
				r: 12,
				placement: 'right',
				variables: {
					colorRefProp: 'backgroundColor'
				}

			});

			// TODO: find a bar corner
			y += 30;
			self._drawCircleEditBarWidth({
				selector: self.diagram,
				kind: 'baroutline',
				title: 'Bar Gap/Outline',
				x: x,
				y: y,
				r: 12,
				placement: 'top',
				variables: {
					barWidthProp: 'paddingInner',       // ggc 'barWidth',
					strokeWidthProp: 'barOutlineWidth',
					colorRefProp: 'barOutlineColor',
					strokeDashArrayProp: 'barOutlineStrokeDashArray'
				}

			});

		}

	}


	_computeWidthAndScroll() {
		var self = this;

		self.isYScrollDisplayed = self.barWidth * self.data.length > self.height;

		// Compute left margin (y axis).

		// We are trying to compute how much room to leave on the left - by figuring out how long the widest
		// label is - there is only one i hbar, so this doesn't work here.  Need to compute label lengths for each of
		// this.ganttLabels (maxbe limit Questions to max length so we don't get too wide)
		self.yAxisColumnOffsets = [];
		let offsetTotal = 0;
		let pixelwidth = 0;
		for (let i=self.ganttLabels.length-1; i>=0; i--) {
			let myLabel = self._trimEllipses(self.ganttLabels[i]);
			let pixLen = 1.5*self._textWidth(myLabel, self.yAxisFont) + 2*self.yAxisColumnMargin;
			pixelwidth += pixLen;
			self.yAxisColumnOffsets.unshift(offsetTotal);
			offsetTotal += pixLen;
		}
		self.yAxisColumnOffsets.unshift(offsetTotal);

		// Any 'horizontal' graph needs a _yLabelWidth as tehe axisggc uses it
		self._yLabelWidth = pixelwidth;

		if (self.isYScrollDisplayed) {		// on Y axis
			pixelwidth += self.selectorHeight;
		}

		self.margin.left = pixelwidth; // self.margin.left > pixelwidth ? self.margin.left : pixelwidth;    

		self.width = self.origWidth - self.margin.left - self.margin.right - self.legendSpace - self.extraRightMargin;


	}

	// override, so do not change name..
	_xaxis_stagger() {
		var self = this;

		this.diagram.selectAll('.x.axis').remove();
		this.diagram.selectAll('.x.axis-grid').remove();

		if (self.horizontalGraph()) {
			this.diagram.append("g")
				.attr("class", "x axis")
				.attr('stroke-width', this.xAxisStrokeWidth)
				.call(this.xAxis)
				.style("font", this.xAxisFont)
				.style('font-weight', this.xAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
				.attr('text-decoration', this.xAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
				.attr("transform", "translate(0," + (this.height) + ")")
				.append("text");
		}

		this.diagram.selectAll('.x.axis')
			.selectAll("text")
			.attr('fill', this.xAxisLabelColor);

		var skew = 0; // have to do this each time, as the skew 'stays' unless cleared...
		if (this.xAxisFontStyle.indexOf('I') !== -1)
			skew = self._italicSkewDegrees;
		this.diagram.selectAll('.x.axis').selectAll('g').selectAll('text')
			.attr("transform", function (d, i) {
				return " skewX(" + (i * 10 - skew) + ") rotate(-45)";
			})
			.attr('dy', '0.5em')
			.attr('dx', '-0.5em')
			.style("text-anchor", "end");

		this.diagram.selectAll('.x.axis').selectAll('g').selectAll('line')
			.style('stroke', self.xAxisColor);
		this.diagram.selectAll('.x.axis').selectAll('path')
			.style('stroke', self.xAxisColor);


		// All labels
		self._bStagger = false;

		return self._bStagger;
	}
	_yaxis_add() {
		var self = this;

		this.diagram.selectAll('.y.axis').remove();
		this.diagram.selectAll('.y.axis-grid').remove();

		if (self.horizontalGraph()) {
			for (let i = 0; i< self.ganttLabels.length; i++) {
				// Put labels on 'top'
				this.diagram.append("text")
					.attr("class", "columnLabel")
					.attr('x', -(self.yAxisColumnOffsets[i] + self.yAxisColumnOffsets[i + 1])/2)
					.attr('y', -(this._axisLabelAscent))
					.text(function (d, ix) {
						return self.ganttLabels[i];
					})
					.style("font", this.yAxisFont)
					.style('font-weight', this.yAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
					.attr('text-decoration', this.yAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
					.style("text-anchor", "middle");


				// left y axes
				this.diagram.append("g")
					.attr("class", "y axis")
					.style("font", this.yAxisFont)
					.style('font-weight', this.yAxisFontStyle.indexOf('B') !== -1 ? 700 : 400)
					.attr('text-decoration', this.yAxisFontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
					.attr("transform", "translate(" + (-self.yAxisColumnOffsets[i+1]) + ",0)")
					.attr('stroke-width', this.yAxisStrokeWidth)
					.call(this.yAxis)
					.selectAll("text")
					.data(self.cur_data)
					.text(function (d, ix) {
							if (d['type'] === self.ganttLabels[i]) {
								return self._trimEllipsesLen(d['name'], self.yAxisFont, self.yAxisColumnOffsets[i] - self.yAxisColumnOffsets[i + 1] - 2 * self.yAxisColumnMargin);
							} else {
								return '';
							}
					})
					.attr('fullText', function (d) {
						if (d['type'] === self.ganttLabels[i]) {
							return d['name'];
						} else {
							return '';
						}
					})
					.style("text-anchor", "end")
					.append('title')
					.text(function (d) {
						return d.name;
					});
		
			}

			if (this.hasOwnProperty('hasYGrid') && this.hasYGrid) {
				this.yAxisGrid.tickSize(-(this.width+this.yAxisColumnOffsets[0]));
				this.yAxisGrid.ticks(this.numBars);

				this.diagram.append("g")
						.attr('class', 'y axis-grid')
						.attr('stroke-width', this.yAxisGridStrokeWidth)
						.attr("transform", "translate(" + -this.yAxisColumnOffsets[0] + "," + -(self.barWidth/2) + ")")
						.call(this.yAxisGrid);
				this.diagram.selectAll('.y.axis-grid').selectAll('path').remove();
				//this.diagram.selectAll('.y.axis-grid').select('g').filter(function (d, i) { return i === 0; }).remove();

				// Add the bottom line
				this.diagram.append("line")
					.style('stroke-width', this.yAxisGridStrokeWidth)
					.style('stroke', self.yAxisGridColor)
					.attr('y1', this.height)
					.attr('y2', this.height)
					.attr('x1', -this.yAxisColumnOffsets[0])
					.attr('x2, 0');
			}

		} 

		this.diagram.selectAll('.y.axis')
			.selectAll("text")
			.attr("transform", "translate(-" + self.yAxisColumnMargin + ",0)")
			.attr('fill', this.yAxisLabelColor);

		this.diagram.selectAll('.columnLabel')
			.attr('fill', this.yAxisLabelColor);


		let skew = 0; // have to do this each time, as the skew 'stays' unless cleared...
		if (this.yAxisFontStyle.indexOf('I') !== -1)
			skew = self._italicSkewDegrees;

		this.diagram.selectAll('.y.axis').selectAll('g').selectAll('text')
			.attr("transform", function (d, i) {
				return " skewX(" + (i * 10 - skew) + ") translate(-" + self.yAxisColumnMargin + ",0)";
			});

			// column labels
		this.diagram.selectAll('.columnLabel')
			.attr("transform", function (d, i) {
				return " skewX(" + (- skew) + ")";
			});


		this.diagram.selectAll('.y.axis').selectAll('g').selectAll('line')
			.style('stroke', self.yAxisColor);
		this.diagram.selectAll('.y.axis').selectAll('path')
			.style('stroke', self.yAxisColor);

		if (this.hasOwnProperty('hasYGrid') && this.hasYGrid) {
			this.diagram.selectAll('.y.axis-grid').selectAll('g').selectAll('line')
				.style('stroke', self.yAxisGridColor);
		}

	}
	rightRoundedRect(x, y, width, height, radius) {
		if (width === 0 || height === 0) return null;
		if (width < radius*2) {
			return this.normalRect(x, y, width, height, radius);
		}

		return "M" + x + "," + y
				 + "h" + (width - radius)
				 + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
				 + "v" + (height - 2 * radius)
				 + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
				 + "h" + (radius - width)
				 + "z";
	}
	leftRoundedRect(x, y, width, height, radius) {
		if (width === 0 || height === 0) return null;
		if (width < radius*2) {
			return this.normalRect(x, y, width, height, radius);
		}

		return "M" + (x + radius) + "," + y
				 + "h" + (width - radius)
				 + "v" + (height)
				 + "h" + (radius - width)
				 + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius
				 + "v" + (2*radius - height)
				 + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius
				 + "z";
	}
	normalRoundedRect(x, y, width, height, radius) {
		if (width === 0 || height === 0) return null;
		if (width < radius*2) {
			return this.normalRect(x, y, width, height, radius);
		}

		return "M" + (x + radius) + "," + y
				 + "h" + (width - radius*2)
				 + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
				 + "v" + (height - radius*2)
				 + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
				 + "h" + (2*radius - width)
				 + "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius
				 + "v" + (2*radius - height)
				 + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius
				 + "z";

	}
	normalRect(x, y, width, height, radius) {
		if (width === 0 || height === 0) return null;

		return "M" + x + "," + y
				 + "h" + (width)
				 + "v" + (height)
				 + "h" + (-width)
				 + "z";
	}

	mapToObject(map) {
		let self = this;
		return Array.from(
			map.entries(),
			([k, v]) => ({
				"key": k,
				"values": v instanceof Map ? self.mapToObject(v) : v
			})
		)
	}

	drawSparkLine(d, i) {
		let self = this;

		const votesDB = getDatabaseVotes();

		let x = self.xscale(d.start);
		let width = self.xscale(d.end) - self.xscale(d.start);
		let myId = d.id;
		let fldName;

		switch (d.type) {
			case 'Project':
				fldName = 'projectID';
				break;
			case 'Campaign':
				fldName = 'campaignID';
				break;
			case 'Location':
				fldName = 'locationID';
				break;
			case 'Question':
				fldName = 'questionID';
				break;
			default:
				return this.drawProperRect(d, i);
		}

		// d.start and d.end are actual Date()s
		let marks = [];
		for (let j=0; j<votesDB.length; j++) {
			const thisRow = votesDB[j];	// vote: '1'  timestamp: '2023-10-14 16:39:28.955'
			if (thisRow[fldName] === myId) {
				try {
					let myDate = new Date(thisRow.eventtime);
					if (myDate >= d.start && myDate <= d.end) {
						marks.push({date: myDate, vote: +thisRow.vote})
					}
				} catch (err) {

				}

			}
		}
		// Now, we can bin the marks to the timescale
		let ivNew = self.getInterval();

		// we have x and width, now top and height
		let yTop = self.yscale(i) - self.barWidth/2 + self.padding;

		let height = self.yscale.bandwidth() - self.padding*2;

		/*
		const nested = d3.nest()
			.key((d) => ivNew(d.date))
			.rollup((a) => d3.sum(a, (d) => d.vote))
			.entries(marks);
			*/
		const nested = d3.rollup(marks, (a) => d3.sum(a, (d) => d.vote), (d) => ivNew(d.date));
		// nested is an array with {key: date, value: #votes}
		const keys = [...nested.keys()];

		let minVal = 0;
		let maxVal = 0;
		for (let j=0; j<keys.length; j++) {
			// keys[i] = Date  Note since we bin dates, could show up 'before' d.start, so we want
			// to clip our drawing from x => x+width
			const val = nested.get(keys[j]);
			if (val > maxVal) {
				maxVal = val;
			}
			if (val < minVal) {
				minVal = val;
			}
		}

		// newyScale = d3.scale
		let newyScale = d3.scaleLinear()
			.domain([minVal, maxVal])
			.range([height, 0]);

		x = self.xscale(keys[0]);
		let y = newyScale(nested.get(keys[0]));

		let retVal = "M " + x + " " + (y+yTop);

		for (let j=1; j<keys.length; j++) {
			// keys[i] = Date  Note since we bin dates, could show up 'before' d.start, so we want
			// to clip our drawing from x => x+width
			x = self.xscale(keys[j]);
			y = yTop + newyScale(nested.get(keys[j]));
			retVal += " L " + x + " " + y;
		}

		return retVal;

//		return this.drawProperRect(d, i);

	}

	drawProperRect(d, i) {
		let self = this;

		let x = self.xscale(d.start);
		let width = self.xscale(d.end) - self.xscale(d.start);
		if (d.votes === 0) {
			return null;
		}

		if (x > 0) {
			if (x + width >= self.width) {
				return  self.leftRoundedRect(
					x,
					self.yscale(i) - self.barWidth/2 + self.padding,
					width,
					self.yscale.bandwidth() - self.padding*2,
					5
				)		
			} else {
				return  self.normalRoundedRect(
					x,
					self.yscale(i) - self.barWidth/2 + self.padding,
					width,
					self.yscale.bandwidth() - self.padding*2,
					5
				)
			}
		} else {
			if (x + width >= self.width) {
				return  self.normalRect(
					x,
					self.yscale(i) - self.barWidth/2 + self.padding,
					width,
					self.yscale.bandwidth() - self.padding*2,
					5
				)
			} else {
				return  self.rightRoundedRect(
					x,
					self.yscale(i) - self.barWidth/2 + self.padding,
					width,
					self.yscale.bandwidth() - self.padding*2,
					5
				)		
			}
		}

	}
	draw_bars () {
		var self = this;

		this.diagram.selectAll('.barsHolder').remove();

		this.barsHolder = this.diagram.append('g')
			.attr('class', 'barsHolder')
			.attr("transform", "translate(0," + (self.barWidth / 2) + ")");

		this.barsHolder.selectAll('.bar').remove();

		if (this.sparkLine) {
			this.barsHolder.selectAll('.bar')
				.data(self.cur_data)
				.enter()
				.append('path')
				.attr("d", function (d, i) {
					return self.drawSparkLine(d, i);
				})
				.attr('class', 'bar')
				.attr('fill', 'transparent')
				.attr('opacity', function (d, i) {
					return self.valueStatus[self.valueNames.indexOf(d.type)];
				})
				.attr('stroke-width', self.barOutlineWidth)
				.attr('stroke', function (d) { return self.color(d.type) })
				.attr('stroke-dasharray', self.barOutlineStrokeDashArray);
		} else {
			this.barsHolder.selectAll('.bar')
				.data(self.cur_data)
				.enter()
				.append('path')
				.attr("d", function (d, i) {
					return self.drawProperRect(d, i);
				})
				.attr('class', 'bar')
				.attr('fill', function (d) {
					return self.color(d.type);
				})
				.attr('opacity', function (d, i) {
					return self.valueStatus[self.valueNames.indexOf(d.type)];
				})
				.attr('stroke-width', self.barOutlineWidth)
				.attr('stroke', function (d) { return self.color(d.type) })		// self.barOutlineColor)
				.attr('stroke-dasharray', self.barOutlineStrokeDashArray);

		}

	}

	_setBarsMouseEvents() {
		var self = this;
		this.barsHolder.selectAll('path')		// "rect")
			.on('mouseover', function () {
				if (self.editMode) {
				} else {
					//self.focus.style('opacity', 1);
				}
			})
			.on("mousemove", function (event, d) {		// v6 added event
				if (self.editMode) {
				} else {
					//v5 let y = d3.event.offsetY;
					const coords = d3.pointer(event);
					let y = coords[1];

					// center line is 0
					let ypos = y + self.yscale.bandwidth()/2;		// normalize to top of cell
					var y0 = Math.floor(ypos / (self.yscale.bandwidth()));
					if (y0 < 0) y0 = 0;
					else if (y0 >= self.cur_data.length) y0 = self.cur_data.length - 1;

					y0 += self.yscale.domain()[0];

					d3.select(this).attr("stroke", "blue").attr("stroke-width", 0.8);

					y0 -= self.yscale.domain()[0];
					let ixType = self.valueNames.indexOf(self.cur_data[y0].type);
					if (ixType !== -1) {
						if (self.valueStatus[ixType] !== self._hiddenOpacity /*0.25*/) {
							self.tooltipSetRow(0, self.cur_data[y0].name, self.cur_data[y0].votes, self.color(self.cur_data[y0].type));
						}
					}

					//self.tooltipShow(x0 + self.margin.left + svgRect.left, y + svgRect.top);
					// 0, 0 is corner of this.container, even if scrolled
					//v5 let tx = d3.event.offsetX;
					//v5 let ty = d3.event.offsetY;
					let tx = coords[0];
					let ty = coords[1];
					self.tooltipShow(tx+self.margin.left, ty+self.yscale.bandwidth()/2);

				}
			})
			.on("mouseout", function () {
				if (self.editMode) {
				} else {
					//self.focus.style('opacity', 0);
					self.tooltipHide();
					d3.select(this).attr("stroke", "pink").attr("stroke-width", 0.2);
				}
			})
			.on('click', function (event, d) {
				console.log(d.name);
			});

	}
	_checkYScroller() {
		var self = this;

		function display(event) {		// V6 added event
			let y = parseInt(d3.select(this).attr("y"));
				//v5 vny = y + d3.event.dy,
			let ny = y + event.dy;
			let w = parseInt(d3.select(this).attr("height")), f, nf;

			if (ny < 0) { // drug off the top (minimum)
				if (self._dataRange[0] === 0)
					return;
				d3.select(this).attr("y", y);
				nf = 0;
			} else if (ny + w > Math.ceil(self.height)) { // drug off bottom
				if (self._dataRange[1] === self.data.length)
					return;
				d3.select(this).attr("y", self.height - d3.select(this).attr("height"));
				nf = self.data.length - self.numBars;
			} else {
				d3.select(this).attr("y", ny);
				f = self.yDisplayed(y);
				nf = self.yDisplayed(ny);
				if (f === nf) return;
			}

			//        if ( ny < 0 || ny + w > Math.ceil(self.height) ) return;
			//        d3.select(this).attr("y", ny);
			//        f = self.displayed(y);
			//        nf = self.displayed(ny);
			//        if ( f === nf ) return;
			self.cur_data = self.data.slice(nf, nf + self.numBars);
			self._dataRange = [nf, nf + self.numBars];

			// 2023/11
			let fitArray = [];
			for (let i=0; i<self.numBars; i++) {
				fitArray.push(i);
			}
			self.yscale.domain(fitArray.map(function (d, ix) { return ix; }));

			self._yaxis_add();

			self.draw_bars();

			self._setBarsMouseEvents();

			self._bringEditToFront();

		};

		if (this.isYScrollDisplayed) {
			// V5
			var yOverview = d3.scaleBand()
				.domain(self.data.map(function (d, ix) { return ix; }))
				.range([0, self.height])
				.paddingInner(0.1);

			self.xOverview = d3.scaleTime().range([0, self.widthOverview]);
			self.xOverview.domain(self.date_range);

			self.diagram.selectAll('.overviewBarG').remove();

			var subBars = self.diagram.append('g')
				.attr("class", "overviewBarG")
				.attr("transform", "translate(" + -(self.margin.left) + ",0)");
//				.attr("transform", "translate(" + -(self.yAxisColumnOffsets[0]+self.widthOverview) + ",0)");

			subBars.selectAll('.ovBar').remove();

			subBars.selectAll('.ovBar')
				.data(self.data)
				.enter()
				.append('rect')
				.attr('class', 'ovBar')
				.attr('width', function (d) {
					return self.xOverview(d.end) - self.xOverview(d.start);
				})
				.attr('height', function (d) {
					return yOverview.bandwidth();
				})
				.attr('x', function (d) {
					return self.xOverview(d.start); // 0;
				})
				.attr('y', function (d, lix) {
					return yOverview(lix);
				})


			// V5
			self.yDisplayed = d3.scaleQuantize()
				.domain([0, self.height])
//				.range([0, self.data.length]);
				.range(d3.range(self.data.length));

			var ovHeight = Math.round(parseFloat(self.numBars * self.height) / self.data.length);
			// If the grabber is too small, make it bigger
			if (ovHeight < 10) ovHeight = 10;

			self.diagram.select('.overviewMover').remove();

			self.diagram.append("rect")
				.attr("transform", "translate(" + (-self.margin.left) + ",0)")
				.attr("class", "overviewMover")
				.attr("x", 0)
				.attr("y", 0)
				.attr("width", self.selectorHeight)
				.attr("height", ovHeight)
				.attr("pointer-events", "all")
				.attr("cursor", "ns-resize")
				.call(d3.drag().on("drag", display));
		}
	}
}
