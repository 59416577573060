import * as d3 from 'd3';
const tinycolor = require("tinycolor2");

export function clegend2({
  color,
  title,
  tickSize = 6,
  width = 320, 
  height = 44 + tickSize,
  marginTop = 18,
  marginRight = 6,
  marginBottom = 16 + tickSize,
  marginLeft = 6,
  ticks = width / 64,
  tickFormat,
  tickValues,
  barHeight = 10,
  font,
  fontStyle,
  fontColor
} = {}) {
    
    // 4px
    // TITLE
    // 4px
    // 10px image
    // 6px tick
    // 4px
    // TICK LABELS
    // 4px
    
  let vGap = 4;
  let fntHeight = legendTextHeight("YN", font);
  marginTop = vGap*2 + fntHeight;
  marginBottom = vGap*3 + fntHeight + tickSize;
  height = marginTop + marginBottom + barHeight;

    if (!font)
        font = "10px sans-serif";
    if (!fontStyle)
        fontStyle = '';
    if (!fontColor)
        fontColor = '#434343';
    
  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible")
      .style("display", "block");

    
    
    
  let tickAdjust = g => g.selectAll(".tick line").attr("y1", -barHeight);
  let x;

  // Continuous
  if (color.interpolate) {
    const n = Math.min(color.domain().length, color.range().length);

    x = color.copy().rangeRound(d3.quantize(d3.interpolate(marginLeft, width - marginRight), n));

    svg.append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr("href", cl_ramp(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))).toDataURL());  // xlink
  }

  // Sequential
  else if (color.interpolator) {
    x = Object.assign(color.copy()
        .interpolator(d3.interpolateRound(marginLeft, width - marginRight)),
        {range() { return [marginLeft, width - marginRight]; }});

    svg.append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr("href", cl_ramp(color.interpolator()).toDataURL());   // xlink

    // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
    if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        tickValues = d3.range(n).map(i => d3.quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== "function") {
        tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
      }
    }
  }

  // Threshold
  else if (color.invertExtent) {
    const thresholds
        = color.thresholds ? color.thresholds() // scaleQuantize
        : color.quantiles ? color.quantiles() // scaleQuantile
        : color.domain(); // scaleThreshold

    const thresholdFormat
        = tickFormat === undefined ? d => d
        : typeof tickFormat === "string" ? d3.format(tickFormat)
        : tickFormat;

    x = d3.scaleLinear()
        .domain([-1, color.range().length - 1])
        .rangeRound([marginLeft, width - marginRight]);

    svg.append("g")
      .selectAll("rect")
      .data(color.range())
      .join("rect")
        .attr("x", (d, i) => x(i - 1))
        .attr("y", marginTop)
        .attr("width", (d, i) => x(i) - x(i - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", d => d);

    tickValues = d3.range(thresholds.length);
    tickFormat = i => thresholdFormat(thresholds[i], i);
  }

  // Ordinal
  else {
    x = d3.scaleBand()
        .domain(color.domain())
        .rangeRound([marginLeft, width - marginRight]);

    svg.append("g")
      .selectAll("rect")
      .data(color.domain())
      .join("rect")
        .attr("x", x)
        .attr("y", marginTop)
        .attr("width", Math.max(0, x.bandwidth() - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", color);

    tickAdjust = () => {};
  }
    
  svg.append("g")
        .attr("class", "legaxis")
        .style("font", font)
//        .style("stroke", fontColor)
        .style("fill", fontColor)
        .style('font-weight', fontStyle.indexOf('B') !== -1 ? 700 : 400)
        .attr('text-decoration', fontStyle.indexOf('U') !== -1 ? 'underline' : 'none')
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x)
        .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
        .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
        .tickSize(tickSize)
        .tickValues(tickValues))
      .call(tickAdjust)
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop + marginBottom - height - vGap)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        .text(title));
    
    if (fontStyle.indexOf('I') !== -1 ) {
        svg.selectAll('.legaxis text')
            .attr("transform", function (d,i) {
                return " skewX(-20)";    // _ialicSkewDegrees
            });
    }

    // All labels
    try {
        var translateX = [];
        svg.selectAll('.legaxis g')
            .select(function(d, i) {
            let xlate = d3.select(this).attr('transform').match(/translate\((-?\d+\.?\d*),(-?\d+\.?\d*)\)/);
            if (xlate.length === 3) {
                translateX.push(+xlate[1]);
            }
        });
        var labels = [];
        svg.selectAll('.legaxis g text')
            .select(function(d, i) {
            let lbl = d3.select(this).html();
            labels.push({label: lbl, width: legendTextWidth(lbl, font)});
        });
        
        // Possibly adjust labels (if they don't fit, we stagger by TextHeight)
        if (labels.length === translateX.length) {       // seems like they better
            let bStagger = false;
            var newWidth = -1;
            for (let i=0; i<labels.length; i++) {
                if (i === labels.length-1) {
                    if (translateX[i] + labels[i].width/2 > width) {
                        newWidth = marginRight + translateX[i] + labels[i].width/2;   
                    }
                } else if (translateX[i] + labels[i].width/2 > translateX[i+1] - labels[i+1].width/2) {
                    bStagger = true;
                }
            }
            
            if (bStagger) {
                svg.selectAll('.legaxis g')
                    .select(function(d, i) {
                        if (i % 2) {
                            let line = d3.select(this).select('line');
                            let y2 = +line.attr('y2') + fntHeight;
                            line.attr('y2', y2);
                            let txt = d3.select(this).select('text');
                            let xform = txt.attr('transform');
                            if (!xform) xform = "";
                            xform = "translate(0, " + fntHeight + ")" + xform;
                            txt.attr('transform', xform);
                        }
                    });
            }
            
            if (newWidth !== -1) {
                // adjust svg.attr('width') and .attr('viewbox')
                svg.attr('width', newWidth)
                    .attr('viewBox', [0, 0, newWidth, height]);
            }
        }
        
    } catch (ex) {
        
    }

    
    // select all .legaxis g 
    //    get translates x value.  save them
    //    get all labels  .legaxis g text VALUE
    // need textWidth of each label to see if it fits,
    // if not, then alternate every 2, add translate to the transform (keeping skewX if there)
    //      and change <line> y2 to be += same offset (text height)
    

  return svg;       // GGC dom element return: .node();
}

function legendTextHeight(s, fnt) {
    var fontSizer = document.getElementById('fontSizer');
    fontSizer.textContent = s;
    fontSizer.style.font = (typeof(fnt) !== 'undefined') ? fnt : "10px sans-serif";
    return (fontSizer.clientHeight + 1);    
}
export function legendTextWidth(s, fnt) {
        var fontSizer = document.getElementById('fontSizer');
        fontSizer.textContent = s;
        fontSizer.style.font = (typeof(fnt) !== 'undefined') ? fnt : "10px sans-serif";
        return (fontSizer.clientWidth + 1);    
}

function cl_ramp(color, n = 256) {
  const canvas = document.createElement('canvas');  // GGC DOM.canvas(n, 1);
    canvas.width = n;           // GGC
    canvas.height = 1;          // GGC
  const context = canvas.getContext("2d");
  for (let i = 0; i < n; ++i) {
    context.fillStyle = color(i / (n - 1));
    context.fillRect(i, 0, 1, 1);
  }
  return canvas;
}

export class cl_gradient {
    constructor(grad) {
        var paletteCanvas = document.createElement('canvas');
        var paletteCtx = paletteCanvas.getContext('2d');

        paletteCanvas.width = 256;
        paletteCanvas.height = 1;

        var gradient = paletteCtx.createLinearGradient(0, 0, 256, 1);
        for (var key in grad) {
          gradient.addColorStop(key, grad[key]);
        }

        paletteCtx.fillStyle = gradient;
        paletteCtx.fillRect(0, 0, 256, 1);

        this.gradientData = paletteCtx.getImageData(0, 0, 256, 1).data;
    }
    
    interpolate(r) {
        if (r < 0 || r > 1) r -= Math.floor(r);
        let ix=4*Math.floor(r * 255);
        // this.gradientData[ix] = r, ix+1=g, ix+2=b
        return new tinycolor({r: this.gradientData[ix], g: this.gradientData[ix+1], b: this.gradientData[ix+2]}).toHexString();
    }
}
