import React, { useCallback, useState } from 'react';
import { colors, Divider } from '../../utils/Utils';
import { _genId } from '../../graphs/domutils';
import { cvtFontSize } from '../../utils/Utils';
import TreeAccordion from '../AccordionGroup/TreeAccordion';

// leafProp is the name of the property that indicates a leaf node
const TreeAccordionView2 = ({ item, level, style = {}, leafProp, onSelect = () => null }) => {
	const [treeId, setTreeId] = useState("tvgcc" + _genId());
	const [collapsed, setCollapsed] = useState({});


	const leafLabelClicked = (e) => {
		onSelect(e.currentTarget.dataset.labelid);
		e.stopPropagation();
	}

	const renderNodeName = (item, addMargin) => {
		if (leafProp && item[leafProp]) {
			let marginBottom = 0;
			if (addMargin) {
				marginBottom = 8;
			}
			return (
				<React.Fragment
					key={item.id}
				>
					<div style={{
						display: 'flex', flexDirection: 'row',
						alignItems: 'center', marginBottom: marginBottom, marginLeft: 48, fontSize: cvtFontSize(16), fontWeight: 'normal', color: colors.darktext
					}}>
						<div data-labelid={item.id} style={{ cursor: 'pointer' }} onClick={leafLabelClicked}>
							{item.name}
						</div>
					</div>
				</React.Fragment>

			)
		} else {
			return (
				<div style={{ ...item.style }}>
					{item.name}
				</div>
			)
		}
	}


	const openItem = useCallback((kidId) => {
		
	}, [item]);

	const listItem = useCallback((kidId) => {
		const thisItem = item.children.find((k) => k.id === kidId);
		if (level < 2) {
			if (thisItem.children && thisItem.children.length > 0) {
				return (
					<TreeAccordionView2
						key={thisItem.id}
						item={thisItem}
						level={level + 1}
						style={{ marginLeft: 10 }}
						leafProp={leafProp}
						onSelect={onSelect}
					/>
				)
			} else {	// never happens...
				return renderNodeName(thisItem);
			}
		} else {		// level 3&4 are Location, QuestionLocation
			if (thisItem.children && thisItem.children.length > 0) {
				return (
					thisItem.children.map((location, lix) => {		// Locations
						return (
							<React.Fragment
								key={lix}
							>
								<div style={getAccordionTitleStyle(3)}>
									{location.name}
								</div>
								{location.children && location.children.map((question, qix) => {		// Questions
									return (
										<div key={question.id} style={{ marginTop: 8, marginBottom: 8 }}>
											{renderNodeName(question, qix < location.children.length - 1)}
										</div>
									)
								})
								}
							</React.Fragment>
						)
					}
					)
				)

			}

		}

	}, [item]);

	const getAccordionColor = () => {
		switch (level) {
			case 0:
				return '#FFFFFF';
			case 1:
				return '#F000B4';
			case 2:
			default:
				return '#FFFFFF';
		}
	}
	const getArrowColor = () => {
		switch (level) {
			case 1:
				return '#FFFFFF';
			case 2:
			default:
				return '#555555';
		}
	}

	const getDividerColor = () => {
		switch (level) {
			case 0:
				return '#cdcdcd';
			case 1:
				return '#eeeeee';
			case 2:
			default:
				return '#cdcdcd';
		}
	}

	const getAccordionTitleStyle = (lev) => {
		switch (lev) {
			case 0:
				return { fontSize: cvtFontSize(20), fontWeight: '600', color: colors.darktext };
			case 1:
				return { fontSize: cvtFontSize(18), fontWeight: 'bold', color: '#ffffff' };
			case 2:
				return { fontSize: cvtFontSize(18), fontWeight: 'normal', color: colors.darktext };
			case 3:
				return { marginLeft: 20, fontSize: cvtFontSize(16), fontWeight: '600', color: colors.darktext };
			case 4:
				return { marginLeft: 40, fontSize: cvtFontSize(16), fontWeight: 'normal', color: colors.darktext };
			default:
					return { fontSize: cvtFontSize(16), fontWeight: 'normal', color: colors.darktext };
		}
	}

	if (item.children && item.children.length > 0) {
		let leafs = [];
		let accGroup = [];
		for (let i=0; i<item.children.length; i++) {
			const kid = item.children[i];
			if (kid.leaf) {
				leafs.push(kid);
			} else {
				accGroup.push({title: kid.name, id: kid.id});
			}
		}
		if (leafs.length > 0) {
			return (
				<div style={{ marginTop: 8, marginBottom: 8 }}>
					{leafs.length > 0 && leafs.map((leaf, lix) => {
						return renderNodeName(leaf, lix < leafs.length - 1);
					})
					}
				</div>
			)
		} else if (accGroup.length > 0) {
			return (
				<TreeAccordion
					titles={accGroup}
					listItem={listItem}
					openItem={openItem}
					accordionColor={getAccordionColor()}
					arrowColor={getArrowColor()}
					dividerColor={getDividerColor()}
					titleMargin={0}
					accordionTitleStyle={getAccordionTitleStyle(level)}

				/>
			)	
		}
	} else {
		return null;		// never happens
	}
}

export default TreeAccordionView2;