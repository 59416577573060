const amplifyConfig = {
	"aws_appsync_graphqlEndpoint": "https://sy35eokcbzcrvjomxqrx3xgawy.appsync-api.us-east-1.amazonaws.com/graphql",
	"aws_appsync_region": "us-east-1",
	'aws_appsync_authenticationType': "AWS_LAMBDA",	
	API: {
    endpoints: [
      {
        name: "apiDashboard",
        endpoint: "https://286543zore.execute-api.us-east-1.amazonaws.com/devp"
      },
      {
        name: "apiPDF",
        endpoint: "https://zcspi4rpw6.execute-api.us-east-1.amazonaws.com/devp"
      }
    ]
  }
}
export default amplifyConfig;