import React, {useEffect, useState} from 'react';
import { _genId } from '../../graphs/domutils';
import { useTranslation } from 'react-i18next';

// dataList is an array of objects {id: <>, name: <>, use: <boolean>}
// checkField is name of boolean field to toggle.
const DropDownChecks = ({style={}, dataList, checkField='use', onChange=()=>null, onDone=()=>null}) => {
	const [filteredList, setFilteredList] = useState([]);
	const [mySearchId] = useState('s' + _genId());
	const [myDiv] = useState('ddc' + _genId());

	const [t] = useTranslation();

	const handleClick = (e) => {
		const ourElement = document.getElementById(myDiv);
		if (ourElement && ourElement.contains(e.target)){
			// Clicked in box
		} else {
			e.stopPropagation();
			onDone();
		}
	}
	
	useEffect(() => {
		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		}
	}, []);
	
	useEffect(() => {
		setFilteredList(dataList);
	}, [dataList]);

	const onSearchKey = (e) => {
		let x = document.getElementById(mySearchId);
		let val = x.value.toLowerCase();
		if (val === "") {
			setFilteredList(dataList);
		} else {
			let filtered = dataList.filter((item) => {
				return item.name.toLowerCase().includes(val);
			});

			setFilteredList(filtered);
		}	
	}

	const renderItem = (item, ix) => {
		return (
			<div
				key={ix}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: 8,
					borderBottom: '1px solid #cdcdcd',
					cursor: 'pointer'
				}}
			>
				<div>{item.name}</div>
				<div>
					<input
						type="checkbox"
						checked={item[checkField]}
						onChange={() => {
							let newItem = {...item};
							newItem[checkField] = !newItem[checkField];
							onChange(newItem);
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<div
			id={myDiv}
			style={
				{
					...style
				}
			}
		>
			<div style={{ margin: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<input id={mySearchId} type="text" placeholder={t("search")} onKeyUp={onSearchKey}
					style={{
						width: '100%', height: 30, borderRadius: 5, border: '1px solid #ccc', padding: 5
					}}
				/>
				<div style={{ width: 20 }}></div>
				<div style={{ width: 20 }}></div>
			</div>

			{filteredList.map((item, ix) => renderItem(item, ix))}
		</div>
	);
}

export default DropDownChecks;