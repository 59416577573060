import React from "react";
import { colors } from "../../utils/Utils";

const HorizontalSizer = ({value, parentId, max=100, padding=0, onChange={}}) => {

	const onMouseMove = (e) => {
		e.preventDefault();
		let y = e.clientY;
		let bbox = document.getElementById(parentId).getBoundingClientRect();
		const height = bbox.bottom - bbox.top;
		if (y < bbox.top) {
			onChange(0);
		} else if (y > bbox.top + (max * height/100)) {
			onChange(max);
		} else {
			onChange(100 * (y - bbox.top) / height);
		}
	}

	const onMouseUp = (e) => {
		e.preventDefault();
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseup', onMouseUp);
	}

	if (!parentId || !document.getElementById(parentId)) {
		return null;
	}

	const parentRect = document.getElementById(parentId).getBoundingClientRect();
	const pWidth = parentRect.right - parentRect.left;

	// top is value% + padding
	// 		<div style={{width: `${pWidth}px`, position: 'abslolute', top:`calc(${value}% + ${padding}px)`, left: 0, height: 2, backgroundColor: colors.primary}} 

	return (
		<div style={{width: `${pWidth}px`, position: 'relative', top:`0px`, left: `-${padding}px`, height: '2px', backgroundColor: colors.primary}} 
		  onMouseOver={(e) => {
				e.preventDefault();
				e.target.style.cursor = 'row-resize';				
				e.target.style.height = '4px';
			}}
			onMouseOut={(e) => {
				e.preventDefault();
				e.target.style.cursor = 'auto';				
				e.target.style.height = '2px';
			}}
			onMouseDown={(e) => {
				e.preventDefault();
				e.stopPropagation();
				document.addEventListener('mousemove', onMouseMove);
				document.addEventListener('mouseup', onMouseUp);
			}}
		></div>
	)
}
export default HorizontalSizer;