
import * as d3 from 'd3';
import $ from "jquery";
import GGCPrimitives from './GGCPrimitives';
import { axisGGC } from './axisggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

export class hBarGGC extends axisGGC {
    constructor(args) {
        super(args);

        this.superClass = 'hBarGGC';

        this.selectorHeight = 50;
        this.heightOverview = 40;
        this.active_link = "0";

        this.grouped = false;
        this.minBarWidth = 10;
        this.origMinBarWidth = this.minBarWidth;
        this.barOutlineWidth = 1;
        this.barOutlineColor = '#E8EAED'.toColorRef(); 
        this.barOutlineStrokeDashArray = '0';
        this.hasXGrid = true;
        this.padding = 0;

        this.y_orig = undefined;

        this.numBars = undefined;
        this.isScrollDisplayed = undefined;
        this.xscale = undefined;
        this.yscale = undefined;
        this.xAxis = undefined;
        this.xAxisGrid = undefined;
        this.yAxis = undefined;
        this.barsHolder = undefined;
        this.idx = undefined;
        this._axisLabelHeight = undefined;
        this.barWidth = undefined;
        this.cur_data = undefined;
        this.align = .5;        // evenly distribute
        this.topPadding = 0;
        this.paddingInner = 0.05;
        this.paddingOuter = 0.025;


        this._yLabelWidth = undefined;

        this.y1 = undefined;
        this.ntips = undefined;
        this.svgId = undefined;
        this._pickerId = undefined;
        this._computedBarWidth = undefined;

        this._dataRange = undefined;
        this.viewBoxAdjustHeight = 0;

        this._init(args);
    }
    getGraphType() {
        if (this.grouped)
            return 'hbar';
        else
            return 'shbar';
    }
    _init(args) {
        //2023 this._axis_init(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var axis = self._axis_saveProps();
        var me = {
            hasXGrid: self.hasXGrid,
            selectorHeight: self.selectorHeight,
            heightOverview: self.heightOverview,
            grouped: self.grouped,
            minBarWidth: self.minBarWidth,
            barOutlineWidth: self.barOutlineWidth,
            barOutlineColor: self.barOutlineColor,
            barOutlineStrokeDashArray: self.barOutlineStrokeDashArray,
            _computedBarWidth: self._computedBarWidth,
            paddingInner: self.paddingInner,
            paddingOuter: self.paddingOuter,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight
        };
        if (this._computedBarWidth !== this.barWidth)
            me.barWidth = this.barWidth;

        return { ...axis, ...me };
    }
    _initVariables(args) {

        this.isHorizontal = true;
        this._autoMarginLeft = true;

        if (args.hasOwnProperty('selectorHeight'))
            this.selectorHeight = args.selectorHeight;

        if (args.hasOwnProperty('heightOverview'))
            this.heightOverview = args.heightOverview;


        if (args.hasOwnProperty('grouped'))
            this.grouped = args.grouped;
        if (args.hasOwnProperty('minBarWidth'))
            this.minBarWidth = args.minBarWidth;

        if (args.hasOwnProperty('barOutlineWidth'))
            this.barOutlineWidth = args.barOutlineWidth;

        if (args.hasOwnProperty('barOutlineColor')) {
            this.barOutlineColor = GGCPrimitives._argsToColorRef(args, 'barOutlineColor');
        }

        if (args.hasOwnProperty('barOutlineStrokeDashArray'))
            this.barOutlineStrokeDashArray = args.barOutlineStrokeDashArray;

        if (args.hasOwnProperty('barWidth'))
            this.barWidth = args.barWidth;

        if (args.hasOwnProperty('_computedBarWidth'))
            this._computedBarWidth = args._computedBarWidth;

        if (args.hasOwnProperty('hasXGrid'))
            this.hasXGrid = args.hasXGrid;

        if (args.hasOwnProperty('paddingInner'))
            this.paddingInner = args.paddingInner;
        if (args.hasOwnProperty('paddingOuter'))
            this.paddingOuter = args.paddingOuter;

        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }

    }
    _trimEllipses(s) {
        if (s && s.length > this.ellipses)
            return s.substr(0, this.ellipses) + '...';
        else
            return s;
    }
    resize(opts) {
        var self = this;
        let svgWidth, svgHeight;
        let width, height;
        let gonnaDraw = true;

        // We need to check this because if we don't draw, then we've set the outerWidth/Height to something
        // from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                gonnaDraw = false;
            }
        }

        if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
            const vbCoords = this.viewBox.split(' ');
            width = parseInt(vbCoords[2]);
            height = parseInt(vbCoords[3]);
            svgWidth = opts.width;
            svgHeight = opts.height + this.viewBoxAdjustHeight;
        } else {
            width = opts.width;
            height = opts.height;
        }

        self.outerWidth = width;
        self.outerHeight = height;

        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();
        // remove any leftover popovers
        $('.popover').popover('hide');

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;

        if (self._pagingControl) {
            this.origHeight -= $(self._pagingControl).height();
            this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        if (self._sortDivId) {    // _sortTableId GGC 2023 - outer div is _sortDivId
            this.origHeight -= $('#' + self._sortDivId).height();
            this.viewBoxAdjustHeight = -$('#' + self._sortDivId).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);

        // TODO: perhaps rotate this and measure 'height'
        this._axisLabelHeight = this._textHeight('Yg', this.yAxisFont) * 2.5;
        this._axisLabelAscent = this._textAscent('g', this.yAxisFont);

        this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City

        var valueMax = null;
        var valueMin = null;
        var sumLen = 0;
        self.maxCharLen = 0;

        this.data.forEach(function (d, ix) {
            var myLabel = self._trimEllipses(d[self.ixLabel]);
            var pixLen = self._textWidth(myLabel, self.yAxisFont);
            sumLen += pixLen;
            if (pixLen > self.maxCharLen) {
                self.maxCharLen = pixLen;
            }

            var y0 = 0;
            d.values = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                    let name = self.color.domain()[i];
                    let val = +d[self._valueIndex(name)];
                    if (self.grouped)
                        d.values.push({ myix: ix, vi: i, /*myLabel:myLabel,*/ name: name, y0: y0, y1: val });

                    else
                        d.values.push({ myix: ix, vi: i, /*myLabel:myLabel,*/ name: name, y0: y0, y1: y0 += +d[self._valueIndex(name)] });

                }
            }

            if (self.grouped) {
                d.total = d3.max(d.values.map(function (d) { return d.y1; }));
            } else {
                if (d.values.length > 0) {
                    d.total = d.values[d.values.length - 1].y1;
                } else {
                    d.total = 0;
                }
            }
            if (valueMax == null || d.total > valueMax)
                valueMax = d.total;
            if (valueMin == null || d.total < valueMin)
                valueMin = d.total;
        });

        if (valueMin > 0) valueMin = 0;

        //this.maxCharLen = 1.2 * (sumLen / this.data.length);


        // 2* in case we need to stagger
        this.height = self.origHeight - this.margin.top - this.margin.bottom - 2 * self._textHeight('Yg', self.xAxisFont ? self.xAxisFont : self.font);

        if (typeof self.barWidth === 'undefined') { // don't change if we are refreshing from edit
            self.barWidth = self._textHeight('Yg', self.yAxisFont ? self.yAxisFont : self.font) * 2.0;

            if (this.grouped) {
                if (this.barWidth < this.minBarWidth * this.valueNames.length)
                    this.barWidth = this.minBarWidth * this.valueNames.length;
            }
            this._computedBarWidth = this.barWidth;
        }

        this.numBars = Math.round(this.height / self.barWidth);

        self._computeWidthAndScroll();


        this.svgId = this._genId();
        if (!isRuntimeOnly()) {
            this.viewBox = [0, 0, self.origWidth, self.origHeight].join(' ');
            svgWidth = self.origWidth;
            svgHeight = self.origHeight;
        }
        this.svg = d3.select(this.container).append("svg")
            .attr('xmlns', "http://www.w3.org/2000/svg")
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .attr("viewBox", this.viewBox)
            .attr('id', self.svgId);


        this.diagram = this.svg.append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this.color.domain(this.valueNames);
        this._colorDomainSet(['barOutlineColor']);
        self._fillBackground(this.svg);

        this.cur_data = self.data.slice(0, this.numBars);
        this._dataRange = [0, this.numBars];

        this.paddingOuter = Math.max(0.1, self.paddingInner / 2);

        this.yscale = d3.scaleBand()
            .domain(self.cur_data.map(function (d, ix) { return ix; }))
            .range([0, this.height])
            .paddingInner(self.paddingInner)
            .paddingOuter(self.paddingOuter)
            .align(self.align);


        if (this.grouped) {
            this.y1 = d3.scaleBand()
                .domain(self.valueNames)
                .range([0, self.yscale.bandwidth()])
                .paddingInner(0.05)       // GGC 2023/11/1
                .paddingOuter(0)

        }
        this.topPadding = self.yscale.step() * self.paddingOuter * this.align * 2;


        self.ntips = 0;
        if (self.grouped) {
            self.ntips = 1;
        } else {
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    self.ntips++;
            }
        }
        this.tooltipCreate(self.ntips);

        // if domain changes (say we get new data bigger than valueMax...)
        // axis transition: https://stackoverflow.com/questions/13513177/display-only-whole-values-along-y-axis-in-d3-js-when-range-is-0-1-or-0-2
        this.xscale = d3.scaleLinear()
            .domain([valueMin, valueMax])
            .range([0, this.width]);
            
        const nDigits = Math.floor(Math.log10(valueMax)) + 1;

        this.yAxis = d3.axisLeft(this.yscale);
        this.xAxis = d3.axisBottom(this.xscale)
            .ticks(nDigits > 1 ? this.maxTicks : this.minTicks)
            .tickFormat(this._axis_doFormat);
        this.xAxisGrid = d3.axisBottom(this.xscale).tickFormat('');

        this._axis_groupLabels(0);

        this._axis_focusY();

        this._axis_drawXY();

        this._drawBarsAndRects();

        this._setBarsMouseEvents();

        this._base_makeLegend({ resize: true });

        this._checkScroller();

        var kind;
        if (typeof opts !== 'undefined') {
            if (opts.hasOwnProperty('kind')) {
                kind = opts.kind;
            }
        }
        
        this._axis_drawXY_edit();
        this.checkEditMode(kind);
    }
    //hBarGGC.prototype.closeEdit = function(self) {
    //    
    //}
       // Updates axis dimensions based on yStackMax
    // https://stackoverflow.com/questions/13513177/display-only-whole-values-along-y-axis-in-d3-js-when-range-is-0-1-or-0-2

    _update() {

        // Do we have a new valueMax
        let xStackMax = d3.max(this.data, function (d) { return d.total; });    // ggc ?
        let decimals = this.countDecimals(xStackMax)
        if (xStackMax < this.maxTicks) {
            this.format = this.defaultFormat
            this.x_axis.ticks(this.minTicks)
        }
        else {
            this.x_axis.ticks(this.maxTicks)
            if     (decimals < 3 ) this.format = this.defaultFormat;
            else if(decimals < 6 ) this.format = this._axis_toKilo;
            else if(decimals < 9 ) this.format = this._axis_toMega;
            else if(decimals < 12) this.format = this._axis_toGiga;
            else                   this.format = this._axis_toTerra;
        }

        this.diagram.transition()
            .duration(this.interval)
            .ease("linear")
            .call(this.x_axis);
    }

    _computeWidthAndScroll() {
        var self = this;

        self.isScrollDisplayed = self.barWidth * self.data.length > self.height;
        // Compute left margin (y axis)    
        var pixelwidth = self.maxCharLen + 9 + self._axisLabelHeight / 2; //-9 is text start, add a txtht gap

        // Any 'horizontal' graph needs a _yLabelWidth as tehe axisggc uses it
        self._yLabelWidth = pixelwidth;

        pixelwidth += (this.categoryNames.length - 1) * self._axisLabelHeight; // really rotated 'width'
        if (self.isScrollDisplayed) {
            pixelwidth += self.selectorHeight;
        }

        // set max to half of available width
        if (pixelwidth > (self.origWidth - self.margin.right - self.legendSpace - self.extraRightMargin)/2) {
            pixelwidth = (self.origWidth - self.margin.right - self.legendSpace - self.extraRightMargin)/2;
        }

        // was trying to use max of margin.left if we enabled leftAxis dragging.
        self.margin.left = pixelwidth;  // self.margin.left > pixelwidth ? self.margin.left : pixelwidth;

        self.width = self.origWidth - self.margin.left - self.margin.right - self.legendSpace - self.extraRightMargin;
    }
    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }
    checkEditMode(kind) {
        var self = this;

        self._axis_checkEditMode(kind);

        if (self.editMode) {
            let x = self.origWidth / 4;
            let y = Math.max(self.margin.top/2, 0);
            self._drawCircleEditColor({
                selector: self.diagram,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    colorRefProp: 'backgroundColor'
                }

            });

            // TODO: find a bar corner
            y += 30;
            self._drawCircleEditBarWidth({
                selector: self.diagram,
                kind: 'baroutline',
                title: i18next.t("graph.edit.bar_gap_outline"),
                x: x,
                y: y,
                r: 12,
                placement: 'top',
                variables: {
                    barWidthProp: 'paddingInner',       // ggc 'barWidth',
                    strokeWidthProp: 'barOutlineWidth',
                    colorRefProp: 'barOutlineColor',
                    strokeDashArrayProp: 'barOutlineStrokeDashArray'
                }

            });

        }

    }
    refresh(kind) {
        var self = this;

        var valueMax = null;
        var valueMin = null;
        var bForceRefresh = false;

        if (typeof self.barWidth === 'undefined' || this._computedBarWidth === this.barWidth) {
            self.barWidth = self._textHeight('Yg', self.yAxisFont ? self.yAxisFont : self.font) * 2.0;

            if (this.grouped) {
                if (this.barWidth < this.minBarWidth * this.valueNames.length)
                    this.barWidth = this.minBarWidth * this.valueNames.length;
            }

            if (this._computedBarWidth !== this.barWidth) {
                bForceRefresh = true;
            }
            this._computedBarWidth = this.barWidth;

        }

        // barWidth can change in 'edit' mode
        this.numBars = Math.round(this.height / self.barWidth);

        var scrollBefore = this.isScrollDisplayed;

        self._computeWidthAndScroll();

        if (bForceRefresh) {
            this.diagram
                .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
        }


        this.xscale.range([0, this.width]); // if width changes because of isScrollDisplayed

        if (scrollBefore && !this.isScrollDisplayed) {
            self.diagram.selectAll('.overviewBar').remove();
            self.diagram.selectAll('.overviewMover').remove();
            self._axis_drawXY();
        } else if (bForceRefresh || (!scrollBefore && this.isScrollDisplayed)) {
            // need to show scroly area
            self._axis_drawXY();
            this._checkScroller();
            // TODO: move edit margin.left
        } else {
            self._axis_refresh();
        }

        if (this.grouped) {
            this.y1 = d3.scaleBand()
                .domain(self.valueNames)
                .range([0, self.yscale.bandwidth()])
                .paddingInner(0.05)       // GGC 2023/11
                .paddingOuter(0)
            }


        self._colorDomainSet(['barOutlineColor']); // really updates palette based on interpolate or not

        var sumLen = 0;
        self.maxCharLen = 0;

        this.data.forEach(function (d, ix) {
            var myLabel = self._trimEllipses(d[self.ixLabel]);
            var pixLen = self._textWidth(myLabel, self.yAxisFont);
            sumLen += pixLen;
            if (pixLen > self.maxCharLen)
                self.maxCharLen = pixLen;

            var y0 = 0;

            d.values = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                    let name = self.color.domain()[i];
                    let val = +d[self._valueIndex(name)];
                    if (self.grouped)
                        d.values.push({ myix: ix, vi: i, /*myLabel:myLabel,*/ name: name, y0: y0, y1: val });

                    else
                        d.values.push({ myix: ix, vi: i, /*myLabel:myLabel,*/ name: name, y0: y0, y1: y0 += +d[self._valueIndex(name)] });

                }
            }
            if (self.grouped) {
                d.total = d3.max(d.values.map(function (d) { return d.y1; }));
            } else {
                if (d.values.length > 0) {
                    d.total = d.values[d.values.length - 1].y1;
                } else {
                    d.total = 0;
                }
            }
            if (valueMax == null || d.total > valueMax)
                valueMax = d.total;
            if (valueMin == null || d.total < valueMin)
                valueMin = d.total;
        });
        if (valueMin > 0) valueMin = 0;
        // Compute maxCharLen as the average or look at variance so not lopsided
        this.maxCharLen = 1.2 * (sumLen / this.data.length);

        this.cur_data = self.data.slice(0, this.numBars);
        this._dataRange = [0, this.numBars];

        self.paddingOuter = Math.max(0.1, self.paddingInner / 2);
        this.yscale = d3.scaleBand()
            .domain(self.cur_data.map(function (d, ix) { return ix; }))
            .range([0, this.height])
            .paddingInner(self.paddingInner)
            .paddingOuter(self.paddingOuter);

        this.topPadding = self.yscale.step() * self.paddingOuter * this.align * 2;

        this.yAxis = d3.axisLeft(this.yscale);

        this.xscale.domain([valueMin, valueMax]);

        self._axis_refresh(); // changes properties and redraws text
        self._axis_groupLabels(0); // changes props of groups and redraws text and lines

        self.ntips = 0;
        if (self.grouped) {
            self.ntips = 1;
        } else {
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    self.ntips++;
            }
        }
        this.tooltipCreate(self.ntips);

        this._drawBarsAndRects();

        //2023 why was this here GGC? rect.exit().remove();

        self._setBarsMouseEvents();

        self._base_makeLegend();

    }
    _drawBarsAndRects() {
        var self = this;

        if (this.grouped) {
            let vn = [];
            for (let i = 0; i < self.valueNames.length; i++) {
                if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/)
                    vn.push(self.valueNames[i]);
            }
            self.y1 = d3.scaleBand()
                .domain(vn)
                .range([0, self.yscale.bandwidth()])
                .paddingInner(0.05)       // GGC 023/11
                .paddingOuter(0)

        }

        // just draw bars...
        if (self.barsHolder)
            self.barsHolder.remove();

        self.barsHolder = self.diagram.selectAll(".barsHolder")
            .data(self.cur_data)
            .enter()
            .append("g")
            .attr("class", "g")
            .attr("transform", function (d) { return "translate(0,0)"; });

        self.barsHolder.exit().remove();

        let rect = self.barsHolder.selectAll("rect");

        rect
            .data(function (d) {
                return d.values;
            })
            .enter()
            .append("rect")
            .attr("height", function (d) {
                if (self.grouped)
                    return self.y1.bandwidth();

                else
                    return self.yscale.bandwidth();
            })
            .attr("x", function (d) {
                return self.xscale(d.y0);
            })
            .attr("y", function (d) {
                if (self.grouped)
                    return self.yscale(d.myix) + self.y1(d.name);

                else
                    return self.yscale(d.myix);
            })
            .attr("width", function (d) {
                return Math.abs(self.xscale(d.y0) - self.xscale(d.y1));
            })
            .attr("class", function (d) {
                let classLabel = self._fixName(d); //remove spaces
                return "class" + classLabel;
            })
            .style("fill", function (d) {
                return self._getFillColor(d.vi);
            })
            .style("opacity", function (d) {
                return self._getOpacity(d.vi);
            })
            .style("stroke-width", function (d) {
                return self.barOutlineWidth;
            })
            .style("stroke", function (d) {
                return GGCPrimitives._getColorFromRef(self.barOutlineColor);
            })
            .style("stroke-opacity", function (d) {
                return GGCPrimitives._getOpacityFromRef(self.barOutlineColor);
            })
            .style('stroke-dasharray', function (d) {
                return self.barOutlineStrokeDashArray;
            });

    }
    _setBarsMouseEvents() {
        var self = this;
        this.barsHolder.selectAll("rect")
            .on('mouseover', function () {
                if (self.editMode) {
                } else {
                    self.focus.style('opacity', 1);
                }
            })
            .on("mousemove", function (event, d) {      // v6 added event
                if (self.editMode) {
                } else {
                    //v5 let y = d3.event.offsetY;
                    let coords = d3.pointer(event);
                    let y = coords[1];

                    // left-pad = step × paddingOuter × align × 2
                    var ypos = y - self.topPadding;

                    var y0 = Math.floor(ypos / (self.yscale.step()));
                    if (y0 < 0) y0 = 0;
                    else if (y0 >= self.cur_data.length) y0 = self.cur_data.length - 1;

                    y0 += self.yscale.domain()[0];
                    d3.select(this).attr("stroke", "blue").attr("stroke-width", 0.8);

                    if (self.grouped) {
                        var nsubs = 0;
                        for (let i = 0; i < self.valueNames.length; i++) {
                            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                                nsubs++;
                            }
                        }
                        var ypossub = y - self.yscale(y0);
                        var y0sub = Math.floor(ypossub / self.y1.step());
                        if (y0sub < 0) y0sub = 0;
                        else if (y0sub >= nsubs) y0sub = nsubs - 1;

                        let fy = self.margin.top + self.yscale(y0) +  y0sub * self.y1.step() + self.y1.bandwidth() / 2;
                        if (self.y1.step() !== self.y1.bandwidth()) {
                            fy += (self.y1.step() - self.y1.bandwidth()) / 2;
                        }

                        self.focus
                            .attr("y1", fy)
                            .attr("y2", fy)
                            .attr('x1', self.xscale(self.xscale.domain()[0]) + self.margin.left)
                            .attr('x2', self.xscale(self.xscale.domain()[1]) + self.margin.left);
                        self.tooltipSetRow(0, d.name, (d.y1 - d.y0));
                    } else {
                        let fy = self.margin.top + self.yscale(y0) + self.yscale.bandwidth() / 2;

                        self.focus
                            .attr("y1", fy)
                            .attr("y2", fy)
                            .attr('x1', self.xscale(self.xscale.domain()[0]) + self.margin.left)
                            .attr('x2', self.xscale(self.xscale.domain()[1]) + self.margin.left);
                        let lix = 0;
                        y0 -= self.yscale.domain()[0];
                        for (let i = 0; i < self.valueNames.length; i++) {
                            if (self.valueStatus[i] !== self._hiddenOpacity /*0.25*/) {
                                var delta = self.cur_data[y0].values[i].y1 - self.cur_data[y0].values[i].y0;
                                self.tooltipSetRow(self.ntips - lix - 1, self.valueNames[i], delta);
                                lix++;
                            }
                        }
                    }
                    //self.tooltipShow(x0 + self.margin.left + svgRect.left, y + svgRect.top);
                    // 0, 0 is corner of this.container, even if scrolled
                    //v5 let tx = d3.event.offsetX;
                    //v5 let ty = d3.event.offsetY;
                    let tx = coords[0];
                    let ty = coords[1];
                    self.tooltipShow(tx+self.margin.left, (ty+self.margin.top));

                }


            })
            .on("mouseout", function () {
                if (self.editMode) {
                } else {
                    self.focus.style('opacity', 0);
                    self.tooltipHide();
                    d3.select(this).attr("stroke", "pink").attr("stroke-width", 0.2);
                }
            });

        // Highlight the Category axis labels on mouseover
        this.diagram.select(".y.axis")
            .selectAll('.tick')
            .on("mouseover", function (event, d) {      // v6 added event
                if (self.editMode) {
                } else {
                    // TODO: if text() != attr('textFull'), then show tooltip w/ full text, on click generate event
                    // else, do default display bolding.  this.getBoundingClientRect()  gets x, y,width,height
                    var fullText = d3.select(this).select("text").attr('fullText');
                    if (fullText && (fullText !== d3.select(this).select("text").text())) {

                        //v5 var mpos = d3.mouse(self.diagram.node());
                        var mpos = d3.pointer(event, self.diagram.node());

                        self.diagram.append("text")
                            .attr("x", mpos[0] + 3)
                            .attr("y", mpos[1] - 18)    // move away from this pointer, or we keep getting mouseout
                            .attr("class", "ylabelGGC")
                            .text(fullText)
                            .style("cursor", "pointer")
                            .style("font", this.yAxisFont);

                        var tbx = self.diagram.select('.ylabelGGC').node().getBBox();

                        self.diagram.insert('rect', '.ylabelGGC')
                            .attr('x', tbx.x - 2)
                            .attr('y', tbx.y - 2)
                            .attr('width', tbx.width + 4)
                            .attr('height', tbx.height + 4)
                            .attr('class', 'ylabelBox')
                            .style('fill', 'white')
                            .style('stroke', 'blue')
                            .style("cursor", "pointer")
                            .on('click', function () {
                                var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                                var tpsf_event = {
                                    // eid:
                                    // schema:
                                    table: self.tableName,
                                    field: self.categoryNames[ixC],
                                    value: d3.select(this).select("text").attr('fullText') // text()
                                };

                                self.diagram.select(".ylabelBox").remove();
                                self.diagram.select(".ylabelGGC").remove();

                                self.categoryClick(tpsf_event);

                            });

                    }
                    d3.select(this).select('text').attr('fill', 'blue');
                    d3.select(this).select('text').attr('font-weight', 'bold');
                    d3.select(this).style("cursor", "pointer");
                }
            })
            .on("mouseout", function () {
                if (self.editMode) {
                } else {
                    d3.select(this).select('text').attr('fill', self.yAxisLabelColor);
                    d3.select(this).select('text').attr('font-weight', 'normal');
                    d3.select(this).style("cursor", "auto");

                    self.diagram.select(".ylabelBox").remove();
                    self.diagram.select(".ylabelGGC").remove();
                }
            })
            .on("click", function () {
                if (self.editMode) {
                } else {
                    var ixC = self.categoryNames.length - 1; // TODO: get axis index (if multiple)
                    var tpsf_event = {
                        // eid:
                        // schema:
                        table: self.tableName,
                        field: self.categoryNames[ixC],
                        value: d3.select(this).select("text").attr('fullText') // text()
                    };
                    self.categoryClick(tpsf_event);
                }
            });

    }
    _checkScroller() {
        var self = this;

        function display(event) {       // v6 added event
            let y = parseInt(d3.select(this).attr("y"));
                //v5 ny = y + d3.event.dy, 
            let ny = y + event.dy; 
            let w = parseInt(d3.select(this).attr("height")), f, nf;

            if (ny < 0) { // drug off the top (minimum)
                if (self._dataRange[0] === 0)
                    return;
                d3.select(this).attr("y", y);
                nf = 0;
            } else if (ny + w > Math.ceil(self.height)) { // drug off bottom
                if (self._dataRange[1] === self.data.length)
                    return;
                d3.select(this).attr("y", self.height - d3.select(this).attr("height"));
                nf = self.data.length - self.numBars;
            } else {
                d3.select(this).attr("y", ny);
                f = self.displayed(y);
                nf = self.displayed(ny);
                if (f === nf) return;
            }

            //        if ( ny < 0 || ny + w > Math.ceil(self.height) ) return;
            //        d3.select(this).attr("y", ny);
            //        f = self.displayed(y);
            //        nf = self.displayed(ny);
            //        if ( f === nf ) return;
            self.cur_data = self.data.slice(nf, nf + self.numBars);
            self._dataRange = [nf, nf + self.numBars];

            self.yscale.domain(self.cur_data.map(function (d, ix) {
                return nf + ix;
            }));

            self._yaxis_add();
            //        self._xaxis_stagger();    TODO: maybe draw Y axis??
            self.barsHolder.remove();

            self.barsHolder = self.diagram.selectAll(".barsHolder")
                .data(self.cur_data)
                .enter().append("g")
                .attr("class", "g")
                .attr("transform", function (d) { return "translate(0,0)"; });
            self.barsHolder.exit().remove();

            let rect = self.barsHolder.selectAll("rect")
                .data(function (d) {
                    return d.values;
                })
                .enter().append("rect")
                .attr("height", function (d) {
                    if (self.grouped)
                        return self.y1.bandwidth();

                    else
                        return self.yscale.bandwidth();
                })
                .attr("x", function (d) {
                    return self.xscale(d.y0);
                })
                .attr("y", function (d) {
                    if (self.grouped)
                        return self.yscale(d.myix) + self.y1(d.name);

                    else
                        return self.yscale(d.myix);
                })
                .attr("width", function (d) {
                    return Math.abs(self.xscale(d.y0) - self.xscale(d.y1));
                })
                .attr("class", function (d) {
                    let classLabel = self._fixName(d.name); //remove spaces
                    return "class" + classLabel;
                })
                .style("fill", function (d) {
                    return self._getFillColor(d.vi);
                })
                .style("opacity", function (d) {
                    return self._getOpacity(d.vi);
                })
                .style("stroke-width", function (d) {
                    return self.barOutlineWidth;
                })
                .style("stroke", function (d) {
                    return GGCPrimitives._getColorFromRef(self.barOutlineColor);
                })
                .style("stroke-opacity", function (d) {
                    return GGCPrimitives._getOpacityFromRef(self.barOutlineColor);
                })
                .style('stroke-dasharray', function (d) {
                    return self.barOutlineStrokeDashArray;
                });

            rect.exit().remove();

            self._setBarsMouseEvents();

            self._axis_groupLabels(nf);

            self._bringEditToFront();

        };

        if (this.isScrollDisplayed) {
            // V5
            var yOverview = d3.scaleBand()
                .domain(self.data.map(function (d, ix) { return ix; }))
                .range([0, self.height])
                .paddingInner(self.paddingInner)
                .paddingOuter(Math.max(0.1, self.paddingInner / 2));

            var y1O;

            if (self.grouped) {
                y1O = d3.scaleBand()
                    .domain(self.valueNames)
                    .range([0, yOverview.bandwidth()])
                    .paddingInner(this.paddingInner);   // GGC 2023/11
                }

            var xOverview = d3.scaleLinear().range([0, self.heightOverview]);
            xOverview.domain(self.xscale.domain());

            var subBars = self.diagram.selectAll('.overviewBarG')
                .data(self.data)
                .enter().append("g")
                .attr("class", "overviewBarG");

            self.diagram.selectAll('.overviewBarG')
                .attr("transform", "translate(" + (-self.margin.left) + ",0)");

            var rect = subBars.selectAll("rect");

            rect
                .data(function (d) {
                    return d.values;
                })
                .enter()
                .append("rect")
                .classed('overviewBar', true)
                .attr('width', function (d) {
                    return Math.abs(xOverview(d.y0) - xOverview(d.y1));
                })
                .attr('height', function (d) {
                    if (self.grouped)
                        return y1O.bandwidth();

                    else
                        return yOverview.bandwidth();
                })
                .attr('y', function (d, lix) {
                    if (self.grouped)
                        return yOverview(d.myix) + y1O(d.name);

                    else
                        return yOverview(d.myix);
                })
                .attr('x', function (d) {
                    return xOverview(d.y0); // 0;
                })

            // V5
            self.displayed = d3.scaleQuantize()
                .domain([0, self.height])
                .range(d3.range(self.data.length));

            var ovHeight = Math.round(parseFloat(self.numBars * self.height) / self.data.length);
            // If the grabber is too small, make it bigger
            if (ovHeight < 10) ovHeight = 10;

            self.diagram.select('.overviewMover').remove();

            self.diagram.append("rect")
                .attr("transform", "translate(" + (-self.margin.left) + ",0)")
                .attr("class", "overviewMover")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", self.selectorHeight)
                .attr("height", ovHeight)
                .attr("pointer-events", "all")
                .attr("cursor", "ns-resize")
                .call(d3.drag().on("drag", display));
        }
    }
}

//hBarGGC.prototype = new axisGGC();

