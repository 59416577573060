
import i18next from 'i18next';

// db is what is returned from the d3.tsvParse() function of the tsv files with answers, questions, campaigns, etc.
// it is accessed by db['locations'] - which is an array of object, eg. {id: <>, name: <>, etc.}
var db = null;
// dbIndex is a map of id to index in the db array as well as the min/max dates votes were tallied, eg. 
// dbIndex['locations'][<id>] = {index: <indexIntoArray>, start: date, end: date}
// NOTE: that the dates fields could be empty if there were no votes for that questionlocation
var dbIndex = null;
var dbDuration = {};		// start, end, duration

export const setDatabaseFiles = async (hashFiles) => {
	// Reset vars
	dbIndex = null;
	dbDuration = {};


	// Setup database
	db = hashFiles;
	if (db['votes']) {
		// Sort by eventtime
		delete db['votes'].columns;
		db['votes'].sort((a, b) => {
			if (a.eventtime < b.eventtime) {
				return -1;
			} else if (a.eventtime > b.eventtime) {
				return 1;
			} else {
				return 0;
			}
		});
		for (let i=0; i<db['votes'].length; i++) {
			db['votes'][i].date = new Date(db['votes'][i].eventtime);
		}
	}
	if (db['responses']) {
		// Sort by id
		delete db['responses'].columns;
		db['responses'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});		
	}
	if (db['responsequestionanswer']) {
		// Sort by id
		delete db['responsequestionanswer'].columns;
		db['responsequestionanswer'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});
	}
	if (db['projects']) {
		// Sort by id
		delete db['projects'].columns;
		db['projects'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});
		makeIndex('projects');
	}
	// campaigns
	if (db['campaigns']) {
		// Sort by id
		delete db['campaigns'].columns;
		db['campaigns'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});		
		makeIndex('campaigns');
	}
	// locations
	if (db['locations']) {
		// Sort by id
		delete db['locations'].columns;
		db['locations'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});		
		makeIndex('locations');
	}
	if (db['questionlocations']) {
		// Sort by id
		delete db['questionlocations'].columns;
		db['questionlocations'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});		
		for (let i = 0; i < db['questionlocations'].length; i++) {
			db['questionlocations'][i].questions = JSON.parse(db['questionlocations'][i].questions);
		}
		makeIndex('questionlocations');
	}
	if (db['questions']) {
		// Sort by id
		delete db['questions'].columns;
		db['questions'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});		
		makeIndex('questions');
	}
	// answers
	if (db['answers']) {
		// Sort by id
		delete db['answers'].columns;
		db['answers'].sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			} else if (a.id > b.id) {
				return 1;
			}
			return 0;
		});
		makeIndex('answers');
	}

	// This index is a bit different in that it holds just start/end since there is no qlq table.
	dbIndex['qlq'] = new Map();

	// At this point, we have dbIndex setup with direct index into source db array.  Now, scan the votes and set the
	// start and end dates for each projectId, campaignID, questionLocationId, locationId. (Questions are derived from questionLocation)
	if (db['votes']) {
		for (let i = 0; i < db['votes'].length; i++) {
			const vote = db['votes'][i];
			if (!dbDuration.start || dbDuration.start > vote.date) {
				dbDuration.start = vote.date;
			}
			if (!dbDuration.end || dbDuration.end < vote.date) {
				dbDuration.end = vote.date;
			}


			if (vote.projectID) {
				const project = dbIndex['projects'].get(vote.projectID);
				if (project) {
					if (!project.start || project.start > vote.date) {
						project.start = vote.date;
					}
					if (!project.end || project.end < vote.date) {
						project.end = vote.date;
					}
					if (!project.hasOwnProperty('votes')) {
						project.votes = 0;
					}
					project.votes += +vote.vote;
				}
			}
			if (vote.campaignID) {
				const campaign = dbIndex['campaigns'].get(vote.campaignID);
				if (campaign) {
					if (!campaign.start || campaign.start > vote.date) {
						campaign.start = vote.date;
					}
					if (!campaign.end || campaign.end < vote.date) {
						campaign.end = vote.date;
					}
					if (!campaign.hasOwnProperty('votes')) {
						campaign.votes = 0;
					}
					campaign.votes += +vote.vote;
				}
			}
			if (vote.questionLocationID) {
				const ql = dbIndex['questionlocations'].get(vote.questionLocationID);
				if (ql) {
					if (!ql.start || ql.start > vote.date) {
						ql.start = vote.date;
					}
					if (!ql.end || ql.end < vote.date) {
						ql.end = vote.date;
					}
					if (!ql.hasOwnProperty('votes')) {
						ql.votes = 0;
					}
					ql.votes += +vote.vote;

					// Now make a start/end for combination of questionLocationID and questionID - which is really the question at a location
					if (vote.questionID) {
						const qlqId = vote.questionLocationID + '_' + vote.questionID;
						const qlq = dbIndex['qlq'].get(qlqId);
						if (!qlq) {
							dbIndex['qlq'].set(qlqId, { start: vote.date, end: vote.date, votes: +vote.vote });
						} else {
							if (!qlq.start || qlq.start > vote.date) {
								qlq.start = vote.date;
							}
							if (!qlq.end || qlq.end < vote.date) {
								qlq.end = vote.date;
							}
							qlq.votes += +vote.vote;
						}
					}
				}
			}
			/* don't think we need this...
			if (vote.locationID) {
				const location = dbIndex['locations'].get(vote.locationID);
				if (!location.start || location.start > vote.date) {
					location.start = vote.date;
				}
				if (!location.end || location.end < vote.date) {
					location.end = vote.date;
				}
			}
			*/
		}

		dbDuration.duration = dbDuration.end.getTime() - dbDuration.start.getTime();

		// var mapAsc = new Map([...map.entries()].sort());
		sortMap('projects');
		sortMap('campaigns');
		sortMap('questionlocations');
		//sortMap('locations');

		console.log('dbIndex', dbIndex);
	}
}

const sortMap = (tableName) => {
	//console.log([...dbIndex[tableName].entries()]);

	dbIndex[tableName] = new Map([...dbIndex[tableName].entries()].sort((a, b) => {
		if (a[1].start && b[1].start) {
			return a[1].start.getTime() - b[1].start.getTime();
		} else if (a[1].start) {
			return -1;
		} else if (b[1].start) {
			return 1;
		} else {
			return 0;
		}
	}));
		
}

const makeIndex = (tableName) => {
	if (!dbIndex) {
		dbIndex = {};
	}
	if (!dbIndex[tableName]) {
		dbIndex[tableName] = new Map();
	}
	for (let i=0; i<db[tableName].length; i++) {
		dbIndex[tableName].set(db[tableName][i].id, {index: i});
	}
}

export const getDatabaseFiles = () => {
	return db;
}

export const getDatabaseVotes = () => {
	return db['votes'];
}

let binarySearchId = function (sorted_arr, target) {
	let start = 0,
		 end = sorted_arr.length - 1;

	// Iterate as long as the beginning does not encounter the end.
	while (start <= end) {
		 // find out the middle index
		 let mid = Math.floor((start + end) / 2);

		 // Return True if the element is present in the middle.
		 if (sorted_arr[mid].id === target) return mid;
		 // Otherwise, look in the left or right half
		 else if (sorted_arr[mid].id < target) start = mid + 1;
		 else end = mid - 1;
	}

	return -1;
};

export const findQuestion = (questionId) => {
	if (!db || !db['questions']) {
		return null;
	}
	const ix = binarySearchId(db['questions'], questionId);
	if (ix === -1) {
		return null;
	} else {
		return db['questions'][ix];
	}
}

export const findProject = (projectId) => {
	if (!db || !db['projects']) {
		return null;
	}
	const ix = binarySearchId(db['projects'], projectId);
	if (ix === -1) {
		return null;
	} else {
		return db['projects'][ix];
	}
}
export const findResponse = (responseId) => {
	if (!db || !db['responses']) {
		return null;
	}
	const ix = binarySearchId(db['responses'], responseId);
	if (ix === -1) {
		return null;
	} else {
		return db['responses'][ix];
	}
}
export const findResponseQuestionAnswer = (rqaId) => {
	if (!db || !db['responsequestionanswer']) {
		return null;
	}
	const ix = binarySearchId(db['responsequestionanswer'], rqaId);
	if (ix === -1) {
		return null;
	} else {
		return db['responsequestionanswer'][ix];
	}
}
export const findQuestionLocation = (qlId) => {
	if (!db || !db['questionlocations']) {
		return null;
	}
	const ix = binarySearchId(db['questionlocations'], qlId);
	if (ix === -1) {
		return null;
	} else {
		return db['questionlocations'][ix];
	}
}
export const findLocation = (locationId) => {
	if (!db || !db['locations']) {
		return null;
	}
	const ix = binarySearchId(db['locations'], locationId);
	if (ix === -1) {
		return null;
	} else {
		return db['locations'][ix];
	}
}
export const findCampaign = (campaignId) => {
	if (!db || !db['campaigns']) {
		return null;
	}
	const ix = binarySearchId(db['campaigns'], campaignId);
	if (ix === -1) {
		return null;
	} else {
		return db['campaigns'][ix];
	}
}
export const findAnswer = (answerId) => {
	if (!db || !db['answers']) {
		return null;
	}
	const ix = binarySearchId(db['answers'], answerId);
	if (ix === -1) {
		return null;
	} else {
		return db['answers'][ix];
	}
}

// Date Utils ----------------------
export const dayNameFromDate = (d) => {
	const day = d.getDay();
	const days = [
		i18next.t("time.day.sunday"), 
		i18next.t("time.day.monday"),
		i18next.t("time.day.tuesday"),
		i18next.t("time.day.wednesday"),
		i18next.t("time.day.thursday"),
		i18next.t("time.day.friday"),
		i18next.t("time.day.saturday")
	]
	return days[day];
}
export const dayAbbrFromDate = (d) => {
	const day = d.getDay();
	const days = [
		i18next.t("time.day.abbrev.sunday"),
		i18next.t("time.day.abbrev.monday"),
		i18next.t("time.day.abbrev.tuesday"),
		i18next.t("time.day.abbrev.wednesday"),
		i18next.t("time.day.abbrev.thursday"),
		i18next.t("time.day.abbrev.friday"),
		i18next.t("time.day.abbrev.saturday")
	];
	return days[day];
}
export const monthNameFromDate = (d) => {
	const month = d.getMonth();
	const months = [
		i18next.t("time.month.january"),
		i18next.t("time.month.february"),
		i18next.t("time.month.march"),
		i18next.t("time.month.april"),
		i18next.t("time.month.may"),
		i18next.t("time.month.june"),
		i18next.t("time.month.july"),
		i18next.t("time.month.august"),
		i18next.t("time.month.september"),
		i18next.t("time.month.october"),
		i18next.t("time.month.november"),
		i18next.t("time.month.december")
	];
	return months[month];
}
// "Jan", "Feb", "Mar", etc.
export const monthAbbrFromDate = (d) => {
	const month = d.getMont();
	const monthAbbrev = [
		i18next.t("time.month.abbrev.january"),
		i18next.t("time.month.abbrev.february"),
		i18next.t("time.month.abbrev.march"),
		i18next.t("time.month.abbrev.april"),
		i18next.t("time.month.abbrev.may"),
		i18next.t("time.month.abbrev.june"),
		i18next.t("time.month.abbrev.july"),
		i18next.t("time.month.abbrev.august"),
		i18next.t("time.month.abbrev.september"),
		i18next.t("time.month.abbrev.october"),
		i18next.t("time.month.abbrev.november"),
		i18next.t("time.month.abbrev.december")
	]
	return [monthAbbrev[month]];
}
// return 0-23
export const hourFromDate = (d) => {
	const hour = d.getHours();
	return hour;
}

// Flatten
/*
[{type: 'Project', name: 'Sample Project Conference', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Campaign', name: 'New Campaign', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Location', name: 'Meeting Room 201a', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Question', name: 'What topic would you like to cover today?', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Location', name: 'Meeting Room 201b', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Question', name: 'What topic would you like to cover today?', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Question', name: 'What up?', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
{type: 'Campaign', name: 'Campaign 2', start: '2023-10-06T00:13:43.295Z', end: '2023-11-04T23:18:59.425Z', duration: 2588716130},
next Project, etc.
*/
export const getGanttData = () => {

	let ganttData = [];

	for (const [projectId, projTime] of dbIndex['projects']) {
		if (projTime.start) {
			const projRecord = db['projects'][projTime.index];
			const projName = projRecord.name;
			ganttData.push( {		// projects
				type: 'Project',
				name: projName, 
				id: projectId,
				start: projTime.start, 
				end: projTime.end, 
				duration: projTime.end.getTime() - projTime.start.getTime(), 
				votes: projTime.votes
			} );
			// Now we need all campaign records with projectID === projectId
			for (const [campaignId, campTime] of dbIndex['campaigns']) {
				if (campTime.start) {
					const campRecord = db['campaigns'][campTime.index];
					if (campRecord.projectID === projectId) {
						const campName = campRecord.name;
						ganttData.push( {		//projects, campaigns
							type: 'Campaign',
							name: campName, 
							id: campaignId,
							start: campTime.start, 
							end: campTime.end, 
							duration: campTime.end.getTime() - campTime.start.getTime(), 
							votes: campTime.votes
						} );

						// Now we need all questionLocation records with campaignID === campaignId
						for (const [qlId, qlTime] of dbIndex['questionlocations']) {
							const qlRecord = db['questionlocations'][qlTime.index];
							if (qlRecord.campaignID === campaignId) {
								if (qlTime.start) {
									// So, we have a qlRecord, but they are not sorted by Location then Question
									// So for each record, we need to get the locationName, questionName
									// Then group by locationName 
									const locQuestionIds = qlRecord.questions;		// IDs of the Questions at this location
									const locationRecord = findLocation(qlRecord.locationID);	//  db['locations'][qlRecord.locationID];
									const locName = locationRecord.name;
									ganttData.push( {	// projects, campaigns, qustionLocations
										type: 'Location',
										name: locName, 
										id: qlId,
										start: qlTime.start, 
										end: qlTime.end, 
										duration: qlTime.end.getTime() - qlTime.start.getTime(), 
										votes: qlTime.votes
									} );
									for (let i=0; i<locQuestionIds.length; i++) {
										const quest = findQuestion(locQuestionIds[i]);	//  db['questions'][locQuestionIds[i]];
										const questName = quest.text;
										const qlqIndex = dbIndex['qlq'].get(qlId + '_' + quest.id);
										if (qlqIndex && qlqIndex.start) {
											ganttData.push( {	// projects, campaigns, questionLocations, questions
												type: 'Question',
												name: questName, 
												id: quest.id,
												start: qlqIndex.start, 
												end: qlqIndex.end, 
												duration: qlqIndex.end.getTime() - qlqIndex.start.getTime(), 
												votes: qlqIndex.votes
											} );
										}
									}

								}
							}
						}
					}
				}
			}
		}
	}
	return {
		labels: ['Project', 'Campaign', 'Location', 'Question'], 
		tables: ['projects', 'campaigns', 'questionlocations', 'questions'],
		duration: dbDuration,
		data: ganttData
	};
}
// Only return the chain with some activity (votes)
// To abstract out the names of the fields,  we name each array 'children' and each text value 'name'
// Return an array of Labels for each 'children'.  e.g. 
// labels: ['Project', 'Campaign', 'Location', 'Question']
// tables: ['projects', 'campaigns', 'questionlocations', 'questions']
// data: {...}
// Hierarchical
/*
export const getGanttData_orig = () => {

	let ganttData = { children: [] };	// projects

	for (const [projectId, projTime] of dbIndex['projects']) {
		if (projTime.start) {
			const projRecord = db['projects'][projTime.index];
			const projName = projRecord.name;
			ganttData.children.push( {		// projects
				name: projName, 
				id: projectId,
				start: projTime.start, end: projTime.end, duration: projTime.end.getTime() - projTime.start.getTime(), 
				children: []		// campaigns
			} );
			let ixProj = ganttData.children.length-1;		// projects
			// Now we need all campaign records with projectID === projectId
			for (const [campaignId, campTime] of dbIndex['campaigns']) {
				if (campTime.start) {
					const campRecord = db['campaigns'][campTime.index];
					if (campRecord.projectID === projectId) {
						const campName = campRecord.name;
						ganttData.children[ixProj].children.push( {		//projects, campaigns
							name: campName, 
							id: campaignId,
							start: campTime.start, end: campTime.end, duration: campTime.end.getTime() - campTime.start.getTime(), 
							children: []		// questionLocations
						} );
						let ixCamp = ganttData.children[ixProj].children.length-1;		// projects, campaigns

						// Now we need all questionLocation records with campaignID === campaignId
						for (const [qlId, qlTime] of dbIndex['questionlocations']) {
							const qlRecord = db['questionlocations'][qlTime.index];
							if (qlRecord.campaignID === campaignId) {
								if (qlTime.start) {
									// So, we have a qlRecord, but they are not sorted by Location then Question
									// So for each record, we need to get the locationName, questionName
									// Then group by locationName 
									const locQuestionIds = qlRecord.questions;		// IDs of the Questions at this location
									const locationRecord = findLocation(qlRecord.locationID);	//  db['locations'][qlRecord.locationID];
									const locName = locationRecord.name;
									ganttData.children[ixProj].children[ixCamp].children.push( {	// projects, campaigns, qustionLocations
										name: locName, 
										id: qlId,
										start: qlTime.start, end: qlTime.end, duration: qlTime.end.getTime() - qlTime.start.getTime(), 
										children: []		//questions
									} );
									let ixQL = ganttData.children[ixProj].children[ixCamp].children.length-1;	// projects, campaigns, questionLocations
									for (let i=0; i<locQuestionIds.length; i++) {
										const quest = findQuestion(locQuestionIds[i]);	//  db['questions'][locQuestionIds[i]];
										const questName = quest.text;
										const qlqIndex = dbIndex['qlq'].get(qlId + '_' + quest.id);
										if (qlqIndex && qlqIndex.start) {
											ganttData.children[ixProj].children[ixCamp].children[ixQL].children.push( {	// projects, campaigns, questionLocations, questions
												name: questName, 
												id: quest.id,
												start: qlqIndex.start, end: qlqIndex.end, duration: qlqIndex.end.getTime() - qlqIndex.start.getTime(), 
												answers: []
											} );
										}
									}

								}
							}
						}
					}
				}
			}
		}
	}
	return {
		labels: ['Project', 'Campaign', 'Location', 'Question'], 
		tables: ['projects', 'campaigns', 'questionlocations', 'questions'],
		duration: dbDuration,
		data: ganttData
	};
}
*/