import * as d3 from 'd3';
import $ from "jquery";
import { graphBaseGGC, biaColors } from './graphbaseggc';
import i18next from 'i18next';
import { isRuntimeOnly } from '../utils/Utils';

const tinycolor = require("tinycolor2");

export class sunburstGGC extends graphBaseGGC {
    constructor(args) {
        super(args);

        this.hierPalette = null;

        this.radius = undefined;
        this.format = undefined;
        this.ixValue = undefined;

        this._path = undefined;
        this._label = undefined;
        this._parent = undefined;
        this.hierFont = this.font;
        this.hierFontColor = '#FFFFFF';
        this.hierFontStyle = '';
		this.viewBoxAdjustHeight = 0;

        this._init(args);
    }
    getGraphType() {
        return  'sunburst';
    }
    _init(args) {
        //this._base_init(args);
        this._initVariables(args);
    }
    _saveProps() {
        var self = this;

        var base = self._base_saveProps();
        var me = {
            hierPalette: self.hierPalette,
            hierFont: self.hierFont,
            hierFontColor: self.hierFontColor,
            hierFontStyle: self.hierFontStyle,
            hierDomain: self.hierDomain,
            viewBoxAdjustHeight: self.viewBoxAdjustHeight,

        };

        return { ...base, ...me };
    }
    _initVariables(args) {

        // Put any if (args.hasOwnProperty('prop')) checks here...
        if (args.hasOwnProperty('hierPalette'))
            this.hierPalette = args.hierPalette;

        if (!Array.isArray(this.hierPalette)) {
            this.hierPalette = null;
        }

        if (this.hierPalette == null) {
            this.hierPalette = [];
            var me = { type: 'interpolate', index: biaColors._d3ColorsInterpolateDefaultIx, opacity: 0.7, gradient: {} };
            this.hierPalette.push(me);
        }
        if (args.hasOwnProperty('hierFont'))
            this.hierFont = args.hierFont;
        if (args.hasOwnProperty('hierFontColor'))
            this.hierFontColor = args.hierFontColor;
        if (args.hasOwnProperty('hierFontStyle'))
            this.hierFontStyle = args.hierFontStyle;
        if (args.hasOwnProperty('hierDomain'))
            this.hierDomain = args.hierDomain;

        if (args.hasOwnProperty('viewBox') && isRuntimeOnly()) {
            this.viewBox = args.viewBox;
            if (args.hasOwnProperty('viewBoxAdjustHeight')) {
                this.viewBoxAdjustHeight = +args.viewBoxAdjustHeight;
            }
        }
    
    }
    // Overrides base class
    setData(opts, dimhier) {

        if (opts.hasOwnProperty('valueNames')) {
            this.valueNames = opts.valueNames;
            this.color.domain(this.valueNames);
        }

        if (opts.hasOwnProperty('categoryNames')) {
            this.categoryNames = opts.categoryNames;
            this.ixLabel = this.categoryNames.length - 1; // label is in last entry of categoryNames, e.g. City                
        }

        if (!opts.hasOwnProperty('tableName'))
            throw new Error ('tablename is required');
        this.tableName = opts.tableName;


        if (opts.hasOwnProperty('ajax')) {
            this.ajax = opts.ajax;
        }
        else
            this._setData(dimhier);

        this.format = d3.format(",d");

    }
    _setData(dimhier) {

        const childAccFn = ([ key, value ]) => value.size && Array.from(value)
        const root = d3.hierarchy([null, dimhier], childAccFn)  // dimhier)
            .sum(([key, value]) => value)
            .sort((a, b) => b.value - a.value);
        this.data = d3.partition()
            .size([2 * Math.PI, root.height + 1])(root);

        this._fixSunPalette();
    }
    _fixSunPalette() {
        var self = this;

        if (this.data.hasOwnProperty('children')) {
            // adding type === 'custom' check on hierPalette lets the editor override it
            if (this.hierPalette[0].type === 'custom' && this.colorPaletteType === 'custom') {
                const kids = this.hierDomain;   // set via useImagesAsFill in GraphComponent
                let custColors = [];
                for (let i=0; i<kids.length; i++) {
                    if (self.customPalette.colorRef[i].loc) {
                        custColors.push( 'url(#' + self.customPalette.colorRef[i].ref + ')' );
                    } else {
                        custColors.push( self.customPalette.colorRef[i].rgb );
                    }
                }
                this.color = d3.scaleOrdinal()
                    .domain(kids)
                    .range(custColors)
                    /*
                opacity = d3.scaleLinear()    in our case for votes, we don't have children..
                        .domain([minval, maxval])
                        .range([0.5, 1])
                */

            } else if (this.hierPalette[0].type === 'interpolate') {
                this.color = d3.scaleOrdinal(
                    d3.quantize(
                        biaColors._d3ColorsInterpolate[this.hierPalette[0].index].interpolate,
                        this.data.children.length + 1)
                );
            }
            else if (this.hierPalette[0].type === 'gradient') {
                self.gradientStops = this.hierPalette[0].gradient;
                self.gradientData = null; // to reset
                var gd = self.getGradientData();
                var interp = function (d) {
                    // 0= gd[0], gd[1], gd[2]
                    var ix = 4 * Math.floor(d * 255);
                    return new tinycolor({ r: gd[ix], g: gd[ix + 1], b: gd[ix + 2] }).toHexString();
                };
                this.color = d3.scaleOrdinal(
                    d3.quantize(
                        interp,
                        this.data.children.length + 1)
                );

            }
        }

    }
    resize(opts) {
        var self = this;


		let width, height;
		let gonnaDraw = true;

		// We need to check this because if we don't draw, then we've set the outerWidth/Height to something
		// from the viewBox - which is not what we want because _getData calls refresh with self.outerWidth/outerHeight
		if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
				if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
						gonnaDraw = false;
				}
		}

		if (isRuntimeOnly() && this.viewBox && gonnaDraw) {
				const vbCoords = this.viewBox.split(' ');
				width = parseInt(vbCoords[2]);
				height = parseInt(vbCoords[3]);
				this.svgWidth = opts.width;
				this.svgHeight = opts.height + this.viewBoxAdjustHeight;
		} else {
				width = opts.width;
				height = opts.height;
		}


        this.outerHeight = height;
        this.outerWidth = width;

        if (typeof (this.data) === 'undefined' || !this.data || (opts.hasOwnProperty('refreshData') && opts.refreshData)) {
            if (this.ajax) {
                this._getData((opts.hasOwnProperty('refreshData') && opts.refreshData));
            }
            if (!(opts.hasOwnProperty('refreshData') && opts.refreshData)) {
                return;
            }
        }

        this.origWidth = self.outerWidth;
        this.origHeight = self.outerHeight;
        if (self._pagingControl) {
            this.origHeight -= $(self._pagingControl).height();
			this.viewBoxAdjustHeight = -$(self._pagingControl).height();
        }
        //2023 GGC $(this.outerContainer).height(this.outerHeight);

        if (this.hasOwnProperty('svg') && this.svg)
            this.svg.remove();

        this.margin.left = this.margin.top = this.margin.right = this.margin.bottom = 0;

        this.width = this.origWidth - this.margin.left - this.margin.right;
        this.height = this.origHeight - this.margin.top - this.margin.bottom;

        if (!isRuntimeOnly()) {
            this.viewBox = [0, 0, this.origWidth, this.origHeight].join(' ');
            this.svgWidth = this.origWidth;
            this.svgHeight = this.origHeight;
        }

        this.svg = d3.select(this.container).append("svg")
            .attr("width", this.svgWidth)
            .attr("height", this.svgHeight)
            .attr('viewBox', this.viewBox)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style('font', this.hierFont);


        this._colorDomainSet();
        this._fillBackground(this.svg);


        this.data.each(d => d.current = d);


        this.diagram = this.svg.append('g')
            .attr('transform', `translate(${this.width / 2},${this.height / 2})`);

        this.radius = Math.min(this.width, this.height) / 6;

        this.refresh();

        //        this._base_makeLegend();
        this.checkEditMode();


    }
    _drawSunburst() {
        var self = this;

        if (self._path)
            self._path.remove();
        if (self._label)
            self._label.remove();
        if (self._parent)
            self._parent.remove();

        var arc = d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(self.radius * 1.5)
            .innerRadius(d => d.y0 * self.radius)
            .outerRadius(d => {
                return Math.max(d.y0 * self.radius, d.y1 * self.radius - 1);
            });

        var opacity = self.hierPalette[0].opacity;
        var cMult = 0.8;
        var oMult = 0.5;
        if (opacity === 1.0) {
            cMult = 1.0;
            oMult = 1.0;
        }

        self._path = this.diagram.append("g")
            .selectAll("path")
            .data(this.data.descendants().slice(1))
            .join("path")
            .attr("fill", d => {
                while (d.depth > 1)
                    d = d.parent;
                return self.color(d.data[0])        // .name);
            })
            //.attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? (cMult * opacity) : (oMult * opacity)) : 0)
            .attr("d", d => arc(d.current));

        self._path.filter(d => d.children)
            .style("cursor", "pointer")
            .on("click", clicked);

        self._path.append("title")
            .text(d => {
                let total = 0;
                total = d.value;
                return `${d.ancestors().map(d => d.data[0]/*.name*/).reverse().join("\n").slice(1)}\n${i18next.t("graph.lbl.votes")} ${self.format(total/*value*/)}`
            });


        self._label = this.diagram.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(self.data.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", d => +labelVisible(d.current))
            .attr("transform", d => labelTransform(d.current))
            .text(d => d.data[0])   // rollup .name)
            .style("fill", self.hierFontColor)
            .style("font", self.hierFont)
            .style('font-weight', self.hierFontStyle.indexOf('B') !== -1 ? 700 : 400)
            .attr('text-decoration', self.hierFontStyle.indexOf('U') !== -1 ? 'underline' : 'none');

        self._parent = this.diagram.append("circle")
            .datum(self.data)
            .attr("r", self.radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .on("click", clicked);

        function clicked(event, p) {
            if (self.editMode) return;

            self._parent.datum(p.parent || self.data);

            self.data.each(d => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t = self.diagram.transition().duration(750);

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            var opacity = self.hierPalette[0].opacity; // if we are fully opaque, then don't let background bleed thru
            var cMult = 0.8;
            var oMult = 0.5;
            if (opacity === 1.0) {
                cMult = 1.0;
                oMult = 1.0;
            }
            self._path.transition(t)
                .tween("data", d => {
                    const i = d3.interpolate(d.current, d.target);
                    return t => d.current = i(t);
                })
                .filter(function (d) {
                    return +this.getAttribute("fill-opacity") || arcVisible(d.target);
                })
                .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? (cMult * opacity) : (oMult * opacity)) : 0)
                .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none") 
                .attrTween("d", d => () => arc(d.current));

            self._label.filter(function (d) {
                return +this.getAttribute("fill-opacity") || labelVisible(d.target);
            }).transition(t)
                .attr("fill-opacity", d => +labelVisible(d.target))
                .attrTween("transform", d => () => labelTransform(d.current));
        }

        function arcVisible(d) {
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d) {
            return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }

        function labelTransform(d) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * self.radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }
    }
    refresh() {

        this._colorDomainSet();

        this._fixSunPalette();

        this._drawSunburst();
    }
    toggleEdit() {
        var self = this;
        return self._toggleEdit();
    }
    checkEditMode() {
        var self = this;

        if (self.editMode) {
            let x = -(3 * this.width / 8);
            let y = -(3 * this.height / 8);
            self._drawCircleEditColor({
                selector: self.diagram,
                kind: 'bgcolor',
                title: i18next.t("graph.edit.background"),
                x: x,
                y: y,
                r: 12,
                placement: 'right',
                variables: {
                    colorRefProp: 'backgroundColor'
                }

            });

            x = 0;
            y = 0;
            self._drawCircleEditPalette({
                selector: self.diagram,
                kind: 'sunb',
                title: i18next.t("graph.edit.sunburst_fill"),
                x: x,
                y: y,
                r: 12,
                class: 'sunbEditCircs',
                placement: 'auto',
                ordinal: 0,
                variables: {
                    paletteTypeProp: 'hierPalette.0.type',
                    paletteIndexProp: 'hierPalette.0.index',
                    customPaletteProp: 'hierPalette.0.gradient',
                    paletteOpacityProp: 'hierPalette.0.opacity'
                }
            });

            // d3.select(this).node().getBBox();
            // svg -> g (transform) -> g -> text dy, transform, translate
            let foo = this.diagram.selectAll('text').filter(function (d, i) { return i === 0; });
            let tBBox = foo.node(0).getBBox();

            x = tBBox.x;    // - tBBox.width/2;
            y = tBBox.y;    // - tBBox.height/2;
            self._drawCircleEditText({
                selector: self.diagram,
                kind: 'sunlbl',
                label: null,
                title: i18next.t("graph.edit.labels"),
                drawLabel: false,
                x: x,
                y: y,
                r: 12,
                placement: 'top',
                disable: ['italic'],
                variables: {
                    fontProp: 'hierFont',
                    styleProp: 'hierFontStyle',
                    colorProp: 'hierFontColor'
                }
            });
        }
    }
}

//sunburstGGC.prototype = new graphBaseGGC();


