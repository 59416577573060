// Url Entry Box
// Author: Greg Carpenter
/*global textInput*/
(function (factory) {
    module.exports = factory(require('jquery'));
})(function ($, undefined) {

    var defaultOpts = {
        // Callbacks
        beforeShow: noop,
        move: noop,
        change: noop,
        show: noop,
        hide: noop,
        theme: "ugc-light",
        containerClassName: "",
        replacerClassName: "",
        cancelText: "Cancel",
        chooseText: "Save"
    },
        urlentryggcs = [],
        IE = !!/msie/i.exec(window.navigator.userAgent),
        markup = (function () {
            return [
                "<div class='ugc-container ugc-hidden'>",
                'URL:<input class="ugc-url" type="text" style="width: 20em;">',
                "<a class='ugc-cancel' href='#'></a>",
                "<button type='button' class='ugc-choose'></button>",
                "</div>"
            ].join("");
        })();

    function hideAll() {
        for (var i = 0; i < urlentryggcs.length; i++) {
            if (urlentryggcs[i]) {
                urlentryggcs[i].hide();
            }
        }
    }

    function instanceOptions(o, callbackContext) {
        var opts = $.extend({}, defaultOpts, o);
        opts.callbacks = {
            'move': bind(opts.move, callbackContext),
            'change': bind(opts.change, callbackContext),
            'show': bind(opts.show, callbackContext),
            'hide': bind(opts.hide, callbackContext),
            'beforeShow': bind(opts.beforeShow, callbackContext)
        };

        return opts;
    }

    function urlentryggc(element, o) {

        var opts = instanceOptions(o, element),
            resize = throttle(reflow, 10),
            callbacks = opts.callbacks;


        var doc = element.ownerDocument,
            //body = doc.body,
            boundElement = $(element),
            disabled = false,
            theme = opts.theme,
            container = $(markup, doc).addClass(theme),
            editUrl = container.find('.ugc-url'),
            visible = false,
            isInput = boundElement.is("input"),
            shouldReplace = isInput,
            initialUrl = opts.url || (isInput && boundElement.val()),
            urlOnShow = false,
            cancelButton = container.find(".ugc-cancel"),
            chooseButton = container.find(".ugc-choose"),
            clickoutFiresChange = opts.clickoutFiresChange;



        var replacer;
        if (shouldReplace) {
            // wrap boundElement with <div class="sp-replacer">
            // append <div clas="sp-dd"></div>
            replacer = document.createElement('div');
            replacer.setAttribute('class', 'ugc-replacer');
            element.parentNode.insertBefore(replacer, element);
            replacer.appendChild(element);

            var dd = document.createElement('div');
            dd.setAttribute('class', 'ugc-dd');
            dd.innerHTML = '&#9660;';
            replacer.appendChild(dd);
            replacer = $(replacer);
            replacer.addClass(theme).addClass(opts.replacerClassName);
        } else {
            replacer = $([])
        }

        var offsetElement = (shouldReplace) ? replacer : boundElement;

        function initialize() {

            if (shouldReplace) {
                //dies                boundElement.after(replacer).hide();
            }

            var appendTo = opts.appendTo === "parent" ? boundElement.parent() : $(opts.appendTo);
            if (appendTo.length !== 1) {
                appendTo = $("body");
            }

            appendTo.append(container);


            offsetElement.on("click.urlentryggc touchstart.urlentryggc", function (e) {
                if (!disabled) {
                    toggle();
                }

                e.stopPropagation();

                if (!$(e.target).is("input")) {
                    e.preventDefault();
                }
            });

            if (boundElement.is(":disabled") || (opts.disabled === true)) {
                disable();
            }

            // Prevent clicks from bubbling up to document.  This would cause it to be hidden.
            container.click(stopPropagation);

            cancelButton.text(opts.cancelText);
            cancelButton.on("click.urlentryggc", function (e) {
                e.stopPropagation();
                e.preventDefault();
                revert();
                hide();
            });

            chooseButton.text(opts.chooseText);
            if (opts.readonly) {
                chooseButton.prop('disabled', true);
            } else {
                chooseButton.on("click.urlentryggc", function (e) {
                    e.stopPropagation();
                    e.preventDefault();

                    if (IE && textInput.is(":focus")) {
                        textInput.trigger('change');
                    }

                    if (isValid()) {
                        updateOriginalInput(true);
                        hide();
                    }
                });
            }

            if (!!initialUrl) {
                set(initialUrl);

                updateUI();
            }
            else {
                updateUI();
            }

        }

        function isValid() {
            // todo: validate url input
            return true;
        }

        function get() {
            //            return value of input field;
            return editUrl.val();
        }
        function set(s) {
            editUrl.val(s);
            updateUI();
        }

        function toggle() {
            if (visible) {
                hide();
            }
            else {
                show();
            }
        }

        function show() {
            var event = $.Event('beforeShow.spectrum');

            if (visible) {
                reflow();
                return;
            }

            boundElement.trigger(event, [get()]);

            if (callbacks.beforeShow(get()) === false || event.isDefaultPrevented()) {
                return;
            }

            hideAll();
            visible = true;

            $(doc).on("click.urlentryggc", clickout);
            $(window).on("resize.urlentryggc", resize);
            replacer.addClass("ugc-active");
            container.removeClass("ugc-hidden");

            reflow();
            updateUI();

            urlOnShow = get();

            callbacks.show(urlOnShow);
            boundElement.trigger('show.urlentryggc', [urlOnShow]);
        }

        function updateUI() {
        }


        function updateOriginalInput(fireCallback) {
            var url = get(),
                hasChanged;
            if (urlOnShow != null && urlOnShow !== false)
                hasChanged = url.toLowerCase() !== urlOnShow.toLowerCase();
            //else
            //    hasChanged = true;

            if (isInput && url.length > 0) {     // GGC 2023/11
                boundElement.attr('src', url);
                //boundElement.val(url);
            }

            if (fireCallback && hasChanged) {
                callbacks.change(url);
                boundElement.trigger('change', [url]);
            }
        }

        function reflow() {
            if (!visible) {
                return; // Calculations would be useless and wouldn't be reliable anyways
            }

            container.css("position", "absolute");
            if (opts.offset) {
                container.offset(opts.offset);
            } else {
                container.offset(getOffset(container, offsetElement));
            }


            boundElement.trigger('reflow.urlentryggc');
        }

        function clickout(e) {
            // Return on right click.
            if (e.button === 2) { return; }

            if (clickoutFiresChange) {
                updateOriginalInput(true);
            }
            else {
                revert();
            }
            hide();
        }

        function hide() {
            // Return if hiding is unnecessary
            if (!visible) { return; }
            visible = false;

            $(doc).off("click.urlentryggc", clickout);
            $(window).off("resize.urlentryggc", resize);

            replacer.removeClass("ugc-active");
            container.addClass("ugc-hidden");

            callbacks.hide(get());
            boundElement.trigger('hide.urlentryggc', [get()]);
        }
        function revert() {
            set(urlOnShow, true);
            updateOriginalInput(true);
        }

        function enable() {
            disabled = false;
            boundElement.attr("disabled", false);
            offsetElement.removeClass("ugc-disabled");
        }

        function disable() {
            hide();
            disabled = true;
            boundElement.attr("disabled", true);
            offsetElement.addClass("ugc-disabled");
        }

        function setOffset(coord) {
            opts.offset = coord;
            reflow();
        }

        function destroy() {
            boundElement.show();
            offsetElement.off("click.urlentryggc touchstart.urlentryggc");
            container.remove();

            if (shouldReplace) {
                // unwrap element
                replacer.remove('.ugc-dd');
                boundElement.unwrap();
            } else {
                replacer.remove();
            }

            urlentryggcs[spect.id] = null;
        }

        initialize();

        var spect = {
            show: show,
            hide: hide,
            toggle: toggle,
            reflow: reflow,
            //            option: option,
            enable: enable,
            disable: disable,
            offset: setOffset,
            set: function (c) {
                set(c);
                updateOriginalInput();
            },
            get: get,
            destroy: destroy,
            container: container
        };

        spect.id = urlentryggcs.push(spect) - 1;

        return spect;
    }

    /*
    <div class="sp-container" style="position: absolute;top: 182px;left: 649px;z-index: 99999;">URL:<input type="text" style="width: 40em;"><input type="button" value="Save"></div>
    */

    /**
    * checkOffset - get the offset below/above and left/right element depending on screen position
    * Thanks https://github.com/jquery/jquery-ui/blob/master/ui/jquery.ui.datepicker.js
    */
    function getOffset(picker, input) {
        var extraY = 0;
        var dpWidth = picker.outerWidth();
        var dpHeight = picker.outerHeight();
        var inputHeight = input.outerHeight();
        var doc = picker[0].ownerDocument;
        var docElem = doc.documentElement;
        var viewWidth = docElem.clientWidth + $(doc).scrollLeft();
        var viewHeight = docElem.clientHeight + $(doc).scrollTop();
        var offset = input.offset();
        var offsetLeft = offset.left;
        var offsetTop = offset.top;

        offsetTop += inputHeight;

        offsetLeft -=
            Math.min(offsetLeft, (offsetLeft + dpWidth > viewWidth && viewWidth > dpWidth) ?
                Math.abs(offsetLeft + dpWidth - viewWidth) : 0);

        offsetTop -=
            Math.min(offsetTop, ((offsetTop + dpHeight > viewHeight && viewHeight > dpHeight) ?
                Math.abs(dpHeight + inputHeight - extraY) : extraY));

        return {
            top: offsetTop,
            bottom: offset.bottom,
            left: offsetLeft,
            right: offset.right,
            width: offset.width,
            height: offset.height
        };
    }

    /**
    * noop - do nothing
    */
    function noop() {

    }
    /**
    * stopPropagation - makes the code only doing this a little easier to read in line
    */
    function stopPropagation(e) {
        e.stopPropagation();
    }

    /**
    * Create a function bound to a given object
    * Thanks to underscore.js
    */
    function bind(func, obj) {
        var slice = Array.prototype.slice;
        var args = slice.call(arguments, 2);
        return function () {
            return func.apply(obj, args.concat(slice.call(arguments)));
        };
    }

    function throttle(func, wait, debounce) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var throttler = function () {
                timeout = null;
                func.apply(context, args);
            };
            if (debounce) clearTimeout(timeout);
            if (debounce || !timeout) timeout = setTimeout(throttler, wait);
        };
    }

    /**
    * Define a jQuery plugin
    */
    var dataID = "urlentryggc.id";
    $.fn.urlentryggc = function (opts, extra) {

        if (typeof opts == "string") {

            var returnValue = this;
            var args = Array.prototype.slice.call(arguments, 1);

            this.each(function () {
                var spect = urlentryggcs[$(this).data(dataID)];
                if (spect) {
                    var method = spect[opts];
                    if (!method) {
                        throw new Error("urlentryggc: no such method: '" + opts + "'");
                    }

                    if (opts === "get") {
                        returnValue = spect.get();
                    }
                    else if (opts === "container") {
                        returnValue = spect.container;
                    }
                    else if (opts === "option") {
                        returnValue = spect.option.apply(spect, args);
                    }
                    else if (opts === "destroy") {
                        spect.destroy();
                        $(this).removeData(dataID);
                    }
                    else {
                        method.apply(spect, args);
                    }
                }
            });

            return returnValue;
        }

        // Initializing a new instance of urlentryggc
        return this.urlentryggc("destroy").each(function () {
            var options = $.extend({}, $(this).data(), opts);
            var spect = urlentryggc(this, options);
            $(this).data(dataID, spect.id);
        });
    };

    $.fn.urlentryggc.defaults = defaultOpts;

    $.urlentryggc = {};


});

