import React, {useEffect, useState} from 'react';
import { cvtFontSize, colors, Divider } from '../../utils/Utils';
import ListAccordion from '../ListAccordion/ListAccordion';
import ToolSeparator from '../ToolSeparator/ToolSeparator';

// titles[] {title: "Some Title", id: <some id>}
//
const TreeAccordion = ({
	titles,
	listItem,
	openItem,
	expand = -1,
	accordionColor = colors.accordion,
	accordionTextColor = colors.text,
	accordionTitleStyle = { fontSize: cvtFontSize(20), color: colors.darktext },
	arrowColor= colors.darktext,
	titleMargin=8,
}) => {
	const [accordionsExpanded, setAccordionsExpanded] = useState([]);

	useEffect(() => {
		var ae = [];
		for (var ix=0; ix<titles.length; ix++) {
			ae[ix] = false;
		}
		// expand is the .id
		const fnd = titles.findIndex( elem => elem.id === expand);
		if (fnd !== -1) {
			ae[fnd] = true;
			setAccordionsExpanded(ae);
			openItem(titles[fnd].id);
		} else {
			setAccordionsExpanded(ae);
		}
	}, [titles, expand]);
	
	return (
		<>
		<div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
				{titles && titles.map((agroup, aix) => {
					return (
						<React.Fragment
							key={aix}
						>
							<ToolSeparator margin={0}/>
							<ListAccordion
								key={aix}
								expanded={accordionsExpanded[aix]}
								title={agroup.title}
								arrowColor={arrowColor}
								titleMargin={titleMargin}
								titleStyle={{...accordionTitleStyle}}
								outerStyle={accordionsExpanded[aix] ? { flexGrow: 1 } : {}}
								style={{
									flexGrow: 1, 
									color: accordionTextColor,
									backgroundColor: accordionColor,
								}}
								onPress={() => {
									var ae = accordionsExpanded.map((ele, eix) => eix === aix ? !ele : false);
									setAccordionsExpanded(ae);
									if (ae[aix]) {
										openItem(agroup.id);
									}
								}}
							>
								{accordionsExpanded[aix] &&
									listItem(agroup.id)
								}
							</ListAccordion>
						</React.Fragment>
					)
				})}

			</div>
		</>

	);
}

export default TreeAccordion;