import {useEffect, useState} from "react";
import $ from "jquery";
import { genCleanId } from "../../graphs/domutils";
import { useTranslation } from 'react-i18next';

let isChange = false;

const ColorPicker = ({color, disabled=false, onChange, outerStyle={}}) => {
	const [oldColor, setOldColor] = useState(null);
  const [t, i18n] = useTranslation();

	const myId = 'cp_' + genCleanId();

	useEffect(() => {
		$('#'+myId).spectrum("set", color);
	}, [color]);

	useEffect(() => {
		if (disabled) {
			$('#'+myId).spectrum("disable");
		} else {
			$('#'+myId).spectrum("enable");
		}
	}, [disabled]);

	useEffect(() => {
		$('#' + myId).off('change.spectrum').on('change.spectrum', function (e, c) {
			isChange = true;
			if (c) {
				onChange(c.toRgbString());
			} else {	// empty
				onChange('');
			}
		});
		// 'move' - when fiddling in popup control (send to parent)
		// 
		$('#' + myId).off('move.spectrum').on('move.spectrum', function (e, c) {
			if (c) {
				onChange(c.toRgbString());
			} else {
				onChange('');
			}
		});
		$('#' + myId).off('hide.spectrum').on('hide.spectrum', function (e, c) {
			if (!isChange) {
				onChange(oldColor);
			}
		});


	}, [onChange, oldColor]);

	useEffect(() => {
    $('#'+myId).spectrum({
      color: color,
			allowEmpty: true,
			showAlpha: true,
			clickoutFiresChange: false,
			showInput: true,
			chooseText: t("save"),
			preferredFormat: 'hex',
			show: function(c) {
				console.log('show');
				isChange = false;
				setOldColor(c == null ? '' : c.toRgbString());
			},
    });

	}, []);


	return (
		<div style={outerStyle}>
			<input id={myId} disabled={disabled} type="text" />
		</div>
	);
};

export default ColorPicker;