import React, {useState} from "react";
import { useEffect } from "react";
import {FaRegSquareCheck, FaRegSquare} from "react-icons/fa6";
import { colors } from "../../utils/Utils";

const IconCheckbox = ({checked, icon, onClick, disabled=false, style={}}) => {
	const [myIcon, setMyIcon] = useState(icon);

	useEffect(() => {
		let cIcon = React.cloneElement(icon, {
			color: disabled ? 'gray' : 'black',
		})

		setMyIcon(cIcon);
	}, [icon, disabled]);

	return (
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...style}} disabled={disabled} onClick={disabled ? ()=>null: onClick}>
				{checked ? (
					<FaRegSquareCheck style={{ cursor: (disabled ? 'auto' : 'pointer'), color:(disabled ? 'gray' : 'black'), marginRight: 4 }} />
				) : (
					<FaRegSquare style={{ cursor: (disabled ? 'auto' : 'pointer'), color:(disabled ? 'gray' : 'black'), marginRight: 4 }} />
				)}
				{myIcon}
		</div>
	)
}
export default IconCheckbox;