import { memo } from 'react'
import { useDrag } from 'react-dnd'
import { usePreview } from 'react-dnd-preview';

export const dndLabelHeight = 20;

const localstyle = {
  border: '1px dashed #F000B4',
  backgroundColor: 'white',
  borderRadius: 5,
	height: `${dndLabelHeight}px`,
	fontSize: '10px',
	display: 'flex',
	flexDirection: 'column',
  boxShadow: '0px 4px #22222240',
  cursor: 'move',
  marginBottom: '6px',
}
const previewStyle = {
  border: '1px dashed #F000B4',
  backgroundColor: 'white',
  borderRadius: 5,
	fontSize: '10px',
  boxShadow: '0px 4px #22222240',
}

// The styling on this is bizarre.  I dont' know why I have to give bottom and right vs
// width and height.  It ignores both.  Then, if I do set right and bottom to small numbers
// it doesn't size the actual element, it takes those values from the window width/height
// so, below does the math to end up with what we need.  Ican't figure out margins on the
// contained object tho...
export const MyPreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { itemType, item, style } = preview;
  let bottom = window.innerHeight - 20;
  let right = window.innerWidth - 78;
  let newStyle = {...style, 
    bottom: `${bottom}px`, 
    right: `${right}px`, 
    minHeight: '20px',
    height: '20px',
    ...previewStyle
  };

  return (
    <div  style={newStyle}>
      <div style={{ color: 'black', marginLeft: 12, marginTop: 2 }}>{item.name}</div>
    </div>
  )
}

export const DnDLabel = memo(function Box({ name, type }) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type],
  )
  return (
    <div ref={drag} style={{ ...localstyle, opacity }} data-testid="dnd-lbl">
			<div style={{color: 'black', marginTop: 'auto', marginBottom: 'auto'}}>{name}</div>
    </div>
  )
})
